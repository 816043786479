/**
 * Created by andrey on 12.02.2025
 */

var CloseRoundButton = cleverapps.UI.Button.extend({
    ctor: function (options) {
        this.options = options;
        this._super({
            type: {
                button_png: bundles.buttons_main.frames.window_close_round_png,
                button_on_png: bundles.buttons_main.frames.window_close_round_on_png
            },
            onClicked: options.onClicked
        });

        this.setPositionRound(cleverapps.styles.CloseRoundButton);
        this.setLocalZOrder(3);
        this.updateSize();
    },

    updateSize: function () {
        this.setScale(this.baseScale || (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? 0.9 : 1));
    },

    completeAnimationOnResize: function () {
        this.stopAllActions();
        this.setVisible(true);
    },

    hide: function () {
        this.setVisible(false);
    },

    show: function () {
        var scale = this.getScale();
        this.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0, 0.3 * scale),
                new cc.Show(),
                new cc.ScaleTo(0.4, scale).easing(cc.easeBackOut())
            )
        );
    },

    adjustPosition: function () {
        var windowTitle = this.options.window && this.options.window.windowTitle;
        if (windowTitle) {
            this.setPositionRound(cleverapps.styles.CloseRoundButton.x, windowTitle.y);
        }
    }
});

cleverapps.styles.CloseRoundButton = {
    x: { align: "right", dx: 6 },
    y: { align: "top", dy: -150 }
};