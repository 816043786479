/**
 * Created by Andrey Popov on 09.10.20
 */

var RewardsConfig = {
    Packs: [],
    Tournament: {},

    DailyCup: [],
    WeeklyCup: [],
    ClanCup: [],

    BonusWorld: [
        {
            level: 3,
            reward: {
                boosters: {
                    13: 1,
                    14: 1
                }
            }
        },
        {
            level: 8,
            reward: {
                hard: 50,
                boosters: {
                    13: 1,
                    14: 1
                }
            }
        },
        {
            level: 15,
            reward: {
                hard: 200,
                boosters: {
                    13: 1,
                    14: 1,
                    15: 1
                }
            }
        }
    ],

    ShortMeta: {
        5: {
            hard: { factor: 3 }
        },

        9: {
            hard: { factor: 3 }
        }
    },

    PromotionSale: [
        {
            id: 0, product: "gold500", profit: "+60%", reward: { hard: 300 }
        },
        {
            id: 1, product: "gold1800", profit: "+56%", reward: { hard: 1000 }
        },
        {
            id: 2, product: "gold3800", profit: "+53%", reward: { hard: 2000 }
        },
        {
            id: 3, product: "gold6000", profit: "+50%", reward: { hard: 3000 }
        },
        {
            id: 4, product: "gold25000", profit: "+40%", reward: { hard: 10000 }
        }
    ],

    ChainSale: [
        {
            reward: { soft: 300 }
        },
        {
            hardPrice: 300,
            reward: {
                soft: 300
            }
        },
        {
            reward: {
                soft: 100
            }
        },
        {
            reward: {
                soft: 150
            }
        },
        {
            hardPrice: 1000,
            reward: {
                soft: 2000
            }
        },
        {
            reward: {
                hard: 200
            }
        },
        {
            reward: {
                hard: 500
            }
        }
    ],

    ReturnBonus: {
        hard: 150
    },

    TreasureSearchLevelRewards: {
        hard: 4.5,
        tricky: 2,
        bonus: 2,
        default: 1.5
    },

    StickersBookLevelRewards: {
        hard: 3,
        default: 1
    },

    PinballLevelRewards: {
        hard: 4.5,
        tricky: 2,
        bonus: 2,
        default: 1.5
    },

    LevelBasicReward: {
        bonus: 0,
        default: 5,
        tricky: 7,
        hard: 10
    }
};

if (cleverapps.config.type === "battlefield") {
    RewardsConfig.ShortMeta = {
        5: {
            soft: { factor: 3 }
        },

        9: {
            soft: { factor: 3 }
        }
    };

    RewardsConfig.Packs = {
        0: {
            hard: 100,
            soft: 500,
            troopCards: {
                probabilities: [100, 0, 0],
                amount: 5
            }
        },
        1: {
            hard: 500,
            soft: 2500,
            troopCards: {
                probabilities: [0, 100, 0],
                amount: 7
            }
        },
        2: {
            hard: 2000,
            soft: 10000,
            troopCards: {
                probabilities: [0, 0, 100],
                amount: 10
            }
        }
    };
}

if (cleverapps.config.type === "board") {
    RewardsConfig.Tournament = {
        places: [{
            hard: 300
        }, {
            hard: 250
        }, {
            hard: 200
        }, {
            hard: 100
        }, {
            hard: 50
        }]
    };

    RewardsConfig.TreasureSearch = {
        stages: [{
            hard: 10
        }, {
            hard: 20
        }, {
            hard: 30
        }, {
            hard: 40
        }, {
            hard: 50
        }]
    };

    RewardsConfig.DailyTask = {
        easy: [{ hard: 20 }],
        medium: [{ hard: 30 }],
        hard: [{ hard: 40 }],
        completeAll: [{ hard: 50 }]
    };

    RewardsConfig.DailyCup = [
        {
            hard: 400
        },
        {
            hard: 250
        },
        {
            hard: 150
        },
        {
            hard: 50
        },
        {
            hard: 50
        },
        {
            hard: 50
        },
        {
            hard: 50
        },
        {
            hard: 50
        },
        {
            hard: 50
        },
        {
            hard: 50
        }
    ];

    RewardsConfig.Packs = [{
        hard: 800
    }, {
        hard: 2600
    }, {
        hard: 17000
    }];

    RewardsConfig.Subscription = {
        hard: 600
    };

    RewardsConfig.MiniGame = [
        {
            hard: 25
        },

        {
            hard: 50
        }
    ];

    RewardsConfig.MiniGameNovice = [
        {
            hard: 25
        }
    ];

    RewardsConfig.CookieJar = {
        hard: 5
    };

    RewardsConfig.PromotionSale = [
        {
            id: 0,
            hardPrice: 1000,
            profit: "+60%",
            reward: {
                hard: 500,
                boosters: {
                    3: 3
                }
            }
        },
        {
            id: 1,
            hardPrice: 300,
            profit: "+56%",
            reward: {
                hard: 800,
                boosters: {
                    3: 5
                }
            }
        }
    ];

    RewardsConfig.ChainSale = [
        {
            reward: { hard: 10 }
        },
        {
            hardPrice: 300,
            reward: {
                hard: 200,
                boosters: {
                    3: 1
                }
            }
        },
        {
            reward: {
                hard: 100
            }
        },
        {
            reward: {
                hard: 150
            }
        },
        {
            hardPrice: 1000,
            reward: {
                hard: 800,
                boosters: {
                    3: 3
                }
            }
        },
        {
            reward: {
                hard: 200
            }
        },
        {
            reward: {
                hard: 250,
                boosters: {
                    3: 3
                }
            }
        }
    ];

    RewardsConfig.Supplies = {
        hardPrice: 1000,
        rewards: [
            {
                hard: 50
            }, {
                hard: 100
            }, {
                hard: 150,
                boosters: {
                    3: 1
                }
            }, {
                hard: 200
            }, {
                hard: 250,
                boosters: {
                    3: 3
                }
            }, {
                hard: 350
            }, {
                hard: 500,
                boosters: {
                    3: 5
                }
            }
        ]
    };
    RewardsConfig.StickersBook = [
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 350
        },
        {
            hard: 350
        },
        {
            hard: 350
        },
        {
            hard: 350
        }
    ];
}

if (cleverapps.config.type === "match3") {
    RewardsConfig.MiniGame = [
        {
            hard: 25
        },

        {
            boosters: {
                5: 1
            },
            hard: 25
        },

        {
            boosters: {
                5: 1
            }
        },

        {
            hard: 25
        },

        {
            boosters: {
                6: 1
            }
        },

        {
            boosters: {
                6: 1
            },
            hard: 25
        },

        {
            hard: 25
        },

        {
            boosters: {
                7: 1
            }
        },

        {
            boosters: {
                7: 1
            },
            hard: 25
        }
    ];

    RewardsConfig.MiniGameBeginner = [
        {
            unlimitedLives: "30 minutes"
        }
    ];

    RewardsConfig.MiniGameNovice = [
        {
            hard: 25
        }
    ];

    RewardsConfig.Tournament = {
        places: [{
            boosters: {
                5: 2,
                6: 2,
                7: 2
            },
            unlimitedLives: "12 hours"
        }, {
            boosters: {
                6: 1,
                7: 1
            },
            unlimitedLives: "6 hour"
        }, {
            boosters: {
                5: 1,
                6: 1
            },
            unlimitedLives: "1 hour"
        }, {
            boosters: {
                6: 1
            },
            unlimitedLives: "45 minutes",
            exp: 5
        }, {
            boosters: {
                5: 1
            },
            unlimitedLives: "30 minutes",
            exp: 3
        }]
    };

    RewardsConfig.DailyTask = {
        easy: [
            {
                unlimitedLives: "15 minutes"
            }
        ],
        medium: [
            {
                boosters: { 6: 1 },
                exp: 1
            }
        ],
        hard: [
            {
                unlimitedLives: "30 minutes"
            }
        ],
        completeAll: [
            {
                boosters: { 0: 1, 1: 1 },
                exp: 5
            }
        ]
    };

    RewardsConfig.TreasureSearch = {
        stages: [{
            boosters: {
                5: 2
            }
        }, {
            boosters: {
                5: 2,
                6: 1
            },
            unlimitedLives: "3 hours"
        }, {
            boosters: {
                5: 1,
                6: 2,
                7: 1
            }
        }, {
            boosters: {
                5: 3,
                6: 2,
                7: 1
            }
        }, {
            boosters: {
                5: 3,
                6: 3,
                7: 3
            },
            hard: 150
        }]
    };

    RewardsConfig.PrizeCalendar = {
        stages: [
            {
                free: true,
                tileIndex: 0,
                reward: {
                    boosters: { 5: 1 }
                }
            },
            {
                timer: true,
                tileIndex: 1,
                reward: {
                    boosters: { 6: 1 }
                }
            },
            {
                hard: 10,
                tileIndex: 2,
                reward: {
                    unlimitedLives: "10 minutes"
                }
            },
            {
                timer: true,
                tileIndex: 5,
                reward: {
                    boosters: { 5: 1 }
                }
            },
            {
                hard: 30,
                reward: {
                    unlimitedLives: "15 minutes"
                }
            },
            {
                timer: true,
                reward: {
                    boosters: { 6: 1 }
                }
            },
            {
                hard: 60,
                reward: {
                    unlimitedLives: "30 minutes"
                }
            },
            {
                timer: true,
                reward: {
                    boosters: { 7: 1 }
                }
            },
            {
                hard: 100,
                reward: {
                    boosters: { 7: 2 },
                    unlimitedLives: "4 hours"
                }
            }
        ]
    };

    RewardsConfig.DailyCup = [
        {
            unlimitedLives: "1 day",
            boosters: {
                5: 5,
                6: 5,
                7: 5
            }
        },
        {
            unlimitedLives: "6 hours",
            boosters: {
                5: 4,
                6: 4,
                7: 4
            }
        },
        {
            unlimitedLives: "3 hours",
            boosters: {
                5: 3,
                6: 3,
                7: 3
            }
        },
        {
            unlimitedLives: "2 hour",
            boosters: {
                5: 1,
                6: 1,
                7: 1
            }
        },
        {
            boosters: {
                5: 1,
                6: 1
            },
            unlimitedLives: "2 hour"
        },
        {
            boosters: {
                5: 1,
                6: 1
            },
            unlimitedLives: "2 hour"
        },
        {
            boosters: {
                5: 1,
                6: 1
            },
            unlimitedLives: "2 hour"
        },
        {
            boosters: {
                5: 1
            },
            unlimitedLives: "1 hour"
        },
        {
            boosters: {
                5: 1
            },
            unlimitedLives: "1 hour"
        },
        {
            boosters: {
                5: 1
            },
            unlimitedLives: "1 hour"
        }];

    RewardsConfig.Packs = [
        {
            boosters: {
                5: 1,
                6: 1,
                7: 1
            },
            hard: 500,
            unlimitedLives: "1 day"
        },
        {
            boosters: {
                5: 2,
                6: 2,
                7: 2
            },
            hard: 1800,
            unlimitedLives: "3 day"
        },
        {
            boosters: {
                5: 14,
                6: 14,
                7: 14
            },
            hard: 12000,
            unlimitedLives: "9 day"
        }
    ];

    RewardsConfig.Subscription = {
        hard: 300,
        exp: 1,
        unlimitedLives: "2 hours",
        boosters: {
            0: 3
        }
    };

    RewardsConfig.BonusWorld = [
        {
            level: 3,
            reward: {
                unlimitedLives: "1 hour"
            }
        },
        {
            level: 8,
            reward: {
                unlimitedLives: "1 hours",
                exp: 5,
                boosters: {
                    5: 1,
                    6: 1
                }
            }
        },
        {
            level: 15,
            reward: {
                hard: 150,
                unlimitedLives: "24 hours",
                boosters: {
                    5: 1,
                    6: 1,
                    7: 1
                }
            }
        }
    ];

    RewardsConfig.PromotionSale = [
        {
            id: 0, hardPrice: 2000, profit: "150%", reward: { hard: 2000, unlimitedLives: "48 hours", boosters: { 7: 2 } }
        },
        {
            id: 1, hardPrice: 600, profit: "300%", reward: { hard: 1600, unlimitedLives: "24 hours", boosters: { 2: 5 } }
        }
    ];

    RewardsConfig.Supplies = {
        hardPrice: 2000,
        rewards: [{
            unlimitedLives: "12 hours",
            hard: 100,
            boosters: {
                5: 3
            }
        }, {
            unlimitedLives: "12 hours",
            hard: 200,
            boosters: {
                6: 3
            }
        }, {
            unlimitedLives: "12 hours",
            hard: 300,
            boosters: {
                7: 3
            }
        }, {
            unlimitedLives: "12 hours",
            hard: 400,
            boosters: {
                0: 3
            }
        }, {
            unlimitedLives: "12 hours",
            hard: 500,
            boosters: {
                1: 2
            }
        }, {
            unlimitedLives: "12 hours",
            hard: 600,
            boosters: {
                2: 2
            }
        }, {
            unlimitedLives: "72 hours",
            hard: 700,
            boosters: {
                2: 4
            }
        }
        ]
    };

    RewardsConfig.ChainSale = [
        {
            reward: { hard: 30 }
        },
        {
            watchAds: true,
            reward: {
                hard: 50,
                boosters: {
                    5: 1
                }
            }
        },
        {
            hardPrice: 600,
            reward: {
                hard: 400,
                unlimitedLives: "24 hours"
            }
        },
        {
            reward: {
                boosters: {
                    6: 1,
                    7: 1
                }
            }
        },
        {
            watchAds: true,
            reward: {
                boosters: {
                    0: 1,
                    1: 1
                }
            }
        },
        {
            hardPrice: 2000,
            reward: {
                hard: 1000,
                unlimitedLives: "24 hours"
            }
        },
        {
            reward: {
                boosters: {
                    5: 1,
                    6: 1,
                    7: 1
                }
            }
        },
        {
            watchAds: true,
            reward: {
                hard: 1200,
                unlimitedLives: "24 hours",
                boosters: {
                    2: 3
                }
            }
        },
        {
            hardPrice: 10000,
            reward: {
                hard: 4500,
                unlimitedLives: "72 hours",
                exp: 10
            }
        },
        {
            reward: {
                boosters: {
                    5: 8,
                    6: 8,
                    7: 8
                }
            }
        },
        {
            watchAds: true,
            reward: {
                boosters: {
                    0: 8,
                    1: 8,
                    2: 8
                }
            }
        }
    ];

    RewardsConfig.ReturnBonus = {
        unlimitedLives: "6 hours",
        boosters: {
            5: 1,
            6: 1
        }
    };

    RewardsConfig.StickersBook = [
        {
            unlimitedLives: "2 hours"
        },
        {
            boosters: { 5: 1 }
        },
        {
            boosters: { 6: 1 }
        },
        {
            hard: 150
        },
        {
            boosters: { 5: 1, 7: 1 }
        },
        {
            unlimitedLives: "4 hours"
        },
        {
            boosters: { 5: 2, 6: 1 }
        },
        {
            boosters: { 7: 1 }
        },
        {
            boosters: { 6: 1, 7: 1 }
        },
        {
            hard: 300,
            unlimitedLives: "10 hours"
        }
    ];

    RewardsConfig.LevelBasicReward = {
        bonus: 0,
        default: 10,
        tricky: 20,
        hard: 30
    };
}

if (cleverapps.config.type === "solitaire") {
    RewardsConfig.MiniGame = [
        {
            hard: 25,
            randomBoosters: {
                types: [10, 11, 12],
                amount: 1
            }
        },
        {
            hard: 25,
            randomBoosters: {
                types: [10, 11, 12],
                amount: 2
            }
        },
        {
            randomBoosters: {
                types: [10, 11, 12],
                amount: 1
            }
        },
        {
            // betGold: 500,
            randomBoosters: {
                types: [10, 11, 12],
                amount: 2
            }
        }
    ];

    RewardsConfig.MiniGameBeginner = [
        {
            hard: 25
        },
        {
            hard: 50
        }
    ];

    RewardsConfig.MiniGameNovice = [
        {
            hard: 25,
            randomBoosters: {
                types: [10, 11, 12],
                amount: 1
            }
        }
    ];

    RewardsConfig.TreasureSearch = {
        stages: [{
            boosters: {
                10: 1,
                11: 1
            }
        }, {
            unlimitedLives: "2 hours",
            boosters: {
                10: 2,
                11: 1
            }
        }, {
            boosters: {
                10: 2,
                11: 1,
                12: 1
            }
        }, {
            unlimitedLives: "6 hours",
            boosters: {
                10: 2,
                11: 2,
                12: 1
            }
        }, {
            boosters: {
                10: 2,
                11: 2,
                12: 2
            },
            hard: 250
        }]
    };

    RewardsConfig.Tournament = {
        places: [{
            boosters: {
                10: 2,
                11: 2,
                12: 2
            },
            unlimitedLives: "3 hours"
        }, {
            boosters: {
                11: 2,
                12: 2
            },
            unlimitedLives: "1 hour"
        }, {
            boosters: {
                10: 1,
                11: 1,
                12: 1
            },
            unlimitedLives: "30 minutes"
        }, {
            boosters: {
                11: 1,
                12: 1
            },
            unlimitedLives: "15 minutes"
        }, {
            boosters: {
                10: 1,
                11: 1
            },
            unlimitedLives: "15 minutes"
        }]
    };

    RewardsConfig.DailyTask = {
        easy: [
            {
                unlimitedLives: "15 minutes"
            }
        ],
        medium: [
            {
                boosters: {
                    12: 1
                }
            }
        ],
        hard: [
            {
                unlimitedLives: "30 minutes"
            }
        ],
        completeAll: [
            {
                boosters: {
                    10: 1,
                    11: 1
                },
                unlimitedLives: "30 minutes"
            }
        ]
    };

    RewardsConfig.DailyCup = [
        {
            unlimitedLives: "1 day",
            // betGold: 2400,
            boosters: {
                10: 5,
                11: 5,
                12: 5
            }
        },
        {
            unlimitedLives: "6 hours",
            boosters: {
                10: 4,
                11: 4,
                12: 4
            }
        },
        {
            unlimitedLives: "3 hours",
            boosters: {
                10: 3,
                11: 3,
                12: 3
            }
        },
        {
            unlimitedLives: "1 hour",
            boosters: {
                10: 2,
                11: 2,
                12: 2
            }
        },
        {
            unlimitedLives: "30 minutes",
            boosters: {
                10: 1,
                11: 1,
                12: 1
            }
        },
        {
            unlimitedLives: "30 minutes",
            boosters: {
                10: 1,
                11: 1,
                12: 1
            }
        },
        {
            unlimitedLives: "30 minutes",
            boosters: {
                10: 1,
                11: 1,
                12: 1
            }
        },
        {
            unlimitedLives: "15 minutes",
            // betGold: 50,
            boosters: {
                10: 1,
                11: 1,
                12: 1
            }
        },
        {
            unlimitedLives: "15 minutes",
            // betGold: 50,
            boosters: {
                10: 1,
                11: 1,
                12: 1
            }
        },
        {
            unlimitedLives: "15 minutes",
            // betGold: 50,
            boosters: {
                10: 1,
                11: 1,
                12: 1
            }
        }
    ];

    RewardsConfig.Packs = [{
        boosters: {
            10: 2,
            11: 2,
            12: 2
        },
        hard: 200
    }, {
        boosters: {
            10: 6,
            11: 6,
            12: 6
        },
        hard: 800
    }, {
        boosters: {
            10: 30,
            11: 30,
            12: 30
        },
        hard: 8000
    }];

    RewardsConfig.Subscription = {
        boosters: {
            10: 2,
            11: 2,
            12: 2
        },
        hard: 250,
        unlimitedLives: "3 hours"
    };

    RewardsConfig.PromotionSale = [
        {
            id: 0,
            hardPrice: 2000,
            profit: "30%",
            reward: {
                boosters: {
                    10: 7,
                    12: 5
                },
                hard: 1500
            }
        },
        {
            id: 1,
            hardPrice: 600,
            profit: "275%",
            reward: {
                boosters: {
                    11: 5,
                    12: 5
                },
                hard: 1000
            }
        }
    ];

    RewardsConfig.Supplies = {
        hardPrice: 2000,
        rewards: [{
            boosters: {
                10: 1,
                11: 1
            },
            unlimitedLives: "3 hours"
        }, {
            hard: 50,
            boosters: {
                11: 2,
                12: 1
            }
        }, {
            hard: 100,
            boosters: {
                10: 2,
                12: 1
            }
        }, {
            boosters: {
                10: 2,
                11: 2
            },
            unlimitedLives: "3 hours"
        }, {
            boosters: {
                12: 2,
                11: 2
            },
            unlimitedLives: "6 hours"
        }, {
            hard: 150,
            boosters: {
                11: 1,
                12: 1
            }

        }, {
            hard: 300,
            boosters: {
                10: 3,
                12: 3,
                11: 3
            }
        }
        ]
    };

    RewardsConfig.ChainSale = [
        {
            reward: { hard: 100 }
        },
        {
            watchAds: true,
            reward: {
                unlimitedLives: "3 hours",
                boosters: {
                    11: 1
                }
            }
        },
        {
            hardPrice: 600,
            reward: {
                boosters: {
                    12: 3,
                    11: 3
                },
                hard: 100
            }
        },
        {
            reward: {
                boosters: {
                    12: 1,
                    10: 1
                },
                hard: 200
            }
        },
        {
            watchAds: true,
            reward: {
                unlimitedLives: "6 hours",
                boosters: {
                    11: 1
                },
                hard: 200
            }
        },
        {
            hardPrice: 2000,
            reward: {
                boosters: {
                    12: 5,
                    10: 5
                },
                hard: 1000
            }
        },
        {
            reward: {
                boosters: {
                    10: 3,
                    12: 1
                }
            }
        },
        {
            watchAds: true,
            reward: {
                unlimitedLives: "9 hours",
                boosters: {
                    11: 3,
                    12: 1
                }
            }
        }
    ];

    RewardsConfig.ReturnBonus = {
        unlimitedLives: "3 hours",
        boosters: { 11: 2, 12: 2 }
    };

    RewardsConfig.StickersBook = [{
        boosters: { 10: 2 }
    }, {
        hard: 150
    }, {
        boosters: { 11: 2 }
    }, {
        boosters: { 12: 1 }
    }, {
        boosters: { 10: 1, 11: 1 }
    }, {
        boosters: { 10: 1, 12: 1 }
    }, {
        hard: 250
    }, {
        boosters: { 11: 1, 12: 1 }
    }, {
        boosters: { 10: 1, 11: 1, 12: 1 }
    }, {
        hard: 300
    }];

    RewardsConfig.PinballRewards = [
        {
            unlimitedLives: "4 hours",
            boosters: { before_cheap: 1 }
        },
        {
            unlimitedLives: "4 hours",
            boosters: { before_cheap: 1 }
        },
        {
            unlimitedLives: "4 hours",
            boosters: { before_cheap: 1 }
        }
    ];

    RewardsConfig.PinballPack = {
        pinballChips: 15,
        boosters: { before_medium: 3, before_expensive: 1 }
    };
}

if (cleverapps.config.type === "differences") {
    RewardsConfig.Tournament = {
        places: [{
            boosters: {
                8: 3,
                9: 3
            },
            unlimitedLives: "3 hours"
        }, {
            boosters: {
                8: 2,
                9: 2
            },
            unlimitedLives: "1 hour"
        }, {
            boosters: {
                8: 1,
                9: 1
            },
            unlimitedLives: "30 minutes"
        }, {
            boosters: {
                8: 1
            },
            unlimitedLives: "15 minutes"
        }, {
            boosters: {
                9: 1
            },
            unlimitedLives: "15 minutes"
        }]
    };

    RewardsConfig.DailyTask = {
        easy: [
            {
                unlimitedLives: "15 minutes"
            }
        ],
        medium: [
            {
                boosters: {
                    8: 1
                }
            }
        ],
        hard: [
            {
                unlimitedLives: "30 minutes"
            }
        ],
        completeAll: [
            {
                unlimitedLives: "15 minutes",
                boosters: {
                    8: 1,
                    9: 1
                }
            }
        ]
    };

    RewardsConfig.MiniGame = [
        {
            hard: 25,
            randomBoosters: {
                types: [8, 9],
                amount: 1
            }
        },
        {
            hard: 50
        }
    ];

    RewardsConfig.MiniGameBeginner = [
        {
            hard: 25,
            randomBoosters: {
                types: [8, 9],
                amount: 1
            }
        },
        {
            hard: 50
        }
    ];

    RewardsConfig.MiniGameNovice = [
        {
            hard: 25,
            randomBoosters: {
                types: [8, 9],
                amount: 1
            }
        }
    ];

    RewardsConfig.TreasureSearch = {
        stages: [{
            unlimitedLives: "12 hours",
            boosters: {
                8: 1,
                9: 2
            }
        }, {
            unlimitedLives: "12 hours",
            boosters: {
                8: 1,
                9: 2
            }
        }, {
            unlimitedLives: "12 hours",
            boosters: {
                8: 1,
                9: 2
            }
        }, {
            unlimitedLives: "12 hours",
            boosters: {
                8: 1,
                9: 2
            }
        }, {
            unlimitedLives: "12 hours",
            boosters: {
                8: 1,
                9: 2
            }
        }]
    };

    RewardsConfig.DailyCup = [
        {
            unlimitedLives: "1 day",
            boosters: {
                2: 1
            }
        },
        {
            unlimitedLives: "6 hours",
            boosters: {
                1: 1
            }
        },
        {
            unlimitedLives: "3 hours",
            boosters: {
                0: 1
            }
        },
        {
            unlimitedLives: "1 hour"
        },
        {
            unlimitedLives: "30 minutes"
        },
        {
            unlimitedLives: "30 minutes"
        },
        {
            unlimitedLives: "30 minutes"
        },
        {
            unlimitedLives: "15 minutes"
        },
        {
            unlimitedLives: "15 minutes"
        },
        {
            unlimitedLives: "15 minutes"
        }
    ];

    RewardsConfig.Packs = [{
        boosters: {
            8: 2,
            9: 2
        },
        hard: 200
    }, {
        boosters: {
            8: 6,
            9: 6
        },
        hard: 800
    }, {
        boosters: {
            8: 30,
            9: 30
        },
        hard: 8000
    }];

    RewardsConfig.Subscription = {
        unlimitedLives: "3 hours",
        hard: 250,
        boosters: {
            8: 3,
            9: 3
        }
    };

    RewardsConfig.ReturnBonus = {
        unlimitedLives: "3 hours",
        boosters: { 8: 2, 9: 2 }
    };
}

if (cleverapps.config.name === "olympics") {
    RewardsConfig.Tournament = {
        places: [{
            hard: 300,
            unlimitedLives: "1 hour"
        }, {
            hard: 250,
            unlimitedLives: "45 minutes"
        }, {
            hard: 200,
            unlimitedLives: "30 minutes"
        }, {
            hard: 100,
            unlimitedLives: "15 minutes"
        }, {
            hard: 50,
            unlimitedLives: "5 minutes"
        }]
    };

    RewardsConfig.Subscription = {
        hard: 600,
        unlimitedLives: "3 hours"
    };

    RewardsConfig.TreasureSearch = {
        stages: [{
            unlimitedLives: "12 hours"
        }, {
            unlimitedLives: "12 hours"
        }, {
            unlimitedLives: "12 hours"
        }, {
            unlimitedLives: "12 hours"
        }, {
            unlimitedLives: "12 hours"
        }]
    };

    RewardsConfig.WeeklyCup = [
        {
            hard: 3000
        },
        {
            hard: 2000
        },
        {
            hard: 1000
        },
        {
            hard: 200
        },
        {
            hard: 200
        },
        {
            hard: 200
        },
        {
            hard: 200
        },
        {
            hard: 200
        },
        {
            hard: 200
        },
        {
            hard: 200
        }
    ];
}

if (cleverapps.config.name === "scramble") {
    RewardsConfig.Tournament = {
        places: [{
            boosters: {
                13: 2,
                14: 2,
                15: 2
            }
        }, {
            boosters: {
                14: 2,
                15: 2
            }
        }, {
            boosters: {
                13: 1,
                14: 1,
                15: 1
            }
        }, {
            boosters: {
                14: 1,
                15: 1
            }
        }, {
            boosters: {
                13: 1,
                14: 1
            }
        }]
    };

    RewardsConfig.Packs = [{
        boosters: {
            13: 2,
            14: 2,
            15: 2
        },
        hard: 200
    }, {
        boosters: {
            13: 6,
            14: 6,
            15: 6
        },
        hard: 800
    }, {
        boosters: {
            13: 30,
            14: 30,
            15: 30
        },
        hard: 8000
    }];

    RewardsConfig.DailyCup = [
        {
            boosters: {
                13: 5,
                14: 5,
                15: 5
            },
            hard: 300
        },
        {
            boosters: {
                13: 4,
                14: 4,
                15: 4
            },
            hard: 200
        },
        {
            boosters: {
                13: 3,
                14: 3,
                15: 3
            },
            hard: 100
        },
        {
            boosters: {
                13: 2,
                14: 2,
                15: 2
            },
            hard: 50
        },
        {
            boosters: {
                13: 1,
                14: 1,
                15: 1
            },
            hard: 50
        },
        {
            boosters: {
                13: 1,
                14: 1,
                15: 1
            },
            hard: 50
        },
        {
            boosters: {
                13: 1,
                14: 1,
                15: 1
            },
            hard: 50
        },
        {
            boosters: {
                13: 1,
                14: 1,
                15: 1
            }
        },
        {
            boosters: {
                13: 1,
                14: 1,
                15: 1
            }
        },
        {
            boosters: {
                13: 1,
                14: 1,
                15: 1
            }
        }
    ];

    RewardsConfig.Subscription = {
        hard: 250,
        boosters: {
            13: 2,
            14: 2,
            15: 2
        }
    };

    RewardsConfig.TreasureSearch = {
        stages: [{
            hard: 10
        }, {
            boosters: {
                13: 1
            }
        }, {
            boosters: {
                13: 2
            }
        }, {
            boosters: {
                14: 1
            }
        }, {
            boosters: {
                15: 1
            }
        }]
    };

    RewardsConfig.CookieJar = {
        hard: 15
    };

    RewardsConfig.ReturnBonus = {
        boosters: {
            13: 1,
            14: 1,
            15: 1
        }
    };

    RewardsConfig.Butterfly = [
        {
            hard: 10,
            boosters: {
                13: 1
            }
        },
        {
            hard: 30,
            boosters: {
                14: 1
            }
        },
        {
            hard: 50,
            boosters: {
                15: 1
            }
        }
    ];

    RewardsConfig.PromotionSale = [
        {
            id: 0,
            hardPrice: 2000,
            profit: "+60%",
            reward: {
                hard: 300,
                boosters: {
                    3: 5,
                    16: 5
                }
            }
        },
        {
            id: 1,
            hardPrice: 600,
            profit: "+56%",
            reward: {
                hard: 300,
                boosters: {
                    14: 3,
                    15: 3
                }
            }
        }
    ];

    RewardsConfig.ChainSale = [
        {
            reward: { hard: 10 }
        },
        {
            hardPrice: 600,
            reward: {
                hard: 100,
                boosters: {
                    13: 1
                }
            }
        },
        {
            reward: {
                boosters: {
                    16: 1
                }
            }
        },
        {
            reward: {
                hard: 50,
                boosters: {
                    13: 1
                }
            }
        },
        {
            hardPrice: 2000,
            reward: {
                hard: 250,
                boosters: {
                    3: 3,
                    15: 3
                }
            }
        },
        {
            reward: {
                hard: 200,
                boosters: {
                    14: 3
                }
            }
        },
        {
            reward: {
                hard: 250,
                boosters: {
                    16: 1
                }
            }
        }
    ];

    RewardsConfig.Supplies = {
        hardPrice: 2000,
        rewards: [
            {
                hard: 30
            }, {
                hard: 80,
                boosters: {
                    13: 1
                }
            }, {
                hard: 100,
                boosters: {
                    14: 1
                }
            }, {
                hard: 150,
                boosters: {
                    16: 1
                }
            }, {
                hard: 200,
                boosters: {
                    15: 1
                }
            }, {
                hard: 300,
                boosters: {
                    3: 1
                }
            }, {
                boosters: {
                    13: 3,
                    14: 3,
                    15: 3
                }
            }
        ]
    };

    RewardsConfig.StickersBook = [
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 350
        },
        {
            hard: 350
        },
        {
            hard: 350
        },
        {
            hard: 350
        }
    ];
}

if (cleverapps.config.type === "merge") {
    RewardsConfig.ReturnBonus = {
        energy: 100,
        worker: "6 hour"
    };

    RewardsConfig.Packs = [
        {
            hard: 50,
            energy: 200
        },
        {
            hard: 100,
            energy: 350,
            wands: 250,
            worker: "3 day"
        },
        {
            hard: 1000,
            energy: 1500,
            wands: 500,
            soft: 3000,
            worker: "5 day"
        }
    ];

    RewardsConfig.LivesFeast = {
        places: [{
            unit: [
                { code: "treasure", stage: 2, amount: 1 },
                { code: "energytree", stage: 2, amount: 1 }
            ]
        }, {
            unit: [
                { code: "treasure", stage: 1, amount: 1 },
                { code: "energytree", stage: 1, amount: 1 }
            ]
        }, {
            unit: [
                { code: "treasure", stage: 0, amount: 1 },
                { code: "energytree", stage: 0, amount: 1 }
            ]
        }],

        offer: {
            energy: 250,
            worker: Product.FormatTimePeriod("1 day", true)
        }
    };

    RewardsConfig.SoftFeast = {
        places: [{
            unit: [
                { code: "treasure", stage: 2, amount: 1 },
                { code: "coinstree", stage: 2, amount: 1 }
            ]
        }, {
            unit: [
                { code: "treasure", stage: 1, amount: 1 },
                { code: "coinstree", stage: 1, amount: 1 }
            ]
        }, {
            unit: [
                { code: "treasure", stage: 0, amount: 1 },
                { code: "coinstree", stage: 0, amount: 1 }
            ]
        }],

        offer: {
            soft: 1000,
            energy: 100
        }
    };

    RewardsConfig.KrakenFeast = {
        places: [{
            unit: { code: "energytree", stage: 2, amount: 1 }
        }, {
            unit: { code: "energytree", stage: 1, amount: 1 }
        }, {
            unit: { code: "energytree", stage: 0, amount: 1 }
        }],

        offer: {
            kraken: true,
            unit: [
                { code: "wands", stage: 4, amount: 2 },
                { code: "sacks", stage: 2, amount: 3 }
            ]
        }
    };

    RewardsConfig.ExpeditionFeast = {
        places: [{
            unit: { code: "treasure", stage: 2, amount: 1 }
        }, {
            unit: { code: "treasure", stage: 1, amount: 1 }
        }, {
            unit: { code: "treasure", stage: 0, amount: 1 }
        }]
    };

    RewardsConfig.SnailFeast = {
        places: [{
            unit: [{ code: "worker", stage: 3, amount: 2 },
                { code: "sacks", stage: 2, amount: 1 },
                { code: "coins", stage: 5, amount: 1 }]
        }, {
            unit: [{ code: "wands", stage: 2, amount: 3 },
                { code: "sacks", stage: 1, amount: 1 },
                { code: "coins", stage: 1, amount: 1 }]
        }, {
            unit: [{ code: "worker", stage: 1, amount: 1 },
                { code: "sacks", stage: 0, amount: 1 }]
        }]
    };

    RewardsConfig.BuildPass = {
        offer: {
            unit: [
                { code: "crystal", stage: 3, amount: 2 },
                {
                    code: "bppointsstar", stage: 0, amount: 1, pointsValue: 250
                }
            ]
        }
    };

    RewardsConfig.SalePass = {
        offer: {
            unit: [
                {
                    code: "bppointscrystal", stage: 0, amount: 1, pointsValue: 250
                }
            ],
            soft: 1200
        }
    };

    RewardsConfig.CollectionsPass = {
        offer: {
            hard: 500,
            soft: 500,
            unit: [{ code: "crystal", stage: 3, amount: 3 }, { code: "clsack", stage: 0, amount: 5 }]
        }
    };

    RewardsConfig.Subscription = {
        worker: {
            subscription: true
        },
        energy: 100,
        unit: { code: "treasure", stage: 2, amount: 1 }
    };

    RewardsConfig.SubscriptionInstant = {
        energy: 50,
        unit: { code: "treasure", stage: 1, amount: 1 }
    };

    RewardsConfig.DragoniaPack = {
        hard: 1200,
        unit: [{ code: "dragonpack", stage: 0, amount: 1 }]
    };

    RewardsConfig.Dragonia2Pack = {
        hard: 1200,
        unit: [{ code: "dr2dragonpack", stage: 0, amount: 1 }]
    };

    RewardsConfig.BlackFridayPack = {
        hard: 800,
        energy: 300,
        unit: [{ code: "dr2dragonpack", stage: 0, amount: 1 }]
    };

    RewardsConfig.BlackFridayPackMain = {
        hard: 500,
        energy: 500,
        unit: [{ code: "herochest", stage: 2, amount: 3 }, { code: "worker", stage: 3, amount: 3 }]
    };

    RewardsConfig.UnderseaPack = {
        hard: 1200,
        unit: [{ code: "underseapack", stage: 0, amount: 1 }]
    };

    RewardsConfig.Undersea2Pack = {
        hard: 1200,
        unit: [{ code: "sea2pack", stage: 0, amount: 1 }]
    };

    RewardsConfig.ClanCup = [{
        soft: 800
    }, {
        soft: 400
    }, {
        soft: 270
    }, {
        soft: 200
    }, {
        soft: 130
    }];

    RewardsConfig.SnailPack = {
        unit: [{
            code: "seasnailhouse", stage: 0, amount: 3
        }]
    };

    RewardsConfig.SnailHouseDragoniaPack = {
        unit: [{
            code: "drsnailhouse", stage: 0, amount: 3
        }]
    };

    RewardsConfig.RapunzelPack = {
        hard: 800,
        energy: 300
    };

    RewardsConfig.XmasPack = {
        hard: 800,
        energy: 300,
        unit: [{ code: "dr2dragonpack", stage: 0, amount: 1 }]
    };

    RewardsConfig.ChinaPack = {
        hard: 1200,
        unit: [{ code: "dr2dragonpack", stage: 0, amount: 1 }]
    };

    RewardsConfig.SpringPack = {
        hard: 1200,
        unit: [{ code: "dr2dragonpack", stage: 0, amount: 1 }]
    };

    RewardsConfig.EasterPack = {
        hard: 800,
        energy: 300
    };

    RewardsConfig.DailyTask = {
        easy: [
            {
                soft: 15
            }
        ],
        medium: [
            {
                soft: 30
            }
        ],
        hard: [
            {
                soft: 45
            }
        ],
        completeAll: [
            {
                unit: { code: "dailytaskchest", stage: 0, amount: 1 }
            }
        ]
    };

    (function () {
        if (cleverapps.config.features.includes("stickers_book")) {
            RewardsConfig.DailyTask.easy[0].stickers = 1;
            RewardsConfig.DailyTask.medium[0].stickers = 2;
            RewardsConfig.DailyTask.completeAll[0].stickers = 3;
        }
    }());

    RewardsConfig.PromotionSale = [
        {
            id: 0, hardPrice: 1000, profit: "150%", reward: { energy: 500, hard: 150, unit: [{ code: "energycup", stage: 2, amount: 3 }] }
        },
        {
            id: 1, hardPrice: 300, profit: "300%", reward: { energy: 500, soft: 500, unit: { code: "energytree", stage: 2, amount: 1 } }
        }
    ];

    RewardsConfig.ClPromotionSale = [
        {
            id: 0,
            hardPrice: 1000,
            profit: "150%",
            reward: { unit: [{ code: "clstrawberry", stage: 3, amount: 5 }, { code: "clsimplechest", stage: 2, amount: 3 }, { code: "clsack", stage: 0, amount: 5 }] }
        },
        {
            id: 1,
            hardPrice: 300,
            profit: "300%",
            reward: {
                hard: 150,
                unit: [{ code: "clpaychest", stage: 2, amount: 1 }, { code: "crystal", stage: 3, amount: 3 }]
            }
        }
    ];

    RewardsConfig.PrPromotionSale = [
        {
            id: 0,
            hardPrice: 1000,
            profit: "150%",
            reward: {
                energy: 300,
                worker: "3 days",
                unit: [{ code: "caravanbox", stage: 2, amount: 1 }, { code: "crystal", stage: 3, amount: 3 }]
            }
        },
        {
            id: 1,
            hardPrice: 300,
            profit: "300%",
            reward: {
                unit: [{ code: "worker", stage: 4, amount: 3 }, { code: "magicplant", stage: 8, amount: 1 }, { code: "herochest", stage: 2, amount: 5 }],
                hard: 500
            }
        }
    ];

    RewardsConfig.Supplies = {
        hardPrice: 1000,
        rewards: [{
            energy: 90,
            soft: 200,
            unit: [{ code: "energycup", stage: 2, amount: 1 }]
        }, {
            energy: 90,
            hard: 30,
            unit: [{ code: "treasure", stage: 2, amount: 1 }]
        }, {
            energy: 90,
            soft: 300,
            unit: [{ code: "crystal", stage: 3, amount: 1 }]
        }, {
            energy: 90,
            hard: 50,
            unit: [{ code: "herochest", stage: 2, amount: 1 }]
        }, {
            energy: 90,
            soft: 400,
            unit: [{ code: "worker", stage: 4, amount: 1 }]
        }, {
            energy: 90,
            hard: 70,
            unit: [{ code: "seasonchest", stage: 2, amount: 1 }]
        }, {
            energy: 150,
            hard: 100,
            soft: 500,
            worker: "12 hour"
        }]
    };

    RewardsConfig.ClSupplies = {
        rewards: [{
            soft: 200,
            unit: [{ code: "clsack", stage: 0, amount: 1 }, { code: "clsimplechest", stage: 0, amount: 1 }]
        }, {
            unit: [{ code: "clstrawberry", stage: 3, amount: 1 }, { code: "clsimplechest", stage: 1, amount: 1 }, { code: "clsack", stage: 0, amount: 2 }]
        }, {
            soft: 300,
            unit: [{ code: "crystal", stage: 2, amount: 1 }, { code: "clstrawberry", stage: 4, amount: 3 }]
        }, {
            hard: 50,
            unit: [{ code: "clsimplechest", stage: 2, amount: 1 }, { code: "clsack", stage: 0, amount: 3 }]
        }, {
            soft: 400,
            unit: [{ code: "clpaychest", stage: 0, amount: 1 }, { code: "clstrawberry", stage: 3, amount: 3 }]
        }, {
            hard: 70,
            unit: [{ code: "clpaychest", stage: 1, amount: 1 }, { code: "clsack", stage: 0, amount: 5 }]
        }, {
            hard: 100,
            soft: 500,
            unit: [{ code: "clpaychest", stage: 2, amount: 1 }, { code: "clstrawberry", stage: 3, amount: 5 }]
        }]
    };

    RewardsConfig.ChainSale = [
        {
            reward: { soft: 50 }
        },
        {
            watchAds: true,
            reward: {
                energy: 10,
                soft: 80
            }
        },
        {
            hardPrice: 300,
            reward: {
                energy: 150,
                soft: 350
            }
        },
        {
            reward: {
                hard: 70,
                worker: "3 hour"
            }
        },
        {
            watchAds: true,
            reward: {
                soft: 200,
                wands: 50
            }
        },
        {
            hardPrice: 1000,
            reward: {
                soft: 1000,
                energy: 250,
                unit: [{ code: "worker", stage: 4, amount: 2 }]
            }
        },
        {
            reward: {
                wands: 100,
                hard: 150
            }
        },
        {
            watchAds: true,
            reward: {
                worker: "12 hour",
                hard: 300,
                energy: 300
            }
        },
        {
            hardPrice: 5000,
            reward: {
                hard: 500,
                energy: 500,
                unit: [{ code: "worker", stage: 4, amount: 5 }]
            }
        },
        {
            reward: {
                wands: 250,
                soft: 500,
                unit: [{ code: "crystal", stage: 3, amount: 5 }]
            }
        },
        {
            watchAds: true,
            reward: {
                worker: "7 day",
                hard: 1000,
                energy: 550
            }
        }
    ];

    RewardsConfig.ClChainSale = [
        {
            reward: {
                unit: [{
                    code: "clstrawberry",
                    stage: 0,
                    amount: 1
                }]
            }
        },
        {
            watchAds: true,
            reward: {
                unit: [{ code: "clsack", stage: 0, amount: 1 }, { code: "clstrawberry", stage: 4, amount: 2 }]
            }
        },
        {
            hardPrice: 300,
            reward: {
                soft: 350,
                unit: [{ code: "clsimplechest", stage: 2, amount: 2 }]
            }
        },
        {
            reward: {
                unit: [{ code: "clsimplechest", stage: 2, amount: 1 }, { code: "clstrawberry", stage: 2, amount: 2 }]
            }
        },
        {
            watchAds: true,
            reward: {
                unit: [{ code: "clpaychest", stage: 1, amount: 1 }, { code: "clsack", stage: 0, amount: 2 }]
            }
        },
        {
            hardPrice: 1000,
            reward: {
                soft: 1000,
                unit: [{ code: "clstrawberry", stage: 3, amount: 3 }, { code: "crystal", stage: 3, amount: 3 }]
            }
        },
        {
            reward: {
                unit: [{ code: "clsack", stage: 0, amount: 3 }, { code: "clstrawberry", stage: 3, amount: 2 }]
            }
        },
        {
            watchAds: true,
            reward: {
                unit: [{ code: "clsimplechest", stage: 2, amount: 3 }, { code: "clpaychest", stage: 1, amount: 1 }, { code: "clstrawberry", stage: 3, amount: 3 }]
            }
        },
        {
            hardPrice: 5000,
            reward: {
                hard: 300,
                unit: [{ code: "clpaychest", stage: 2, amount: 3 }, { code: "crystal", stage: 3, amount: 5 }]
            }
        },
        {
            reward: {
                unit: [{ code: "clsack", stage: 0, amount: 5 }, { code: "clstrawberry", stage: 3, amount: 5 }, { code: "clsimplechest", stage: 2, amount: 3 }]
            }
        },
        {
            watchAds: true,
            reward: {
                unit: [{ code: "clsimplechest", stage: 2, amount: 3 }, { code: "clpaychest", stage: 2, amount: 3 }, { code: "clstrawberry", stage: 3, amount: 5 }]
            }
        }
    ];

    RewardsConfig.StickersBook = [{
        boosters: { 10: 2 }
    }, {
        hard: 150
    }, {
        boosters: { 11: 2 }
    }, {
        boosters: { 12: 1 }
    }, {
        boosters: { 10: 1, 11: 1 }
    }, {
        boosters: { 10: 1, 12: 1 }
    }, {
        hard: 250
    }, {
        boosters: { 11: 1, 12: 1 }
    }, {
        boosters: { 10: 1, 11: 1, 12: 1 }
    }, {
        hard: 300
    }];
}

if (cleverapps.config.type === "tile3") {
    RewardsConfig.MiniGame = [
        {
            soft: 50,
            randomBoosters: {
                types: [17, 18],
                amount: 1
            }
        },
        {
            soft: 50,
            randomBoosters: {
                types: [17, 18],
                amount: 2
            }
        },
        {
            randomBoosters: {
                types: [17, 18, 23],
                amount: 1
            }
        },
        {
            randomBoosters: {
                types: [17, 18, 23],
                amount: 2
            }
        }
    ];

    RewardsConfig.MiniGameBeginner = [
        {
            soft: 50
        },
        {
            soft: 75
        }
    ];

    RewardsConfig.MiniGameNovice = [
        {
            soft: 50,
            randomBoosters: {
                types: [17, 18, 23],
                amount: 1
            }
        }
    ];
    RewardsConfig.DailyTask = {
        easy: [
            {
                unlimitedLives: "15 minutes"
            }
        ],
        medium: [
            {
                unlimitedLives: "15 minutes"
            }
        ],
        hard: [
            {
                unlimitedLives: "15 minutes"
            }
        ],
        completeAll: [
            {
                unlimitedLives: "15 minutes"
            }
        ]
    };

    RewardsConfig.Tournament = {
        places: [{
            soft: 300,
            unlimitedLives: "3 hours"
        }, {
            soft: 200,
            unlimitedLives: "1 hour"
        }, {
            soft: 100,
            unlimitedLives: "30 minutes"
        }, {
            unlimitedLives: "15 minutes"
        }, {
            unlimitedLives: "15 minutes"
        }]
    };

    RewardsConfig.Packs = [
        {
            hard: 200,
            soft: 800
        },
        {
            hard: 800,
            soft: 2500
        },
        {
            hard: 5000,
            soft: 10000
        }
    ];

    RewardsConfig.PromotionSale = [
        {
            id: 0, hardPrice: 1000, profit: "150%", reward: { hard: 150, soft: 2000, boosters: { 23: 7 } }
        },
        {
            id: 1, hardPrice: 300, profit: "300%", reward: { hard: 400, soft: 3000, boosters: { 18: 10 } }
        }
    ];

    RewardsConfig.ChainSale = [
        {
            reward: { soft: 300 }
        },
        {
            watchAds: true,
            reward: {
                boosters: {
                    18: 1,
                    17: 1
                } 
            }
        },
        {
            hardPrice: 300,
            reward: {
                soft: 300,
                boosters: {
                    17: 3,
                    23: 3
                }
            }
        },
        {
            reward: {
                soft: 100,
                boosters: {
                    18: 1,
                    23: 1
                }
            }
        },
        {
            watchAds: true,
            reward: {
                soft: 150,
                boosters: {
                    18: 3,
                    17: 1
                }
            }
        },
        {
            hardPrice: 1000,
            reward: {
                soft: 2000,
                boosters: {
                    23: 5,
                    18: 5
                }
            }
        },
        {
            reward: {
                hard: 200,
                lives: 3
            }
        },
        {
            watchAds: true,
            reward: {
                boosters: {
                    18: 3,
                    17: 2,
                    23: 1
                } 
            }
        }
    ];
    RewardsConfig.Supplies = {
        hardPrice: 1000,
        rewards: [{
            boosters: { 17: 1, 18: 1 },
            unlimitedLives: "3 hours"
        }, {
            soft: 150,
            boosters: { 18: 2, 23: 1 }
        }, {
            hard: 100,
            boosters: { 17: 3, 23: 1 }
        }, {
            unlimitedLives: "3 hours",
            boosters: { 18: 2, 23: 1 }
        }, {
            unlimitedLives: "6 hours",
            boosters: { 17: 2, 18: 1 }
        }, {
            unlimitedLives: "6 hours",
            boosters: { 18: 3, 23: 2 }
        }, {
            hard: 350,
            soft: 1000,
            boosters: { 18: 3, 23: 3 }
        }
        ]
    };

    RewardsConfig.StickersBook = [{
        boosters: { 17: 1 }
    }, {
        soft: 300
    }, {
        boosters: { 18: 2 }
    }, {
        boosters: { 23: 1 }
    }, {
        boosters: { 17: 1, 18: 1 }
    }, {
        boosters: { 18: 1, 23: 1 }
    }, {
        soft: 500
    }, {
        boosters: { 18: 1, 23: 2 }
    }, {
        boosters: { 23: 2 }
    }, {
        soft: 700
    }
    ];

    RewardsConfig.ReturnBonus = {
        unlimitedLives: "3 hours",
        boosters: { 17: 2, 23: 2 }
    };

    RewardsConfig.LevelBasicReward = {
        default: 3,
        hard: 5
    };
}

if (cleverapps.config.type === "blocks") {
    RewardsConfig.PromotionSale = [
        {
            id: 0, product: "gold500", profit: "+78%", reward: { hard: 50, soft: 500 }
        },
        {
            id: 1, product: "gold1800", profit: "+52%", reward: { hard: 150, soft: 1500 }
        },
        {
            id: 2, product: "gold3800", profit: "+45%", reward: { hard: 300, soft: 3000 }
        },
        {
            id: 3, product: "gold6000", profit: "+43%", reward: { hard: 550, soft: 4500 }
        },
        {
            id: 4, product: "gold25000", profit: "+40%", reward: { hard: 3000, soft: 12000 }
        }
    ];
    RewardsConfig.TreasureSearch = {
        stages: [{
            boosters: {
                cheap: 1
            }
        }, {
            unlimitedLives: "1 hours",
            boosters: {
                medium: 1
            }
        }, {
            boosters: {
                cheap: 2,
                expensive: 1
            }
        }, {
            unlimitedLives: "3 hours",
            boosters: {
                cheap: 1,
                medium: 1,
                expensive: 1
            }
        }, {
            boosters: {
                cheap: 2,
                medium: 2,
                expensive: 1
            },
            soft: 100
        }]
    };

    RewardsConfig.Tournament = {
        places: [{
            hard: 300
        }, {
            hard: 250
        }, {
            hard: 200
        }, {
            hard: 100
        }, {
            hard: 50
        }]
    };

    RewardsConfig.ReturnBonus = {
        unlimitedLives: "3 hours",
        boosters: { 20: 2, 21: 2 }
    };

    RewardsConfig.StickersBook = [{
        boosters: { cheap: 1 }
    }, {
        boosters: { medium: 1 }
    }, {
        soft: 250
    }, {
        boosters: { cheap: 1, medium: 1 }
    }, {
        boosters: { cheap: 2, medium: 2 }
    }, {
        soft: 300
    }, {
        boosters: { expensive: 1 }
    }, {
        boosters: { medium: 2, expensive: 1 }
    }, {
        boosters: { cheap: 1, expensive: 2 }
    }, {
        soft: 500
    }
    ];

    RewardsConfig.DailyTask = {
        easy: [
            {
                unlimitedLives: "15 minutes"
            }
        ],
        medium: [
            {
                unlimitedLives: "15 minutes"
            }
        ],
        hard: [
            {
                unlimitedLives: "15 minutes"
            }
        ],
        completeAll: [
            {
                unlimitedLives: "15 minutes"
            }
        ]
    };

    RewardsConfig.MiniGame = [
        {
            soft: 50,
            randomBoosters: {
                types: [20, 22],
                amount: 1
            }
        },
        {
            soft: 50,
            randomBoosters: {
                types: [20, 22],
                amount: 2
            }
        },
        {
            randomBoosters: {
                types: [20, 21, 22],
                amount: 1
            }
        },
        {
            randomBoosters: {
                types: [20, 21, 22],
                amount: 2
            }
        }
    ];

    RewardsConfig.MiniGameBeginner = [
        {
            soft: 50
        },
        {
            soft: 75
        }
    ];

    RewardsConfig.MiniGameNovice = [
        {
            soft: 50,
            randomBoosters: {
                types: [20, 21, 22],
                amount: 1
            }
        }
    ];

    RewardsConfig.Weekly_Tournament = [
        { unlimitedLives: "3 hours", boosters: { cheap: 1 } },
        { soft: 50, boosters: { medium: 1 } },
        { hard: 10, boosters: { cheap: 1, medium: 1 } },
        { soft: 150, unlimitedLives: "24 hours", boosters: { cheap: 1 } },
        { hard: 20, boosters: { medium: 1, expensive: 1 } }
    ];
}

if (cleverapps.config.name === "wordsoup") {
    RewardsConfig.ReturnBonus = {
        homeStar: 10,
        hard: 150
    };
}

if (cleverapps.config.name === "woodenblock") {
    RewardsConfig.BoosterOffer = {
        boosters: {
            expensive: 10
        }
    };
    RewardsConfig.Packs = {
        0: {
            hard: 150,
            soft: 500,
            boosters: {
                expensive: 1,
                cheap: 1
            }
        },
        1: {
            hard: 500,
            soft: 1500,
            unlimitedLives: "48 hours",
            boosters: {
                medium: 1
            }
        },
        2: {
            hard: 3500,
            soft: 10000,
            boosters: {
                expensive: 15,
                medium: 15
            }
        }
    };
}
