/**
 * Created by mac on 7/26/18
 */

var MissionWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission, options) {
        this.mission = mission;

        options = options || {};
        this.options = options;

        this.name = options.name || mission.getName() + "_missionwindow";
        this.bundleName = options.bundleName || "mission_window";

        options.styles = cleverapps.overrideStyles(options.styles || {}, {
            windowShowUpAnimation: { name: "slideUp", force: true }
        }, true);

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            cleverapps.overrideStyles(options.styles, cleverapps.styles.MissionWindow.verticalWindow);
        }

        this._super({
            name: options.name,
            content: this.createContent(),
            contentPadding: cleverapps.styles.MissionWindow.contentPadding,
            noBackground: true,
            closeButton: !this.tableCloseButton,
            styles: options.styles,
            notCloseByTouchInShadow: true,
            offerWindowButton: options.offerWindowButton,
            openSound: options.openSound
        });

        this.mission.updateCompetitionResults();

        Mission.logEvent(cleverapps.EVENTS.MISSION_OPEN, this.mission.getName());
    },

    createContent: function () {
        var styles = cleverapps.styles.MissionWindow;
        var mission = this.mission;

        var table = this.createTable();
        table.setAnchorPoint(0.5, 0.5);

        var titleSlot = this.backgroundSpine.getSlot("slot_title");
        var helpSlot = this.backgroundSpine.getSlot("slot_help");
        var timerSlot = this.backgroundSpine.getSlot("slot_timer");
        var closeSlot = this.backgroundSpine.getSlot("slot_close");
        var clockSlot = this.backgroundSpine.getSlot("slot_clock");

        var mainTitle = cleverapps.UI.generateOnlyText(this.options.title || ("Missions." + mission.getName() + ".Title"), cleverapps.styles.FONTS.MISSION_MAIN_TITLE_TEXT);
        titleSlot.addChild(mainTitle);
        mainTitle.setPositionRound(titleSlot.width / 2, titleSlot.height / 2);
        mainTitle.fitTo(titleSlot.width, titleSlot.height);

        var helpButton = new cleverapps.UI.HelpButton({
            type: cleverapps.styles.UI.Button.Images.help_button_light,
            callback: function () {
                new GuideWindow(this.getGuideOptions());
            }.bind(this)
        });
        helpSlot.addChild(helpButton);
        helpButton.setPositionRound(helpSlot.width / 2, helpSlot.height / 2);
        helpButton.setScale(0.85);
        helpButton.baseScale = helpButton.scale;

        if (closeSlot) {
            var closeButton = this.tableCloseButton = new CloseRoundButton({ onClicked: this.close.bind(this) });
            closeSlot.addChild(closeButton);
            closeButton.setPositionRound(closeSlot.width / 2, closeSlot.height / 2);
            closeButton.setScale(0.7);
            closeButton.baseScale = closeButton.scale;
        }

        if (mission.isRunning()) {
            var timeLeft = mission.getTimeLeft();
            if (timeLeft > 0) {
                timeLeft = new cleverapps.CountDown(timeLeft, {
                    onFinish: function () {
                        var FinishWindow = this.mission.finishWindow;
                        new FinishWindow(this.mission);
                        this.close();
                    }.bind(this)
                });
            }

            var timer = new cleverapps.CountDownView(timeLeft, {
                font: cleverapps.styles.FONTS.MISSION_TIMER_TEXT
            });
            timerSlot.addChild(timer);
            timer.fitTo(timerSlot.width, timerSlot.height);
            timer.setPositionRound(timerSlot.width / 2, timerSlot.height / 2);
        } else {
            var finishTitle = cleverapps.UI.generateOnlyText(this.options.finishedText || "Missions.windowFinished", cleverapps.styles.FONTS.MISSION_TIMER_TEXT);
            timerSlot.addChild(finishTitle);
            finishTitle.setPositionRound(timerSlot.width / 2, timerSlot.height / 2);
            finishTitle.fitTo(timerSlot.width, timerSlot.height);
        }

        var clock = new cc.Sprite(bundles.big_timer.frames.big_timer_png);
        clockSlot.addChild(clock);
        clock.setPosition(clockSlot.width / 2, clockSlot.height / 2);

        var button = this.createButton();

        return new cleverapps.Layout([table, button].filter(Boolean), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createTable: function () {
        var mission = this.mission;
        var jsons = bundles[this.bundleName].jsons;

        var json = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL && jsons.mission_board_vertical
            ? jsons.mission_board_vertical : jsons.mission_board;

        var background = this.backgroundSpine = new cleverapps.Spine(json);
        if (background.hasAnimation("open")) {
            background.setAnimationAndIdleAfter("open", "idle");
        } else {
            background.setAnimation(0, "idle", true);
        }

        var tableSlot = background.getSlot("slot_table");

        var table = this.mission.table;
        var tableView = new TableView(table, {
            id: mission.type,
            data: mission.getResults(),
            height: tableSlot.height,
            bg: false,
            rowOptions: {
                width: tableSlot.width,
                dataIcon: MissionWindow.getTableRowIcon(mission.type),
                dataIconAnimation: MissionWindow.getTableRowIconAnimation(mission.type),
                dataIconAnimationSound: bundles.feast_window && bundles.feast_window.urls.row_icon_fly_effect,
                scoreTooltip: this.options.scoreTooltip,
                mission: mission
            }
        });
        tableSlot.addChild(tableView);
        tableView.setPositionRound(tableSlot.width / 2, tableSlot.height / 2);

        mission.onChangeResults = this.createListener(function () {
            var results = mission.getResults();
            table.updateRows(results);
        });

        var skin = (Mission.GetChildType(this.mission.type) === Mission.TYPE_EXPEDITION_FEAST ? cleverapps.meta.selectedLocationId() : "")
            + this.mission.getName();

        if (background.hasSkin(skin)) {
            background.setSkin(skin);
        }

        if (connector.info.isMobile && cleverapps.resolution.isHorizontal()) {
            background.setScale(1.1);
        }

        return background;
    },

    createButton: function () {
        var styles = cleverapps.styles.MissionWindow;

        var mission = this.mission;

        if (mission.isRunning() && cleverapps.config.type === "merge") {
            return;
        }

        var options = {
            width: styles.button.width,
            height: styles.button.height,
            type: cleverapps.styles.UI.Button.Images.button_red,
            text: Messages.get("Play").toUpperCase(),

            onClicked: function () {
                this.close();

                cleverapps.focusManager.distract({
                    focus: "MissionPlayButtonPressed",
                    action: function (f) {
                        cleverapps.meta.wantsToPlay(f);
                    }
                });
            }.bind(this)
        };

        if (!mission.isRunning()) {
            options = Object.assign(options, {
                type: cleverapps.styles.UI.Button.Images.button_blue,
                text: mission.getPrize() ? "ClaimPrize.Button.text" : "Close",
                onClicked: this.close.bind(this)
            });
        }

        var button = new cleverapps.UI.Button(options);
        button.setPositionRound(styles.button);
        return button;
    },

    onClose: function () {
        this.mission.runningCompetition.updateShownPlace();
        this.mission.updateMissionIcon();
    },

    getGuideOptions: function () {
        return GuideWindow.getMissionRulesWindowOptions(this.mission);
    },

    listBundles: function () {
        return ["mission_window"];
    }
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    MISSION_STROKE: {
        color: new cc.Color(95, 29, 17, 128),
        size: 1
    },

    MISSION_SHADOW: {
        color: new cc.Color(11, 13, 37, 128),
        direction: cc.size(0, -3),
        blur: 4
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MISSION_MAIN_TITLE_TEXT: {
        size: 78,
        color: new cc.Color(255, 201, 39, 255),
        stroke: cleverapps.styles.DECORATORS.MISSION_STROKE,
        shadow: cleverapps.styles.DECORATORS.MISSION_SHADOW
    },
    MISSION_TIMER_TEXT: {
        size: 50,
        color: new cc.Color(255, 220, 132, 255),
        stroke: cleverapps.styles.DECORATORS.MISSION_STROKE,
        shadow: cleverapps.styles.DECORATORS.MISSION_SHADOW
    }
});

cleverapps.styles.MissionWindow = {
    margin: 30,

    contentPadding: {
        bottom: 0
    },

    table: {
        margin: -2
    },

    button: {
        x: { align: "center", dx: -25 },
        y: { align: "center" },
        width: 400,
        height: 95
    },

    verticalWindow: {
        Background: {
            padding: {
                x: -15
            }
        }
    }
};

MissionWindow.getTableRowIcon = function (type) {
    var img = cleverapps.skins.getSlot("missionTableRowIcon", { type: type });

    if (!img) {
        switch (Mission.GetChildType(type)) {
            case Mission.TYPE_LETTER:
                return bundles.tablerow_icons.frames.letter_mission;
            case Mission.TYPE_RAINBOW:
                return bundles.tablerow_icons.frames.combo;
            case Mission.TYPE_COLLECT_MARK:
                return bundles.tablerow_icons.frames.collect_mark;
            case Mission.TYPE_BURN_NEARBY:
                return bundles.tablerow_icons.frames.burn_nearby;
            case Mission.TYPE_EXPEDITION_FEAST:
            case Mission.TYPE_LIVESFEAST:
                return bundles.tablerow_icons.frames.feast_energy;
            case Mission.TYPE_SOFTFEAST:
                return bundles.tablerow_icons.frames.feast_coin;
            case Mission.TYPE_KRAKENFEAST:
                return bundles.tablerow_icons.frames.feast_tentacle;
        }
    }

    return img;
};

MissionWindow.getTableRowIconAnimation = function (type) {
    var animation = cleverapps.skins.getSlot("missionTableRowIconAnimation", { type: type });
    if (animation) {
        return animation;
    }

    switch (Mission.GetChildType(type)) {
        case Mission.TYPE_LIVESFEAST:
            return bundles.tablerow_icons.jsons.feast_energy_animation_json;
        case Mission.TYPE_SOFTFEAST:
            return bundles.tablerow_icons.jsons.feast_coin_animation_json;
        case Mission.TYPE_KRAKENFEAST:
            return bundles.tablerow_icons.jsons.feast_tentacle_animation_json;
    }
};