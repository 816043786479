/**
 * Created by vladislav on 28.12.2021
 */

var ShareCheckBoxModel = function (shareId, window) {
    this.shareId = shareId;
    this.window = window;

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SHARE_PROPOSED + this.shareId);
};

ShareCheckBoxModel.prototype.onChange = function (state) {
    ShareCheckBoxModel.UNCHECKED[this.window.name] = !state;
};

ShareCheckBoxModel.prototype.isSelected = function () {
    return !ShareCheckBoxModel.UNCHECKED[this.window.name];
};

ShareCheckBoxModel.prototype.onWindowClose = function (f) {
    if (this.isSelected()) {
        this.show(f);
    } else {
        f();
    }
};

ShareCheckBoxModel.prototype.show = function (callback) {
    cleverapps.SocialManager.share(undefined, this.shareId, function (success) {
        if (success) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SHARE + this.shareId);

            this.save();
        }

        callback();
    }.bind(this));
};

ShareCheckBoxModel.isReady = function () {
    var save = cleverapps.dataLoader.load(DataLoader.TYPES.LAST_SUCCESSFUL_SHARE) || {};
    return !save.date || Date.now() - save.date >= ShareCheckBoxModel.INTERVAL;
};

ShareCheckBoxModel.isAvailable = function () {
    var availableChannels = connector.platform.listShareChannels();
    if (availableChannels.length === 0) {
        return false;
    }

    if (!availableChannels.includes("shareNative") && !connector.social.isLoggedIn()) {
        return false;
    }

    return ShareCheckBoxModel.isReady() && cleverapps.user.checkAvailable(ShareCheckBoxModel.AVAILABLE);
};

ShareCheckBoxModel.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.LAST_SUCCESSFUL_SHARE, {
        date: Date.now()
    });
};

ShareCheckBoxModel.UNCHECKED = {};

ShareCheckBoxModel.INTERVAL = cleverapps.parseInterval(cleverapps.config.debugMode ? "3 minute" : "3 days");

ShareCheckBoxModel.AVAILABLE = {
    level: 0.93
};

if (cleverapps.config.type === "merge") {
    ShareCheckBoxModel.AVAILABLE = {
        level: 4
    };
}