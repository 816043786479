/**
 * Created by vladislav on 2/4/19
 */

var DailyCupLeadersRow = RowView.extend({
    generateMedalAmount: function (place, amount) {
        var styles = cleverapps.styles.DailyCupLeadersRow;

        var medal = new cc.Sprite(bundles.table.frames["badge_" + place]);
        medal.setScale(0.7);

        var amountText = cleverapps.UI.generateImageText(amount, cleverapps.styles.FONTS.WHITE_TEXT);
        amountText.setAnchorPoint(0.5, 0.5);
        amountText.fitTo(styles.text.width);

        var amountWrap = new cc.Node();
        amountWrap.setAnchorPoint(0.5, 0.5);
        amountWrap.setContentSize2(styles.text.width, amountText.height);
        amountWrap.addChild(amountText);
        amountText.setPositionRound(amountWrap.width / 2, amountWrap.height / 2);

        return new cleverapps.Layout([medal, amountWrap], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.iconMargin
        });
    },

    createScore: function () {
        var styles = cleverapps.styles.DailyCupLeadersRow;

        var items = [];
        items.push(this.generateMedalAmount(1, this.row.data.firstPlace));
        items.push(this.generateMedalAmount(2, this.row.data.secondPlace));
        items.push(this.generateMedalAmount(3, this.row.data.thirdPlace));

        var content = new cleverapps.Layout(items, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });
        content.setPositionRound({ align: "center" }, { align: "center" });

        var background = new cc.Scale9Sprite(this.row.isPlayer() ? bundles.table.frames.amount_bg_you_png : bundles.table.frames.amount_bg_png);
        background.setContentSize2(content.width, content.height);
        background.addChild(content);
        return background;
    }
});

cleverapps.styles.DailyCupLeadersRow = {
    text: {
        width: 70
    },

    iconMargin: 2,
    margin: 4,
    padding: {
        x: 15
    }
};