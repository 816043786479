/**
 * Created by slava on 14/8/17
 */

var AvailableMove = function () {
    cleverapps.EventEmitter.call(this);

    this.move = undefined;
    this.cooldown = undefined;

    this.paused = false;

    cleverapps.eventBus.on("changeFocus", function () {
        if (cleverapps.focusManager.isFocused()) {
            this.pause();
        } else {
            var game;
            if (typeof Game !== "undefined" && Game.currentGame) {
                game = Game.currentGame;
            } else if (typeof Merge !== "undefined" && Merge.currentMerge) {
                game = Merge.currentMerge;
            }

            if (!game || !game.counter.isActive()) {
                this.resume();
            }
        }
    }.bind(this));

    cleverapps.userStatus.on("active", this.hide.bind(this));
    cleverapps.userStatus.on("inactive_very_short", this.findMove.bind(this));
    cleverapps.userStatus.on("inactive_long", this.findMove.bind(this));
};

AvailableMove.prototype = Object.create(cleverapps.EventEmitter.prototype);
AvailableMove.prototype.constructor = AvailableMove;

AvailableMove.prototype.pause = function () {
    this.hide();
    this.paused = true;
};

AvailableMove.prototype.resume = function () {
    if (this.cooldown) {
        clearTimeout(this.cooldown);
    }

    this.paused = false;
    this.cooldown = setTimeout(this.findMove.bind(this), AvailableMove.COOLDOWN);
};

AvailableMove.prototype.hide = function () {
    if (this.move) {
        this.trigger("hide");
    }

    if (this.cooldown) {
        clearTimeout(this.cooldown);
    }

    this.move = undefined;
};

AvailableMove.prototype.clear = function () {
    this.hide();
    this.purge();
};

AvailableMove.prototype.findMove = function () {
    if (this.paused || this.move || cleverapps.config.adminMode
        || cleverapps.config.wysiwygMode || cleverapps.environment.isAtlasAnalyzerScene()) {
        return;
    }

    var move;
    AvailableMove.REGISTERED_ALGORITHMS.find(function (algorithm) {
        move = algorithm.findMove();
        return move;
    });

    if (move) {
        this.setMove(move);
    }
};

AvailableMove.prototype.setMove = function (move) {
    this.move = move;
    this.trigger(move.type, move.options);
};

AvailableMove.prototype.afterResize = function () {
    var move = this.move;

    if (this.paused || !move) {
        return;
    }

    this.trigger("hide");
    this.trigger(move.type, move.options);
};

AvailableMove.REGISTERED_ALGORITHMS = [];
AvailableMove.COOLDOWN = 3000;