/**
 * Created by andrey on 12.03.2025
 */

var KpiLogger = function () {
    this.load();

    this.initChannels();

    cleverapps.whenAllInitialized(this.init.bind(this));
};

KpiLogger.prototype.initChannels = function () {
    this.addChannel("");
    cleverapps.country.whenReady(function (country) {
        this.setChannel("us", country === "US");
    }.bind(this));
};

KpiLogger.prototype.init = function () {
    if (connector.platform.oneOf(connector.ANDROID, connector.AMAZON, connector.IOS)) {
        connector.platform.subscribeNative("AdjustPlugin", this.onNativeEvent.bind(this));
        connector.platform.callNative("AdjustPlugin.getAttribution", function (code, attribution) {
            this.onAttributionChanged(attribution);
        }.bind(this));
        return;
    }

    var channel = cleverapps.user.channel || "";
    if (connector.platform.oneOf(connector.INSTANT) && !cleverapps.isNumber(channel)) {
        channel = "";
    }
    this.onAttributionChanged({ campaign: channel });
};

KpiLogger.prototype.onAttributionChanged = function (attribution) {
    console.log("KpiLogger.onAttributionChanged - ", attribution);

    attribution = attribution || {};
    var channel = attribution.network || attribution.campaign || "";

    if (!channel) {
        this.setOrganic();
    } else {
        this.setPaid();
    }

    channel = channel.toLowerCase();

    KpiLogger.AD_NETWORKS.filter(function (adNetwork) {
        return channel.includes(adNetwork);
    }).forEach(this.addChannel.bind(this));

    if (cleverapps.user.isFirstSession()) {
        cleverapps.country.whenReady(this.logInstall.bind(this));
    }
};

KpiLogger.prototype.onNativeEvent = function (event, options) {
    console.log("KpiLogger.onNativeEvent - " + event);

    switch (event) {
        case "onAttributionChanged":
            this.onAttributionChanged(options);
            break;
    }
};

KpiLogger.prototype.load = function () {
    this.data = cleverapps.dataLoader.load(DataLoader.TYPES.KPI_LOGGER) || {};
    this.data.channels = this.data.channels || {};
};

KpiLogger.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.KPI_LOGGER, this.data);
};

KpiLogger.prototype.setPaid = function () {
    this.addChannel("paid");
    this.removeChannel("organic");
};

KpiLogger.prototype.setOrganic = function () {
    this.data.channels = {};

    this.initChannels();
    this.addChannel("organic");
};

KpiLogger.prototype.setChannel = function (channel, value) {
    if (value) {
        this.addChannel(channel);
    } else {
        this.removeChannel(channel);
    }
};

KpiLogger.prototype.addChannel = function (channel) {
    if (!this.data.channels[channel]) {
        this.data.channels[channel] = 1;
        this.save();
    }
};

KpiLogger.prototype.removeChannel = function (channel) {
    if (this.data.channels[channel]) {
        delete this.data.channels[channel];
        this.save();
    }
};

KpiLogger.prototype.logPurchase = function (product) {
    this.logConversionKpi("payments", product.priceUSD);
};

KpiLogger.prototype.logAdRevenue = function (name, impressionCost) {
    this.logConversionKpi("ads", impressionCost);
};

KpiLogger.prototype.logInstall = cleverapps.once(function () {
    this.logKpi("installs", 1);
});

KpiLogger.prototype.logConversionKpi = function (name, value) {
    var conversionWindow = this.getConversionWindow();
    if (conversionWindow === -1) {
        return;
    }

    this.logKpi(name + "_d" + conversionWindow, value);
};

KpiLogger.prototype.logKpi = function (name, value) {
    var event = this.getUserRegisteredMonth();
    for (var channel in this.data.channels) {
        cleverapps.eventLogger.logEvent("kpi_" + name, {
            value: value,
            event: event,
            channel: channel
        });
    }
};

KpiLogger.prototype.getUserRegisteredMonth = function () {
    var date = new Date(cleverapps.user.registered);
    date.setUTCDate(1);
    date.setUTCHours(0, 0, 0, 0);
    return date.getTime();
};

KpiLogger.prototype.getConversionWindow = function () {
    var days = cleverapps.user.getDaysSinceRegistration();

    var conversionWindow = KpiLogger.CONVERSION_WINDOWS.find(function (conversionWindow) {
        return days <= conversionWindow;
    });

    return conversionWindow !== undefined ? conversionWindow : -1;
};

KpiLogger.CONVERSION_WINDOWS = cleverapps.arrayFill(90, 0).map(function (item, index) {
    return index;
}).concat([120, 150, 180, 210, 240, 270, 300, 330, 364]);

KpiLogger.AD_NETWORKS = ["bing", "vungle", "applovin", "google", "tyrads", "unity", "maf", "minintegral", "apple"];