/**
 * Created by Denis Kuzin on 05 february 2025
 */

var BoosterCellTutorial = function () {
    return new TutorialScenario("booster_cell", {
        listSteps: BoosterCellTutorial.listSteps,
        isAvailable: BoosterCellTutorial.isAvailable
    });
};

BoosterCellTutorial.listSteps = function () {
    var data = {
        textBlock: {
            text: "BoosterCellTutorial"
        }
    };

    return [Match3TutorialHelper.createBoosterCellStep(data)];
};

BoosterCellTutorial.isAvailable = function () {
    var game = Game.currentGame;
    return game
        && game.boosterAdded
        && !game.shownBoosterCellTutorial
        && game.outcome === GameBase.OUTCOME_UNKNOWN
        && game.moves > 0
        && BombCell.listToExplode().length === 0;
};