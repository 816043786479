/**
 * Created by iamso on 24.04.19.
 */

var AskLivesTileModel = function (data) {
    TileModel.call(this, data);
};

AskLivesTileModel.prototype = Object.create(TileModel.prototype);
AskLivesTileModel.prototype.constructor = AskLivesTileModel;

AskLivesTileModel.prototype.getCurrentPrice = function () {
    return "FREE";
};

AskLivesTileModel.prototype.getProductDescription = function () {
    return {
        text: "AskLivesProduct.description"
    };
};

AskLivesTileModel.prototype.isEnabled = function () {
    return cleverapps.flags.requestsEnabled;
};

AskLivesTileModel.prototype.buy = function (closeShopCallback) {
    cleverapps.SocialManager.enforceLogin(function (code) {
        if (code !== cleverapps.CODE_SUCCEED) {
            return;
        }

        SelectFriendWindow.CreateAndSendRequest("askLiveRequest", undefined, function (code, friends) {
            if (code === cleverapps.CODE_SUCCEED) {
                friends.forEach(function (id) {
                    levels.friendRequests.sendServerRequest(id, levels.FriendRequest.TYPE_ASK_LIVE);
                });
            }

            closeShopCallback();
        });
    });
};
