/**
 * Created by mac on 2019-10-24
 */

cleverapps.Spine = cc.Node.extend({
    ctor: function (json, atlas) {
        this._super();

        json = json instanceof VirtualJson ? json.resolve() : json;
        if (cleverapps.config.wysiwygMode && typeof json === "object" && json.atlas) {
            atlas = json.atlas;
            json = json.json;
        }

        this.jsonName = json;

        var bundleName = json instanceof VirtualJson ? json.bundleName : undefined;
        var loaded = cleverapps.bundleLoader.isLoaded(bundleName);

        if (!loaded && bundleName !== "loading_scene" && json && bundleName) {
            var deleteStack = cleverapps.bundleLoader.deleteStack && cleverapps.bundleLoader.deleteStack[bundleName];
            throw "Json " + json + " bundle " + bundleName + " not loaded stack" + deleteStack;
        }

        if (bundleName === undefined && !atlas) {
            throw "Can't find bundle for json - " + json + " in bundles cache ";
        }

        var jsonFile = json;
        var atlasFile = atlas || bundles[bundleName].atlas;

        var atlasLoaded = typeof atlasFile === "string" ? cc.loader.getRes(atlasFile) : atlasFile;
        if (!atlasLoaded) {
            throw "Atlas " + jsonFile + " bundle " + bundleName + " not loaded " + loaded;
        }

        var cached = cleverapps.Spine.getCachedData(json);
        if (cached) {
            jsonFile = cached.data;
            atlasFile = cached.atlas;
        }

        try {
            var spine = this.spine = sp.SkeletonAnimation.createWithJsonFile(jsonFile, atlasFile, json);
        } catch (e) {
            console.error(e);
            if (cleverapps.config.debugMode) {
                var gitData = cleverapps.Spine.getGitPath(json) || {};
                e.message = gitData.name + "\n" + gitData.link + "\n" + e;
            } else {
                e.message = json + "\n" + e;
            }
            throw new Error(e.message);
        }
        spine.jsonName = json;

        var data = {
            data: spine._skeletonData,
            atlas: atlasFile
        };
        if (engine === "cocos2d") {
            data.atlas = true;
        }
        cleverapps.Spine.setCachedData(data, json, spine.getSkin && spine.getSkin());

        this.data = cc.loader.getRes(json);
        var skeleton = this.data.skeleton;
        this.setContentSize2(skeleton.width * resolutionScale, skeleton.height * resolutionScale);
        spine.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(spine);
        
        var anchorBone = this.getAnchorBone();
        if (anchorBone) {
            var x = (this.width / 2 + anchorBone.x * resolutionScale) / this.width;
            var y = (this.height / 2 + anchorBone.y * resolutionScale) / this.height;
            this.setAnchorPoint(x, y);
        } else {
            this.setAnchorPoint(0.5, 0.5);
        }

        this.setCascadeOpacityEnabledRecursively(true);
        this.setRecCascadeColorEnabled(true);

        // TODO: track current animation
        this.runningAnimation = undefined;

        this.initialize();

        this.createSlots();

        if (cleverapps.config.debugMode && !cleverapps.config.wysiwygMode && ["highstaging", "highstagingpng", "highlocal"].includes(cleverapps.resolution.resolutionName)) {
            this._checkProps();
        }
        if (cleverapps.Spine.debugSlots) {
            this.setDebugSlots(true);
        }
        if (cleverapps.Spine.debugBones) {
            this.setDebugBones(true);
        }
    },

    initialize: function () {
        this.setVisible(false);
        this.startVisibleListener = undefined;
    },

    getAnchorBone: function () {
        return this.getBone("anchor");
    },

    createSlots: function () {
        this.slots = {};

        this.data.bones.forEach(function (bone) {
            if (bone.name.startsWith("slot_")) {
                var node = new cc.Node();
                node.setAnchorPoint(0.5, 0.5);
                node.setContentSize(
                    bone.scaleX * resolutionScale || 0,
                    bone.scaleY * resolutionScale || 0
                );
                node.setPositionRound(
                    this.width / 2 + (bone.x * resolutionScale || 0),
                    this.height / 2 + (bone.y * resolutionScale || 0)
                );
                this.addChild(node);

                this.slots[bone.name] = node;

                if (cleverapps.config.debugMode) {
                    node._slotName = bone.name;

                    if (cleverapps.flags.debugBoneSlots) {
                        node.debugBorder({ labelText: false, permanent: true });
                    }
                }
            }
        }, this);

        if (cleverapps.config.debugMode) {
            cleverapps.flags.on("change:debugBoneSlots", function () {
                for (var slot in this.slots) {
                    if (cleverapps.flags.debugBoneSlots && this.slots[slot].children.length === 0) {
                        this.slots[slot].debugBorder({ labelText: false, permanent: true });
                    } else {
                        this.slots[slot].cleanupBorder({ permanent: true });
                    }
                }
            }.bind(this), this);
        }
    },

    getSlot: function (slot) {
        if (cleverapps.config.debugMode) {
            if (this.slots[slot]) {
                this.slots[slot].cleanupBorder();
            }
        }

        return this.slots[slot];
    },

    getBone: function (boneName) {
        return this.data.bones.find(function (bone) {
            return bone.name === boneName;
        });
    },

    setStartVisibleListener: function (listener) {
        this.startVisibleListener = listener;
    },

    setIdle: function (idleAnimation) {
        if (idleAnimation !== this.idle) {
            this.idle = idleAnimation;
            this.setAnimation(0, this.idle, true);
        }
    },

    setIdleSet: function (animations) {
        var sum = 0;
        Object.values(animations).forEach(function (prob) {
            sum += prob;
        });
        var choose = function () {
            var number = Math.random() * sum;
            for (var key in animations) {
                number -= animations[key];
                if (number <= 0) {
                    return key;
                }
            }
        };

        var setAnimationAndChoose = function () {
            this.setAnimation(0, choose(), false);
            this.setCompleteListener(setAnimationAndChoose);
            this.setSafeToRemove();
        }.bind(this);

        setAnimationAndChoose();
    },

    hasAnimation: function (name) {
        return this.data.animations[name];
    },

    getAnimationData: function (name) {
        return this.spine.findAnimation(name);
    },

    getAnimationDuration: function (name) {
        var data = this.getAnimationData(name);
        if (data) {
            return data.duration;
        }
    },

    hasSkin: function (name) {
        return this.data.skins[name] !== undefined;
    },

    listAnimations: function () {
        return Object.keys(this.data.animations);
    },

    setAnimation: function (track, animation, repeat) {
        if (this.getCurrentAnimationName()) {
            this.setCompleteListener();
        }
        this.spine.setAnimation(track, animation, repeat);

        if (this.idle) {
            this.idle = undefined;
        }

        var json = this.spine.jsonName;
        if (!this.data.animations[animation]) {
            cleverapps.throwAsync("Not found animation " + animation + " in json " + json);
        }

        if (!this.visible) {
            this.visible = true;
            this.startVisibleListener && this.startVisibleListener();
        }
    },

    createAction: function (animation, duration, callback) {
        return new cc.SpineAction(this, animation, duration, callback);
    },

    addAnimation: function (trackIndex, name, loop, delay) {
        this.spine.addAnimation(trackIndex, name, loop, delay);

        var json = this.spine.jsonName;
        if (!this.data.animations[name]) {
            cleverapps.throwAsync("Not found animation " + name + " in json " + json);
        }
    },

    getCurrentState: function (track) {
        return this.spine.getCurrent(track || 0);
    },

    getTimeLeft: function (track) {
        var state = this.getCurrentState(track);
        var duration = state && state.animation && state.animation.duration || 0;
        var trackTime = state && state.trackTime || 0;
        return Math.max(0, (duration - trackTime) / this.getTimeScale());
    },

    getCurrentAnimationName: function (track) {
        var state = this.spine.getCurrent(track);
        return state && state.animation && state.animation.name;
    },

    setTimeScale: function (scale) {
        this.spine.setTimeScale(scale);
    },

    getTimeScale: function () {
        return this.spine.getTimeScale();
    },

    clearTrack: function (trackIndex) {
        this.spine.clearTrack(trackIndex);
    },

    setSkin: function (skin) {
        this.spine.setSkin(skin);
    },

    setStartListener: function (callback) {
        this.spine.setStartListener(callback);
    },

    setCompleteListener: function (callback) {
        this.spine.setCompleteListener(callback || function () {});

        this._cantBeRemoved = Boolean(callback);

        if (this.saveStack && this._cantBeRemoved) {
            this.actionStack = new Error().stack;
        }
    },

    setCompleteListenerRemove: function (callback) {
        this.setCompleteListener(function () {
            if (callback) {
                callback();
            }
            this.runAction(new cc.RemoveSelf());
        }.bind(this));
        this.setSafeToRemove();
    },

    setCompleteListenerOnce: function (listener) {
        this.setCompleteListener(function () {
            listener();
            this.setCompleteListener();
        }.bind(this));
    },

    setAnimationAndIdleAfter: function (animation, idle) {
        this.setAnimation(0, animation, false);
        this.addAnimation(0, idle, true);
    },

    setDebugSlots: function (enable) {
        this.spine.setDebugSolots(enable);
    },

    setDebugBones: function (enable) {
        this.spine.setDebugBones(enable);
    },

    _checkProps: function () {
        if (!cleverapps.UI.ImageFont.IsApplicable(cleverapps.styles.FONTS.WHITE_TEXT, "*")) {
            return;
        }

        var marks = [];
        var root = this.data.bones[0];
        if (root.scaleX && root.scaleX !== 1 || root.scaleY && root.scaleY !== 1) {
            var dX = root.scaleX ? Math.abs(1 - Math.abs(root.scaleX)) : 0;
            var dY = root.scaleY ? Math.abs(1 - Math.abs(root.scaleY)) : 0;
            var value = Math.max(dX, dY);

            var color = cleverapps.styles.COLORS.YELLOW;
            if (value >= 0.25) {
                color = cleverapps.styles.COLORS.COLOR_RED;
            } else if (value > 0.1) {
                color = cleverapps.styles.COLORS.ORANGE;
            }

            var scaleMark = cleverapps.UI.generateImageText("*", cleverapps.styles.FONTS.WHITE_TEXT);
            scaleMark.setColor(color);
            marks.push(scaleMark);
        }
        
        var verts = 0;
        for (var skin in this.data.skins) {
            for (var bone in this.data.skins[skin]) {
                for (var img in this.data.skins[skin][bone]) {
                    if (this.data.skins[skin][bone][img].vertices) {
                        verts += this.data.skins[skin][bone][img].vertices.length;
                    }
                }
            }
        }
        this._verts = verts;
        if (verts >= 800) {
            var vertsMark = cleverapps.UI.generateImageText("%", cleverapps.styles.FONTS.WHITE_TEXT);
            marks.push(vertsMark);

            color = cc.color(255, 0, 0);
            if (verts < 5000) {
                var mapToColor = function (value, inMin, inMax, outMin, outMax) {
                    return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
                };
                var r = mapToColor(verts, 800, 5000, 70, 255);
                var b = mapToColor(verts, 800, 5000, 63, 74);
                var g = mapToColor(verts, 800, 5000, 255, 74);
                color = cc.color(r, g, b);
            }
            vertsMark.setColor(color);
        }

        var marksNode = new cleverapps.Layout(marks, { direction: cleverapps.UI.VERTICAL, margin: -20 });
        marksNode.setPosition(this.width, this.height);
        this.addChild(marksNode);
        cleverapps.UI.onClick(marksNode, function () {
            cleverapps.sceneDebugger.selectNode(this);
        }.bind(this));
    },

    setSafeToRemove: function () {
        this._cantBeRemoved = false;
        delete this.actionStack;
    },

    isSaveToRemove: function () {
        return !this._cantBeRemoved;
    },

    getCurSkin: function () {
        if (!this.spine._skeleton.skin) {
            return "default";
        }
        return this.spine._skeleton.skin.name;
    },

    getBoundingBoxToWorld: function () {
        var rect = cc.rect(0, 0, this._contentSize.width, this._contentSize.height);
        var trans = this.getNodeToWorldTransform();
        return cc.rectApplyAffineTransform(rect, trans);
    },

    _getBoundingBoxToCurrentNode: function (parentTransform) {
        var rect = cc.rect(0, 0, this._contentSize.width, this._contentSize.height);
        var trans = (parentTransform === undefined) ? this.getNodeToParentTransform() : cc.affineTransformConcat(this.getNodeToParentTransform(), parentTransform);
        return cc.rectApplyAffineTransform(rect, trans);
    },

    unuse: function () {
        this.removeTemporarily(false);

        this.setStartListener(undefined);
        this.setCompleteListener(undefined);
        this.clearTrack(0);
    },

    reuse: function () {
        this.initialize();
    },

    getSkeletonData: function () {
        return cc.loader.getRes(this.jsonName);
    },

    listSkins: function () {
        var data = this.getSkeletonData();

        return Object.keys(data.skins).filter(function (name) {
            return name !== "default";
        });
    },

    visitTextureBundle: function () {
        if (this.jsonName instanceof VirtualJson) {
            cc.rendererBundle = this.jsonName.bundleName;
        }
    }
});

cleverapps.Spine.getCachedData = function (json, skin) {
    if (json instanceof VirtualJson) {
        var cached = json.getCachedData();
        if (engine === "creator") {
            skin = skin || "default";
            cached = cached && cached[skin];
        }

        return cached;
    }
};

cleverapps.Spine.setCachedData = function (data, json, skin) {
    if (!(json instanceof VirtualJson)) {
        return;
    }

    if (engine === "cocos2d") {
        json.setCachedData(data);
    } else if (engine === "creator") {
        var cached = json.getCachedData() || {};

        if (!cached[skin]) {
            cached[skin] = data;
        }

        json.setCachedData(cached);
    }
};

cleverapps.Spine.processJson = function (json) {
    json = cleverapps.skins.getSlot("spine", {
        json: json
    });
    return json instanceof VirtualJson ? json.resolve() : json;
};

cleverapps.Spine.hasAnimation = function (name, json) {
    var data = cleverapps.Spine.getSkeletonData(json);
    return data && data.animations && data.animations[name] !== undefined;
};

cleverapps.Spine.hasSkin = function (name, json) {
    var data = cleverapps.Spine.getSkeletonData(json);
    return data && data.skins && data.skins[name] !== undefined;
};

cleverapps.Spine.getSkeletonData = function (json) {
    return cc.loader.getRes(json.resolve());
};

cleverapps.Spine.isAnimationLoaded = function (json) {
    if (json instanceof VirtualJson) {
        json = cleverapps.skins.getSlot("spine", { json: json }).resolve();
        return cleverapps.bundleLoader.isLoaded(json.bundleName);
    }
};

cleverapps.Spine.removeSlot = function (json, toRemove) {
    json = json.resolve();
    json = cleverapps.skins.getSlot("spine", {
        json: json
    });
    json = cc.loader.getRes(json);

    json.slots = json.slots.filter(function (slot) {
        return slot.name !== toRemove;
    });

    Object.values(json.skins).forEach(function (skin) {
        delete skin[toRemove];
    });

    Object.values(json.animations).forEach(function (animation) {
        delete animation.slots[toRemove];
    });
};

cleverapps.Spine.getGitPath = function (json) {
    var originalPath;

    if (json instanceof VirtualResource) {
        originalPath = json.resolve().originalPath();
    }

    if (originalPath) {
        var repoInd = originalPath.indexOf("/");
        var repoName = originalPath.substring(0, repoInd);
        if (repoName === "res") {
            repoName = cleverapps.config.name;
            repoInd = -1;
        }
        var link = "https://github.com/rvvslv/" + repoName + "/tree/master/" + originalPath.substring(repoInd + 1, originalPath.lastIndexOf("/"));
        return {
            name: json.toString(),
            link: link
        };
    }
};

cleverapps.Spine.cache = {};

cleverapps.Spine.GetPoolId = function (obj) {
    if (obj instanceof cleverapps.Spine) {
        obj = obj.jsonName;
    }
    return "spine_" + obj;
};

cleverapps.Spine.GetFromPool = function (json) {
    json = json instanceof VirtualJson ? json.resolve() : json;

    return cc.pool.getFromPool(cleverapps.Spine, json) || new cleverapps.Spine(json);
};

// cleverapps.Spine.debugSlots = true;
