/**
 * Created by vladislav on 19.05.20.
 */

var FarmTool = {
    set: function (buildingId, questId, skipLast) {
        var farm = cleverapps.meta.getSelectedLocation();

        farm._earnKeys = farm.earnKeys;
        farm.earnKeys = function (key) {
            this.keys[key] = (this.keys[key] || 0) + 1;
        }.bind(farm);

        this.reset();

        while (!farm.isCompleted() && (farm.currentBuildingId !== buildingId || farm.getBuilding().currentQuestId !== questId)) {
            var currentBuilding = farm.getBuilding();
            var currentQuest = currentBuilding.getQuest();
            while (!currentQuest.isCompleted()) {
                var building = farm.findBuildingForActiveItem();
                cleverapps.user.incLevel();
                building.levelUp();
            }

            var nextProgress = this.calcNextProgress(currentBuilding);
            if (skipLast && nextProgress.buildingId === buildingId && nextProgress.questId === questId) {
                break;
            } else {
                this.moveNext();
            }
        }

        delete farm.justCollectedDialogue;
        farm.save();
        cleverapps.user.save();

        farm.earnKeys = farm._earnKeys;
        delete farm._earnKeys;
    },

    calcNextProgress: function (building) {
        if (building.currentQuestId + 1 === building.quests.length) {
            return {
                buildingId: building.id + 1,
                questId: 0
            };
        }

        return {
            buildingId: building.id,
            questId: building.currentQuestId + 1
        };
    },

    moveNext: function () {
        var farm = cleverapps.meta.getSelectedLocation();

        var items = farm.getBuilding().getQuest().items;
        items.forEach(function (key) {
            farm.keys[key.name] -= key.amount;
        });

        if (!this.nextQuest(farm.getBuilding())) {
            farm.currentBuildingId++;
        }
    },

    nextQuest: function (building) {
        building.currentQuestId++;

        if (building.getQuest() === undefined) {
            building.open = true;
            return false;
        }

        return true;
    },

    reset: function () {
        var farm = cleverapps.meta.getSelectedLocation();
        farm.reset();

        cleverapps.user.level = 0;
        cleverapps.user.episode = 0;
    }
};