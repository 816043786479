/**
 * Created by slava on 02.02.17.
 */

/*
 * 1) problem with decorators !Completely replace cell by it's decorator
 * 2) bug with Game.animate counter !use only Game.currentGame.counter.setTimeout() in models
 * 3) event dependent css changes !Skins in views
 */

var Match3 = function (level, options) {
    GameBase.call(this, level, options);

    if (cleverapps.gameModes.newRules || this.level.isNewRules()) {
        Match3Rules = NewRules;
    } else {
        Match3Rules = CleverAppsHeroesRules;
    }

    this.loader = new Loader();

    ColorCookieManCell.exitToNextMove = ColorCookieManCell.amountToExit = 0;

    this.moves = this.savedGame.moves || this.levelContent.moves;
    if (this.hasDanger()) {
        this.dangerDuration = this.savedGame.dangerDuration || this.levelContent.dangerDuration;

        this.addDangerComponent();
        this.moves = -1;
    }

    this.leftMoves = this.savedGame.leftMoves || this.moves;
    this.beginMoves = this.savedGame.beginMoves || this.moves;
    this.moveMade = this.beginMoves !== this.moves;

    this.goals = new Goals(this.levelContent, this.savedGame.goals);
    this.field = new Field(this.loader, this.savedGame.field);

    this.started = false;
    this.on("beforeStartActions", function () {
        this.started = true;
    }.bind(this), this);

    BaseAnimationFactories = {};

    this.boosters = new FieldBoosters();

    this.field.load(this.levelContent);

    this.counter.registerStage(0, this.checkWin.bind(this));
    this.counter.registerStage(99, ComboComponent.resetComboTargets);
    this.counter.registerStage(100, this.burn.bind(this));
    this.counter.registerStage(102, this.field.resetCoefs.bind(this.field));
    this.counter.registerStage(199, this.fall.bind(this));
    this.counter.registerStage(300, this.shuffle.bind(this));
    this.counter.registerStage(401, this.checkFinish.bind(this));
    this.counter.registerStage(410, this.explodeOnGameFinish.bind(this));
    this.counter.registerStage(430, this.tutorCompatibilityHint.bind(this));
    this.counter.registerStage(999, this.begin.bind(this));

    if (this.withIceMakerCell) {
        this.on("beforeStartActions", IceMakerCell.onGameStart);
        this.counter.registerStage(212, IceMakerCell.process);
    }

    if (this.withDirtyHoneyTile) {
        this.on("beforeStartActions", DirtyHoneyTile.onGameStart);
        this.counter.registerStage(211, DirtyHoneyTile.process);
    }

    if (this.withCandleCell) {
        this.on("beforeStartActions", CandleCell.onGameStart);
        this.counter.registerStage(150, CandleCell.applyIncGoal);
    }

    if (this.HOUSES) {
        this.on("beforeStartActions", GingerHouseCell.onGameStart);
    }

    if (this.withSharkCell) {
        this.on("beforeStartActions", SharkCell.onGameStart);
        this.counter.registerStage(213, SharkCell.process);
    }

    if (this.withIceBlockMakerTile) {
        this.on("beforeStartActions", IceBlockMakerTile.process);
        this.counter.registerStage(214, IceBlockMakerTile.process);
    }

    this.goalCounter = new Counter();
    this.goalCounter.registerStage(0, function () {
        this.counter.trigger();
    }.bind(this));

    this.onChangeMovesStateListener = function () {};
    this.onChangeMovesListener = function () {};
    this.onBoosterCellCollected = function () {};
    this.onAnimatedChangeMovesListener = function () {};

    this.start = undefined;
    this.field.on("touchMoved", this.onFieldTouchMoved.bind(this), this);
    this.field.on("touchEnded", this.onFieldTouchEnded.bind(this), this);
    this.field.on("move", this.onFieldMoved.bind(this), this);

    if (cleverapps.config.rpg) {
        this.enemies = Game.SelectEnemies(this.level);
    }

    if (cleverapps.config.name === "runes") {
        if (this.moves < 0) {
            this.moves = 100;
            this.leftMoves = this.moves;
            this.beginMoves = this.moves;
        }
        this.counter.registerStage(398, this.enemyAttackStage.bind(this));
        this.counter.registerStage(399, SharkCell.process);
    }

    this.onGetMovesView = function () {};

    BombCell.checkExplode();
};

var Game = Match3;

Match3.prototype = Object.create(GameBase.prototype);
Match3.constructor = Match3;

Match3.prototype.getInfo = function () {
    var info = GameBase.prototype.getInfo.call(this);

    info.dangerDuration = this.dangerDuration;
    info.moves = this.moves;
    info.leftMoves = this.leftMoves;
    info.beginMoves = this.beginMoves;
    info.goals = this.goals.getInfo();
    info.field = this.field.getInfo();
    return info;
};

Match3.prototype.showScreen = function (f, silent) {
    this.boosters.trigger("show", true);
    this.trigger("showPanels", silent);

    cleverapps.focusManager.compound(f, [
        function (f) {
            this.goals.trigger("showUpAnimationStart", f, silent);
        }.bind(this),

        function (f) {
            this.field.trigger("showUpFloor", f, silent);
        }.bind(this),

        function (f) {
            this.field.showUpCells(f, silent);
        }.bind(this)
    ]);
};

Match3.prototype.showStartGameMessage = function (f, silent) {
    if (silent || this.levelWithTutorial()) {
        f();
        return;
    }

    if (this.level.isHard()) {
        cleverapps.audio.playSound(bundles.game.urls.openlevel_hard_effect);
        this.showMessage("message.hardLevel", f);
        return;
    }

    cleverapps.exclamation.show("message.Letsgo", undefined, f);
};

Match3.prototype.getDailyCupStars = function () {
    return this.score.stars;
};

Match3.prototype.onMoveChangeMoves = function () {
    this.decMoves();
};

Match3.prototype.incMoves = function () {
    this.setMoves(this.moves + 1);
    this.leftMoves++;
};

Match3.prototype.decMoves = function () {
    this.setMoves(this.moves - 1);
    this.leftMoves--;
};

Match3.prototype.initColorCellsPool = function () {
    // create 10 elements each level color and add in pool
    for (var i = 0; i < this.levelContent.colors.length; i++) {
        var tmpCell = this.loader.loadNext(0, 0, [this.levelContent.colors[i]]);
        if (tmpCell && tmpCell.putInPoolAvailable && !cleverapps.gameModes.putInPoolDisabled) {
            var ViewClass = tmpCell.getViewClass();
            for (var j = 0; j < 10; j++) {
                if (j > 0) {
                    tmpCell = this.loader.loadNext(0, 0, [this.levelContent.colors[i]]);
                }
                cc.pool.putInPool(new ViewClass(tmpCell));
            }
        }
    }
};

Match3.prototype.initPool = function () {
    FieldScoreView.fillPool();

    this.initColorCellsPool();

    ExplosionView.fillPool();
    CoefAnimation.fillPool();
    if (typeof ColorFulAnimationView !== "undefined") {
        ColorFulAnimationView.fillPool();
    }
    BombByLeftMovesAnimation.fillPool();
};

Match3.prototype.canHelpByFiveAttempts = function () {
    return this.field.getRandomMulticolorTo() !== false && !this.helpByFiveAttemptsDone;
};

Match3.prototype.helpByFiveAttempts = function () {
    if (!this.helpByFiveAttemptsDone) {
        this.helpByFiveAttemptsDone = 1;
        this.field.addRandomMulticolor();
        cleverapps.playSession.inc(cleverapps.EVENTS.HELP_BY_FIVE_ATTEMPTS);
    }
};

Match3.prototype.onFieldTouchMoved = function (cell) {
    if (this.boosters.active) {
        this.boosters.active.onFieldTouchMoved(cell);
        return;
    }
    if (this.field.selected) {
        if (this.field.selected.onMouseMove) {
            this.field.selected.onMouseMove(cell);
        }
    }
};

Match3.prototype.onFieldTouchEnded = function (cell) {
    if (!cell) {
        return;
    }

    if (cell.movable && this.field.selected && this.field.canMove(this.field.selected, cell)) {
        var dir = {
            row: cell.y - this.field.selected.y,
            col: cell.x - this.field.selected.x
        };
        this.tryMove(dir);
    } else {
        var component = cell.findComponent && cell.findComponent([MuffinComponent, GoalCoefComponent]);
        if (cell.smile) {
            cell.smile();
        } else if (component && component.smile) {
            component.smile();
        }
        this.field.select(cell);
        this.field.debugHighlightCells(cell);
    }

    cell.onTouchEnd();
};

Match3.prototype.tryMove = function (dir, fromPos) {
    var from = this.field.selected;
    if (fromPos) {
        from = this.field.cells[fromPos.row] ? this.field.cells[fromPos.row][fromPos.col] : undefined;
    }

    if (!from) {
        return;
    }

    var toCol = from.x + dir.col;
    var toRow = from.y + dir.row;

    if (!this.field.isWithinBounds(toRow, toCol)) {
        return;
    }

    var to = this.field.cells[toRow][toCol];

    if (!to) {
        return;
    }

    var isMovable = function (cell) {
        return cell.movable && !cell.controlDisabled;
    };

    if (!isMovable(from)) {
        from.animate(BaseCell.ANIMATION_MISTAKE);
        return;
    }

    if (!isMovable(to)) {
        this.field.invalidMove(from, to);
        return;
    }

    var isValidMove = this.field.isValidMove(from, dir);
    if (isValidMove === false) {
        return;
    }

    var finish = this.field.cells[from.y + dir.row][from.x + dir.col];

    if (isValidMove === true) {
        this.field.move(from, finish);
    } else {
        // (isValidMove === undefined)
        this.field.wrongMove(from, finish);
    }

    return true;
};

Match3.prototype.onFieldMoved = function () {
    this.moveInProgress = true;
    this.moveMade = true;

    if (this.moves > 0) {
        this.onMoveChangeMoves();

        if (this.outcome === GameBase.OUTCOME_UNKNOWN && BoosterCell.isNeedToAdd(this.moves, this.beginMoves)) {
            this.field.pushNextCell(BoosterCell);
        }
        if (this.outcome === GameBase.OUTCOME_UNKNOWN && TulpanCell.isNeedToAdd(this.moves, this.beginMoves)) {
            this.field.pushNextCell(TulpanCell);
        }
    }
    this.updateOneTimePerMoveFeatures();
    this.field.prepareResetCoefs();
};

Match3.MOVES_STATE_NORMAL = 0;
Match3.MOVES_STATE_DANGER = 1;

Match3.prototype._setMoves = function (moves, options) {
    if (this.goals.isDone || (moves > 5 && this.moves <= 5)) {
        this.onChangeMovesStateListener(Match3.MOVES_STATE_NORMAL);
    } else if (!this.goals.isDone && moves <= 5 && this.moves > 5) {
        this.onChangeMovesStateListener(Match3.MOVES_STATE_DANGER);
    }

    var movesDiff = moves - this.moves;
    this.moves = moves;
    this.onChangeMovesListener(moves, movesDiff, options);
};

Match3.prototype.setMoves = function (moves, options) {
    options = options || {};
    if (options.silent) {
        this._setMoves(moves, options);
        return;
    }
    if (options.animate) {
        this.onAnimatedChangeMovesListener(moves - this.moves, options, this._setMoves.bind(this, moves, options));
    } else {
        this.counter.setTimeout(function () {
            options.delay = 0;

            this._setMoves(moves, options);

            if (options.bonus) {
                for (var i = 0; i < options.bonus; i++) {
                    this.field.addRandomMulticolor();
                }
            }
        }.bind(this), options.delay || 0);
    }
};

Match3.prototype.stop = function () {
    this.field.stopSmile();

    this.boosters.stop();

    GameBase.prototype.stop.call(this);
};

Match3.prototype.checkWin = function () {
    if (this.goals.isDone && this.outcome === GameBase.OUTCOME_UNKNOWN) {
        this.win();
    }
};

Match3.prototype.animateOutcome = function () {
    if (this.outcome === GameBase.OUTCOME_UNKNOWN || cleverapps.focusManager.isFocused()) {
        return;
    }

    if (this.goalCounter && this.goalCounter.isActive()) {
        return;
    }

    var actions = [];
    if (this.hasDanger()) {
        actions.push(function (f) {
            var scene = cleverapps.scenes.getRunningScene();
            scene.showDangerButton(this.outcome, f);
        }.bind(this));
    }

    this.goals.whenCollectsFinished(function () {
        new ActionPlayer(actions).play(function () {
            this.trigger("animateOutcome", this.outcome);
        }.bind(this));
    }.bind(this));
};

Match3.prototype.updateOneTimePerMoveFeatures = function () {
    this.dirtDecoratorCellCanProcess = true;
    this.crabDecoratorCellCanProcess = true;
    this.crabMakerCellCanProcess = true;
    this.dirtMakerCellCanProcess = true;
    this.lollipopCellCanProcess = true;
    this.dirtyHoneyTileCanProcess = true;
    this.iceMakerCellCanProcess = true;
    this.sharkCellCanProcess = true;
    this.iceBlockMakerTileCanProcess = true;
    this.cannonCellCanProcess = true;
    this.smallPetardCellCanProcess = true;
    CookieManCell.prepareToUp();
    ColorCookieManCell.prepareToUp();
    TransporterTile.prepareToMove();
    SharkCell.prepareToProcess();
    CandleCell.prepare();
    BombCell.prepareToProcess();
};

Match3.prototype.tutorCompatibilityHint = function () {
    if (this.goalCounter.isActive() || this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }

    // required only to recalculate cleverapps.Random.state for tutors
    cleverapps.Random.nextInt();
};

Match3.prototype.onExecuteBooster = function () {
    this.cannonCellCanProcess = true;
};

Match3.prototype.checkFinish = function () {
    if (this.goalCounter.isActive()) {
        return;
    }
    if (this.outcome === GameBase.OUTCOME_VICTORY) {
        this.score.addGoalFinished = true;
        return;
    }

    if (this.outcome === GameBase.OUTCOME_UNKNOWN) {
        this.handleFinish();
    }
};

Match3.prototype.handleFinish = function () {
    if (this.dangerComponent && this.dangerComponent.isFinished() && !this.goals.isDone) {
        this.handleExpiredTime();
    } else if (this.moveInProgress) {
        this.moveInProgress = false;

        if (!this.goals.isDone) {
            if (this.moves === 0) {
                this.handleNoMoves();
            } else if (this.moves <= 5) {
                this.trigger("lowMoves", this.moves);
            }
        }
    }
};

Match3.prototype.handleExpiredTime = function () {
    this.lose();
};

Match3.prototype.handleNoMoves = function () {
    if (this.loseByBombExplodeRunning) {
        return;
    }
    if (this.level.isBonus()) {
        this.win();
    } else {
        this.trigger("outOfMoves");
    }
};

Match3.prototype.shakeField = function (params) {
    this.field.trigger("shakeField", params);
};

Match3.prototype.explodeOnGameFinish = function () {
    if (this.goalCounter.isActive()) {
        return;
    }
    if (this.goals.isDone) {
        cleverapps.availableMove.pause();

        this.field.onGameFinish();
    }
};

Match3.prototype.hasBombByLeftMovesAnimation = function () {
    if (cleverapps.wysiwyg && cleverapps.wysiwyg.playable) {
        return false;
    }

    return ["heroes"].indexOf(cleverapps.config.name) !== -1 && this.goals.isDone && this.moves > 0;
};

Match3.prototype.bombByLeftMoves = function (f) {
    this.field.bombByLeftMoves(this.moves, f);
};

Match3.prototype.bigExplode = function () {
    if (this.tutorial && !this.tutorial.finished) {
        return;
    }

    var msg = cleverapps.Random.mathChoose(cleverapps.exclamation.getCongratulations());
    cleverapps.exclamation.show(msg, Exclamation.Types.Congrats);
};

Match3.prototype.burn = function () {
    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (this.field.cells[i][j]) {
                this.field.cells[i][j].onBurnStage();
            }
        }
    }

    var shapes = this.field.findAllShapes();

    if (shapes.length === 0) {
        return;
    }

    shapes.forEach(function (shape) {
        shape.cells.forEach(function (cell) {
            cell.prepareForBurn();
        });
    });

    SyrupTile.enableProcess();
    shapes.forEach(function (shape) {
        var center = shape.getCenter();

        var shapeForm = shape.calculateForm();

        if (Match3Rules.Plus) {
            var boomCoef = 1;

            switch (shapeForm) {
                case Shape.FOUR_V:
                case Shape.FOUR_H:
                case Shape.BOMB:
                    boomCoef = 2;
                    break;
            }
        }

        if (center.findComponent(GoalCoefComponent) || center.innerCell && center.innerCell.findComponent(GoalCoefComponent)) {
            var score = 0;
            shape.cells.forEach(function (cell) {
                var goalCoefComponent = cell.findComponent(GoalCoefComponent);
                if (goalCoefComponent) {
                    score++;
                }
                if (goalCoefComponent && goalCoefComponent.coef) {
                    score += goalCoefComponent.coef;
                }
            });
            this.field.trigger("addScore", score, center.getColor(), center.x, center.y);
        }

        if ([Mission.TYPE_RAINBOW].indexOf(this.getMissionType()) !== -1 && shapeForm === Shape.FIVE) {
            var clover = 1;

            if (this.outcome === undefined) {
                this.addClover(this.getMissionType(), clover);
                this.field.trigger("addClover", clover, center.x, center.y);
            }
        }

        shape.cells.sort(function (a, b) {
            if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
                if (a.x !== b.x) {
                    return a.x - b.x;
                }
                return a.y - b.y;
            }
            if (a.y !== b.y) {
                return a.y - b.y;
            }
            return a.x - b.x;
        });

        shape.cells.forEach(function (cell, id) {
            var params = {
                id: id,
                groupSize: shape.cells.length
            };

            if (cell === center && center.lives <= 1 && center.lives >= 0 && !center.innerCell) {
                var combo = ComboComponent.Create(center, {
                    shape: shape
                });

                if (combo) {
                    shape.merge({
                        target: center,
                        duration: 0.2,
                        filter: function (cell) {
                            return cell.movable && !cell.controlDisabled && cell.lives === 1 && !(cell instanceof HeroCell);
                        }
                    });

                    params.callback = function () {
                        Game.currentGame.counter.setTimeout(function () {
                            this.field.addCell(combo);
                            combo.animate(BaseCell.ANIMATION_CREATE);
                        }.bind(this), 200);
                    }.bind(this);
                }
            }

            if (Match3Rules.Plus) {
                cell.boomNeighbors(boomCoef);
            } else {
                cell.boomNeighbors();
            }
            Game.currentGame.counter.setTimeout(function () {
                cell.hurt(params);
            }, 200);
        }, this);

        RugTile.process(shape.cells);
        SyrupTile.process(shape.cells);
    }, this);

    this.lastMove = undefined;
};

Match3.prototype.fall = function () {
    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }
    this.field.markNotWillFall();

    var didFall = this.field.replenishGaps(function (x, y) {
        return this.field.createRandomCell(x, y);
    }.bind(this));

    if (didFall) {
        this.counter.onceZero = this.fall.bind(this);
    }

    this.field.animateDidFallNotWillFall();

    if (cleverapps.config.debugMode) {
        this.field.trigger("debugHighlightCells", []);
    }
};

Match3.prototype.shuffle = function () {
    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }

    return this.field.shuffle();
};

Match3.prototype.addBoosterCellReward = function (boosterCell) {
    this.rewards[GameBase.REWARD_BOOSTERS] = {};
    this.rewards[GameBase.REWARD_BOOSTERS][boosterCell.type] = 1;
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BOOSTER_CELL_COLLECTED);
    this.onBoosterCellCollected();
};

Match3.prototype.addDangerComponent = function () {
    this.dangerComponent = new DangerComponent(this.dangerDuration);
};

Match3.prototype.getPercentOfCompletion = function () {
    var target = 0, amount = 0;
    var elements = this.goals.elements;
    for (var type in elements) {
        target += elements[type].target;
        amount += Math.min(elements[type].amount, elements[type].target);
    }
    return amount / target;
};

Match3.prototype.needBonusByLeftMoves = function () {
    return this.needChestAnimation() || this.hasBombByLeftMovesAnimation();
};

Match3.prototype.needChestAnimation = function () {
    return this.level.isBonus() && this.moveMade;
};

Match3.prototype.hideField = function (f) {
    this.counter.clearStages();
    this.field.trigger("hide", f);
};

Match3.prototype.animateBonus = function (f) {
    var actions = [];
    if (this.needChestAnimation()) {
        actions.push(this.hideField.bind(this));
        actions.push(function (f) {
            this.score.updatePoints(true);

            if (this.skipBonusAnimations) {
                var amount = this.goals.getInfo()[CoinCell.GOAL_ID].amount;
                this.addHardReward(amount);
                f();
                return;
            }

            var scene = cleverapps.scenes.getRunningScene();
            scene.createBonusChest();
            var goal = this.goals.findTargetFor(CoinCell.GOAL_ID);
            goal.animateBonus(function () {
                scene.bonusChest.animationLeave(f);
            });
        }.bind(this));
    } else if (this.hasBombByLeftMovesAnimation()) {
        this.on("onSkip", f);
        actions.push(this.bombByLeftMoves.bind(this));
    }

    new ActionPlayer(actions).play(f);
};

Match3.BIG_EXPLODE_AMOUNT = 9;