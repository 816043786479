/**
 * Created by Andrey Popov on 11.02.2021.
 */

var Puzzle15Player = function (options) {
    options = options || {};
    GamePlayer.call(this, {}, options);

    this.fingerActionStep = 0;

    this.clickSound = options.clickSound;
    this.miniMerge = options.gameType === "MINI_MERGE";
    this.stepNoForZigzag = options.stepNoForZigzag;
};

if (typeof GamePlayer !== "undefined") {
    Puzzle15Player.prototype = Object.create(GamePlayer.prototype);
    Puzzle15Player.prototype.constructor = Puzzle15Player;
}

Puzzle15Player.prototype.playAction = function (action, f) {
    if (!this.finger.isShown) {
        var unit = action.units ? action.units[0] : action.unit;
        var unitView = this.getUnitView(unit);

        if (this.miniMerge) {
            unitView.select();
        }

        var pos = this.scene.getRelativeCoordinates(unitView);
        this.finger.swipePress({ x: pos.x, y: pos.y });
        this.finger.isShown = true;
        this.finger.play(this.playAction.bind(this, action, f));
        return;
    }

    if (action === "finish") {
        this.fingerFadeOut(f);
    } else if (action.path) {
        this.fingerAction(this.getUnitView(action.unit), function (callback) {
            action.unit.move(action.path, this.game.slots, function () {
                this.finger.finger.setScale(this.finger.finger.lastScale || 1);
                this.finger.finger.lastScale = undefined;

                if (this.game.logic.upgrade) {
                    this.game.logic.upgrade(action);
                }

                callback();
            }.bind(this), function () {
                this.game.logic.afterMove(this.game.map, action, false, function (progress, options) {
                    this.game.afterSuccessMove(progress, options);
                }.bind(this));
            }.bind(this));
        }.bind(this), f);
    } else if (action.units) {
        this.pickUnitsAction(action.units, function () {
            action.units.forEach(function (unit) {
                unit.delete();
            });

            this.game.afterSuccessMove();
            f();
        }.bind(this));
    }
};

Puzzle15Player.prototype.getUnitView = function (unit) {
    var slot = this.game.slots[unit.x + unit.y * this.game.map.width];
    return slot && (slot.unitView || (slot.getChildren().length > 0 ? slot.getChildren()[0] : undefined));
};

Puzzle15Player.prototype.fingerAction = function (target, action, callback) {
    this.fingerActionStep++;

    var worldPos = this.scene.getRelativeCoordinates(target);

    this.finger.moveTo(worldPos);

    this.finger.swipePress();
    this.finger.action(function (f) {
        if (this.miniMerge) {
            target.unSelect();
        }

        cleverapps.audio.playSound(this.clickSound);
        this.finger.finger.lastScale = this.finger.finger.getScale();
        this.finger.finger.replaceParentSamePlace(target, { keepScale: true });
        action(function () {
            f();
        });
    }.bind(this));
    this.finger.swipeRelease();
    this.finger.action(function (f) {
        if (this.miniMerge) {
            target.select();
        }

        f();
    }.bind(this));

    if (this.miniMerge) {
        this.finger.delay(0.2);
    }

    if (callback) {
        this.finger.action(callback);
    }

    this.finger.play();
};

Puzzle15Player.prototype.pickUnitsAction = function (targets, action) {
    targets.forEach(function (target, index) {
        this.finger.moveTo(this.scene.getRelativeCoordinates(this.getUnitView(target)));
        this.finger.swipePress();
        this.finger.action(function (f) {
            cleverapps.audio.playSound(bundles.puzzle15.urls.puzzle_click_effect);
            this.getUnitView(target).select();
            if (index === 2) {
                action();
            }
            f();
        }.bind(this));
    }.bind(this));

    this.finger.play();
};
