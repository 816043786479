/**
 * Created by slava on 4/12/17.
 */

cleverapps.SyncIn = function () {
};

cleverapps.SyncIn.prototype.executeSync = function (sync) {
    if (cleverapps.synchronizer._clientSessionExpired) {
        cleverapps.dataLoader.resetClientSession();
        cleverapps.synchronizer._clientSessionExpired = false;
    }
    cleverapps.synchronizer._syncOut.reset();

    // if (cleverapps.config.debugMode) {
    //     cleverapps.throwAsync({ group: "SyncIn.executeSync", message: connector.platform.getUserID() + " " + JSON.stringify(Object.keys(sync)) });
    // }

    for (var type in CustomSyncers.importerData) {
        if (type in sync) {
            var processor = CustomSyncers.importerData[type];
            if (processor) {
                processor(sync[type]);
            }
        }
    }

    cleverapps.meta.refreshLocation();

    GameDataSaver.Reset();

    cleverapps.dataLoader.processSaveQueue();

    cleverapps.localPushes.reset();

    cleverapps.abExperiments.updateGroups();
};

cleverapps.SyncIn.prototype.needShowReloadWindow = function () {
    return cleverapps.synchronizer._loadedSync;
};

cleverapps.SyncIn.prototype.isReloadInProcess = function () {
    return this._reloadInProgress;
};

cleverapps.SyncIn.prototype.reloadAction = function (f) {
    this._reloadInProgress = true;

    new ReloadWindow(function () {
        if (this.needShowReloadWindow()) {
            this.executeSync(cleverapps.synchronizer._loadedSync);

            cleverapps.synchronizer.on();

            cleverapps.scenes.sceneRefresh();

            this._reloadInProgress = false;
        }

        f();
    }.bind(this));
};

cleverapps.SyncIn.prototype.distractToReloadWindow = function () {
    if (cleverapps.config.wysiwygMode) {
        return;
    }

    cleverapps.focusManager.distract({
        focus: "ReloadWindow",
        action: function (f) {
            this.reloadAction(f);
        }.bind(this)
    });
};

cleverapps.SyncIn.prototype.showReloadWindow = function () {
    if (cleverapps.config.wysiwygMode) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "ReloadWindow",
        action: function (f) {
            this.reloadAction(f);
        }.bind(this)
    });
};

cleverapps.SyncIn.prototype.process = function () {
    if (!cleverapps.allInitialized) {
        this.executeSync(cleverapps.synchronizer._loadedSync);
        cleverapps.synchronizer.on();
    } else if (!cleverapps.focusManager.isFocused()) {
        this.showReloadWindow();
    } else if (!this.isReloadInProcess() && cleverapps.scenes.isSceneReady() && !cleverapps.windows.findWindow(SignInWindow)) {
        this.distractToReloadWindow();
    }
};
