/**
 * Created by olga on 05.02.2025
 */

var BorderRenderer = {
    place: function (area, imagePlacement, options) {
        options = options || {};

        if (area[0] && area[0].x !== undefined) {
            area = BorderRenderer.createAreaMap(area);
        }

        var iterator = function (x, y) {
            if (!area[y] || !area[y][x]) {
                return;
            }

            var borders = BorderRenderer.getBorders(x, y, area, options);
            if (borders.length) {
                imagePlacement(borders, cc.p(x, y), options);
            }
        };

        if (options.part) {
            options.part.forEach(function (cell) {
                iterator(cell.x, cell.y);
            });
        } else {
            BorderRenderer.iterateMap2d(area, iterator);
        }
    },

    createAreaMap: function (positions) {
        var areaMap = [];
        positions.forEach(function (pos) {
            areaMap[pos.y] = areaMap[pos.y] || [];
            areaMap[pos.y][pos.x] = true;
        });

        return areaMap;
    },

    getBorders: function (x, y, area, options) {
        return BorderRenderer.BORDERS.filter(function (border) {
            if (border.isNeighbor && !border.isNeighbor.every(function (dir) {
                var neighbor = BorderRenderer.getNeighbor(x, y, dir, options);
                return area[neighbor.y] && area[neighbor.y][neighbor.x];
            })) {
                return false;
            }

            if (border.noNeighbor && border.noNeighbor.some(function (dir) {
                var neighbor = BorderRenderer.getNeighbor(x, y, dir, options);
                return area[neighbor.y] && area[neighbor.y][neighbor.x];
            })) {
                return false;
            }

            return true;
        }).map(function (border) {
            return {
                name: border.name,
                isNeighbor: border.isNeighbor,
                noNeighbor: border.noNeighbor,
                borderCell: BorderRenderer.getNeighbor(x, y, border.direction, options)
            };
        });
    },

    getNeighbor: function (x, y, direction, options) {
        var yAxis = options && options.yAxis || 1;
        var dir = BorderRenderer.DIRS[direction];
        return cc.p(x + dir.x, y + dir.y * yAxis);
    },

    getNeighbours: function (x, y, options) {
        return Object.keys(BorderRenderer.DIRS).map(function (dir) {
            return BorderRenderer.getNeighbor(x, y, dir, options);
        });
    },

    iterateMap2d: function (array2d, iterator) {
        array2d.forEach(function (row, y) {
            row.forEach(function (cell, x) {
                if (cell) {
                    iterator(x, y);
                }
            });
        });
    }
};

BorderRenderer.DIRS = {
    up: { y: -1, x: 0 },
    right: { y: 0, x: 1 },
    down: { y: 1, x: 0 },
    left: { y: 0, x: -1 },
    up_right: { y: -1, x: 1 },
    up_left: { y: -1, x: -1 },
    down_right: { y: 1, x: 1 },
    down_left: { y: 1, x: -1 }
};

BorderRenderer.BORDERS = [
    { name: "up", noNeighbor: ["up"], direction: "up" },
    { name: "right", noNeighbor: ["right"], direction: "right" },
    { name: "left", noNeighbor: ["left"], direction: "left" },
    { name: "down", noNeighbor: ["down"], direction: "down" },

    { name: "up_left", noNeighbor: ["up", "left", "up_left"], direction: "up_left" },
    { name: "up_right", noNeighbor: ["up", "right", "up_right"], direction: "up_right" },
    { name: "down_left", noNeighbor: ["down", "left", "down_left"], direction: "down_left" },
    { name: "down_right", noNeighbor: ["down", "right", "down_right"], direction: "down_right" },

    {
        name: "inner_up_left", noNeighbor: ["up"], isNeighbor: ["up_left"], direction: "up" 
    },
    {
        name: "inner_up_right", noNeighbor: ["up"], isNeighbor: ["up_right"], direction: "up" 
    },
    {
        name: "inner_down_left", noNeighbor: ["down"], isNeighbor: ["down_left"], direction: "down" 
    },
    {
        name: "inner_down_right", noNeighbor: ["down"], isNeighbor: ["down_right"], direction: "down" 
    }
];

// corners types:  https://gyazo.com/f801034658bc513c86021e831a15a6e9
