/**
 * Created by olga on 23.10.2024
 */

var Meta = function () {
    cleverapps.EventEmitter.call(this);

    this.logic = this.createLogic();
    this.adapter = this.createAdapter();
};

Meta.prototype = Object.create(cleverapps.EventEmitter.prototype);
Meta.prototype.constructor = Meta;

Meta.prototype.createAdapter = function () {
    switch (this.getType()) {
        case Meta.HOSE:
            return new HoseAdapter();
        case Meta.SHORTMETA:
            return new ShortMetaAdapter();
        case Meta.SIMPLE:
            return new SimpleAdapter();
        case Meta.HOMEFIX:
            return new HomefixAdapter();
        case Meta.FARM:
            return new FarmAdapter();
        case Meta.MERGE:
            return new MergeMetaAdapter();
        default:
            return new DefaultMetaAdapter();
    }
};

Meta.prototype.createLogic = function () {
    switch (this.getType()) {
        case Meta.SHORTMETA:
        case Meta.HOSE:
        case Meta.SIMPLE:
            return new SimpleLogic();
        case Meta.FARM:
            return new FarmLogic();
        case Meta.HOMEFIX:
            return new HomefixLogic();
        case Meta.MERGE:
            return new MergeLogic();
        default:
            return new NoMetaLogic();
    }
};

Meta.prototype.getType = function () {
    if (connector.info.source === "playable") {
        return Meta.NONE;
    }

    return cleverapps.config.meta;
};

Meta.prototype.load = function () {
    var needSave = this.adapter.load();

    this.switchLocation(this.logic.getMainLocation());

    if (needSave) {
        this.save();
    }
};

Meta.prototype.save = function (fromSever) {
    this.adapter.save(fromSever);
};

Meta.prototype.updateInfo = function (serverData) {
    this.adapter.updateInfo(serverData);

    this.save(true);

    if (cleverapps.highscore) {
        cleverapps.highscore.updateInfo(serverData);
        cleverapps.highscore.save(true);
    }
};

Meta.prototype.getInfo = function () {
    var data = this.adapter.getInfo();

    if (cleverapps.highscore) {
        Object.assign(data, cleverapps.highscore.getInfo());
    }

    return data;
};

Meta.prototype.refreshLocation = function () {
    this.switchLocation(this.logic.getMainLocation());
};

Meta.prototype.loadLocation = function (locationId) {
    return this.adapter.loadLocation(locationId);
};

Meta.prototype.saveLocation = function (locationId, data) {
    this.adapter.saveLocation(locationId, data);
};

Meta.prototype.switchLocation = function (locationId) {
    if (typeof Merge !== "undefined" && Merge.currentMerge) {
        Merge.currentMerge.stop();
        Merge.currentMerge = undefined;
    }

    if (typeof Homefix !== "undefined" && Homefix.currentHomefix) {
        Homefix.currentHomefix.stop();
        Homefix.currentHomefix = undefined;
    }

    var LocationClass = this.logic.getLocationClass();
    this.location = new LocationClass(locationId);

    if (typeof LivesHelper !== "undefined") {
        LivesHelper.Switch(this.location.slot);
    }

    cleverapps.playSession.set(cleverapps.EVENTS.META_LOCATION, this.location.locationId);
};

Meta.prototype.gotoLocationScene = function (f) {
    var MainSceneClass = this.getMainScene();
    cleverapps.scenes.replaceScene(new MainSceneClass(), f);
};

Meta.prototype.gotoMainLocation = function (f) {
    if (cleverapps.config.demoMode) {
        cleverapps.unitsDemoMode.gotoUnitsDemoScene(f);
        return;
    }

    var locationId = this.logic.getMainLocation();
    
    if (this.selectedLocationId() !== locationId) {
        this.switchLocation(locationId);
    }

    this.gotoLocationScene(f);
};

Meta.prototype.isLocationAvailable = function (locationId) {
    return this.logic.isLocationAvailable(locationId);
};

Meta.prototype.listAvailableLocations = function () {
    if (this.logic.listAvailableLocations) {
        return this.logic.listAvailableLocations();
    }

    var locationId = 0;
    var locations = [];

    while (this.isLocationAvailable(locationId)) {
        locations.push(locationId);
        locationId++;
    }

    return locations;
};

Meta.prototype.listActiveLocations = function () {
    return this.logic.listActiveLocations();
};

Meta.prototype.getSelectedLocation = function () {
    return this.location;
};

Meta.prototype.selectedLocationId = function () {
    return this.location.locationId;
};

Meta.prototype.nextLocationId = function (locationId) {
    locationId = this.logic.getNextLocation(locationId);

    if (this.isLocationAvailable(locationId)) {
        return locationId;
    }
};

Meta.prototype.gamePlayed = function (outcome, game) {
    if (outcome === GameBase.OUTCOME_VICTORY) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
        cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);
    }

    this.getSelectedLocation().gamePlayed(outcome, game);
};

Meta.prototype.isCompleted = function () {
    return this.logic.isCompleted();
};

Meta.prototype.moveNextLocation = function () {
    this.logic.moveNextLocation();

    this.switchLocation(this.logic.getMainLocation());
    this.save();
};

Meta.prototype.startLocation = function (locationId) {
    this.logic.startLocation(locationId);
    this.save();
};

Meta.prototype.finishLocation = function (locationId) {
    this.logic.finishLocation(locationId);
    this.save();
};

Meta.prototype.overrideLocationInfo = function (locationId, data) {
    this.logic.overrideLocationInfo(locationId, data);
    this.save();
};

Meta.prototype.removeSilents = function () {
    this.logic.listActiveLocations().forEach(function (locationId) {
        if (this.logic.isLocationCompleted(locationId) && this.logic.canRemoveSilently(locationId)) {
            this.finishLocation(locationId);
        }
    }.bind(this));
};

Meta.prototype.wantsToPlay = function (f, level) {
    if (cleverapps.lives && cleverapps.lives.isEmpty()) {
        if (cleverapps.unlimitedLives && cleverapps.unlimitedLives.freeOfferIsAvailable()) {
            cleverapps.unlimitedLives.showFreeOffer(f);
        } else {
            new LivesShopWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }

        return;
    }

    var location = this.getSelectedLocation();

    if (this.getType() === Meta.FARM) {
        var building = level && level.building && level.building.canStillProvideQuestItems()
            ? level.building
            : location.findBuildingForActiveItem() || cleverapps.Random.mathChoose(location.listAvailableProvidesBuildings());

        level = cleverapps.meta.getCurrentLevel(building);
    } else {
        level = level || cleverapps.meta.getCurrentLevel();
    }

    if (cleverapps.knockoutGame) {
        cleverapps.knockoutGame.wantsToPlay(f, level);
    } else {
        cleverapps.meta.playLevel(f, level);
    }
};

Meta.prototype.wantsToReplay = function (f, level) {
    cleverapps.meta.playLevel(f, level);
};

Meta.prototype.reset = function () {
    this.location = undefined;

    this.logic.reset();
    this.adapter.reset();

    this.switchLocation(this.logic.getMainLocation());
};

Meta.prototype.getMainScene = function () {
    if (connector.info.source === "playable") {
        return PlayableAdsScene;
    }

    var LocationClass = this.logic.getLocationClass();
    return LocationClass.GetMainScene();
};

Meta.prototype.processChatMessage = function (message) {
    this.logic.processChatMessage(message);
};

Meta.prototype.playLevel = function (f, level) {
    if (cleverapps.isLevels() && level.episodeNo >= levels.user.getEpisodesAmount()) {
        f();
        return;
    }

    if (!LevelStartWindow.isAvailable(level) || cleverapps.config.features.includes("boosters_before") && !WindowBoostersBefore.isAvailable()) {
        level.play(f);
        return;
    }

    cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");

    if (level.building) {
        new BuildingStartWindow({
            building: level.building,
            level: level
        });
    } else {
        new LevelStartWindow(level.getStartWindowOptions());
    }
    cleverapps.focusManager.onceNoWindowsListener = f;
};

Meta.prototype.getCurrentLevel = function (building) {
    if (cleverapps.meta.getType() === Meta.FARM) {
        var label = building.label;

        cleverapps.playButton.disableIncLevel();
        if (label === undefined || label.episodeNo >= levels.user.getEpisodesAmount()) {
            var nextLevel = cleverapps.meta.getSelectedLocation().chooseNextLevel();
            label = {
                episodeNo: nextLevel.episodeNo,
                levelNo: nextLevel.levelNo
            };
        }

        if (building.label !== label) {
            building.label = label;
            cleverapps.meta.getSelectedLocation().save();
        }
        cleverapps.playButton.enableIncLevel();

        var level = new Level(label.episodeNo, label.levelNo);
        level.building = building;
        return level;
    }

    if (cleverapps.isKnockoutGame()) {
        return cleverapps.knockoutGame.getNextLevel();
    }

    return cleverapps.user.getCurrentLevel();
};

Meta.HOSE = "hose";
Meta.FARM = "farm";
Meta.HOMEFIX = "homefix";
Meta.SHORTMETA = "shortmeta";
Meta.SIMPLE = "simple";
Meta.FISHDOM = "fishdom";
Meta.MERGE = "merge";
Meta.NONE = "none";

Meta.SLOT_MAIN = "";
Meta.SLOTS = [Meta.SLOT_MAIN];

if (cleverapps.config.type === "merge" || cleverapps.config.name === "garden") {
    Meta.EXPEDITION_SLOT1 = "1";
    Meta.EXPEDITION_SLOT2 = "2";
    Meta.EXPEDITION_SLOT3 = "3";
    Meta.EXPEDITION_SLOT4 = "4";

    Meta.SLOTS = [Meta.SLOT_MAIN, Meta.EXPEDITION_SLOT1, Meta.EXPEDITION_SLOT2, Meta.EXPEDITION_SLOT3, Meta.EXPEDITION_SLOT4];
}
