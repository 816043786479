/**
 * Created by decipaliz on 28.06.2024
 */

var LanternStartComponent = cc.Node.extend({
    ctor: function (level) {
        this._super();

        var styles = cleverapps.styles.LanternStartComponent;

        var res = bundles.lantern_start.frames.lantern_start_bg_png;

        var bg;
        if (styles.bg.scale9) {
            bg = cleverapps.UI.createScale9Sprite(res, cleverapps.UI.Scale9Rect.TwoPixelXY);
            bg.setContentSize(styles.bg.scale9);
        } else {
            bg = new cc.Sprite(res);
        }

        bg.setPositionRound(styles.bg);

        this.setContentSize(bg);
        this.setAnchorPoint(0.5, 0.5);

        this.addChild(bg);

        this.level = level;

        var streak = Lantern.GetStreak(level);

        var lantern = new cc.Node();

        var lanternIcon = new cleverapps.Spine(bundles.lantern_start.jsons.lantern_component_json);
        lanternIcon.setAnimation(0, "idle_" + streak);

        lantern.setPositionRound(styles.lanternIcon);

        lantern.addChild(lanternIcon);
        this.addChild(lantern);

        lantern.setScale(0);
        lantern.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.CallFunc(function () {
                lantern.setScale(0.5);
                lantern.setVisible(true);

                if (cleverapps.config.ui === "heroes") {
                    StandartAnimations.rays(lantern, { skin: "white", size: 120 });
                }
            }),
            new cc.ScaleTo(0.4, 1, 1).easing(cc.easeBackOut(2)),
            new cc.CallFunc(function () {
                if (streak > 0) {
                    cleverapps.tooltipManager.onClick(lanternIcon, {
                        content: this.getRewardsList(streak),
                        size: styles.tooltip,
                        location: cleverapps.UI.Tooltip.LOCATION_ABOVE,
                        control: "lanternStartTooltip"
                    });
                }
            }.bind(this))
        ));

        if (streak > 0) {
            cleverapps.UI.onClick(lanternIcon, function () {
                cleverapps.tooltipManager.onClick(lanternIcon, {
                    content: this.getRewardsList(streak),
                    size: styles.tooltip,
                    location: cleverapps.UI.Tooltip.LOCATION_ABOVE,
                    control: "lanternStartTooltip"
                });
            }.bind(this), {
                interactiveScale: false
            });
        }

        cleverapps.focusManager.showControlsWhileFocused("lanternStartTooltip");

        var helpButton = new cleverapps.UI.HelpButton({
            type: cleverapps.styles.UI.Button.Images.help_button_light,
            callback: function () {
                new GuideWindow({
                    name: "LanternGuideWindow",
                    bundle: bundles.lantern_guide_window
                });
            } 
        });
        helpButton.setScale2(styles.helpButton.scale);
        helpButton.baseScale = styles.helpButton.scale;
        helpButton.setPositionRound(styles.helpButton);
        this.addChild(helpButton);

        var label = cleverapps.UI.generateOnlyText("LevelStartWindow.Lantern." + cleverapps.config.ui, cleverapps.styles.FONTS.LANTERN_START_COMPONENT_TEXT);
        label.setDimensions(styles.label.width, 0);
        label.fitTo(undefined, styles.label.height);
        label.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        label.setPositionRound(styles.label);
        this.addChild(label);

        var progressBar = new cleverapps.Spine(bundles.lantern_start.jsons.lantern_progress_json);
        progressBar.setPositionRound(styles.progressBar);

        this.addChild(progressBar);

        if (streak === 0 || streak === 3) {
            progressBar.setAnimation(0, "idle_" + streak);
        } else {
            progressBar.setAnimation(0, "idle_" + (streak - 1));
            progressBar.runAction(new cc.Sequence(
                new cc.DelayTime(0.2),
                new cc.CallFunc(function () {
                    progressBar.setAnimationAndIdleAfter("stage_" + streak, "idle_" + streak);
                }),
                new cc.DelayTime(0.7),
                new cc.PlaySound(bundles.lantern_start.urls.lantern_score_effect)
            ));
        }
    },

    getRewardsList: function (streak) {
        return new RewardsListComponent(LanternStartComponent.REWARDS[streak - 1], {
            font: cleverapps.styles.FONTS.LANTERN_START_TOOLTIP
        });
    }
});

LanternStartComponent.REWARDS = [
    { boosters: { 5: 3 } },
    { boosters: { 5: 3, 6: 3 } },
    { boosters: { 5: 3, 6: 3, 7: 1 } }
];

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    LANTERN_START_COMPONENT_STROKE: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_STROKE_COLOR,
        size: 2
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LANTERN_START_COMPONENT_TEXT: {
        size: 42,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LANTERN_START_COMPONENT_STROKE
    },

    LANTERN_START_TOOLTIP: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    }
});

cleverapps.styles.LanternStartComponent = {
    bg: {
        x: { align: "center" },
        y: { align: "center" }
    },
    lanternIcon: {
        x: { align: "center", dx: -170 },
        y: { align: "center", dy: 6 }
    },
    helpButton: {
        x: { align: "right", dx: -26 },
        y: { align: "top", dy: -26 },
        scale: 0.8
    },
    label: {
        width: 240,
        height: 150,
        x: { align: "center", dx: 80 },
        y: { align: "center", dy: 55 }
    },
    progressBar: {
        x: { align: "center", dx: 80 },
        y: { align: "center", dy: -50 }
    },
    tooltip: {
        width: 360,
        height: 180
    }
};