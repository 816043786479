/**
 * Created by slava on 24.03.17.
 */

var SignInWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        options = options || {};
        this.options = options;

        var styles = cleverapps.styles.SignInWindow;

        this.restoreProgress = options.restoreProgress;

        var items = [];

        var animation = new cleverapps.Spine(bundles.sign_in_window.jsons.sign_in_icon_json);
        animation.setAnimationAndIdleAfter("open", "idle");

        items.push(animation);

        var text1 = this.createText("SignInWindow.text1");
        var text2 = this.createText("SignInWindow.text2");

        items.push(text1);
        items.push(text2);

        if (options.errorMessage) {
            var error = cleverapps.UI.generateOnlyText(options.errorMessage, cleverapps.styles.FONTS.SMALL_TEXT_ERROR);
            error.setDimensions(text1.width, 0);
            error.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            items.push(error);
        }

        items.push(this.createButtons());

        var footer = cleverapps.UI.generateOnlyText("SignInWindow.footer", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        footer.setDimensions(text1.width, 0);
        footer.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        footer.setOpacity(170);
        items.push(footer);

        var content = new cleverapps.Layout(items, {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });

        this._super({
            title: "SignInWindow.title",
            closeButton: true,
            name: "signinwindow",
            content: content
        });

        this.closeCallback = cleverapps.once(options.onClose);
        this.success = cleverapps.once(options.onSuccess);
        this.failureLogin = options.onFailure;

        this.waitingSync = false;

        if (this.restoreProgress) {
            this._setGlobalZOrder(true);
        }
    },

    createText: function (msg) {
        var styles = cleverapps.styles.SignInWindow;

        var checkmark = new cc.Sprite(bundles.checkbox.frames.settings_checkmark_png);

        var text = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        text.setDimensions(styles.text.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);

        return new cleverapps.Layout([checkmark, text], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.text.margin
        });
    },

    onClose: function () {
        if (this.failureLogin) {
            this.failureLogin();
        }

        if (this.closeCallback) {
            this.closeCallback();
        }
    },

    waitSync: function () {
        this.waitingSync = true;

        var waitWindow;

        cleverapps.focusManager.display({
            stack: true,
            focus: "WaitSync",
            actions: [
                function (f) {
                    waitWindow = new WaitWindow();
                    f();
                },

                function (f) {
                    f = cleverapps.wait(2, f);
                    cleverapps.synchronizer._onceNextSyncCompletedListener = cleverapps.waitNoMore(15000, f);
                    setTimeout(f, 3000);
                },

                function (f) {
                    if (cleverapps.platformLogic.loadOldUserId && !cleverapps.platformLogic.oldUserId) {
                        cleverapps.platformLogic.loadOldUserId(function (oldUserId) {
                            if (oldUserId) {
                                cleverapps.platformLogic.oldUserId = oldUserId;
                                cleverapps.userIdsHistory.add(oldUserId);
                            }
                            f();
                        });
                    } else {
                        f();
                    }
                },

                function (f) {
                    if (!cleverapps.synchronizer._syncIn.needShowReloadWindow() && cleverapps.platformLogic.oldUserId && cleverapps.synchronizer.isReady()) {
                        f = cleverapps.waitNoMore(15000, f);
                        cleverapps.synchronizer.getProgress(cleverapps.platformLogic.oldUserId, {
                            acceptOnlyIfBetter: true,
                            progressLoadOnly: true,
                            callback: function () {
                                cleverapps.platformLogic.oldUserId = undefined;
                                f();
                            }
                        });
                    } else {
                        f();
                    }
                },

                function (f) {
                    waitWindow.close();
                    this.waitingSync = false;

                    if (!this.closed) {
                        this.close();
                    }
                    this.success();

                    if (cleverapps.synchronizer._syncIn.needShowReloadWindow()) {
                        if (cleverapps.focusManager.isFocused() && !cleverapps.synchronizer._syncIn.isReloadInProcess()) {
                            cleverapps.synchronizer._syncIn.distractToReloadWindow();
                        }
                    } else if (this.restoreProgress) {
                        cleverapps.notification.create("RestoreProgress.progressNotFound");
                    }

                    f();
                }.bind(this)
            ]
        });
    },

    close: function () {
        if (this.waitingSync) {
            return;
        }

        this._super();
    },

    onButtonClick: function () {
        if (this.closed) {
            return;
        }

        this.failureLogin = false;
        this.waitSync();
    },

    createButtons: function () {
        var socials = connector.social.listAvailable();

        if (cleverapps.config.debugMode && this.options.socials) {
            socials = this.options.socials;
        }

        var styles = cleverapps.styles.SignInWindow;

        var buttons = socials.map(function (source) {
            return new LoginButton({
                multiSocials: socials.length > 1,
                height: styles.button.height,
                width: styles.button.width,
                source: source,
                onSuccess: this.onButtonClick.bind(this)
            });
        }, this);

        return new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.button.margin,
            padding: styles.button.padding
        });
    },

    listBundles: function () {
        return ["sign_in_window"];
    }
});

cleverapps.styles.SignInWindow = {
    margin: 30,

    button: {
        height: 110,
        width: 500,
        margin: 20,
        padding: {
            top: 20,
            bottom: 30
        }
    },

    text: {
        width: 720,
        margin: 20
    }
};
