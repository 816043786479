/**
 * Created by vladislav on 28.09.2020
 */

var MagnifierBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_MAGNIFIER);
};

MagnifierBooster.prototype = Object.create(BaseBoosterBefore.prototype);
MagnifierBooster.prototype.constructor = MagnifierBooster;

MagnifierBooster.prototype.getAfterStartActions = function () {
    return function (f) {
        Game.currentGame.keypad.addMagnifiers(5);
        Game.currentGame.keypad.magnifiersShowUp();
        this.execute();
        Game.currentGame.counter.setTimeout(f, cleverapps.config.name === "scramble" ? 0 : 300);
    }.bind(this);
};