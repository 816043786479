/**
 * Created by vtbel on 03.11.2020.
 */

var GitInfoWindow = CleverappsWindow.extend({
    onWindowLoaded: function (command) {
        this.command = command || "push";

        this._super({
            noBackground: true,
            closeButton: false,
            name: "GitInfoWindow",
            content: this.getContent(),
            buttons: this.getButtons()
        });
    },

    onShow: function () {
        if (!cleverapps.environment.isEditorScene()) {
            $(cc.EditBox).hide();
        }

        this._super();
    },

    onHide: function () {
        this._super();

        if (!cleverapps.environment.isEditorScene()) {
            $(cc.EditBox).show();
        }
    },

    getButtons: function () {
        var styles = cleverapps.styles.GitInfoWindow.button;
        return [
            new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.git,
                text: this.command === GitManager.PUSH ? "push all" : "revert all",
                width: styles.width,
                height: styles.height,
                onClicked: this.command === GitManager.PUSH ? this.createListener(this.push.bind(this)) : this.createListener(this.revert.bind(this))
            }),
            new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.git,
                text: "cancel",
                width: styles.width,
                height: styles.height,
                onClicked: this.cancel.bind(this)
            })
        ];
    },

    generateFileLine: function (file, content) {
        var styles = cleverapps.styles.GitInfoWindow;

        var hint;

        if (content === null) {
            hint = cleverapps.UI.generateTTFText("(removed)", cleverapps.styles.FONTS.GIT_INFO_TEXT);
        } else if (content) {
            hint = cleverapps.UI.generateTTFText("(view)", cleverapps.styles.FONTS.GIT_INFO_TEXT);
            cleverapps.UI.onClick(hint, this.exportContent.bind(this, file, content));
        }

        var textWidth = styles.scroll.width - styles.lineButton.width - styles.lineMargin;

        if (hint) {
            hint.fitTo(styles.lineButton.width);
            hint.setDimensions(styles.lineButton.width, 0);
            hint.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            textWidth -= styles.lineButton.width + styles.lineMargin;
        }

        var fileName = cleverapps.UI.generateTTFText(file.split(":")[1] || file, cleverapps.styles.FONTS.GIT_INFO_TEXT, {
            ignoreWarning: true
        });
        fileName.fitTo(textWidth);
        fileName.setDimensions(textWidth, 0);
        fileName.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);

        var actionButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.git,
            text: this.command === GitManager.PUSH ? "push" : "revert",
            onClicked: this.command === GitManager.PUSH ? this.createListener(this.push.bind(this, file)) : this.createListener(this.revert.bind(this, file)),
            width: styles.lineButton.width,
            height: styles.lineButton.height
        });

        return new cleverapps.Layout([fileName, actionButton, hint].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.lineMargin
        });
    },

    getContent: function () {
        var styles = cleverapps.styles.GitInfoWindow;

        var localFiles = [];
        var remoteFiles = [];

        cleverapps.git.listAllChanges().forEach(function (file) {
            if (file.indexOf("local:") === 0) {
                localFiles.push(file);
            } else {
                remoteFiles.push(file);
            }
        });

        var rows = [];

        if (localFiles.length) {
            rows.push(cleverapps.UI.generateTTFText("Local changes:", cleverapps.styles.FONTS.GIT_INFO_TEXT));
            localFiles.forEach(function (file) {
                rows.push(this.generateFileLine(file, cleverapps.git.getContent(file)));
            }.bind(this));
        }

        if (remoteFiles.length) {
            rows.push(cleverapps.UI.generateTTFText("Remote changes:", cleverapps.styles.FONTS.GIT_INFO_TEXT));
            remoteFiles.forEach(function (file) {
                rows.push(this.generateFileLine(file));
            }.bind(this));
        }

        var files = new cleverapps.Layout(rows, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        var scrollHeight = styles.scroll.height;
        if (files.height < scrollHeight) {
            scrollHeight = files.height;
        }

        var scroll = cleverapps.UI.createScale9Sprite(bundles.git.frames.scroll_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        scroll.setContentSize2(styles.scroll.width + 2 * styles.scroll.bgPadding, scrollHeight + 2 * styles.scroll.bgPadding);

        var scrollContainer = new cleverapps.UI.ScrollView(files);
        scrollContainer.setContentSize2(styles.scroll.width, scrollHeight);
        scrollContainer.setPositionRound(scroll.width / 2, scroll.height / 2);
        scroll.addChild(scrollContainer);

        var content = cleverapps.UI.createScale9Sprite(bundles.git.frames.scroll_content_bg);
        content.setContentSize2(styles.width, scroll.height + styles.padding * 2);

        scroll.setPositionRound(content.width / 2, content.height / 2);
        content.addChild(scroll);

        var closeButton = new cleverapps.UI.Button({
            type: {
                button_png: bundles.git.frames.delete_button,
                button_on_png: bundles.git.frames.delete_button
            },
            onClicked: this.close.bind(this)
        });
        closeButton.setPositionRound(styles.closeButton);
        content.addChild(closeButton);

        var title = cleverapps.UI.generateOnlyText(this.command === GitManager.PUSH ? "Push changes?" : "Revert changes?", cleverapps.styles.FONTS.GIT_INFO_TITLE_TEXT);
        content.addChild(title);
        title.setPositionRound(styles.title);

        return content;
    },

    cancel: function () {
        this.close();
    },

    push: function (file) {
        var comment = window.prompt("Enter commit comment:", "");
        if (comment === null) {
            this.cancel();
            return;
        }

        var files = file ? cleverapps.toArray(file) : cleverapps.git.listAllChanges();
        cleverapps.git.push(comment, files);
        this.close();
    },

    revert: function (file) {
        var text = file === undefined
            ? "Are you sure to revert all local changes?"
            : "Are you sure to revert file: " + file + "?";

        var confirm = window.confirm(text);
        if (!confirm) {
            this.cancel();
            return;
        }

        var files = file ? cleverapps.toArray(file) : cleverapps.git.listAllChanges();
        cleverapps.git.revert(files);
        this.close();
    },

    exportContent: function (file, content) {
        if (typeof content !== "string") {
            content = JSON.stringify(content, null, 4);
        }

        var element = document.createElement("a");
        element.href = URL.createObjectURL(new Blob([content], {
            type: "application/json"
        }));
        element.setAttribute("download", file.split("/").pop());
        element.style.display = "none";

        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
});

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    GIT_TEXT_COLOR: new cc.Color(89, 144, 221, 255)
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GIT_INFO_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.BLACK
    },
    GIT_INFO_TITLE_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.GIT_TEXT_COLOR
    }
});

cleverapps.styles.GitInfoWindow = {
    button: {
        width: 150,
        height: 70
    },

    scroll: {
        width: 900,
        height: 500,
        bgPadding: 10
    },

    closeButton: {
        x: { align: "right", dx: 20 },
        y: { align: "top", dy: 20 }
    },

    title: {
        x: { align: "center" },
        y: { align: "top", dy: 60 }
    },

    width: 1000,
    padding: 150,
    margin: 20,
    lineMargin: 10,
    lineButton: {
        width: 100,
        height: 44
    }
};
