/**
 * Created by andrey on 17.08.18.
 */

var Placements = function () {
    this.data = Placements.ENTRIES;

    this.sortKeys();

    this.load();

    cleverapps.whenAllInitialized(function () {
        this.run(Placements.STARTUP);
    }.bind(this));

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, cleverapps.throttle(1000, function () {
        this.run(Placements.ONSHOW);
    }.bind(this)));
};

Placements.prototype.onFocusLostListenerPlacements = function () {
    if (cleverapps.environment.isMainScene() && !cleverapps.meta.getSelectedLocation().isExpedition()) {
        this.run(Placements.FREE_FOCUS_MAIN);
    } else if (cleverapps.environment.isGameScene()) {
        this.run(Placements.FREE_FOCUS_GAME);
    } else {
        this.run(Placements.FREE_FOCUS_OTHER);
    }
};

Placements.prototype.sortKeys = function () {
    this.sortedKeys = Object.keys(this.data);
    this.sortedKeys.sort(function (a, b) {
        a = this.data[a];
        b = this.data[b];
        return (b.priority || 0) - (a.priority || 0);
    }.bind(this));

    this.keysByType = {};
};

Placements.prototype.listKeys = function (type) {
    if (this.keysByType[type]) {
        return this.keysByType[type];
    }

    var key = this.sortedKeys.filter(function (code) {
        var placement = this.data[code];
        return (placement.type & type) !== 0;
    }, this);
    this.keysByType[type] = key;
    return key;
};

Placements.prototype.resetAllPlacements = function () {
    this.lastStarts = {};
    this.onceStarts = {};
    this.save();
};

Placements.prototype.run = function (type, options) {
    options = options || {};

    var keys = this.listKeys(type);
    for (var i = 0; i < keys.length; i++) {
        var code = keys[i];
        var placement = this.data[code];

        if ((typeof placement.once === "function" ? placement.once() : placement.once) && this.onceStarts[code]) {
            continue;
        }

        if (placement.interval) {
            var placementInterval = typeof placement.interval === "function" ? placement.interval() : placement.interval;

            var interval = cleverapps.parseInterval(placementInterval);
            var lastStart = this.lastStarts[code] || 0;
            if (lastStart + interval > Date.now()) {
                continue;
            }
        }

        if (placement.delayFromStart && !options.skipDelayFromStart) {
            var delayFromStart = cleverapps.parseInterval(placement.delayFromStart);
            var start = cleverapps.user.visited || 0;
            if (start + delayFromStart > Date.now()) {
                continue;
            }
        }

        if (placement.filter && !placement.filter()) {
            continue;
        }

        var willUseFocus = (placement.type & Placements.FREE_FOCUS) !== 0;

        if (willUseFocus && cleverapps.focusManager.isFocused()) {
            continue;
        }

        placement.action();
        this.lastStarts[code] = Date.now();

        if (typeof placement.once === "function" ? placement.once() : placement.once) {
            this.onceStarts[code] = true;
        }

        if (willUseFocus && cleverapps.focusManager.isFocused()) {
            break;
        }
    }

    this.save();
};

Placements.prototype.load = function () {
    var info = cleverapps.dataLoader.load(DataLoader.TYPES.PLACEMENTS) || {};
    this.onceStarts = info.onceStarts || {};
    this.lastStarts = info.lastStarts || {};
};

Placements.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.PLACEMENTS, this.getInfo());
};

Placements.prototype.getInfo = function () {
    return {
        lastStarts: this.lastStarts
    };
};

Placements.STARTUP = 1 << 0;
Placements.ONSHOW = 1 << 1;
Placements.INTERMEDIATE = 1 << 2;
Placements.AFTER_GAME_FINISH = 1 << 4;

Placements.FREE_FOCUS_MAIN = 1 << 5;
Placements.FREE_FOCUS_GAME = 1 << 6;
Placements.FREE_FOCUS_OTHER = 1 << 7;

Placements.ONSHOW_OR_STARTUP = Placements.ONSHOW | Placements.STARTUP;
Placements.INTERMEDIATE_OR_AFTER_FINISH = Placements.INTERMEDIATE | Placements.AFTER_GAME_FINISH;

Placements.FREE_FOCUS = Placements.FREE_FOCUS_MAIN | Placements.FREE_FOCUS_GAME | Placements.FREE_FOCUS_OTHER;
