/**
 * Created by andrey on 02.08.2022
 */

var ShowOutcomeWindow = function (f) {
    var outcome = this.game.outcome;
    var boatswain = this.game.boatswain;

    var transitionVictory = this.transitionVictory = outcome === GameBase.OUTCOME_VICTORY
        && Transition.isVictoryTransitionAvailable()
        && !this.level.isBonus() && !this.level.isHard()
        && !LevelStartWindow.isAvailable(cleverapps.meta.getCurrentLevel())
        && cleverapps.missionManager.getRunningMissions().length === 0
        && cleverapps.stickersBook && cleverapps.stickersBook.isAvailable() && cleverapps.stickersBook.isCompleted();

    var skipVictoryWindow = transitionVictory || this.game.shouldSkipVictoryWindow();

    if (outcome === GameBase.OUTCOME_GAVEUP) {
        f();
    } else if (outcome === GameBase.OUTCOME_LOST && cleverapps.config.type !== "battlefield") {
        ShowLoseWindow.call(this, f);
    } else if (skipVictoryWindow) {
        if (transitionVictory) {
            var rewards = new RewardsList(this.game.rewards, {
                event: cleverapps.EVENTS.EARN.LEVEL_REWARD
            });
            rewards.receiveRewards();
            this.game.logCurrencyReward();
        }
        boatswain && boatswain.setIntent(boatswain.canNext() ? Boatswain.NEXT_INTENT : Boatswain.RETURN_INTENT);
        f();
    } else {
        ShowVictoryWindow.call(this, f);
        this.game.logCurrencyReward();
    }
};

var ShowVictoryWindow = function (f) {
    new VictoryWindow(this.game);
    cleverapps.focusManager.onceNoWindowsListener = f;
};

var ShowLoseWindow = function (f) {
    cleverapps.focusManager.compound(f, [
        function (f) {
            if (cleverapps.isRumble() && cleverapps.config.name !== "differences") {
                new BeforeLoseWindow(this.game);
                cleverapps.focusManager.onceNoWindowsListener = f;
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            new LoseWindow(this.game);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }.bind(this)
    ]);
};