/**
 * Created by mac on 1/1/21
 */

var CenterHintView = cc.Node.extend({
    ctor: function (centerHint) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.centerHint = centerHint;
        this.updateSize();
        this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER + 1);

        centerHint.onShowHint = this.createListener(this.showHint.bind(this));
        centerHint.onHideHint = this.createListener(this.hideHint.bind(this));
        centerHint.onRemoveHint = this.createListener(this.removeHint.bind(this));
    },

    updateSize: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();
        this.setContentSize2(sceneSize.width, sceneSize.height);
        this.setPosition(sceneSize.width / 2, sceneSize.height / 2);
    },

    showHint: function (messages, position) {
        var styles = cleverapps.styles.CenterHintView;
        messages = messages.map(this.convertMessageItem.bind(this));
        this.current = new MinimalDialogue({
            items: messages,
            position: styles.positions[position],
            direction: cleverapps.UI.HORIZONTAL,
            delay: 0,
            person: "worker",
            shortAnimations: true
        });
        this.addChild(this.current);
        this.current.display();
        cleverapps.UI.fitToBox(this.current, {
            width: cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? this.width * 0.8 : Math.max(styles.minWidth, this.width - styles.maxPaddingX),
            height: this.current.height
        });
    },

    convertMessageItem: function (item) {
        if (item.icon) {
            if (!this.maxIconHeight) {
                this.maxIconHeight = cleverapps.UI.generateOnlyText("12345", cleverapps.styles.FONTS.FORCE_MESSAGE_TEXT).height * 1.8;
            }
            cleverapps.UI.fitToBox(item.icon, {
                width: item.icon.width,
                height: this.maxIconHeight
            });
            if (item.icon.clearTrack) {
                item.icon.clearTrack(0);
            }
            item.icon.setLocalZOrder(1);
            return item.icon;
        }
        return cleverapps.UI.LabelTTF.GetLocalizedText(item.text || "", item.toReplace);
    },

    hideHint: function () {
        if (!this.current) {
            return;
        }
        var hint = this.current;
        delete this.current;
        hint.remove();
    },

    removeHint: function () {
        if (!this.current) {
            return;
        }

        this.current.stopAllActions();
        this.current.removeFromParent();
        this.current = undefined;
    }
});

cleverapps.styles.CenterHintView = {
    minWidth: 500,
    maxPaddingX: 300,
    maxTextWidth: 1000,

    positions: {}
};

cleverapps.styles.CenterHintView.positions[MinimalDialogue.POSITIONS.TOP] = {
    x: { align: "center" },
    y: { align: "top", dy: -200 }
};

cleverapps.styles.CenterHintView.positions[MinimalDialogue.POSITIONS.BOTTOM] = {
    x: { align: "center" },
    y: { align: "bottom", dy: 160 }
};
