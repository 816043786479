/**
 * Created by andrey on 20.11.17.
 */

cleverapps.Plot = {
    ReturnToMainScene: function (f, level, outcome) {
        var actions = [];

        var heroColor = outcome === GameBase.OUTCOME_VICTORY && typeof match3 !== "undefined" && match3.heroes.readyToOpen();
        if (heroColor) {
            actions.push(function (f) {
                match3.heroes.incLevel(heroColor);

                new HeroWindow({
                    color: heroColor,
                    type: "unlocked"
                });
                cleverapps.focusManager.onceNoWindowsListener = f;
            });

            var heroComics = match3.heroes.getComics(heroColor);
            if (heroComics) {
                actions.push(function (f) {
                    ComicsScene.showComics(f, heroComics);
                });
            }
        }

        var needComics;
        if (cleverapps.meta.getType() === Meta.SIMPLE) {
            needComics = cleverapps.meta.getSelectedLocation().isCompleted() && !cleverapps.meta.isCompleted();
        } else {
            needComics = outcome === GameBase.OUTCOME_VICTORY && level.isRegular() && level.isLastLevelOnEpisode();
        }

        if (needComics) {
            var finishedEpisodeNo = cleverapps.meta.getType() === Meta.SIMPLE ? cleverapps.meta.selectedLocationId() : levels.user.episode - 1;

            var comics = cleverapps.styles.comicses["finishEpisode" + finishedEpisodeNo] || cleverapps.styles.comicses.finishEpisode;
            if (comics) {
                actions.push(function (f) {
                    ComicsScene.showComics(f, comics);
                });
            }
        }

        if (cleverapps.isKnockoutGame() && level.isRegular()) {
            var rumble = cleverapps.knockoutGame.rumble;

            if (rumble.outcome === Rumble.OUTCOME_UNKNOWN) {
                actions.push(function (f) {
                    cleverapps.meta.wantsToPlay(f);
                });
            } else {
                if (rumble.outcome === Rumble.OUTCOME_WIN) {
                    actions.push(function (f) {
                        cleverapps.knockoutGame.openWindow(f);
                    });

                    actions.push(function (f) {
                        new KnockoutVictoryWindow();
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    });
                }

                actions.push(function (f) {
                    cleverapps.knockoutGame.stopRumble();

                    f();
                });

                actions.push(function (f) {
                    cleverapps.meta.gotoMainLocation(f);
                });
            }
        } else {
            actions.push(function (f) {
                var missionWithIntent = cleverapps.missionManager.getMissionWithIntent();
                if (missionWithIntent && missionWithIntent.logic.willUseIntent(level)) {
                    missionWithIntent.useMissionIntent(f);
                } else {
                    cleverapps.meta.gotoMainLocation(f);
                }
            });
        }

        if (level.isRegular() && outcome === GameBase.OUTCOME_VICTORY) {
            if (cleverapps.Plot.getDialogueAfterLevel(level)) {
                actions.push(function (f) {
                    cleverapps.Plot.finishLevelDialogue(f, level);
                });
            }
        }

        cleverapps.focusManager.compound(f, actions);
    },

    onFinishEpisode: function (f, options) {
        cleverapps.focusManager.compound(f, [
            function (f) {
                new FinishEpisodeWindow(options.episodeNo);

                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        ]);
    },

    onStartup: function (f, returnClassOnly) {
        if (levels.user.episode === 0 && levels.user.level === 0) {
            return this.onFirstInit(f, returnClassOnly);
        } 
        if (returnClassOnly) {
            return cleverapps.meta.getMainScene();
        }
        cleverapps.meta.gotoMainLocation(f);
    },

    onFirstInit: function (focus, returnClassOnly) {
        var actions = [];

        if (cleverapps.styles.comicses && cleverapps.styles.comicses.begin && connector.info.source !== "playable") {
            if (returnClassOnly) {
                return {
                    scene: ComicsScene,
                    options: [cleverapps.styles.comicses.begin]
                };
            }
            actions.push(function (f) {
                ComicsScene.showComics(f, cleverapps.styles.comicses.begin);
            });
        } else if (this.hasStartEpisodeWindow()) {
            if (returnClassOnly) {
                return cleverapps.meta.getMainScene();
            }
            actions.push(function (f) {
                cleverapps.meta.gotoMainLocation(f);
            });
            actions.push(function (f) {
                this.onStartEpisode(f, {
                    episodeNo: cleverapps.meta.selectedLocationId()
                });
            }.bind(this));

            cleverapps.focusManager.compound(focus, actions);
            return;
        }

        if ((cleverapps.meta.getType() === Meta.HOSE || cleverapps.meta.getType() === Meta.SIMPLE) && connector.info.source !== "playable") {
            if (returnClassOnly) {
                return {
                    scene: GameScene,
                    options: [{ level: new Level(0, 0) }]
                };
            }
            actions.push(function (f) {
                if (cleverapps.lives) {
                    cleverapps.lives.reset();
                }
                cleverapps.meta.wantsToPlay(f);
            });
        } else {
            if (returnClassOnly) {
                return cleverapps.meta.getMainScene();
            }
            actions.push(function (f) {
                cleverapps.meta.gotoMainLocation(f);
            });
        }

        cleverapps.focusManager.compound(focus, actions);
    },

    hasStartEpisodeWindow: function () {
        return cleverapps.meta.getType() === Meta.HOSE && cleverapps.config.name === "riddles" && connector.info.source !== "playable";
    },

    onStartEpisode: function (f, options) {
        if (cleverapps.meta.isCompleted()) {
            new FinisherWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        } else if (this.hasStartEpisodeWindow()) {
            new StartEpisodeWindow(options.episodeNo);
            cleverapps.focusManager.onceNoWindowsListener = f;
        } else {
            f();
        }
    },

    getDialogueAfterLevel: function (level) {
        if (!cleverapps.styles.dialogues || !level.isRegular()) {
            return false;
        }

        var passedNo = cleverapps.user.getHumanReadableNumber() - 1;
        return cleverapps.styles.dialogues["finishLevel" + passedNo];
    },

    finishLevelDialogue: function (f, level) {
        var styles = this.getDialogueAfterLevel(level);
        if (!styles) {
            return;
        }

        var scene = cleverapps.scenes.getRunningScene();

        cleverapps.focusManager.compound(f, [
            function (f) {
                if (styles.bundles) {
                    cleverapps.bundleLoader.loadBundles(styles.bundles, {
                        onSuccess: function () {
                            f();
                        },
                        blocked: true
                    });
                } else {
                    f();
                }
            },
            function (f) {
                var scale = 1.2;
                var dy = scene.height * (scale - 1);
                scene.createFilmEffect({
                    scale: scale,
                    layerTimeout: 0.3,
                    scaleTimeout: 1,
                    moveBy: { y: dy },
                    onShow: f
                });
            },
            function (f) {
                var dialogue = new Dialogue(styles.dialogue, {
                    autoClose: true,
                    autoScroll: 3,
                    parentScene: true,
                    showUp: true
                });
                dialogue.on("afterClose", f);

                var dialogueView = new DialogueView(dialogue);
                dialogueView.setLocalZOrder(51);
            },
            function (f) {
                scene.removeFilmEffect(0.4, f);
            }
        ]);
    }
};

cleverapps.styles.comicses = {};