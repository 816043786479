/**
 * Created by slava on 9/8/17
 */

var FieldBoosters = function () {
    cleverapps.EventEmitter.call(this);
    this.active = undefined;

    this.list = cleverapps.boosters.listBoosters();

    this.list.forEach(function (booster) {
        booster.onExecuteListener = function () {
            this.onExecuteListener();
        }.bind(this);
    }, this);

    cleverapps.windows.on("openWindow", function () {
        this.setActive(undefined);
    }.bind(this), this);

    this.onSetActiveListener = function () {};
    this.onExecuteListener = function () {};
    this.onGetBoosterView = function () {};
};

FieldBoosters.prototype = Object.create(cleverapps.EventEmitter.prototype);
FieldBoosters.prototype.constructor = FieldBoosters;

FieldBoosters.prototype.stop = function () {
    runCleaners(this);

    this.list.forEach(function (booster) {
        booster.stop();
    });

    this.setActive(undefined);
};

FieldBoosters.prototype.onTouchBegan = function (booster) {
    if (Game.currentGame.counter.isActive()) {
        return;
    }

    if (this.active === booster) {
        this.setActive(undefined);
    } else if (booster.canExecute()) {
        this.setActive(booster);
    } else if (booster.isAdsAvailable()) {
        cleverapps.rewardedAdsManager.playRewarded(booster.limit, function () {
            cleverapps.adsLimits.watch(booster.limit);
            cleverapps.boosters.add(booster.id, 1);
            this.setActive(booster);
        }.bind(this));
    } else if (booster.buy()) {
        this.setActive(booster);
    }

    return true;
};

FieldBoosters.prototype.onTouchMoved = function (touch) {
    var cell = Game.currentGame.field.onGetView().getTouchedCell(touch);
    this.moveThrottled(cell);
};

FieldBoosters.prototype.moveThrottled = cleverapps.timeredThrottle(300, function (cell) {
    if (this.active) {
        this.active.onFieldTouchMoved(cell);
    }
});

FieldBoosters.prototype.onTouchEnded = function (targetCell) {
    if (this.active) {
        this.setActive(undefined, targetCell);
    }
};

FieldBoosters.prototype.onTouchOut = function (booster, targetCell) {
    if (Game.currentGame.counter.isActive()) {
        return;
    }

    if (cleverapps.tutorial.isActive() && this.active && !this.active.isAllowedTarget(targetCell)) {
        return; 
    }

    if (this.active === booster) {
        this.setActive(undefined, targetCell);
    }
};

FieldBoosters.prototype.amountAvailable = function () {
    var amount = 0;
    this.list.forEach(function (booster) {
        if (!booster.isDisabled()) {
            amount++;
        }
    });
    return amount;
};

FieldBoosters.prototype.listAll = function () {
    return this.list;
};

FieldBoosters.prototype.setActive = function (booster, targetCell) {
    if (this.active === booster) {
        return;
    }

    if (this.active) {
        this.active.execute(targetCell);

        cleverapps.availableMove.resume();
        this.active.deactivate();

        this.freeFocus && this.freeFocus();
    }

    this.active = booster;

    if (this.active) {
        cleverapps.focusManager.display({
            focus: "boosterDragging",
            action: function (f) {
                cleverapps.focusManager.setEventNodes([this.active.onGetView()]);
                this.freeFocus = f;
            }.bind(this)
        });

        cleverapps.availableMove.pause();
        Game.currentGame.field.removeSelection();
        this.active.onActivateListener();

        cleverapps.tutorial.trigger("boosterActivation");
    }

    this.onSetActiveListener();
};
