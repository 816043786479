/**
 * Created by mac on 10/22/18
 */

cleverapps.HERO_ACTIVATE_BY_CLICK_NONE = 0;
cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE = 1;
cleverapps.HERO_ACTIVATE_BY_CLICK_NO_MOVE = 2;

var HeroesLibrary = {
    helpers: {
        followDir: function (dir, combo) {
            var x = combo.x, y = combo.y;
            var path = [];
            var actionLength = 0;
            var hurtLength = 0;
            while (actionLength < combo.algo.numTargets) {
                x += dir.x;
                y += dir.y;

                if (!Game.currentGame.field.inField(y, x)) {
                    break;
                }

                var cell = Game.currentGame.field.cells[y][x];

                if (cell instanceof WallCell) {
                    break;
                }

                actionLength++;
                if (cell) {
                    if (CombosLibrary.filters.notOnThreeInRow(combo, cell) && CombosLibrary.filters.hurtable(combo, cell)) {
                        hurtLength++;
                    }
                    path.push(cell);
                }
            }
            return {
                path: path,
                actionLength: actionLength,
                hurtLength: hurtLength
            };
        },

        allFiltersCells: function (comboCell, notNecessarily) {
            var options = [];
            if (Game.currentGame && Game.currentGame.field) {
                var i, j;
                for (i = 0; i < Field.SIZE; i++) {
                    for (j = 0; j < Field.SIZE; j++) {
                        if (CombosLibrary.checkFilters(comboCell, Game.currentGame.field.cells[i][j], notNecessarily)) {
                            options.push(Game.currentGame.field.cells[i][j]);
                        }
                    }
                }
            }
            return options;
        },

        chooseDirection: function (combo, iterator) {
            var variants = BaseCell.neighbors;
            var goodVariants = [];
            var l = -1;

            var smartTargets = HintHelper.smartChoice();
            var variantsWithSmartTarget = [];

            variants.forEach(function (variant) {
                var cells = HeroesLibrary.helpers.followDir(variant, combo).path;

                var getKey = function (item) {
                    return item.x + "_" + item.y;
                };

                if (cleverapps.intersect(cells, smartTargets, getKey).length) {
                    variantsWithSmartTarget.push(variant);
                }
            });

            if (variantsWithSmartTarget.length) {
                variants = variantsWithSmartTarget;
            }

            for (var i = 0; i < variants.length; i++) {
                var res = HeroesLibrary.helpers.followDir(variants[i], combo);
                if (res.hurtLength > l) {
                    l = res.hurtLength;
                    goodVariants = [];
                }

                if (res.hurtLength === l) {
                    goodVariants.push(variants[i]);
                }
            }

            if (iterator) {
                var actionDirection = combo.actionDirection;
                var actionLength = combo.actionLength;

                goodVariants.forEach(function (direct) {
                    combo.actionDirection = direct;
                    combo.actionLength = HeroesLibrary.helpers.followDir(direct, combo).actionLength;
                    iterator();
                });

                combo.actionDirection = actionDirection;
                combo.actionLength = actionLength;

                return;
            }

            var direct = cleverapps.Random.choose(goodVariants);

            combo.actionDirection = direct;
            combo.actionLength = HeroesLibrary.helpers.followDir(direct, combo).actionLength;
        }
    },

    targets: {
        findByFilters: function (comboCell, returnAll) {
            var smartTargets = [];
            if (comboCell.algo.atLeastOneGoal) {
                smartTargets = HintHelper.smartChoice();
            }

            var smartTargetExists = function (target) {
                for (var t = 0; t < smartTargets.length; t++) {
                    if (target.x === smartTargets[t].x && target.y === smartTargets[t].y) {
                        return true;
                    }
                }
                return false;
            };

            var options = HeroesLibrary.helpers.allFiltersCells(comboCell);
            var availableTargets;

            if (options.length < comboCell.algo.numTargets) {
                availableTargets = HeroesLibrary.helpers.allFiltersCells(comboCell, true);
                availableTargets = cleverapps.Random.shuffle(availableTargets);
                for (var i = 0; i < availableTargets.length; i++) {
                    if (options.indexOf(availableTargets[i]) < 0) {
                        options.push(availableTargets[i]);
                        if (options.length >= comboCell.algo.numTargets) {
                            break;
                        }
                    }
                }
            } else {
                availableTargets = cleverapps.Random.shuffle(options);
                options = availableTargets.slice(0, comboCell.algo.numTargets);
            }

            if (smartTargets.length && !options.some(smartTargetExists)) {
                var goal = availableTargets.filter(smartTargetExists)[0];
                if (goal) {
                    options.pop();
                    options.push(goal);
                }
            }

            if (returnAll) {
                return cleverapps.unique(availableTargets.concat(smartTargets), function (item) {
                    return item.x + "_" + item.y;
                });
            }

            return options;
        },

        byDirection: function (combo) {
            var res = HeroesLibrary.helpers.followDir(combo.actionDirection, combo);
            return res.path;
        }
    }
};

HeroesLibrary.levels = {
    e: {
        1: { numTargets: 1 },
        2: { numTargets: 2 },
        3: { numTargets: 3 },
        4: { numTargets: 3, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        5: { numTargets: 4, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        6: { numTargets: 5, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        7: { numTargets: 5, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NO_MOVE }
    },

    d: {
        1: { numTargets: 2 },
        2: { numTargets: 3 },
        3: { numTargets: 4 },
        4: { numTargets: 4, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        5: { numTargets: 5, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        6: { numTargets: 8, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        7: { numTargets: 8, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NO_MOVE }
    },

    a: {
        1: { power: 1, makeHeroProb: 0.2 },
        2: { power: 2, makeHeroProb: 0.25 },
        3: { power: 3, makeHeroProb: 0.3 },
        4: { power: 3, makeHeroProb: 0.3, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        5: { power: 4, makeHeroProb: 0.35, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        6: { power: 5, makeHeroProb: 0.4, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        7: { power: 5, makeHeroProb: 0.4, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NO_MOVE }
    },

    b: {
        1: { power: 1 },
        2: { power: 2 },
        3: { power: 3 },
        4: { power: 3, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        5: { power: 4, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        6: { power: 5, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        7: { power: 5, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NO_MOVE }
    },

    c: {
        1: { numTargets: 7 },
        2: { numTargets: 10 },
        3: { numTargets: 15 },
        4: { numTargets: 15, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        5: { numTargets: 20, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        6: { numTargets: 30, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        7: { numTargets: 30, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NO_MOVE }
    },

    w: {
        1: { numTargets: 1 },
        2: { numTargets: 2 },
        3: { numTargets: 3 },
        4: { numTargets: 3, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        5: { numTargets: 4, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        6: { numTargets: 5, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_MOVE },
        7: { numTargets: 5, activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NO_MOVE }
    }
};

HeroesLibrary.byColors = {
    e: {
        shootFlyDuration: function (heroCell, target) {
            var dist = Math.abs(target.x - heroCell.x) + Math.abs(target.y - heroCell.y);
            var duration = dist / 10;
            duration = Math.max(duration, 0.8);
            duration = Math.min(duration, 2.5);
            return duration;
        },

        shootAlgorithm: "bezier",

        action_timeout: 1300,
        action_delay: 700,
        between_shoot_timeout: 100,

        blast_animation: bundles.game.jsons.bomb_animation_json,

        activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NONE,
        numTargets: 1,
        atLeastOneGoal: true,
        power: 1,

        findTargets: HeroesLibrary.targets.findByFilters,
        filters: [CombosLibrary.filters.notSelf, CombosLibrary.filters.hurtable, CombosLibrary.filters.notAttackBuddies, CombosLibrary.filters.notAttackMulticolorCell, CombosLibrary.filters.notOnThreeInRow],
        action: CombosLibrary.actions.hurt
    },

    d: {
        shootFlyDuration: function () {
            return 0;
        },

        shootAlgorithm: "blast",

        action_timeout: 700,
        action_delay: 1200,
        between_shoot_timeout: 0,

        bigZOrder: true,

        blast_animation: bundles.game.jsons.bomb_animation_json,

        activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NONE,
        numTargets: 1,
        power: 1,

        findTargets: HeroesLibrary.targets.findByFilters,
        filters: [CombosLibrary.filters.notSelf, CombosLibrary.filters.hurtable, CombosLibrary.filters.closeBy, CombosLibrary.filters.notOnThreeInRow, CombosLibrary.filters.notAttackBuddies, CombosLibrary.filters.notAttackMulticolorCell],
        action: CombosLibrary.actions.hurt
    },

    a: {
        shootFlyDuration: function () {
            return 1.2;
        },

        shootAlgorithm: "blast",

        action_timeout: 1500,
        action_delay: 400,
        between_shoot_timeout: 0,

        bigZOrder: true,

        blast_animation: bundles.heroes.jsons.A_hero_spine_effect_json,

        activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NONE,
        numTargets: 5,
        power: 1,
        makeHeroProb: 0.2,

        findTargets: HeroesLibrary.targets.findByFilters,
        filters: [CombosLibrary.filters.notSelf, CombosLibrary.filters.withCoefOrTurnToHero, CombosLibrary.filters.notAttackBuddies, CombosLibrary.filters.notOnThreeInRow],
        action: CombosLibrary.actions.incCoefOrMakeHero
    },

    b: {
        shootFlyDuration: function () {
            return 1;
        },

        shootAlgorithm: "jump",

        action_timeout: 1400,
        action_delay: 175,
        between_shoot_timeout: 0,

        bigZOrder: true,

        activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NONE,
        numTargets: 1,
        power: 1,

        findTargets: CombosLibrary.targets.smartGoal,
        filters: [CombosLibrary.filters.notSelf, CombosLibrary.filters.hurtable, CombosLibrary.filters.notAttackBuddies, CombosLibrary.filters.notAttackMulticolorCell, CombosLibrary.filters.notOnThreeInRow],
        action: CombosLibrary.actions.hurt
    },

    c: {
        shootFlyDuration: function () {
            return 1.2;
        },

        shootAlgorithm: "blast",

        action_timeout: 900,
        action_delay: 700,
        between_shoot_timeout: 0,

        bigZOrder: true,

        blast_animation: bundles.heroes.jsons.C_hero_spine_effect_json,

        activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NONE,
        numTargets: 3,
        power: 1,

        findTargets: HeroesLibrary.targets.findByFilters,
        filters: [CombosLibrary.filters.notSelf, CombosLibrary.filters.shuffleable, CombosLibrary.filters.notAttackBuddies, CombosLibrary.filters.notOnThreeInRow],

        actionToAllCells: true,
        action: CombosLibrary.actions.shuffle
    },

    w: {
        shootFlyDuration: function (heroCell, target) {
            var dist = Math.abs(target.x - heroCell.x) + Math.abs(target.y - heroCell.y);
            var fullDuration = heroCell.algo.direct_timeout * 0.8 / 1000;
            if (!heroCell.actionLength) {
                return fullDuration;
            }
            return fullDuration * dist / heroCell.actionLength;
        },

        shootAlgorithm: "none",

        action_timeout: 1500,
        action_delay: 1000,
        direct_timeout: 600,
        between_shoot_timeout: 0,

        bigZOrder: true,

        activateByClick: cleverapps.HERO_ACTIVATE_BY_CLICK_NONE,
        numTargets: 1,
        power: 1,

        beforeEffect: HeroesLibrary.helpers.chooseDirection,

        findTargets: HeroesLibrary.targets.byDirection,
        filters: [CombosLibrary.filters.hurtable],

        action: CombosLibrary.actions.hurt
    }
};