/**
 * Created by mac on 10/9/20
 */

var AdminDataView = cc.Node.extend({
    ctor: function (availableWidth) {
        this._super();

        this.onReady = function () {};

        var styles = cleverapps.styles.AdminDataView;

        this.availableWidth = availableWidth - styles.padding.x * 2;

        var dataSource = cleverapps.administrator.adminDetails.statistics;

        this.reshape(dataSource.ready ? dataSource.getData() : []);

        if (!dataSource.ready) {
            dataSource.whenReady(this.createListener(function (data) {
                this.reshape(data);
                this.onReady();
            }.bind(this)), this);
        }

        dataSource.on("reset", this.removeAllChildren.bind(this), this);
    },

    reshape: function (data) {
        this.removeAllChildren();

        var content = this.createData(data);
        this.setContentSize2(content.width * content.scaleX, content.height * content.scaleY);
        this.addChild(content);
        content.setPositionRound(this.width / 2, this.height / 2);
    },

    createData: function (data) {
        var styles = cleverapps.styles.AdminDataView;

        var versions = data.map(function (versionData) {
            var versionNo = cleverapps.UI.generateOnlyText("version_" + (versionData.version || 0), cleverapps.styles.FONTS.ADMIN_DATA_TEXT);
            versionNo.setAnchorPoint(0, 0.5);
            versionData = cleverapps.clone(versionData);
            delete versionData.version;

            var versionLayout = this.createVersionData(versionData);

            var layout = new cleverapps.Layout([versionNo, versionLayout], {
                direction: cleverapps.UI.VERTICAL,
                margin: styles.rowsMargin
            });

            var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_bg);
            bg.setContentSize(layout.width + 12, layout.height + 12);
            layout.setPositionRound(bg.width / 2, bg.height / 2);
            bg.addChild(layout);

            return bg;
        }, this);

        if (versions.length) {
            var versionLayout = new cleverapps.Layout(versions, {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.colsMargin
            });
            var versionScroll = this.createScroll(versionLayout);
        }

        var annotation = new AdminLevelAnnotation(this.availableWidth);
        var mapTilesChooser = this.createMapTilesChooser();
        var mapUnitsChooser = this.createMapUnitsChooser();

        var items = [mapTilesChooser, mapUnitsChooser, versionScroll, annotation].filter(function (item, index, array) {
            if (item) {
                item.setAnchorPoint(0, 0.5);
                item.setLocalZOrder(array.length - index);
                return true;
            }
            return false;
        });

        return new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createDropDown: function (title, items, value, callback) {
        var styles = cleverapps.styles.AdminDataView;

        var label = cleverapps.UI.generateOnlyText(title, cleverapps.styles.FONTS.ADMIN_DATA_TEXT);

        var dropdown = new DropDown({
            availableItems: items,
            value: value,
            size: cc.size(styles.dropdown.width, label.height),
            font: cleverapps.styles.FONTS.ADMIN_DATA_TEXT
        });

        dropdown.setDelegate({
            editBoxEditingDidEnd: function (dropdown) {
                callback(dropdown.value);
            }
        });

        var layout = new cleverapps.Layout([label, dropdown], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.dropdown.margin
        });

        cleverapps.UI.fitToBox(layout, {
            width: this.availableWidth
        });

        return layout;
    },

    createMapTilesChooser: function () {
        if (typeof EditorMap2d === "undefined") {
            return;
        }

        var availableTiles = Map2d.AVAILABLE_TILES.map(function (tilesTexture) {
            return tilesTexture.replace("tiles_", "");
        });
        availableTiles.sort(function (a, b) {
            return a.localeCompare(b);
        });

        var adminLevel = cleverapps.administrator.adminLevels.getActive();

        return this.createDropDown("Map tiles:", availableTiles, adminLevel.tiles, function (value) {
            LevelManager.getInstance().processLevelContent(function (content) {
                content.tiles = value;
            });
        });
    },

    createMapUnitsChooser: function () {
        if (typeof EditorMap2d === "undefined") {
            return;
        }

        var availableUnits = Map2d.AVAILABLE_UNITS.map(function (tilesTexture) {
            return tilesTexture.replace("units_", "");
        });
        availableUnits.sort(function (a, b) {
            return Boolean(b === "main") - Boolean(a === "main") || a.localeCompare(b);
        });

        var adminLevel = cleverapps.administrator.adminLevels.getActive();

        return this.createDropDown("Map units:", availableUnits, adminLevel.units, function (value) {
            LevelManager.getInstance().processLevelContent(function (content) {
                content.units = value;
            });
        });
    },

    createScroll: function (content) {
        var styles = cleverapps.styles.AdminDataView;

        var scroll = new cleverapps.UI.ScrollView(undefined, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE
        });

        scroll.setInnerContent(content);
        scroll.setContentSize2(Math.min(this.availableWidth, content.width), content.height + styles.scroll.padding.y * 2);
        scroll.scrollToPercent(0);
        return scroll;
    },

    createVersionData: function (data) {
        var styles = cleverapps.styles.AdminDataView;

        var items = Object.keys(data).map(function (key) {
            var keyText = cleverapps.UI.generateOnlyText(key + ": ", cleverapps.styles.FONTS.ADMIN_DATA_TEXT);
            keyText.setDimensions(styles.keyWidth, 0);

            var value = cleverapps.UI.generateImageText(data[key] + "", cleverapps.styles.FONTS.ADMIN_DATA_TEXT);
            value.setDimensions(styles.valueWidth, 0);

            var item = new cleverapps.Layout([keyText, value], {
                direction: cleverapps.UI.HORIZONTAL
            });
            item.setAnchorPoint(0, 0.5);
            return item;
        });

        var versionLayout = new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            reversed: true,
            margin: styles.rowsMargin
        });
        versionLayout.setAnchorPoint(0, 0.5);

        return versionLayout;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_DATA_TEXT: {
        name: "formal",
        size: 17,
        color: cleverapps.styles.COLORS.BLACK
    },

    ADMIN_RED_TEXT: {
        name: "formal",
        size: 17,
        color: cleverapps.styles.COLORS.COLOR_RED
    }
});

cleverapps.styles.AdminDataView = {
    rowsMargin: 2,
    colsMargin: 20,
    keyWidth: 100,
    valueWidth: 100,

    margin: 10,

    annotation: {
        x: { align: "right" },
        y: { align: "center" }
    },

    dropdown: {
        width: 180,
        margin: 10
    },

    padding: {
        x: 20
    },

    scroll: {
        padding: {
            y: 0
        }
    }
};