/**
 * Created by Vladislav on 05.09.2024.
 */

Object.assign(VirtualProducts, {
    stickers0: {
        title: "StickersBook.products.specialPack",
        currency: "hard",
        spentEvent: cleverapps.EVENTS.SPENT.STICKERS_3,
        price: 1000,
        icon: bundles.stickers_shop.frames.stickers_chest_0,
        availableAfterCollection: 2,
        reward: {}
    },

    stickers1: {
        title: "StickersBook.products.mediumPack",
        currency: "hard",
        spentEvent: cleverapps.EVENTS.SPENT.STICKERS_2,
        price: 500,
        icon: bundles.stickers_shop.frames.stickers_chest_1,
        reward: {}
    },

    stickers2: {
        title: "StickersBook.products.smallPack",
        currency: "hard",
        spentEvent: cleverapps.EVENTS.SPENT.STICKERS_1,
        price: 200,
        icon: bundles.stickers_shop.frames.stickers_chest_2,
        reward: {}
    }
});
switch (cleverapps.config.type) {
    case "tile3":
        Object.assign(VirtualProducts.stickers2, {
            reward: {
                boosters: {
                    cheap: 2,
                    medium: 1
                }
            },
            price: 100
        });
        Object.assign(VirtualProducts.stickers1, {
            reward: {
                boosters: {
                    medium: 1
                },
                lives: 0,
                soft: 300
            },
            price: 250
        });
        Object.assign(VirtualProducts.stickers0, {
            reward: {
                boosters: {
                    expensive: 1
                },
                unlimitedLives: "6 hours"
            },
            price: 500
        });
        break;
    case "solitaire":
        Object.assign(VirtualProducts.stickers2, {
            reward: {
                boosters: {
                    before_medium: 1
                },
                lives: 2
            }
        });
        Object.assign(VirtualProducts.stickers1, {
            reward: {
                boosters: {
                    before_medium: 1
                },
                lives: 3
            }
        });
        Object.assign(VirtualProducts.stickers0, {
            reward: {
                boosters: {
                    before_expensive: 2
                },
                unlimitedLives: "6 hours"
            }
        });
        break;
    case "board":
        Object.assign(VirtualProducts.stickers2, {
            reward: {
                boosters: {
                    cheap: 2
                }
            }
        });
        Object.assign(VirtualProducts.stickers1, {
            reward: {
                boosters: {
                    cheap: 3
                }
            }
        });
        Object.assign(VirtualProducts.stickers0, {
            reward: {
                boosters: {
                    cheap: 2,
                    expensive: 1
                }
            }
        });
        break;
    case "match3":
        Object.assign(VirtualProducts.stickers2, {
            reward: {
                boosters: {
                    before_medium: 1
                },
                lives: 2
            }
        });
        Object.assign(VirtualProducts.stickers1, {
            reward: {
                boosters: {
                    before_medium: 1
                },
                lives: 3
            }
        });
        Object.assign(VirtualProducts.stickers0, {
            reward: {
                boosters: {
                    before_expensive: 2
                },
                unlimitedLives: "6 hours"
            }
        });
        break;
    case "blocks":
        Object.assign(VirtualProducts.stickers2, {
            reward: {
                boosters: {
                    cheap: 2,
                    medium: 1
                }
            },
            price: 100
        });
        Object.assign(VirtualProducts.stickers1, {
            reward: {
                boosters: {
                    medium: 1
                },
                soft: 300
            },
            price: 250
        });
        Object.assign(VirtualProducts.stickers0, {
            reward: {
                boosters: {
                    expensive: 1
                },
                unlimitedLives: "6 hours"
            },
            price: 500
        });
        break;
    case "merge":
        Object.assign(VirtualProducts.stickers2, {
            reward: {
                energy: 10
            }
        });
        Object.assign(VirtualProducts.stickers1, {
            reward: {
                energy: 100
            }
        });
        Object.assign(VirtualProducts.stickers0, {
            reward: {
                energy: 200
            }
        });
}

if (cleverapps.config.name === "scramble") {
    Object.assign(VirtualProducts.stickers2, {
        reward: {
            boosters: {
                before_cheap: 1,
                before_medium: 1
            }
        }
    });
    Object.assign(VirtualProducts.stickers1, {
        reward: {
            boosters: {
                before_cheap: 1,
                before_expensive: 1
            }
        }
    });
    Object.assign(VirtualProducts.stickers0, {
        reward: {
            boosters: {
                before_medium: 1,
                before_expensive: 1
            }
        }
    });
}

Object.assign(VirtualProducts.stickers2.reward, {
    stickers: 2
});
Object.assign(VirtualProducts.stickers1.reward, {
    stickers: 6
});
Object.assign(VirtualProducts.stickers0.reward, {
    stickers: 15
});
