/**
 * Created by spepa on 03.09.2024
 */

var PassHeaderPack = cc.Node.extend({
    ctor: function (offer, options) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.offer = offer;
        this.options = options;

        this.createPackAnimation();
    },

    createPackAnimation: function () {
        var styles = cleverapps.styles.PassHeaderPack[this.offer.type] || cleverapps.styles.PassHeaderPack;

        var pack = new cleverapps.Spine(this.options.pack_json);
        pack.setAnimation(0, "animation", true);
        this.addChild(pack);
        this.setContentSize(pack.getContentSize());
        pack.setPositionRound(this.width / 2, this.height / 2);

        var badge = new PassPackBadge(this.offer);
        pack.addChild(badge);
        badge.setPositionRound(styles.badge);

        var packOnClick = function () {
            new SingleProductOfferWindow(this.offer);
            this.options.close();
        }.bind(this);
        cleverapps.UI.onClick(pack, packOnClick);
        cleverapps.UI.onClick(badge, packOnClick);
        cleverapps.UI.applyHover(pack);
    }
});

cleverapps.styles.PassHeaderPackTemplate = {
    badge: {
        x: { align: "right", dx: 141 },
        y: { align: "center", dy: 11 }
    }
};

cleverapps.styles.PassHeaderPack = cleverapps.overrideStyles(cleverapps.styles.PassHeaderPackTemplate, {}, true);

cleverapps.styles.PassHeaderPack[Offers.TYPES.SALEPASS] = cleverapps.overrideStyles(cleverapps.styles.PassHeaderPackTemplate, {
    badge: {
        x: { align: "right", dx: 181 },
        y: { align: "center", dy: -8 }
    }
}, true);

cleverapps.styles.PassHeaderPack[Offers.TYPES.CLPASS] = cleverapps.overrideStyles(cleverapps.styles.PassHeaderPackTemplate, {
    badge: {
        x: { align: "right", dx: 57 },
        y: { align: "center", dy: -48 }
    }
}, true);