/**
 * Created by mac on 4/16/22
 */

var ManualStarters = {
    FakeStarter: function () {
        return false;
    },

    ChildStarter: function (parent) {
        return function () {
            return cleverapps.missionManager.findByType(parent);
        };
    },

    ExpeditionFeastStarter: function (type) {
        var location = cleverapps.meta.getSelectedLocation();
        if (!location.isCompleted() && Merge.currentMerge && Merge.currentMerge.map.fogs.isOpened("fog6")) {
            if (location.locationId === Missions[type].location) {
                var duration = cleverapps.parseInterval(Missions[type].duration);
                var cooldown = duration;
                var lastRemoved = cleverapps.missionManager.getLastRemoved(type);
                return cleverapps.meta.logic.getLocationTimeLeft(location.locationId) >= duration && lastRemoved + cooldown < Date.now();
            }
        }
        return false;
    },

    SnailFeastStarter: function (type, options) {
        var location = cleverapps.meta.getSelectedLocation();

        if (location.isCompleted() || location.locationId !== Missions[type].location || !MissionTree.targets[type] || !MissionTree.targets[type].length) {
            return false;
        }

        var lastRemoved = cleverapps.missionManager.getLastRemoved(type);

        if (lastRemoved < cleverapps.meta.logic.getLocationStartTime(location.locationId)) {
            lastRemoved = 0;
        }

        if (options && options.fromMissionTree) {
            return !lastRemoved;
        }

        if (!lastRemoved) {
            return false;
        }

        var duration = cleverapps.parseInterval(Missions[type].duration);
        var cooldown = duration;
        if (cleverapps.config.debugMode) {
            cooldown = cleverapps.parseInterval("3 minutes");
        }
        return cleverapps.meta.logic.getLocationTimeLeft(location.locationId) >= duration && lastRemoved + cooldown < Date.now();
    },

    MissionTreeStarter: function (type, options) {
        return options && options.fromMissionTree;
    },

    FogStarter: function (type) {
        return Missions[type].startFog && Map2d.currentMap.fogs.isOpened(Missions[type].startFog);
    },

    UnitStarter: function (type) {
        return Missions[type].startUnit && cleverapps.unitsLibrary.isOpened(Missions[type].startUnit);
    },

    clPassStarter: function (type) {
        if (!cleverapps.user.checkAvailable(Missions[type].available)) {
            return false;
        }
        var lastRemoved = cleverapps.missionManager.getLastRemoved(type);
        return lastRemoved + cleverapps.parseInterval(Missions[type].cooldown) < Date.now();
    }
};
