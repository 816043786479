/**
 * Created by slava on 3/6/19
 */

var HintHelper = {
    findMove: function (ideas, boosterId) {
        if (Game.currentGame.boosters.list[boosterId].isDisabled() || cleverapps.config.wysiwygMode) {
            return;
        }

        if (Math.random() > 0.3) {
            return;
        }

        var field = Game.currentGame.field;

        for (var row = 0; row < Field.SIZE; row++) {
            for (var col = 0; col < Field.SIZE; col++) {
                var cell = field.cells[row][col];
                if (cell) {
                    for (var i = 0; i < ideas.length; i++) {
                        if (ideas[i].call(undefined, cell)) {
                            return {
                                boosterId: boosterId,
                                target: { row: row, col: col }
                            };
                        }
                    }
                }
            }
        }
    },

    isRemovable: function (cell) {
        if (!cell.hurtable || !cell.hurtable) {
            return false;
        }

        return ["DonutBoxCell", "IceCreamMakerCell", "CandleCell"].indexOf(cell.constructor.name) === -1;
    },

    isActiveGoal: function (cell) {
        var goals = Game.currentGame.goals.elements;
        var goalId = cell.getGoalId();

        return goalId !== undefined && goals[goalId] && !goals[goalId].done;
    },

    countSameGoals: function (cell) {
        var goals = Game.currentGame.goals.elements;
        var goalId = cell.getGoalId();

        if (HintHelper.isActiveGoal(cell)) {
            return goals[goalId].getRemaining();
        }
    },

    getEater: function (color) {
        var eaters = Game.currentGame.EATERS.filter(function (eater) {
            return eater.getAcceptedColors().indexOf(color) !== -1;
        });

        if (!eaters || eaters.length === 0) {
            return;
        }

        if (EaterComponent.COLOR_EATERS[color] !== undefined) {
            return eaters[(EaterComponent.COLOR_EATERS[color] + 1) % eaters.length];
        }
        return eaters[0];
    },

    isBottomRowElement: function (cell) {
        return ["FishCell", "GingerHousePartCell", "CookieManCell"].indexOf(cell.constructor.name) !== -1;
    },

    moveToBottomIdea: function (cell) {
        var field = Game.currentGame.field;

        if (!HintHelper.isRemovable(field.cells[cell.y][cell.x])) {
            return;
        }

        var nextUpperCell;
        for (var dy = cell.y - 1; dy >= 0; dy--) {
            nextUpperCell = field.cells[dy][cell.x];
            if (!nextUpperCell) {
                continue;
            }
            if (HintHelper.isBottomRowElement(nextUpperCell)) {
                if (nextUpperCell instanceof GingerHousePartCell) {
                    return GingerHouseCell.GOAL_ID;
                }

                return nextUpperCell.getGoalId();
            }
        }
    },

    completeGoalIdea: function (cell) {
        var game = Game.currentGame;
        var goals = game.goals.elements;

        var goalId;

        var tile = Game.currentGame.field.floor[cell.y][cell.x];
        if (tile && tile.alive && !(tile instanceof RugTile) && !(tile instanceof SyrupTile)) {
            goalId = tile.getGoalId && tile.getGoalId();

            if (goalId && goals[goalId] && !goals[goalId].done) {
                return goalId;
            }
        }

        if (cell instanceof DonutBoxCell && game.goals.hasType(DonutBoxCell.MAKE_COLOR)) {
            goalId = DonutBoxCell.MAKE_COLOR;
        } else if (cell instanceof IceCreamMakerCell && game.goals.hasType(IceCreamMakerCell.MAKE_COLOR)) {
            goalId = IceCreamMakerCell.MAKE_COLOR;
        } else if (cell.findComponent(DecoratorComponent)) {
            goalId = cell.getGoalId() || cell.innerCell.getGoalId();
        } else {
            goalId = cell.getGoalId();
        }

        if (cell instanceof CandleCell) {
            return (cell.state === CandleCell.STATE_OFF) ? goalId : undefined;
        }

        if (cell.hurtable) {
            return goalId;
        }
    },

    collectMarkIdea: function (cell) {
        var markComponent = cell.findComponent(MarkComponent);

        if (markComponent && !markComponent.mark) {
            markComponent = cell.innerCell && cell.innerCell.findComponent(MarkComponent);
        }

        if (markComponent && markComponent.mark) {
            return markComponent.mark.getGoalId();
        }
    },

    feedEaterIdea: function (cell) {
        if (!Game.currentGame.EATERS || !cell.findComponent(ColorComponent) && !cell.findComponent(DecoratorComponent) || cell.findComponent(SpecialColorComponent)) {
            return;
        }

        var eater = HintHelper.getEater(cell.getColor());
        if (!eater) {
            return;
        }

        return eater.getGoalId();
    },

    checkShapes: function (cell, bottomUpSwap, topDownSwap) {
        var field = Game.currentGame.field;
        var moves = field.findValidMove(true);

        for (var i = 0; i < moves.length; i++) {
            var move = moves[i];

            if (move.dir.col !== 0) {
                continue;
            }

            var cellToSwap = field.cells[move.start.y + move.dir.row][move.start.x + move.dir.col];
            if (cell.x !== cellToSwap.x || cell.y !== cellToSwap.y) {
                continue;
            }

            field.swap(move.start, cellToSwap, true);
            var shape = field.findAllShapes(move.start)[0];
            if (shape && shape.cells) {
                if (move.start.y > cellToSwap.y) {
                    bottomUpSwap(shape);
                } else {
                    topDownSwap(shape);
                }
            }
            field.swap(move.start, cellToSwap, true);
        }
    },

    fillRugIdea: function (cell) {
        if (cell instanceof MultiColorCell) {
            return;
        }

        var targetCell;
        var targetTile;

        var field = Game.currentGame.field;

        var bottomUpSwap = function (shape) {
            shape.cells.forEach(function (item) {
                var tile = field.floor[item.y][item.x];

                if (tile && (tile instanceof RugTile)) {
                    targetTile = tile;
                } else {
                    targetCell = item;
                }
            });
        };

        var topDownSwap = function (shape) {
            shape.cells.forEach(function (item) {
                if (shape.minCol === shape.maxCol) {
                    var tile = field.floor[(item.y + 1 > cell.y) ? item.y : item.y + 1][item.x];

                    if (tile && (tile instanceof RugTile)) {
                        targetTile = tile;
                    } else {
                        targetCell = item;
                    }
                }
            });
        };

        HintHelper.checkShapes(cell, bottomUpSwap, topDownSwap);

        if (targetCell && targetTile) {
            return targetTile.getGoalId();
        }
    },

    fillSyrupIdea: function (cell) {
        if (SyrupTile.dir === undefined || cell instanceof MultiColorCell) {
            return;
        }

        var dir = BaseCell.DIRECTIONS[SyrupTile.dir];

        var targetCell;
        var targetTile;

        var field = Game.currentGame.field;

        var bottomUpSwap = function (shape) {
            shape.cells.forEach(function (item) {
                var tile = field.floor[item.y][item.x];

                if (shape.minRow === shape.maxRow) {
                    if (tile && (tile instanceof SyrupTile)) {
                        if (!targetTile || dir.x > 0 && item.x > targetTile.x || dir.x < 0 && item.x < targetTile.x) {
                            targetTile = tile;
                        }
                    } else if (!targetCell || dir.x > 0 && item.x > targetCell.x || dir.x < 0 && item.x < targetCell.x) {
                        targetCell = item;
                    }
                } else if (shape.minCol === shape.maxCol) {
                    if (tile && (tile instanceof SyrupTile)) {
                        if (!targetTile || dir.y > 0 && item.y > targetTile.y || dir.y < 0 && item.y < targetTile.y) {
                            targetTile = tile;
                        }
                    } else if (!targetCell || dir.y > 0 && item.y > targetCell.y || dir.y < 0 && item.y < targetCell.y) {
                        targetCell = item;
                    }
                }
            });
        };

        var topDownSwap = function (shape) {
            shape.cells.forEach(function (item) {
                if (shape.minCol === shape.maxCol) {
                    var tile = field.floor[(item.y + 1 > cell.y) ? item.y : item.y + 1][item.x];

                    if (tile && (tile instanceof SyrupTile)) {
                        if (!targetTile || dir.y > 0 && item.y + 1 > targetTile.y || dir.y < 0 && item.y + 1 < targetTile.y) {
                            targetTile = tile;
                        }
                    } else if (!targetCell || dir.y > 0 && item.y + 1 > targetCell.y || dir.y < 0 && item.y + 1 < targetCell.y) {
                        targetCell = item;
                    }
                }
            });
        };

        HintHelper.checkShapes(cell, bottomUpSwap, topDownSwap);

        if (targetCell && targetTile) {
            if (dir.x > 0 && targetTile.x < targetCell.x || dir.x < 0 && targetTile.x > targetCell.x
                || dir.y > 0 && targetTile.y < targetCell.y || dir.y < 0 && targetTile.y > targetCell.y) {
                return targetTile.getGoalId();
            }
        }
    },

    additionalTargetIdea: function (cell) {
        if (HintHelper.feedEaterIdea(cell)) {
            return true;
        }

        return ["BurstCell", "BombCell"].indexOf(cell.constructor.name) !== -1;
    },

    smartChoice: function () {
        var res = [];
        var ideas = [HintHelper.completeGoalIdea, HintHelper.feedEaterIdea, HintHelper.collectMarkIdea, HintHelper.moveToBottomIdea,
            HintHelper.fillRugIdea, HintHelper.fillSyrupIdea, HintHelper.additionalTargetIdea];

        var goals = Game.currentGame.goals.elements;
        var colorCellTypes = ColorComponent.CODES.concat(GoalCoefComponent.CODES);

        var notColorCell = Object.keys(goals).filter(function (type) {
            return !colorCellTypes.some(function (colorCellType) {
                return type === colorCellType;
            });
        }).filter(function (type) {
            return !goals[type].done;
        });

        var skipGoalId = {};

        if (notColorCell.length > 0) {
            Object.keys(goals).forEach(function (type) {
                var anyColorCell = colorCellTypes.some(function (colorCellType) {
                    return type === colorCellType;
                });

                if (anyColorCell) {
                    skipGoalId[type] = true;

                    var index = ideas.indexOf(HintHelper.additionalTargetIdea);
                    if (index >= 0) {
                        ideas.splice(index, 1);
                    }
                }
            });
        }

        for (var row = 0; row < Field.SIZE; row++) {
            for (var col = 0; col < Field.SIZE; col++) {
                var cell = Game.currentGame.field.cells[row][col];

                if (cell) {
                    for (var i = 0; i < ideas.length; i++) {
                        var goalId = ideas[i].call(undefined, cell);

                        if (goalId === true || goalId && goals[goalId] && !goals[goalId].done && !skipGoalId[goalId]) {
                            res.push({
                                x: cell.x,
                                y: cell.y,
                                goalId: goalId === true ? undefined : goalId
                            });

                            break;
                        }
                    }
                }
            }
        }

        return res;
    }
};