/**
 * Created by evgenijsenkevic on 27.08.2024
 * */

var AdminLocations = function () {
    cleverapps.EventEmitter.call(this);

    this.adminLocations = AdminLocations.ListLocationsIds().map(function (locationId) {
        return new AdminLocation(locationId);
    });

    this.setActive(this.adminLocations[0]);
};

AdminLocations.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminLocations.prototype.constructor = AdminLocations;

AdminLocations.prototype.setActive = function (location) {
    if (this.activeLocation === location) {
        return;
    }

    if (this.activeLocation) {
        this.activeLocation.unselect();
    }

    this.activeLocation = location;
    location.select();

    this.trigger("updateActive", location);
};

AdminLocations.prototype.play = function () {
    if (!this.activeLocation) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "AdminPlayLocation",
        action: function (f) {
            cleverapps.meta.switchLocation(this.activeLocation.getLocationId());
            cleverapps.meta.gotoLocationScene(f);
        }.bind(this)
    });
};

AdminLocations.prototype.edit = function () {
    if (!this.activeLocation) {
        return;
    }

    cleverapps.setUrlHash({
        mode: "editor_location",
        location: this.activeLocation.getLocationId()
    });

    cleverapps.focusManager.display({
        focus: "AdminEditLocation",
        action: function (f) {
            EditorLocationScene.open(f);
        }
    });
};

AdminLocations.prototype.save = function (data) {
    cleverapps.git.edit("location", {
        locationId: this.activeLocation.locationId,
        content: Object.assign({}, this.activeLocation.getContent(), data)
    });
};

AdminLocations.ListLocationsIds = function () {
    return Object.values(bundles).filter(function (bundle) {
        return bundle.meta.location;
    }).map(function (bundle) {
        return bundle.meta.location;
    });
};