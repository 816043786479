/**
 * Created by andrey on 21.05.2020
 */

var MovesBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_MOVES);
};

MovesBooster.prototype = Object.create(BaseBoosterBefore.prototype);
MovesBooster.prototype.constructor = MovesBooster;

MovesBooster.prototype.getAfterStartActions = function () {
    return function (f) {
        MovesBooster.afterStartAction({
            amount: 5,
            booster: this
        }, f);
    }.bind(this);
};

MovesBooster.afterStartAction = function (options, f) {
    var amount = options.amount;
    if (Game.currentGame.moves === -1) {
        f();
        return false;
    }

    Game.currentGame.setMoves(Game.currentGame.moves + amount, {
        animate: true,
        actionBefore: true,
        fromLantern: options.lantern
    });
    Game.currentGame.beginMoves += amount;

    if (options.booster) {
        options.booster.execute();
    }

    Game.currentGame.counter.setTimeout(f, 300);
};