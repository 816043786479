/**
 * Created by andrey on 04.07.17.
 */

cleverapps.CrossPromo = function () {
    this.remoteJson = new RemoteJson(bundles.crosspromo.jsons.crosspromo_json.getJson());

    this.load();
    
    this.promotion = [];
    
    this.remoteJson.initialize(function () {
        this.updatePromotion();
    }.bind(this));
    
    this.remoteJson.fetch(function () {
        this.updatePromotion();
    }.bind(this));
};

cleverapps.CrossPromo.prototype.updatePromotion = function () {
    this.promotion = this.listPromotions();
};

cleverapps.CrossPromo.prototype.havePromotion = function () {
    return this.promotion && this.promotion.length;
};

cleverapps.CrossPromo.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.CROSSPROMO) || {};
    this.shownMarkDate = data.shownMarkDate;
};

cleverapps.CrossPromo.prototype.saveShownMarkTime = function () {
    this.shownMarkDate = Date.now();
    cleverapps.dataLoader.save(DataLoader.TYPES.CROSSPROMO, {
        shownMarkDate: this.shownMarkDate
    });
};

cleverapps.CrossPromo.prototype.getPlatformConfig = function (config) {
    var source = connector.info.source;

    switch (source) {
        case "macos":
            return config.ios;
        case "sp_mbga":
            return config.mbga;
        case "test":
            return {};
        case "cleverapps":
            return {};
        default:
            return config[source];
    }
};

cleverapps.CrossPromo.prototype.hasMark = function () {
    return this.havePromotion()
           && (!this.shownMarkDate
            || Date.now() - this.shownMarkDate >= cleverapps.CrossPromo.SHOW_MARK_INTERVAL);
};

cleverapps.CrossPromo.prototype.listPromotions = function () {
    var promotions = [];

    var json = this.remoteJson.getJson();
    var priorities = json.priorities;

    var projects = Object.keys(json).filter(function (key) {
        return typeof json[key] === "object" && json[key].name;
    }).sort(function (key1, key2) {
        return ((priorities[key2] || 0) - (priorities[key1] || 0)) || key1.localeCompare(key2);
    });

    projects.forEach(function (game) {
        if (game === cleverapps.config.name) {
            return;
        }

        var config = json[game];

        var platformConfig = this.getPlatformConfig(config);
        if (!platformConfig || !cleverapps.config.debugMode && platformConfig.debug) {
            return;
        }

        var bundleNameEn = "crosspromo_" + game + "_" + cc.sys.LANGUAGE_ENGLISH;
        if (!bundles[bundleNameEn]) {
            return;
        }
        var bundleName = "crosspromo_" + game + "_" + cleverapps.settings.language;

        var promo = {
            id: game,
            title: "" + game + "PromoTitle",
            description: "" + game + "Promo",
            bundleName: bundles[bundleName] ? bundleName : bundleNameEn,
            link: connector.utils.guessPlatformURL({
                config: config,
                debugMode: false
            }),
            payload: undefined
        };

        if (connector.info.source === "instant") {
            var projectBusiness = cleverapps.config.instant && cleverapps.config.instant.business || cleverapps.config.business;
            var promoBusiness = config.instant && config.instant.business || config.business;

            if (projectBusiness !== promoBusiness) {
                return;
            }
        }

        if (connector.info.source === "instant") {
            promo.link = config.instant && config.instant.appId;
            promo.payload = { channel: cleverapps.config.name };
        }

        if (connector.info.source === "msstart") {
            promo.link = config.msstart && config.msstart.appId;
            promo.payload = { channel: cleverapps.config.name };
        }

        if (!promo.link) {
            return;
        }

        if (connector.info.source === "facebook") {
            promo.link += "?channel=" + cleverapps.config.name;
        }
        if (connector.info.source === "android") {
            promo.link += "&utm_source=" + cleverapps.config.name;
        }

        promotions.push(promo);
    }.bind(this));

    return promotions;
};

cleverapps.CrossPromo.OpenCrossPromoWindow = function () {
    if (!cleverapps.crossPromo.havePromotion()) {
        return;
    }

    var promotions = cleverapps.crossPromo.promotion;

    if (cleverapps.focusManager.isFocused()) {
        new CrossPromoWindow(promotions);
        return;
    }

    cleverapps.focusManager.display({
        focus: "CrossPromoWindow",
        action: function (f) {
            new CrossPromoWindow(promotions);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

cleverapps.CrossPromo.SHOW_MARK_INTERVAL = cleverapps.parseInterval(cleverapps.config.debugMode ? "20 minute" : "7 days");