/**
 * Created by Vladislav on 30.03.2025.
 */

var IndexedDBStore = function (storeName) {
    this.storeName = storeName;

    this.callbacks = [];

    this.connection = indexedDB.open(cleverapps.config.name, 1);

    this.connection.onupgradeneeded = function (event) {
        var db = event.target.result;
        db.createObjectStore(storeName, { keyPath: "id" });
    };

    this.connection.onsuccess = function (event) {
        this.db = event.target.result;

        this.ready = true;

        this.callbacks.forEach(function (callback) {
            callback();
        });

        this.callbacks = [];
    }.bind(this);
};

IndexedDBStore.prototype._whenReady = function (callback) {
    if (this.ready) {
        callback();
    } else {
        this.callbacks.push(callback);
    }
};

IndexedDBStore.prototype.get = function (id, callback) {
    this._whenReady(function () {
        var transaction = this.db.transaction(this.storeName, "readonly");
        var store = transaction.objectStore(this.storeName);

        var request = store.get(id);
        request.onsuccess = function () {
            callback(request.result && request.result.data && JSON.parse(request.result.data));
        };
        request.onerror = function (err) {
            console.error(err);

            callback();
        };
    }.bind(this));
};

IndexedDBStore.prototype.set = function (id, data, callback) {
    this._whenReady(function () {
        var transaction = this.db.transaction(this.storeName, "readwrite");
        var store = transaction.objectStore(this.storeName);

        var request = store.put({ id: id, data: JSON.stringify(data) });

        request.onsuccess = function () {
            callback(request.result);
        };
        request.onerror = callback;
    }.bind(this));
};