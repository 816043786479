/**
 * Created by Andrey Popov on 29.02.2024
 */

var SingleProductOfferLogic = function (offer) {
    this.offer = offer;

    var data = Offers[offer.type];

    this.mission = data.mission;
    this.location = data.location;
    this.product = data.product;
    this.price = data.price;
    this.reward = data.reward;

    this.onBought = function () {};
};

SingleProductOfferLogic.prototype.getProduct = function () {
    if (this.product) {
        return Product.CreateById(this.product, {
            type: "mission_offer",
            offer: this.offer.type
        });
    }

    return Product.CreateByHardPrice(this.price, {
        type: "mission_offer",
        offer: this.offer.type 
    });
};

SingleProductOfferLogic.prototype.isIconAvailable = function () {
    var locationId;

    if (this.mission) {
        locationId = Missions[this.mission] && Missions[this.mission].location;
    } else if (this.location) {
        locationId = this.location;
    }

    return !locationId || locationId === cleverapps.meta.selectedLocationId();
};

SingleProductOfferLogic.prototype.hasIconAttention = function () {
    return false;
};

SingleProductOfferLogic.prototype.getPriceText = function () {
    return this.getProduct().getCurrentPrice();
};

SingleProductOfferLogic.prototype.getTimeLeft = function () {
    if (this.mission) {
        var mission = cleverapps.missionManager.findByType(this.mission);
        return mission && mission.getTimeLeft() || 0;
    }

    if (this.location) {
        return cleverapps.meta.logic.getLocationTimeLeft(this.location) || 0;
    }

    return 0;
};

SingleProductOfferLogic.prototype.isAvailable = function () {

};

SingleProductOfferLogic.prototype.buy = function () {
    if (this.reward.unit) {
        var unitsAmount = this.reward.unit.reduce(function (sum, unit) {
            return sum + (unit.amount || 0);
        }, 0);

        var notEnough = unitsAmount - Map2d.currentMap.countEmptySlots();
        if (notEnough > 0) {
            cleverapps.centerHint.createTextHint("Spawn.nospace", { left: notEnough });
            return;
        }
    }

    this.getProduct().buy(this.processBought.bind(this), { noRewardWindow: true });
};

SingleProductOfferLogic.prototype.processBought = function (success) {
    if (!success) {
        return;
    }

    new RewardWindow(this.reward, { event: cleverapps.EVENTS.EARN.PURCHASE });

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.OFFER + this.offer.name);
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.OFFER_COST + this.offer.name, {
        value: this.getProduct().getUsdPrice()
    });

    this.offer.complete();

    this.onBought();
};

SingleProductOfferLogic.RestoreActions = function (product, context, consume) {
    var offer = cleverapps.offerManager.findOffer({ type: context.offer });
    if (!offer) {
        return;
    }

    return [
        function (f) {
            consume();
            offer.logic.processBought(true);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    ];
};

Restorable.BY_TYPE["mission_offer"] = new Restorable({
    location: Restorable.LocationOfferExpedition,
    listActions: SingleProductOfferLogic.RestoreActions
});