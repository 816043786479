/**
 * Created by Aleksandr on 17.04.2024
 */

var PictureComponent = cc.Node.extend({
    ctor: function (level) {
        this._super();

        this.sound = bundles.victory_window.urls.bonus_animation_effect;
        var animationName = "bonus_animation_json";
        if (level.isHard()) {
            animationName = "hard_animation_json";
            this.sound = bundles.victory_window.urls.hard_animation_effect;
        }
        this.content = new cleverapps.Spine(bundles.victory_window.jsons[animationName]);
        this.content.setPositionRound(cleverapps.styles.PictureComponent);
        this.addChild(this.content);

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(this.content.getContentSize());
    },

    showUp: function () {
        this.setOpacity(0);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.PlaySound(this.sound),
            new cc.CallFunc(function () {
                if (this.content.setAnimation) {
                    this.content.setAnimationAndIdleAfter("showUp", "idle");
                }
            }.bind(this))
        ));
    },

    beforeWinClose: function () {
        if (this.content.setAnimation) {
            this.content.setAnimation(0, "hide", false);
            this.content.setCompleteListenerRemove();
        }
    }
});

cleverapps.styles.PictureComponent = {
    x: { align: "center" },
    y: { align: "center" }
};