/**
 * Created by razial on 19.12.2024.
 */

var PassLevelsConfig = {};

PassLevelsConfig.buildpass = [
    {
        energy: 0,
        task: {
            goal: 30
        },
        reward: {
            unit: { code: "worker", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 0, amount: 1 }
        }
    }, // 1
    {
        energy: 5,
        task: {
            goal: 75
        },
        reward: {
            unit: { code: "herochest", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 0, amount: 2 }
        }
    }, // 2
    {
        energy: 5,
        task: {
            goal: 150
        },
        reward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 2 }
        }
    }, // 3
    {
        energy: 5,
        task: {
            goal: 350
        },
        reward: {
            unit: { code: "sacks", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 3, amount: 2 }
        }
    }, // 4
    {
        energy: 10,
        task: {
            goal: 700
        },
        reward: {
            unit: { code: "crystal", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 2, amount: 1 }
        }
    }, // 5
    {
        energy: 10,
        task: {
            goal: 1500
        },
        reward: {
            unit: { code: "worker", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 4, amount: 1 }
        }
    }, // 6
    {
        energy: 10,
        task: {
            goal: 3000
        },
        reward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 1, amount: 2 }
        }
    }, // 7
    {
        energy: 15,
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "worker", stage: 3, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 2 }
        }
    }, // 8
    {
        energy: 15,
        task: {
            goal: 8000
        },
        reward: {
            unit: { code: "treasure", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "coinstree", stage: 1, amount: 1 }
        }
    }, // 9
    {
        energy: 15,
        task: {
            goal: 12000
        },
        reward: {
            unit: { code: "magicplant", stage: 4, amount: 1 }
        },
        premiumReward: {
            unit: { code: "energytree", stage: 2, amount: 1 }
        }
    } // 10
];

PassLevelsConfig.salepass = [
    {
        energy: 0,
        task: {
            goal: 30
        },
        reward: {
            unit: { code: "crystal", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 0, amount: 3 }
        }
    }, // 1
    {
        energy: 5,
        task: {
            goal: 150
        },
        reward: {
            unit: { code: "treasure", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 1, amount: 2 }
        }
    }, // 2
    {
        energy: 5,
        task: {
            goal: 275
        },
        reward: {
            unit: { code: "coins", stage: 4, amount: 1 }
        },
        premiumReward: {
            unit: { code: "coinscup", stage: 2, amount: 1 }
        }
    }, // 3
    {
        energy: 5,
        task: {
            goal: 325
        },
        reward: {
            unit: { code: "treasure", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 4, amount: 2 }
        }
    }, // 4
    {
        energy: 10,
        task: {
            goal: 355
        },
        reward: {
            unit: { code: "worker", stage: 3, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 3 }
        }
    }, // 5
    {
        energy: 10,
        task: {
            goal: 400
        },
        reward: {
            unit: { code: "treasure", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 2 }
        }
    }, // 6
    {
        energy: 10,
        task: {
            goal: 475
        },
        reward: {
            unit: { code: "sacks", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "coins", stage: 5, amount: 3 }
        }
    }, // 7
    {
        energy: 15,
        task: {
            goal: 525
        },
        reward: {
            unit: { code: "coins", stage: 3, amount: 1 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 4, amount: 1 }
        }
    }, // 8
    {
        energy: 15,
        task: {
            goal: 600
        },
        reward: {
            unit: { code: "worker", stage: 3, amount: 2 }
        },
        premiumReward: {
            unit: { code: "coinstree", stage: 1, amount: 1 }
        }
    }, // 9
    {
        energy: 15,
        task: {
            goal: 750
        },
        reward: {
            unit: { code: "magicplant", stage: 5, amount: 1 }
        },
        premiumReward: {
            unit: { code: "energytree", stage: 2, amount: 1 }
        }
    } // 10
];

PassLevelsConfig.levelpass = [
    {
        level: 0,
        task: {
            goal: 3
        },
        reward: {
            unlimitedLives: "30 minutes"
        },
        premiumReward: {
            unlimitedLives: "12 hours"
        }
    },
    {
        level: 1,
        task: {
            goal: 5
        },
        reward: {
            boosters: {
                5: 1
            }
        },
        premiumReward: {
            boosters: {
                5: 3
            }
        }
    },
    {
        level: 2,
        task: {
            goal: 8
        },
        reward: {
            unlimitedLives: "1 hour"
        },
        premiumReward: {
            boosters: {
                0: 3
            }
        }
    },
    {
        level: 3,
        task: {
            goal: 12
        },
        reward: {
            boosters: {
                6: 1
            }
        },
        premiumReward: {
            unlimitedLives: "12 hours"
        }
    },
    {
        level: 4,
        task: {
            goal: 15
        },
        reward: {
            unlimitedLives: "2 hours"
        },
        premiumReward: {
            boosters: {
                1: 3
            }
        }
    },
    {
        level: 5,
        task: {
            goal: 20
        },
        reward: {
            exp: 20
        },
        premiumReward: {
            hard: 300
        }
    },
    {
        level: 6,
        task: {
            goal: 25
        },
        reward: {
            boosters: {
                7: 1
            }
        },
        premiumReward: {
            boosters: {
                2: 3
            }
        }
    },
    {
        level: 7,
        task: {
            goal: 30
        },
        reward: {
            boosters: {
                6: 2
            }
        },
        premiumReward: {
            unlimitedLives: "12 hours"
        }
    },
    {
        level: 8,
        task: {
            goal: 40
        },
        reward: {
            unlimitedLives: "2 hours"
        },
        premiumReward: {
            boosters: {
                0: 5
            }
        }
    },
    {
        level: 9,
        task: {
            goal: 50
        },
        reward: {
            boosters: {
                2: 1
            }
        },
        premiumReward: {
            hard: 500
        }
    }
];

PassLevelsConfig.expeditionpass = [
    {
        task: {
            goal: 30
        },
        reward: {
            unit: { code: "worker", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 1, amount: 1 }
        }
    }, // 1
    {
        task: {
            goal: 60
        },
        reward: {
            unit: { code: "sacks", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        }
    }, // 2
    {
        task: {
            goal: 100
        },
        reward: {
            unit: { code: "wands", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "wands", stage: 2, amount: 1 }
        }
    }, // 3
    {
        task: {
            goal: 250
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 3, amount: 1 }
        }
    }, // 4
    {
        task: {
            goal: 500
        },
        reward: {
            unit: { code: "crystal", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 1, amount: 3 }
        }
    }, // 5
    {
        task: {
            goal: 1200
        },
        reward: {
            unit: { code: "treasure", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 1 }
        }
    }, // 6
    {
        task: {
            goal: 1500
        },
        reward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 2, amount: 1 }
        }
    }, // 7
    {
        task: {
            goal: 1800
        },
        reward: {
            unit: { code: "herochest", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 1, amount: 2 }
        }
    }, // 8
    {
        task: {
            goal: 2000
        },
        reward: {
            unit: { code: "coins", stage: 3, amount: 1 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 3, amount: 2 }
        }
    }, // 9
    {
        task: {
            goal: 2600
        },
        reward: {
            unit: { code: "herochest", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 3, amount: 3 }
        }
    }, // 10
    {
        task: {
            goal: 3300
        },
        reward: {
            unit: { code: "worker", stage: 3, amount: 1 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 2, amount: 1 }
        }
    }, // 11
    {
        task: {
            goal: 4000
        },
        reward: {
            unit: { code: "magicplant", stage: 3, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 2, amount: 3 }
        }
    }, // 12
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "sacks", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "magicplant", stage: 6, amount: 1 }
        }
    }, // 13
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "worker", stage: 3, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 4, amount: 2 }
        }
    }, // 14
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "herochest", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 2, amount: 2 }
        }
    }, // 15
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "wands", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "wands", stage: 3, amount: 3 }
        }
    }, // 16
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "coins", stage: 3, amount: 2 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 4, amount: 1 }
        }
    }, // 17
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "treasure", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 3 }
        }
    }, // 18
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "crystal", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 2 }
        }
    }, // 19
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "sacks", stage: 2, amount: 2 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 2, amount: 3 }
        }
    }, // 20
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "coins", stage: 4, amount: 3 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 4, amount: 3 }
        }
    }, // 21
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "herochest", stage: 1, amount: 2 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 3 }
        }
    }, // 22
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "worker", stage: 4, amount: 1 }
        },
        premiumReward: {
            unit: { code: "magicplant", stage: 7, amount: 1 }
        }
    }, // 23
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "magicplant", stage: 4, amount: 1 }
        },
        premiumReward: {
            unit: { code: "coinstree", stage: 3, amount: 1 }
        }
    }, // 24
    {
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "magicplant", stage: 5, amount: 1 }
        },
        premiumReward: {
            unit: { code: "energytree", stage: 3, amount: 1 }
        }
    }// 25
];

PassLevelsConfig.difficultexpeditionpass = cleverapps.clone(PassLevelsConfig.expeditionpass, true);
PassLevelsConfig.difficultexpeditionpass.forEach(function (level) {
    level.task.goal *= 3;
});
