/**
 * Created by olga on 28.01.2025
 */

var StartLevelTutorial = function () {
    return new TutorialScenario(TutorialScenario.BASIC_TUTORIAL_NAME, {
        listSteps: StartLevelTutorial.listSteps,
        prepareNameForLogging: function (name) {
            var level = Game.currentGame.level;
            if (level.episodeNo === 0 && level.levelNo === 0) {
                return name;
            }
           
            name += level.episodeNo;
            name += "_" + level.levelNo;

            return name;
        },

        onFinish: function () {
            cleverapps.exclamation.show("message.KeepGoing", undefined);
        },

        isAvailable: function () {
            if (cleverapps.loadedSnapshot && cleverapps.loadedSnapshot.games) {
                return false;
            }

            var game = Game.currentGame;
            return game && game.introPlaying && game.levelWithTutorial();
        }
    });
};

StartLevelTutorial.listSteps = function () {
    var steps = [];
    var levelContent = Game.currentGame.level.content;
    levelContent.tutorial.forEach(function (step) {
        if (step.booster !== undefined) {
            steps.push(Match3TutorialHelper.createBoosterActivationStep(step));
        } else if (step.control !== undefined) {
            steps.push(Match3TutorialHelper.createMoveStep(step));
        } else {
            cleverapps.throwAsync("No tutorial scenario found for episodeNo " + levelContent.episodeNo + ", levelNo " + levelContent.levelNo);
        }
    });
    return steps;
};
