/**
 * Created by vladislav on 15/03/2023
 */

var DebugSlider = function () {
    this.slider = undefined;

    cleverapps.flags.on("change:debugInfo", this.toggle.bind(this));
};

DebugSlider.prototype.isActive = function () {
    return this.slider;
};

DebugSlider.prototype.remove = function () {
    if (this.slider) {
        this.slider.remove();

        this.slider = undefined;
    }
};

DebugSlider.prototype.toggle = function (visible) {
    if (this.slider) {
        this.slider.style.visibility = visible ? "" : "hidden";
    }
};

DebugSlider.prototype.createTimeScaleSlider = function () {
    this.create({
        action: function (value) {
            cleverapps.timeScaleManager.setValue(value);
        },
        minValue: TimeScaleManager.MIN_VALUE,
        maxValue: TimeScaleManager.MAX_VALUE,
        step: TimeScaleManager.MIN_STEP,
        baseValue: cleverapps.timeScaleManager.value
    });
};

DebugSlider.prototype.createFireworksSlider = function () {
    this.create([{
        action: function (value) {
            Fireworks.DELAYS.sequentBase = value;
        },
        minValue: 0,
        maxValue: 5000,
        step: 1,
        baseValue: Fireworks.DELAYS.sequentBase,
        caption: "sequent base"
    }, {
        action: function (value) {
            Fireworks.DELAYS.sequentRandom = value;
        },
        minValue: 0,
        maxValue: 5000,
        step: 1,
        baseValue: Fireworks.DELAYS.sequentRandom,
        caption: "sequent rnd"
    }, {
        action: function (value) {
            Fireworks.DELAYS.intermission = value;
        },
        minValue: 0,
        maxValue: 20000,
        step: 1,
        baseValue: Fireworks.DELAYS.intermission,
        caption: "intermission"
    }]);
};

DebugSlider.prototype.create = function (options) {
    this.remove();

    var slider = this.slider = document.createElement("div");
    slider.classList.add("tool-slider");

    var close = document.createElement("span");
    close.classList.add("tool-window-close");
    close.onclick = this.remove.bind(this);
    close.innerText = "\u2715";
    slider.appendChild(close);

    cleverapps.toArray(options).forEach(function (options) {
        options.action(options.baseValue);

        var container = document.createElement("div");
        container.classList.add("tool-option");
        slider.appendChild(container);

        var sliderInput = document.createElement("input");
        sliderInput.type = "range";
        sliderInput.min = options.minValue;
        sliderInput.max = options.maxValue;
        sliderInput.step = options.step;
        sliderInput.value = options.baseValue;

        container.appendChild(sliderInput);

        var sliderLabel = this.createLabel(sliderInput, options);
        container.appendChild(sliderLabel);

        sliderInput.oninput = function () {
            options.action(parseFloat(sliderInput.value));
            sliderLabel.content.data = sliderInput.value;
        };

        if (options.caption) {
            var caption = document.createElement("div");
            caption.classList.add("tool-caption");
            caption.innerText = options.caption;
            container.appendChild(caption);
        }
    }.bind(this));

    cc.container.appendChild(slider);

    this.toggle(true);

    return slider;
};

DebugSlider.prototype.createLabel = function (input, options) {
    var number = document.createElement("div");
    var numberContent = document.createTextNode(options.baseValue);
    number.appendChild(numberContent);
    number.style.zIndex = "10";

    number.content = numberContent;
    number.classList.add("tool-label");

    var setInputValue = function (value) {
        numberContent.data = value.toString();
        options.action(value);
        input.value = value;
    };

    number.addEventListener("click", function () {
        var value = parseFloat(window.prompt("Enter zoom from " + options.minValue + " to " + options.maxValue));
        if (value >= options.minValue && value <= options.maxValue) {
            setInputValue(value);
        } else {
            if (value < options.minValue) {
                setInputValue(options.minValue);
            }
            if (value > options.maxValue) {
                setInputValue(options.maxValue);
            }
        }
    });
    return number;
};
