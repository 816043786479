/**
 * Created by evgenijsenkevic on 25.09.2024
 */

cc.Flicker = cc.ActionInterval.extend({
    ctor: function (duration, color, reverse) {
        this._super(duration);
        this._color = color;
        this._reverse = reverse;
    },

    startWithTarget: function (target) {
        cc.ActionInterval.prototype.startWithTarget.call(this, target);
        if (!this.target.normalShader) {
            this.target.normalShader = this.target.getShaderProgram();
            this.target.setShaderProgram(cc.shaderCache.getProgram(cleverapps.Shaders.FLICKER_SHADER_KEY));
        }

        this._shader = this.target.getShaderProgram();
    },

    update: function (dt) {
        this._shader.use();

        if (this._reverse) {
            dt = 1 - dt;
        }

        this._shader.setUniformLocationWith1f("sineTime", dt);
        this._shader.setUniformLocationWith3f("flickColor", this._color[0], this._color[1], this._color[2]);
    },

    _finalize: function () {
        this.target.normalShader && this.target.setShaderProgram(this.target.normalShader);
        delete this.target.normalShader;
    },

    stop: function () {
        this.target.normalShader && this.target.setShaderProgram(this.target.normalShader);
        delete this.target.normalShader;
        delete this._shader;

        this._super();
    }
});