/**
 * @author Spepa
 */

var ExclamationView = cc.Node.extend({
    ctor: function () {
        this._super();
        var styles = cleverapps.styles.Exclamations;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);

        if (styles.scale) {
            this.setScale(styles.scale[cleverapps.resolution.mode]);
        }

        cleverapps.exclamation.on("show", cleverapps.throttle(1000, this.showMessage.bind(this)), this);
        cleverapps.exclamation.on("hide", this.hideMessage.bind(this), this);
    },

    showMessage: function (message, type, callback) {
        this.createAnimation("animation", callback);
        this.createMsg(message);
    },

    hideMessage: function () {
        this.setVisible(false);
    },

    _initMsg: function (message, offsetY, font, styles) {
        offsetY = offsetY || 0;

        message = Messages.getLocalized(message);
        var lines = this.splitTextToLines(message);
        var lineNodes = lines.map(function (line) {
            return this.createLine(line, font, styles);
        }.bind(this));

        var entireNode = new cleverapps.Layout(lineNodes, {
            direction: cleverapps.UI.VERTICAL,
            margin: lineNodes.length && lineNodes[0].height / 10 || 0
        });

        entireNode.setCascadeOpacityEnabled(true);
        entireNode.setOpacity(0);
        entireNode.setScale(0);
        entireNode.setPositionRound(this.width / 2, this.height / 2 + offsetY);
        this.addChild(entireNode);
        return entireNode;
    },

    _calcScale: function (node) {
        var scale = 1.0;
        var scene = cleverapps.scenes.getRunningScene();
        if (node.width > scene.width) {
            scale = scene.width / (node.width * 1.2);
        }
        return scale;
    },

    getEntireNodeAnimation: function (scale, fadeOutDelay) {
        fadeOutDelay = fadeOutDelay || 1.2;
        return new cc.Spawn(
            new cc.FadeIn(0.25),
            new cc.Sequence(
                new cc.ScaleTo(0.2, 1.4 * scale, 1.4 * scale),
                new cc.ScaleTo(0.2, 0.8 * scale, 0.8 * scale),
                new cc.ScaleTo(1.0, scale, scale)
            ),
            new cc.Sequence(
                new cc.DelayTime(fadeOutDelay),
                new cc.FadeOut(0.2),
                new cc.RemoveSelf()
            )
        );
    },

    createLine: function (line, font, styles) {
        font = font || cleverapps.styles.FONTS.CONGRATE_TEXT;
        var node = new cc.Node();
        node.setAnchorPoint(0.5, 0.5);
        var width = 0, height = 0;

        var blocks = this.splitLineToBlocks(line);
        for (var index = 0; index < blocks.length; index++) {
            var letter = cleverapps.UI.generateOnlyText(blocks[index], font);
            node.addChild(letter);

            var dist = Math.abs(index - blocks.length / 2);
            var scale = 1 - 0.2 / blocks.length * dist;
            var duration = 0.2 + 0.6 / blocks.length * dist;
            var letterWidth = blocks[index] !== " " ? letter.width - (styles && styles.offset || 0) : styles && styles.spaceWidth || cleverapps.styles.Exclamations.spaceWidth;

            letter.setPosition(width + letterWidth * scale / 2, letter.height / 2);
            width += letterWidth * scale;
            height = Math.max(height, letter.height);

            letter.setScale(0);
            letter.runAction(new cc.Sequence(
                new cc.ScaleTo(duration, 1.36),
                new cc.ScaleTo(0.13, scale)
            ));
        }

        node.setContentSize(width, height);
        node.setCascadeOpacityEnabled(true);

        return node;
    },

    splitTextToLines: function (text) {
        var maxLength = 16;

        if (!text) {
            return [];
        }

        var index = text.indexOf(" ");
        if (text.length <= maxLength || index === -1) {
            return [text];
        }

        var pos = text.substr(0, maxLength).lastIndexOf(" ");
        if (pos === -1) {
            pos = index;
        }

        var lines = [text.substr(0, pos)];
        lines = lines.concat(text.substr(pos + 1).trim());
        return lines;
    },

    splitLineToBlocks: function (text) {
        var chars = text.split("");
        if (chars.length <= 6) {
            return chars;
        }

        var centerBlock = chars.slice(3, chars.length - 3).join("");
        if (centerBlock.startsWith(" ")) {
            centerBlock = [" ", centerBlock];
        } else if (centerBlock.endsWith(" ")) {
            centerBlock = [centerBlock, " "];
        }
        return chars.slice(0, 3).concat(centerBlock).concat(chars.slice(chars.length - 3, chars.length));
    },

    createMsg: function (message) {
        var entireNode = this._initMsg(message);

        var scale = this._calcScale(entireNode);

        this.setVisible(true);
        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.4),
            new cc.Hide()
        ));

        entireNode.runAction(this.getEntireNodeAnimation(scale));
    },

    createAnimation: function (animation, callback) {
        if (!this.animation) {
            this.animation = new cleverapps.Spine(bundles.exclamations.jsons.exclamations_json);
            this.animation.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(this.animation);
        }
        this.animation.setAnimation(0, animation, false);

        if (callback) {
            this.animation.setCompleteListener(callback);
        }
    }
});

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    CONGRATS_RED: new cc.Color(255, 35, 60, 255),
    CONGRATS_LIGHT_GREEN: new cc.Color(182, 248, 68, 255),
    CONGRATS_BLUE: new cc.Color(0, 177, 249, 255),
    CONGRATS_ORANGE: new cc.Color(233, 117, 38, 255),
    CONGRATS_PURPLE: new cc.Color(194, 11, 227, 255),
    CONGRATS_GREEN: new cc.Color(0, 209, 121, 255),
    CONGRATS_DARK_RED: new cc.Color(241, 15, 51, 255),
    YELLOW_TEXT: new cc.Color(255, 208, 0, 255)
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CONGRATE_TEXT: {
        size: 150,
        color: cleverapps.styles.COLORS.YELLOW_TEXT,
        stroke: cleverapps.styles.DECORATORS.BROWN_STROKE
    }
});

cleverapps.styles.Exclamations = {
    spaceWidth: 10,
    width: 650,
    height: 200
};