/**
 * Created by andrey on 22.08.17.
 */

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    MOVES_DANGER: new cc.Color(255, 0, 0, 255),
    EPISODE_CLOUD_TEXT_COLOR: new cc.Color(220, 56, 98, 255),
    COLOR_WINDOW_TEXT: new cc.Color(130, 70, 40, 255),
    RIDDLES_BROWN: new cc.Color(130, 70, 40, 255)
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    WHITE_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.WHITE,
        size: 2
    },

    MOVES_CELL_STROKE: {
        color: new cc.Color(140, 83, 37, 255),
        size: 4
    },

    BLACK_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 2
    }
});

cleverapps.overrideStyles(cleverapps.styles.FONTS, {
    INGAME_COMPACT_TEXT: {
        size: 37
    },

    DIALOGUE_PERSON_TITLE_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.RIDDLES_BROWN
    },
    EDITOR_BLACK_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.BLACK
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MOVES_TEXT: {
        name: "default",
        size: 60
    },

    GOAL_TEXT: {
        name: "custom_digits",
        size: 43
    },

    RPG_LIFE_DELTA_RED: {
        name: "default",
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_RED
    },

    RPG_LIFE_DELTA_GREEN: {
        name: "default",
        size: 40,
        color: cleverapps.styles.COLORS.LIFE_GREEN
    },

    CLOVERS_TEXT: {
        name: "custom_digits",
        size: 43
    },

    BOOSTER_BEFORE_AMOUNT_TEXT: {
        name: "default",
        size: 50
    },

    WINDOW_WHITE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },

    EPISODE_CLOUD_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.EPISODE_CLOUD_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.WHITE_TEXT_STROKE
    },

    BUSTER_NOTIFICATION_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },

    MOVES_CELL_TEXT: {
        name: "custom_digits",
        size: 50
    },

    BP_LAST_CHANCE_RIBBON: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    PASS_PROGRESS_POINT: {
        name: "nostroke"
    },

    VIPREWARDEDWINDOW_NOTE_TEXT: {
        size: 30
    }
});

if (cleverapps.styles.PagingScrollView) {
    cleverapps.overrideStyles(cleverapps.styles.PagingScrollView, {
        direction: cleverapps.UI.VERTICAL,
        height: cleverapps.styles.SCENE_HEIGHT,
        pageHeight: cleverapps.styles.SCENE_HEIGHT
    });
}

if (cleverapps.styles.LocationView) {
    cleverapps.overrideStyles(cleverapps.styles.LocationView, {
        loadAnimation: true,
        direction: cleverapps.UI.VERTICAL
    });
}

if (cleverapps.styles.LevelView) {
    cleverapps.overrideStyles(cleverapps.styles.LevelView, {
        LevelId: {
            showTypes: [Level.TYPE_PASSED, Level.TYPE_CURRENT]
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.PassHeader, {
    timer: {
        y: { align: "top", dy: -35 }
    },

    amount: {
        y: { align: "bottom", dy: 35 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassProgress, {
    paddingX: 132
});

cleverapps.overrideStyles(cleverapps.styles.ChestVictoryAnimation, {
    chestPosition: {
        x: { align: "center" },
        y: { align: "center", dy: -250 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassRewardIcon, {
    text: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -10 }
    },

    icon: {
        free: {
            scale: 1.0
        },
        prem: {
            scale: 1.0
        }
    },

    attention: {
        y: { align: "center", dy: -132 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.VIPRewardedWindow, {
    width: 900,

    description: {
        width: 850
    },

    title: {
        text: {
            width: 540
        }
    },

    note: {
        width: 850,
        y: { align: "center", dy: -600 }
    },

    progress: {
        y: { align: "center", dy: -284 }
    },

    button: {
        y: { align: "center", dy: -450 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    startAnimation: {
        moveBy: [{ y: -400, x: 0 }, { y: -200, x: 0 }, { y: 0, x: 85 }]
    }
});

cleverapps.overrideStyles(cleverapps.styles.RestoreProgressButton, {
    position: [
        {
            x: { align: "right", dx: -20 },
            y: { align: "bottom", dy: 20 }
        },
        {
            x: { align: "left", dx: 20 },
            y: { align: "bottom", dy: 20 }
        },
        {
            x: { align: "right", dx: -20 },
            y: { align: "top", dy: -110 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.TreasureBag, {
    position: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 100 }
    },
    hideVertical: true
});
