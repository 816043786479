/**
 * Created by slava on 3/6/19
 */

var CellHintAlgorithm = {
    type: "cellBooster",

    findMove: function () {
        if (!Game.currentGame || !Game.currentGame.field) {
            return;
        }

        var ideas = [
            CellHintAlgorithm.removeBombIdea,
            CellHintAlgorithm.moveToBottomIdea,
            CellHintAlgorithm.collectMarkIdea,
            CellHintAlgorithm.completeGoalIdea
        ];

        var moveOptions = HintHelper.findMove(ideas, cleverapps.Boosters.TYPE_CELL);
        if (moveOptions) {
            return {
                type: CellHintAlgorithm.type,
                options: moveOptions
            };
        }
    },

    removeBombIdea: function (cell) {
        if (cell.constructor.name !== "BombCell") {
            return;
        }

        var untilExplode = cell.untilExplode;

        if (untilExplode <= 3 && untilExplode > 0) {
            return true;
        }
    },

    completeGoalIdea: function (cell) {
        if (!HintHelper.isActiveGoal(cell) || HintHelper.countSameGoals(cell) > 3) {
            return;
        }

        if (cell.constructor.name === "CandleCell") {
            return cell.state === CandleCell.STATE_OFF && HintHelper.countSameGoals(cell) === 1;
        }

        return cell.hurtable && cell.lives === 1;
    },

    collectMarkIdea: function (cell) {
        var markComponent = cell.findComponent(MarkComponent);
        if (!markComponent || !markComponent.mark) {
            return;
        }

        var totalMarks = 0;

        var field = Game.currentGame.field;

        field.iterateCells(function (cell) {
            markComponent = cell.findComponent(MarkComponent);
            if (markComponent && markComponent.mark) {
                totalMarks++;
            }
        });

        return totalMarks <= 2;
    },

    moveToBottomIdea: function (cell) {
        var field = Game.currentGame.field;
        var upperCell = cell.y > 0 && field.cells[cell.y - 1][cell.x];
        if (!upperCell || !HintHelper.isBottomRowElement(upperCell)) {
            return;
        }

        var bottomCell = undefined;
        for (var i = cell.y; i < Field.SIZE; i++) {
            if (field.cells[i][cell.x]) {
                if (bottomCell) {
                    return false;
                }

                bottomCell = field.cells[i][cell.x];
            }
        }

        if (!bottomCell || !HintHelper.isRemovable(bottomCell)) {
            return;
        }

        return true;
    }
};

AvailableMove.REGISTERED_ALGORITHMS.push(CellHintAlgorithm);
