/**
 * Created by ivan on 10.04.18.
 */

var ToolbarItemView = cc.Node.extend({
    ctor: function (model) {
        var styles = cleverapps.styles.ToolbarItemView;

        this._super();
        this.model = model;

        var icon = this.icon = this.createIcon();
        icon.setPositionRound(styles.icon);

        var mark = this.mark = new Attention();
        mark.setScale(styles.mark.baseScale || 1);
        mark.setPositionRound(styles.mark);
        mark.pulse();

        var content = this.content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(icon.getContentSize());
        content.setPositionRound(content.width / 2, content.height / 2);
        content.addChild(icon);
        content.addChild(mark);

        this.setContentSize2(content.getContentSize());
        this.addChild(content);
        this.setAnchorPoint(0.5, 0);

        cleverapps.UI.applyHover(this, {
            onMouseOver: this.onMouseOver.bind(this)
        });

        if (model.onUpdateDragState) {
            cleverapps.UI.onPressed(this, function () {
                if (!this.dragIcon) {
                    cleverapps.toolbar.setDragItem(this.model);
                    this.onClick();
                    return true;
                }
            }.bind(this));
        } else {
            cleverapps.UI.onClick(this, this.onClick.bind(this));
        }

        model.onFingerOnListener = this.createListener(this.turnOnFinger.bind(this));
        model.onFingerOffListener = this.createListener(this.turnOffFinger.bind(this));
        model.onChangeStateListener = this.createListener(this.onChangeState.bind(this));
        model.onShowForce = this.createListener(this.showForce.bind(this));
        model.onGetView = this.createListener(function () {
            return this;
        }.bind(this));

        this.turnOnFinger();
        this.onChangeState();

        var targetTypes = model.getTargetTypes();
        if (targetTypes) {
            cleverapps.aims.registerTarget(targetTypes, this.content, {
                controls: "toolbar",
                useGlobalZOrder: true
            });
        }

        this.debugId = "ToolbarItemView" + model.type;
    },

    onClick: function () {
        cleverapps.audio.playSound(bundles.main.urls.click_effect);

        var forceView = cleverapps.forces.getRunningForce();
        if (forceView) {
            cleverapps.forces.closeRunningForce();
        }

        this.model.onClick(forceView && forceView.force);
    },

    onDragStart: function () {
        if (this.price) {
            this.price.runAction(new cc.FadeOut(0.2));
        }
        this.icon.setVisible(false);

        this.dragIcon = new ToolbarDragIcon(this.createIcon(), this.model);
        this.dragIcon.setPositionRound(this.icon.getPosition());
        this.addChild(this.dragIcon);
        this.dragIcon.replaceParentSamePlace(cleverapps.scenes.getMovingNode(), { keepScale: true });
    },

    onDragMove: function (touch) {
        if (this.dragIcon) {
            this.dragIcon.onDragMove(touch);
        }
    },

    onDragEnd: function (touch) {
        cleverapps.toolbar.setDragItem(undefined);

        var returnPos = cleverapps.scenes.getMovingNode().convertToNodeSpace(this.convertToWorldSpace(this.icon.getPosition()));
        var callback = function () {
            this.dragIcon.runAction(new cc.Sequence(
                new cc.MoveTo(0.2, returnPos),
                new cc.CallFunc(function () {
                    this.icon.setVisible(true);
                    if (this.price) {
                        this.price.runAction(new cc.FadeIn(0.2));
                    }
                    delete this.dragIcon;
                }.bind(this)),
                new cc.RemoveSelf()
            ));
        }.bind(this);

        this.dragIcon.onDragEnd(touch, callback);
    },

    turnOnFinger: function () {
        if (!this.model.finger) {
            return;
        }

        this.finger = FingerView.hintTap(this.content, {
            delay: 1.6
        });
    },

    turnOffFinger: function () {
        FingerView.remove(this.finger);
        this.finger = undefined;
    },

    showForce: function () {
        cleverapps.forces.create(this, this.model.getForce(), {
            importantNodes: [cleverapps.scenes.getRunningScene().downToolBarControl]
        });
    },

    updateTimer: function () {
        if (this.timer) {
            this.timer.removeFromParent();
            delete this.timer;
        }

        if (!this.model.countdown || !this.model.countdown.getTimeLeft()) {
            return;
        }

        var styles = cleverapps.styles.ToolbarItemView.timer;

        var timer = this.timer = new cleverapps.CountDownView(this.model.countdown, {
            font: cleverapps.styles.FONTS.TOOLBAR_ITEM_TIMER_TEXT,
            background: {
                frame: bundles.timer.frames.timer_bg_png,
                width: styles.bg.width,
                height: styles.bg.height
            }
        });
        timer.setPositionRound(styles);
        this.content.addChild(timer);
    },

    updateTextReward: function () {
        if (this.reward) {
            this.reward.removeFromParent();
            delete this.reward;
        }

        var text = this.model.getText();
        if (text) {
            var styles = cleverapps.styles.ToolbarItemView.reward;
            var reward = this.reward = new TextWithIcon(text, { font: cleverapps.styles.FONTS.TOOLBAR_ITEM_REWARD_TEXT });
            reward.setPositionRound(styles);
            this.content.addChild(reward);
        }
    },

    updatePrice: function () {
        if (this.price) {
            this.price.removeFromParent();
            delete this.price;
        }

        var price = this.model.getPrice();
        if (price) {
            var styles = cleverapps.styles.ToolbarItemView.price;
            this.price = new TextWithIcon(price, { font: cleverapps.styles.FONTS.TOOLBAR_ITEM_TIMER_TEXT });
            this.price.setPositionRound(styles);
            this.content.addChild(this.price);

            var bg = cleverapps.UI.createScale9Sprite(bundles.timer.frames.timer_bg_png);
            bg.setContentSize2(styles.bg);
            this.price.addChild(bg, -1);
            bg.setPositionRound(this.price.width / 2, this.price.height / 2);
            this.price.setCascadeOpacityEnabledRecursively(true);
        }
    },

    onChangeState: function () {
        if (this.model.grayscaleDisabled) {
            cleverapps.UI.convertToGrayScale(this.icon, this.model.enabled);
        }

        this.mark.setVisible(this.model.marked);

        if (this.model.marked) {
            this.shake();
        }

        this.updateTimer();
        this.updateTextReward();
        this.updatePrice();
    },

    shake: function () {
        if (this.shakeInProgress) {
            return;
        }

        this.shakeInProgress = true;

        this.content.runAction(
            new cc.Sequence(
                new cc.DelayTime(Math.floor(Math.random() * 8) + 8),
                new cc.Sequence(
                    new cc.ScaleTo(0.5, 1.2, 0.8),
                    new cc.Spawn(
                        new cc.ScaleTo(0.2, 1, 1),
                        new cc.MoveBy(0.2, 0, 150)
                    ),
                    new cc.MoveBy(0.2, 0, -150),
                    new cc.MoveBy(0.1, 0, 30),
                    new cc.MoveBy(0.1, 0, -30),
                    new cc.CallFunc(function () {
                        this.shakeInProgress = false;
                    }.bind(this))
                )
            )
        );
    },

    createIcon: function () {
        if (cleverapps.Spine.isAnimationLoaded(this.model.getIcon())) {
            var icon = new cleverapps.Spine(this.model.getIcon());
            icon.setAnimation(0, "idle", true);
        } else {
            icon = new cc.Sprite(this.model.getIcon());
        }
        return icon;
    },

    onMouseOver: function () {
        if (this.model.enabled || this.model.dragAction) {
            this.setScale((this.baseScale * 1.05) || 1.05);
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TOOLBAR_ITEM_TIMER_TEXT: {
        name: "default",
        size: 21
    },

    TOOLBAR_ITEM_REWARD_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.ToolbarItemView = {
    timer: {
        x: { align: "center" },
        y: { align: "bottom", dy: 0 },
        bg: {
            width: 120,
            height: 30
        }
    },

    icon: {
        x: { align: "center" },
        y: { align: "center" }
    },

    mark: {
        x: { align: "center", dx: -30 },
        y: { align: "center", dy: 45 }
    },

    reward: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 12 }
    },

    price: {
        x: { align: "center" },
        y: { align: "bottom", dy: 0 },
        bg: {
            width: 65,
            height: 30
        }
    }
};
