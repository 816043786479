/**
 * Created by olga on 17.01.2023
 */

var SpotlightView = cc.Scale9Sprite.extend({
    ctor: function () {
        this._super(bundles.tool.frames.tool_bg);
        this.setContentSize2(cleverapps.styles.SpotlightView);
        this.createContent();
    },

    setFocus: function () {
        this.editBox.setFocus();
    },

    setBlur: function () {
        this.editBox.setBlur();
    },

    show: function () {
        this.editBox.setVisible(true);
    },

    hide: function () {
        this.editBox.setVisible(false);
    },

    createContent: function () {
        var styles = cleverapps.styles.SpotlightView;
        var icon = new cc.Sprite(bundles.tool.frames.search);
        icon.setPositionRound(styles.icon);
        this.addChild(icon);

        var editBox = this.editBox = new cc.EditBox(styles.editbox, undefined, true);
        editBox.setMaxLength(15);
        editBox.setDelegate(this);
        editBox.stayOnTop(true);
        editBox.setTouchEnabled();
        editBox.setFontColor(cleverapps.styles.FONTS.TOOL_EDIT_TEXT.color);
        editBox.setFont(cleverapps.UI.getFontName(), cleverapps.styles.FONTS.TOOL_EDIT_TEXT.size);
        editBox.setPositionRound(styles.editbox);
        this.addChild(editBox);

        var closeButton = this.closeButton = new cc.Sprite(bundles.tool.frames.close_icon);
        cleverapps.UI.onClick(closeButton, function () {
            cleverapps.toolModel.toggleSearch("");
        });
        cleverapps.UI.applyHover(closeButton);
        closeButton.setPositionRound(styles.closeButton);
        closeButton.setVisible(false);
        this.addChild(closeButton);
    },

    onModelChange: function (str) {
        this.closeButton.setVisible(str && str.length > 0);
        this.editBox.setString(str);
    },

    editBoxOnKeyUp: function (keyCode) {
        if (keyCode === cc.KEY.escape) {
            cleverapps.toolModel.toggleSearch("");
        }
    },

    editBoxReturn: function (sender) {
        this._textBeforeReturn = sender.getString();
        sender.setString("");
    },

    editBoxTextChanged: function (sender, text) {
        if (this._textBeforeReturn || text === "\n") {
            sender.setString(this._textBeforeReturn || "");
            this._textBeforeReturn = undefined;
        }
        var str = sender.getString();
        cleverapps.toolModel.toggleSearch(str);
    }
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    TOOL_EDIT_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 1
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TOOL_EDIT_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TOOL_EDIT_TEXT_STROKE
    }
});

cleverapps.styles.SpotlightView = {
    width: 350,
    height: 60,

    editbox: {
        width: 250,
        height: 60,
        x: { align: "center", dx: 5 },
        y: { align: "center", dy: -10 }
    },
    icon: {
        x: { align: "left", dx: 20 },
        y: { align: "center", dy: 0 }
    },
    closeButton: {
        x: { align: "right", dx: -10 },
        y: { align: "center", dy: 0 }
    }
};