/**
 * Created by denis on 6 august 2020
 */

var DiceBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_DICE);
};

DiceBooster.prototype = Object.create(BaseBoosterBefore.prototype);
DiceBooster.prototype.constructor = DiceBooster;

DiceBooster.prototype.getAfterStartActions = function () {
    return function (f) {
        var dice = Game.currentGame.dice;
        dice.roll();
        dice.reward();
        
        this.execute();
        Game.currentGame.counter.setTimeout(f, 2600 + (dice.number || 6) * 200);
    }.bind(this);
};