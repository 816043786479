/**
 * Created by Vladislav on 07.03.2025.
 */

Placements.ENTRIES["dailyCupReward"] = {
    type: Placements.FREE_FOCUS,
    filter: function () {
        return cleverapps.dailyCup.getReward();
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "dailyCupReward",
            action: function (f) {
                cleverapps.dailyCup.receiveReward(f);
            }
        });
    }
};