/**
 * Created by vladislav on 3/15/19
 */

var IceBlockMakerTile = function (x, y) {
    BaseTile.call(this, x, y);

    this.movable = false;

    Game.currentGame.withIceBlockMakerTile = true;

    this.onCellFreezed = function () {};
};

IceBlockMakerTile.prototype = Object.create(BaseTile.prototype);
IceBlockMakerTile.prototype.constructor = IceBlockMakerTile;

IceBlockMakerTile.prototype.getViewClass = function () {
    return IceBlockMakerTileView;
};

IceBlockMakerTile.prototype.hurt = function () {

};

IceBlockMakerTile.process = function () {
    if (!Game.currentGame.iceBlockMakerTileCanProcess) {
        return;
    }

    Game.currentGame.iceBlockMakerTileCanProcess = false;

    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var tile = Game.currentGame.field.floor[i][j];
            if (tile && tile instanceof IceBlockMakerTile) {
                tile.process();
            }
        }
    }
};

IceBlockMakerTile.prototype.process = function () {
    var cell = Game.currentGame.field.cells[this.y][this.x];
    if (!cell || !this.isFreezable(cell)) {
        this.setCurrent();
        return;
    }

    if (cell === this.currCell) {
        this.setCurrentMoves(this.currMoves + 1);
    } else {
        this.setCurrent(cell);
    }

    if (this.currMoves === 2) {
        if (this.currCell instanceof IceBlockDecoratorCell) {
            this.updateFreeze();
        } else {
            this.freezeCell();
        }
    }
};

IceBlockMakerTile.prototype.setCurrentMoves = function (moves) {
    if ((this.currMoves === 1 || this.currMoves === 2) && moves === 0) {
        this.onStateChanged(false);
    }
    if (this.currMoves === 0 && moves === 1) {
        this.onStateChanged(true);
    }
    this.currMoves = moves;
};

IceBlockMakerTile.prototype.freezeCell = function () {
    var iceBlock = new IceBlockDecoratorCell(this.x, this.y, { components: [DecoratorComponent] });
    iceBlock.setInnerCell(this.currCell);
    iceBlock.lives = 1;

    Game.currentGame.field.addCell(iceBlock);

    this.setCurrent(iceBlock);
    
    this.onCellFreezed();

    Game.currentGame.counter.setTimeout(function () {

    }, 1000);
};

IceBlockMakerTile.prototype.updateFreeze = function () {
    this.currCell.lives = 2;
    this.currCell.onUpdate();

    this.setCurrent();
};

IceBlockMakerTile.prototype.setCurrent = function (cell) {
    if (cell) {
        this.currCell = cell;
    } else {
        delete this.currCell;
    }
    this.setCurrentMoves(0);
};

IceBlockMakerTile.prototype.isFreezable = function (cell) {
    return (!cell.findComponent(DecoratorComponent) || cell instanceof IceBlockDecoratorCell && cell.lives === 1) && cell.getColor() !== undefined;
};

IceBlockMakerTile.prototype.bundleId = function () {
    return ["ice_block_maker", "ice_block"];
};

IceBlockMakerTile.prototype.save = function () {
    return IceBlockMakerTile.CODES;
};

IceBlockMakerTile.MOVES_TO_FREEZE = 2;

IceBlockMakerTile.CODES = ["h"];