/**
 * Created by andrey on 15.05.17.
 */

levels.GameHints = function () {
    var save = cleverapps.dataLoader.load(DataLoader.TYPES.LEVEL_HINTS);
    this.usedHints = typeof save === "number" ? save : 0;
};

levels.GameHints.prototype.reset = function () {
    if (Game.currentGame && Game.currentGame.level.isRegular()) {
        this.usedHints = 0;
        this.save();
    }
};

levels.GameHints.prototype.addHint = function () {
    if (Game.currentGame && Game.currentGame.level.isRegular()) {
        this.usedHints++;
        this.save();
    }
};

levels.GameHints.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.LEVEL_HINTS, this.usedHints);
};