/**
 * Created by evgenijsenkevic on 27.08.2024
 * */

var AdminLocationsScene = cleverapps.FixedWidthScene.extend({
    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_ADMINISTRATOR);

        var adminLocationsView = new AdminLocationsView(cleverapps.administrator.adminLocations);
        this.addChild(adminLocationsView);

        cleverapps.administrator.adminLocations.on(
            "updateActive",
            this.createListener(this.updateActiveLocation.bind(this))
        );

        var log = new AdminLogView();
        this.addChild(log);
        log.setPositionRound({ align: "center" }, { align: "bottom" });
        this.updateActiveLocation();
        this.createGlobalButtons();
        if (cleverapps.git) {
            cleverapps.git.changeFolders(GitManager.FOLDERS.LEVELS);
        }
    },

    createGlobalButtons: function () {
        if (cleverapps.git) {
            this.addChild(new GitButtonsView({
                direction: cleverapps.UI.VERTICAL
            }), 1);
        }
    },

    updateActiveLocation: function () {
        if (this.activeLocationView) {
            this.activeLocationView.unload();
            this.activeLocationView.removeFromParent();
            this.activeLocationView = undefined;
        }

        var location = cleverapps.administrator.adminLocations.activeLocation;
        if (!location) {
            return;
        }

        this.activeLocationView = new AdminActiveLocationView(location);
        this.addChild(this.activeLocationView);
    },

    _closeAction: function () {
        cleverapps.config.adminMode = false;
        cleverapps.setUrlHash({
            mode: undefined
        });
        cleverapps.FixedWidthScene.prototype._closeAction.call(this);
    },

    getBackgroundStyles: function () {
        return {
            scale9: bundles.admin.frames.background
        };
    },

    listBundles: function () {
        return ["admin", "game"];
    }
});

AdminLocationsScene.open = function (f) {
    var action = [
        function (f) {
            cleverapps.setUrlHash({
                mode: "admin_locations"
            });

            cleverapps.config.adminMode = true;
            connector.ads.closeStickyCallback();
            cleverapps.scenes.replaceScene(new AdminLocationsScene(), f);
        }
    ];

    if (f) {
        cleverapps.focusManager.compound(f, action);
    } else if (cleverapps.focusManager.isFocused()) {
        cleverapps.focusManager.distract({
            focus: "adminLocation",
            actions: action
        });
    } else {
        cleverapps.focusManager.display({
            focus: "adminLocation",
            actions: action
        });
    }
};

AdminLocationsScene.IsAvailable = AdministratorScene.IsAvailable;