/**
 * Created by anatoly on 28.02.2025
 */

var SwapHintView = function () {
    cleverapps.availableMove.on("hide", this.hide.bind(this));
    cleverapps.availableMove.on(SwapHintAlgorithm.type, this.show.bind(this));
};

SwapHintView.prototype.show = function (options) {
    this.hide();

    var cellA, cellB;
    var cells = [];
    if (options.shape) {
        cells = options.shape.cells;
    } else {
        cellA = options.start;
        cellB = Game.currentGame.field.cells[cellA.y + options.dir.row] && Game.currentGame.field.cells[cellA.y + options.dir.row][cellA.x + options.dir.col];
        if (!cellA || !cellB) {
            return;
        }
        cells.push(cellA);
        cells.push(cellB);
    }

    cells.forEach(function (cell) {
        var currentCell = cell.innerCell || cell;
        currentCell.onAvailableMoveListener(options);
    });

    cleverapps.audio.playSound(bundles.game.urls.available_move_effect);

    this.cells = cells;
};

SwapHintView.prototype.hide = function () {
    if (!this.cells) {
        return;
    }

    this.cells.forEach(function (cell) {
        var currentCell = cell.innerCell || cell;
        currentCell.onStartMoveListener({ x: cell.x, y: cell.y }, { moveInterval: 0.1, easing: cc.easeOut(1) });
    });

    this.cells = undefined;
};