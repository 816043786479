/**
 * Created by vladislav on 2/20/19
 */

var DailyCupLeadersWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            title: "DailyCupLeadersWindow.Title",
            name: "dailycupleaderswindow",
            content: this.createContent()
        });
    },

    load: function (onSuccess, onFailure) {
        onSuccess = cleverapps.wait(2, onSuccess);

        this._super(onSuccess, onFailure);

        if (Cup.DailyCupLeadersCache) {
            onSuccess();
        } else {
            cleverapps.RestClient.get("/dailycup/leaders/" + encodeURIComponent(connector.platform.getUserID()), {}, function (data) {
                Cup.DailyCupLeadersCache = data;
                onSuccess();
            }, function () {
                cleverapps.notification.create("LeadersLoadFailed");
                onSuccess();
            });
        }
    },

    createContent: function () {
        var data = Cup.DailyCupLeadersCache || {};

        var results = data.leaders || [], playerFound = false;

        results = results.map(function (item) {
            return {
                id: item.id,
                data: {
                    firstPlace: item.firstPlace,
                    secondPlace: item.secondPlace,
                    thirdPlace: item.thirdPlace,
                    avatar: item.avatar,
                    name: item.text
                }
            };
        });

        for (var i = 0; i < results.length; i++) {
            if (results[i].id === connector.platform.getUserID()) {
                playerFound = true;
                results[i].data.player = true;
                break;
            }
        }
        if (!playerFound) {
            var user = {
                id: connector.platform.getUserID(),
                data: {
                    firstPlace: data.playerResult ? data.playerResult.firstPlace : "-",
                    secondPlace: data.playerResult ? data.playerResult.secondPlace : "-",
                    thirdPlace: data.playerResult ? data.playerResult.thirdPlace : "-",
                    player: true,
                    avatar: cleverapps.user.getPlayerInfo().avatar,
                    name: cleverapps.user.getPlayerInfo().name,
                    notInTop: true
                }
            };
            results.push(user);
        }

        results.forEach(function (player, index) {
            player.data.place = index + 1;
        });

        return new TableView(cleverapps.cupsLeadersTable, {
            id: "dailyCupLeadersWindow",
            data: results,
            height: cleverapps.styles.DailyCupLeadersWindow.table[cleverapps.resolution.mode].height,
            rowOptions: {
                prizes: false,
                badges: false
            },
            rowConstructor: DailyCupLeadersRow
        });
    }
});

cleverapps.styles.DailyCupLeadersWindow = {
    table: [{ height: 900 }, { height: 732 }, { height: 732 }]
};