/**
 * Created by vladislav on 28.09.2020
 */

var HighlightBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_HIGHLIGHT);
};

HighlightBooster.prototype = Object.create(BaseBoosterBefore.prototype);
HighlightBooster.prototype.constructor = HighlightBooster;

HighlightBooster.prototype.getAfterStartActions = function () {
    return function (f) {
        Game.currentGame.currentHighlighter.enable();
        this.execute();
        Game.currentGame.counter.setTimeout(f, cleverapps.config.name === "scramble" ? 0 : 300);
    }.bind(this);
};