/**
 * Created by slava on 02.02.17.
 */

var BoosterCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = true;
    this.hurtable = false;
    this.putInPoolAvailable = true;
    this.fallThroughBarrier = true;
    this.selectType();
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BOOSTER_CELL_ADDED);

    var game = Game.currentGame;
    if (game) {
        game.boosterAdded = true;
    }
};

BoosterCell.prototype = Object.create(BaseCell.prototype);
BoosterCell.prototype.constructor = BoosterCell;

BoosterCell.prototype.save = function () {
    return BoosterCell.CODES.concat(this.type);
};

BoosterCell.prototype.load = function (data) {
    if (data[1] !== undefined) {
        this.type = parseInt(data[1]);
    }
};

BoosterCell.prototype.onTouchEnd = function () {
    this.animate(BaseCell.ANIMATION_DOWN);
    cleverapps.notification.create("Booster.MoveToDown");
};

BoosterCell.listAvailable = function () {
    return Game.currentGame.boosters.list.filter(function (booster) {
        return !booster.isDisabled() && booster.id !== 2;
    }).map(function (booster) {
        return booster.id;
    });
};

BoosterCell.prototype.selectType = function () {
    this.type = cleverapps.Random.choose(BoosterCell.listAvailable());
};

BoosterCell.prototype.getViewClass = function () {
    return BoosterCellView;
};

BoosterCell.prototype.onBurnStage = function () {
    if (Game.currentGame) {
        for (var i = this.y + 1; i < Field.SIZE; i++) {
            if (Game.currentGame.field.inField(i, this.x)) {
                return;
            }
        }
        this.explode();
        Game.currentGame.addBoosterCellReward(this);
    }
};

BoosterCell.prototype.explodeDuration = function () {
    return 1;
};

BoosterCell.isNeedToAdd = function (moves, levelMoves) {
    if (Game.currentGame.boosterAdded) {
        return false;
    }

    if (BoosterCell.listAvailable().length === 0) {
        return false;
    }

    if (Game.currentGame.level.isRegular()) {
        if (levelMoves > 15 && levelMoves - moves > 2 && moves > 5 && Math.random() < 0.08 / (levelMoves - 8)) {
            var hasBooster = false;

            var field = Game.currentGame.field;

            field.nextCells.forEach(function (cell) {
                if (cell === BoosterCell.CODES[0]) {
                    hasBooster = true;
                }
            });

            if (!hasBooster) {
                return true;
            }
        }
    }
    return false;
};
