/**
 * Created by Andrey Popov on 14.11.2024
 */

var BlackFridayOfferLogic = function (offer) {
    this.offer = offer;
    this.reward = Offers[offer.type].reward;

    this.onBought = function () {};
};

BlackFridayOfferLogic.prototype.getStage = function () {
    return this.offer.stage || 0;
};

BlackFridayOfferLogic.prototype.getProduct = function () {
    if (this.getStage() === 0) {
        return Product.CreateById("kit10", {
            type: "blackfriday_offer",
            offer: this.offer.type
        });
    }

    return undefined;
};

BlackFridayOfferLogic.prototype.getPriceText = function () {
    var product = this.getProduct();
    return product && product.getCurrentPrice();
};

BlackFridayOfferLogic.prototype.getTimeLeft = function () {
    var event = cleverapps.eventManager.getFeatureEvent("blackfriday_offer_main");
    return event && event.getTimeLeft() || 0;
};

BlackFridayOfferLogic.prototype.isIconAvailable = function () {
    return true;
};

BlackFridayOfferLogic.prototype.hasIconAttention = function () {
    return false;
};

BlackFridayOfferLogic.prototype.buy = function () {
    if (this.reward.unit) {
        var unitsAmount = this.reward.unit.reduce(function (sum, unit) {
            return sum + (unit.amount || 0);
        }, 0);

        var notEnough = unitsAmount - Map2d.currentMap.countEmptySlots();
        if (notEnough > 0) {
            cleverapps.centerHint.createTextHint("Spawn.nospace", { left: notEnough });
            return;
        }
    }

    this.getProduct().buy(this.processBought.bind(this), { noRewardWindow: true });
};

BlackFridayOfferLogic.prototype.processBought = function (success) {
    if (!success) {
        return;
    }

    new RewardWindow(this.reward, { event: cleverapps.EVENTS.EARN.PURCHASE });

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.OFFER + this.offer.name);

    this.offer.incStage();
    this.offer.complete();

    this.onBought();
};

BlackFridayOfferLogic.RestoreActions = function (product, context, consume) {
    var offer = cleverapps.offerManager.findOffer({ type: context.offer });
    if (!offer) {
        return;
    }

    return [
        function (f) {
            consume();
            offer.logic.processBought(true);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    ];
};

Restorable.BY_TYPE["blackfriday_offer"] = new Restorable({
    location: Restorable.LocationOfferExpedition,
    listActions: BlackFridayOfferLogic.RestoreActions
});