/**
 * Created by iamso on 08.07.2021
 */

var InviteIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.invite_icon_json,
        priority: SideBar.PRIORITY_REDUNDANT
    });
};

InviteIcon.prototype = Object.create(SideBarIcon.prototype);
InviteIcon.prototype.constructor = InviteIcon;

InviteIcon.prototype.onPressed = function () {
    cleverapps.focusManager.display({
        focus: "InviteIcon",
        action: function (f) {
            var channel = cleverapps.Random.mathChoose(connector.platform.listShareChannels());

            if (channel) {
                if (this.attention) {
                    cleverapps.dataLoader.save(DataLoader.TYPES.INVITE_ICON, { shown: true });

                    cleverapps.sideBar.resetByClassName(InviteIcon);
                }

                cleverapps.SocialManager.share(channel, "default", function (success) {
                    if (success) {
                        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.INVITE_ICON_SHARE);
                    }
                    f();
                });
            } else {
                f();
            }
        }.bind(this)
    });
};

InviteIcon.prototype.resetState = function () {
    this.available = levels.user.checkAvailable(cleverapps.Availables.INVITE_FRIENDS_ICON)
        && connector.platform.listShareChannels().length > 0;

    var data = cleverapps.dataLoader.load(DataLoader.TYPES.INVITE_ICON) || {};
    this.setAttention(!data.shown);
};
