/**
 * Created by Vladislav on 07.03.2025.
 */

RewardTypes.cup = {
    icon: {},
    smallIcon: {},
    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function (value) {
        return this.icon[value.type];
    },

    getSmallIcon: function (value) {
        return this.smallIcon[value.type];
    },

    handler: function (value) {
        var cup;

        switch (value.type) {
            case CupsConfig.TYPE_DAILY: cup = cleverapps.dailyCup; break;
            case CupsConfig.TYPE_WEEKLY: cup = cleverapps.weeklyCup; break;
            case CupsConfig.TYPE_CLAN: cup = cleverapps.clanCup; break;
        }

        if (cup) {
            cup.addAmount(value.amount);

            if (cup.innerCup) {
                cup.innerCup.addAmount(value.amount);
            }
        }

        return function () {};
    }
};

RewardTypes.cup.icon[CupsConfig.TYPE_CLAN] = bundles.reward_icons.frames.cup_png;
RewardTypes.cup.smallIcon[CupsConfig.TYPE_CLAN] = bundles.reward_icons.frames.cup_small_png;