/**
 * Created by andrey on 20.01.2025
 */

var Row = function (id, options) {
    cleverapps.EventEmitter.call(this);

    this.id = id;
    this.table = options.table;
    this.setScore(options.score);
    this.setData(options.data);
};

Row.prototype = Object.create(cleverapps.EventEmitter.prototype);
Row.prototype.constructor = Row;

Row.prototype.setScore = function (score) {
    this.score = score;
};

Row.prototype.setData = function (data) {
    this.data = Object.assign({}, data || {});
};

Row.prototype.isPlayer = function () {
    return this.data.player;
};

Row.prototype.getPrizeTooltip = function () {
    if (this.data.prizeTooltip) {
        return this.data.prizeTooltip;
    }

    if (this.data.prizeRewards && this.data.prizeRewards.units) {
        return this.data.prizeRewards.units.map(function (unit) {
            return cleverapps.unitsLibrary.getUnitName(unit);
        }).join(", ");
    }

    return "PrizeTooltip";
};

Row.prototype.removeView = function () {
    if (this.view) {
        this.view.removeFromParent();
        this.view = undefined;
    }
};

Row.prototype.getView = function () {
    return this.view;
};

Row.prototype.updateScoreView = function (options) {
    if (this.view && this.view.updateScore) {
        this.view.updateScore(this.score, options);
    } else if (options && options.callback) {
        options.callback();
    }
};

Row.prototype.getViewScore = function () {
    return this.view && this.view.score;
};

Row.prototype.updatePrizeView = function () {
    if (this.view && this.view.updatePrize) {
        this.view.updatePrize();
    }
};

Row.prototype.getPlace = function () {
    return this.data.place;
};

Row.prototype.getViewPlace = function () {
    return this.view && this.view.place;
};

Row.prototype.getViewPosition = function () {
    return this.view && this.view.getPosition();
};

Row.prototype.isViewVisible = function () {
    return this.view && this.view.isVisible();
};

Row.prototype.updateViewPlace = function (place, options) {
    if (this.view) {
        this.view.updatePlace(place, options);
    } else {
        options && options.callback && options.callback();
    }
};
