/**
 * Created by slava on 24.03.17.
 */

var CleverappsWindow = BaseWindow.extend({
    onWindowLoaded: function (options) {
        options = options || {};
        this.params = options;

        if (options.closeButtonDelay === true) {
            options.closeButtonDelay = 1.5;
        }
        this.params.closeButtonManual = options.closeButtonManual;
        this.params.closeButtonDelay = options.closeButtonDelay || 0;

        var styles = cleverapps.styles.Window;
        if (options.styles) {
            styles = cleverapps.overrideStyles(styles, options.styles, true);
        }
        this.styles = styles;

        this.name = options.name || options.title || "window";

        this.content = options.content;
        this.withBg = !options.noBackground && !styles.noBackground;

        this.buttons = this.createWindowButtons();

        if (options.shareId && ShareCheckBoxModel.isAvailable()) {
            this.shareCheckBoxModel = new ShareCheckBoxModel(options.shareId, this);

            this.shareCheckBox = new ShareCheckBox(this.shareCheckBoxModel);
        }

        if (options.closeButton !== false && styles.closeButton !== false) {
            this.closeButton = this.createCloseButton();
        }

        if (options.loginOrInvite) {
            this.loginOrInvite = cleverapps.FBLoginOrInvite();
        }

        if (this.withBg) {
            this.createWindowWithBg();
        } else {
            this.createWindowWithoutBg();
        }

        this.debugId = this.name;

        this.windowSize = this.window.getContentSize();

        if (this.params.title && this.withBg) {
            this.windowTitle = this.createWindowTitle();
            this.window.addChild(this.windowTitle);
            this.windowTitle.adjustPosition();
        }

        if (options.help && !styles.HelpButton.lovesTitle) {
            var helpButton = this.createHelpButton(options.help);
            this.window.addChild(helpButton);
        }

        if (options.homeButton) {
            this.homeButton = this.createHomeButton();
            this.addChild(this.homeButton);
        }

        if (cleverapps.oneOf(this.closeButton, BandButton, CloseRoundButton)) {
            this.addChild(this.closeButton);
        } else if (this.closeButton instanceof CloseButton) {
            this.window.addChild(this.closeButton);
        }

        var decorStyles = cleverapps.skins.getSlot("windowDecors", {
            decors: styles.decors,
            window: this,
            level: options.level
        });
        if (decorStyles) {
            this.decors = new Decors(this, decorStyles);
        }

        if (options.closeButton !== false || options.closeByBackButton) {
            this.closeByBackButton = this.createListener(function () {
                if (cleverapps.focusManager.checkEventNode(this)) {
                    this.close();
                }
            }.bind(this));
        }

        if (options.fireworks) {
            this.fireworks = new Fireworks(this.window, options.fireworks);
            this.fireworks.setPositionRound(this.width / 2, this.height / 2);
            this.fireworks.setLocalZOrder(options.fireworks.zOrder || 0);
            this.addChild(this.fireworks);
        }

        if (options.offerWindowButton) {
            options.offerWindowButton.positioningStartOffset = this.window.width / 2;
            this.windowOffer = new WindowOffer(this, options.offerWindowButton);
        }

        var person = this.getPerson && this.getPerson();
        if (person) {
            this.persons = new WindowPersons(person, this);
        }

        this.windowPadding = this.getWindowPadding();

        this.closeAnimationCallbacks = [];

        cleverapps.audio.playSound(options.openSound || bundles.main.urls.open_window_effect || bundles.main.urls.window_effect);
    },

    createWindowButtons: function () {
        var items = [];

        if (this.params.button) {
            this.params.button.onClicked = this.params.button.onPressed || this.close.bind(this);
            items.push(new BottomButton(this.params.button));
        }

        if (this.params.buttons) {
            items = items.concat(this.params.buttons);
        }

        if (items.length) {
            return new Buttons(items);
        }
    },

    createWindowTitle: function () {
        return new WindowTitle(this, this.params.title, this.styles.HelpButton.lovesTitle && this.params.help);
    },

    createWindowWithoutBg: function () {
        var tapToContinueItems = [];

        if (this.buttons) {
            tapToContinueItems.push(this.buttons);
        }

        if (this.closeButton instanceof TapToContinue) {
            tapToContinueItems.push(this.closeButton);
        }

        if (tapToContinueItems.length) {
            this.tapToContinue = new cleverapps.Layout(tapToContinueItems, {
                direction: cleverapps.UI.VERTICAL,
                margin: this.styles.Footer.margin,
                padding: this.styles.TapToContinue.padding
            });
            this.tapToContinue.setLocalZOrder(2);
            this.tapToContinue.setCascadeOpacityEnabledRecursively(true);
            this.addChild(this.tapToContinue);
        }

        this.contentItems = cleverapps.UI.wrapWithPadding(this.content);
        this.contentWrapper = cleverapps.UI.wrapWithPadding(this.contentItems);
        this.window = cleverapps.UI.wrapWithPadding(this.contentWrapper);

        var windowItems = [this.window];

        if (this.params.title) {
            this.windowTitle = this.createWindowTitle();
            windowItems.unshift(this.windowTitle);
        }

        this.window.setLocalZOrder(1);

        this.wrapper = new cleverapps.Layout(windowItems, {
            direction: cleverapps.UI.VERTICAL,
            margin: this.styles.titleMargin
        });
        this.wrapper.avoidNode = "Window";
        this.wrapper.setLocalZOrder(1);
        this.addChild(this.wrapper);

        this.wrapper.setPositionRound(this.width / 2, this.height / 2);

        if (this.params.title) {
            this.windowTitle.adjustPosition();
        }

        this.createWindowFooter();
    },

    createWindowWithBg: function () {
        this.contentItems = new cleverapps.Layout([this.content, this.shareCheckBox, this.buttons].filter(Boolean), {
            margin: this.styles.margin,
            direction: cleverapps.UI.VERTICAL
        });

        var padding = Array.isArray(this.styles.padding) ? this.styles.padding[cleverapps.resolution.mode] : this.styles.padding;

        this.contentWrapper = cleverapps.UI.wrapWithPadding(this.contentItems, !this.params.noPadding && padding);
        this.contentWrapper.setLocalZOrder(1);

        var borderPadding = !this.params.noBorder && this.styles.border
            ? cc.multPadding(cc.padding(this.styles.border), this.styles.border.scale[cleverapps.resolution.mode]) : cc.padding();

        this.window = cleverapps.UI.wrapWithPadding(this.contentWrapper, borderPadding);
        this.window.setLocalZOrder(1);

        cleverapps.UI.wrapWithPadding(this.window, this.getWindowMinPadding(this.window.getContentSize()), true);

        this.bg = this.createBackground();
        this.window.addChild(this.bg);
        this.bg.setLocalZOrder(-1000);

        this.adjustBackground();

        this.wrapper = cleverapps.UI.wrapWithPadding(this.window);
        this.wrapper.avoidNode = "Window";
        this.wrapper.setLocalZOrder(1);
        this.addChild(this.wrapper);

        if (this.params.foreground) {
            this.foreground = this.createForeground(this.params.foreground);
            this.window.addChild(this.foreground);
        }

        if (this.buttons) {
            this.buttons.contentItemsPosition = this.buttons.getPosition();
            this.buttons.replaceParentSamePlace(this.window);
        }

        this.wrapper.setPositionRound(this.width / 2, this.height / 2);

        if (this.tabs && this.tabs.isVisible()) {
            var contentBox = this.contentWrapper.getGlobalBoundingBox();
            var tabsBox = this.tabs.getGlobalBoundingBox();
            this.contentWrapperPadding = cc.rectUnionPadding(contentBox, tabsBox);
            cleverapps.UI.wrapWithPadding(this.contentWrapper, this.contentWrapperPadding, true);
        }

        this.createWindowFooter();
    },

    createWindowFooter: function () {
        var footerItems = [];

        if (this.params.footNote) {
            footerItems.push(this.params.footNote);
        }

        if (this.shareCheckBox && !this.withBg) {
            this.shareCheckBox.setOpacity(180);
            footerItems.push(this.shareCheckBox);
        }

        if (this.loginOrInvite) {
            footerItems.push(this.loginOrInvite);
        }

        if (footerItems.length) {
            this.windowFooter = new cleverapps.Layout(footerItems, {
                direction: cleverapps.UI.VERTICAL,
                margin: this.styles.Footer.margin
            });
            this.windowFooter.setPositionRound(this.styles.Footer);
            this.windowFooter.setLocalZOrder(2);
            this.addChild(this.windowFooter);
        }
    },

    adjustTapToContinue: function () {
        if (!this.tapToContinue) {
            return;
        }

        var footerBox = this.windowFooter ? this.windowFooter.getBoundingBox() : undefined;
        var footerTop = footerBox ? footerBox.y + footerBox.height : 0;

        this.tapToContinue.setScale(1);
        this.tapToContinue.setPositionRound(this.wrapper.x, footerTop + this.tapToContinue.height / 2);
    },

    adjustBackground: function () {
        if (!this.withBg || !this.bg) {
            return;
        }

        var scale = this.getBackgroundScale();
        var size = this.window.getContentSize();

        this.bg.setScale(scale);
        this.bg.setContentSize2(size.width / scale, size.height / scale);
        this.bg.setPositionRound(this.window.width / 2, this.window.height / 2);
    },

    adjustForeground: function () {
        if (!this.withBg || !this.bg || !this.foreground) {
            return;
        }

        var rect = cc.rect(0, 0, this.window.width, this.window.height);

        if (this.styles.Foreground) {
            var padding = cc.mergePaddings([
                cc.padding(this.styles.Foreground.padding),
                cc.multPadding(cc.padding(this.styles.Foreground.contentPadding), this.contentWrapper.scale)
            ]);

            rect = cc.rectSubPadding(rect, padding);
        }

        this.foreground.setScale(this.bg.scale);
        this.foreground.setPositionRound(rect.x, rect.y);
        this.foreground.setContentSize2(rect.width / this.foreground.scale, rect.height / this.foreground.scale);
    },

    adjustWrapper: function () {
        var contentWrapperRect = this.contentWrapper.getGlobalBoundingBox();
        var contentRect = cc.rectSubPadding(
            contentWrapperRect,
            cc.multPadding(cc.padding(this.contentWrapperPadding), this.contentWrapper.scale)
        );
        var contentPadding = this.withBg && this.bg && !this.params.noBorder
            ? cc.multPadding(cc.padding(this.styles.border), this.getBackgroundScale()) : cc.padding();

        var windowRect = cc.rectAddPadding(contentRect, contentPadding);
        windowRect = cc.rectAddPadding(windowRect, this.getWindowMinPadding(windowRect));

        var wrapperRect = cc.rectAddPadding(windowRect, this.windowPadding);

        this.window.setContentSize2(windowRect);
        this.wrapper.setContentSize2(wrapperRect);

        this.adjustBackground();
        this.adjustForeground();

        this.wrapper.setPosition(this.convertToNodeSpace(cc.rectGetCenter(wrapperRect)));
        this.window.setPosition(this.window.parent.convertToNodeSpace(cc.rectGetCenter(windowRect)));
        this.contentWrapper.setPosition(this.contentWrapper.parent.convertToNodeSpace(cc.rectGetCenter(contentWrapperRect)));

        if (this.withBg && this.buttons) {
            this.buttons.setPosition(this.buttons.parent.convertToNodeSpace(this.contentItems.convertToWorldSpace(this.buttons.contentItemsPosition)));
            this.buttons.setScale(this.contentWrapper.getScale());
        }

        // this.content.debugBorder();
        // this.contentWrapper.debugBorder();
    },

    inflateWindow: function () {
        this._super();

        this.wrapper.setScale(1);
        this.wrapper.setPositionRound(this.width / 2, this.height / 2);

        this.adjustTapToContinue();

        if (!this.params.noScaleAdjustment) {
            var sceneSize = cleverapps.resolution.getSceneSize();
            var lovesPosition = cc.p(sceneSize.width / 2, sceneSize.height / 2);
            if (this.windowTitle && !this.withBg) {
                lovesPosition.y += this.styles.titleMargin;
            }
            cleverapps.UI.inflateToBoundaries(this.contentWrapper, this.getOverlappingNodes(), {
                padding: this.getContentPadding(),
                lovesPosition: this.convertToWorldSpace(lovesPosition),
                maxScale: 1
            });

            this.adjustWrapper();
        }

        if (this.windowTitle) {
            this.windowTitle.adjustScale();
            this.windowTitle.adjustPosition();
        }

        this.adjustHelpButton();

        if (this.closeButton) {
            this.closeButton.adjustPosition && this.closeButton.adjustPosition();
        }

        if (this.persons) {
            this.persons.update();
        }

        if (this.decors) {
            this.decors.updateAll();
        }

        this.wrapper.basePosition = this.wrapper.getPosition();

        // adjust window scale and position while film effect
        var scene = cleverapps.scenes.getRunningScene();
        var safePadding = cleverapps.resolution.getSafePadding();
        var position = scene.convertToNodeSpace(cc.p(scene.width / 2 + safePadding.left, scene.height / 2 + safePadding.bottom));
        var scale = 1 / scene.getScale();
        this.setScale(scale);
        this.setPosition(position.x, position.y);
    },

    getOverlappingNodes: function () {
        var scene = cleverapps.scenes.getRunningScene();

        var withMenubar = cleverapps.focusManager.isControlEnabled([
            "MenuBarGoldItem", "MenuBarGameLevelItem", "MenuBarLivesItem",
            "MenuBarCoinsItem", "MenuBarWandsItem", "MenuBarWorkersItem", "MenuBarGardenProgressItem"]);

        return [
            withMenubar ? scene.upMenuContainer : undefined,
            this.goldInfo,
            this.bottomLine,
            this.windowFooter,
            this.tapToContinue,
            this.windowOffer && this.windowOffer.getOverlapNode()
        ].filter(Boolean);
    },

    getContentPadding: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();

        var backgroundPadding = this.getBackgroundPadding();
        var padding = this.params.contentPadding;

        if (padding === cleverapps.UI.DOCK_RIGHT) {
            padding = { left: sceneSize.width / 2 };
        } else if (padding === cleverapps.UI.DOCK_LEFT) {
            padding = { right: sceneSize.width / 2 };
        } else if (padding === cleverapps.UI.DOCK_BOTTOM) {
            padding = { top: sceneSize.height / 2 };
        } else if (padding === cleverapps.UI.DOCK_TOP) {
            padding = { bottom: sceneSize.height / 2 };
        }

        padding = padding ? cc.mergePaddings([cc.padding(padding), backgroundPadding]) : backgroundPadding;

        padding = cc.paddingAddPadding(padding, this.windowPadding);

        if (this.withBg && this.bg && !this.params.noBorder) {
            padding = cc.paddingAddPadding(padding, cc.multPadding(cc.padding(this.styles.border), this.getBackgroundScale()));
        }

        return padding;
    },

    getBackgroundPadding: function () {
        var styles = this.styles || cleverapps.styles.Window;
        return cc.padding(styles.Background.padding);
    },

    getBackgroundScale: function () {
        return !this.params.noBorder && this.styles.border ? this.styles.border.scale[cleverapps.resolution.mode] : 1;
    },

    getWindowMinPadding: function (windowSize) {
        var minWidth = this.params.minWidth !== undefined ? this.params.minWidth : cleverapps.styles.Window.minWidth;
        var minHeight = this.params.minHeight !== undefined ? this.params.minHeight : cleverapps.styles.Window.minHeight;

        var diffX = (minWidth - windowSize.width) / 2;
        var diffY = (minHeight - windowSize.height) / 2;

        return cc.padding({
            x: diffX > 0 ? diffX : 0,
            y: diffY > 0 ? diffY : 0
        });
    },

    getWindowPadding: function () {
        var windowPadding = cc.padding();
        var windowBox = this.window.getGlobalBoundingBox();

        [!this.params.customTitle ? this.windowTitle : undefined].filter(Boolean).forEach(function (node) {
            var nodeBox = node.getGlobalBoundingBox();
            windowPadding = cc.mergePaddings([windowPadding, cc.rectUnionPadding(windowBox, nodeBox)]);
        });

        return windowPadding;
    },

    setupChildren: function () {
        this._super();

        if (this.fireworks) {
            this.fireworks.setPositionRound(this.width / 2, this.height / 2);
        }

        if (this.windowFooter) {
            this.windowFooter.setPositionRound(this.styles.Footer);
        }

        if (this.windowOffer) {
            this.windowOffer.update();
        }
    },

    createCloseButton: function () {
        var ConstructorClass = BandButton;
        var params = {
            onClicked: this.close.bind(this),
            styles: this.styles.CloseButton,
            window: this
        };

        if (this.params.closeButtonConstructor) {
            ConstructorClass = this.params.closeButtonConstructor;
        } else if (!this.params.bandButton) {
            if (this.withBg) {
                ConstructorClass = CloseButton;
            } else if (!this.params.notCloseByTouchInShadow) {
                ConstructorClass = TapToContinue;
                params = {
                    text: this.params.tapToContinueText || "Window.TapToContinue"
                };
            }
        }

        return new ConstructorClass(params);
    },

    createHelpButton: function (callback) {
        var helpButton = this.helpButton = new cleverapps.UI.HelpButton({
            type: cleverapps.styles.UI.Button.Images.help_button_title,
            callback: callback
        });

        this.adjustHelpButton();

        return helpButton;
    },

    adjustHelpButton: function () {
        if (!this.helpButton) {
            return;
        }

        var styles = this.styles.HelpButton;
        var scale = styles.scale[cleverapps.resolution.mode];

        this.helpButton.baseScale = scale;
        this.helpButton.setScale(scale);
        this.helpButton.setPositionRound(styles.position);
        this.helpButton.setLocalZOrder(styles.zOrder);
    },

    createHomeButton: function () {
        var homeButton = new cleverapps.UI.Button({
            type: {
                button_png: bundles.controlbuttons.frames.back_button_png,
                button_on_png: bundles.controlbuttons.frames.back_button_on_png
            },
            onClicked: function () {
                var boatswain = Game.currentGame && Game.currentGame.boatswain;
                if (boatswain) {
                    boatswain.setIntent(Boatswain.RETURN_INTENT);
                }

                this.close();
            }.bind(this),
            content: new cc.Sprite(bundles.buttons_main.frames.home_icon)
        });
        homeButton.avoidNode = "HomeButton";
        homeButton.setupChildren = function () {
            homeButton.setPositionRound(this.styles.HomeButton.position);
        }.bind(this);
        homeButton.setupChildren();
        return homeButton;
    },

    onShow: function () {
        if (this.windowTitle && !this.params.customTitle) {
            this.windowTitle.show();
        }

        if (this.shareCheckBox) {
            this.shareCheckBox.updateState();
        }

        if (this.fireworks) {
            this.fireworks.start();
        }

        if (this.persons) {
            this.persons.showUp();
        }

        if (this.windowOffer) {
            this.windowOffer.show();
        }

        this.hideBeforeDelayedShowUp();
        if (this.params.closeButtonManual) {
            this.params.closeButtonManual = false; // only first time manual, after reopen - all is shown immediately
        } else {
            this.delayedShowUp();
        }

        if (this.params.showSound) {
            cleverapps.audio.playSound(this.params.showSound);
        }
    },

    hideBeforeDelayedShowUp: function () {
        if (this.homeButton) {
            this.homeButton.stopAllActions();
            this.homeButton.setCascadeOpacityEnabledRecursively(true);
            this.homeButton.setVisible(false);
            this.homeButton.setOpacity(0);
        }

        if (this.closeButton) {
            this.closeButton.hide(true);
        }

        if (this.windowFooter) {
            this.windowFooter.setCascadeOpacityEnabledRecursively(true);
            this.windowFooter.setVisible(false);
            this.windowFooter.setOpacity(0);
        }
    },

    delayedShowUp: function () {
        var delay = this.params.closeButtonDelay;

        if (this.homeButton) {
            this.homeButton.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.Show(),
                new cc.FadeIn(0.4).easing(cc.easeOut(1.2))
            ));
        }

        if (this.closeButton) {
            this.closeButton.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.CallFunc(function () {
                    this.closeButton.show();
                    this.enableTouchInShadow();
                }.bind(this))
            ));
        }

        if (this.windowFooter) {
            this.windowFooter.runAction(new cc.Sequence(
                new cc.DelayTime(delay + 0.5),
                new cc.Show(),
                new cc.FadeIn(0.4).easing(cc.easeOut(1.2))
            ));
        }
    },

    onHide: function () {
        if (this.fireworks) {
            this.fireworks.stop();
        }

        if (this.touchListener) {
            cc.eventManager.removeListener(this.touchListener);
            this.touchListener = undefined;
        }
    },

    createBackground: function () {
        var styles = this.styles.Background;

        var sprite = styles.image;

        var image = cleverapps.skins.getSlot("windowBg", {
            image: sprite,
            level: this.params.level
        });

        var bg = cleverapps.UI.createScale9Sprite(image, styles.scale9);

        if (styles.opacity) {
            bg.setOpacity(styles.opacity);
        }

        bg.setLocalZOrder(-1000);

        return bg;
    },

    createForeground: function (image) {
        this.foreground = cleverapps.UI.createScale9Sprite(image, cleverapps.UI.Scale9Rect.TwoPixelXY);

        if (cleverapps.config.debugMode) {
            this.foreground._debugExclude = true;
        }

        this.foreground.setAnchorPoint(0, 0);
        this.foreground.setLocalZOrder(1);

        this.adjustForeground();

        return this.foreground;
    },

    onTouchInShadow: function () {
        if (!this.params.notCloseByTouchInShadow) {
            cleverapps.audio.playSound(bundles.main.urls.click_effect);
            this.close();
        }
    },

    setTitle: function (title, toReplace) {
        if (this.windowTitle) {
            this.windowTitle.setTitle(title, toReplace);
        }
    },

    getMinimalDialogueRects: function () {
        var styles = cleverapps.styles.Window;
        var rects = [
            cc.rectSubPadding(this.window.getSceneBoundingBox(), cc.padding(styles.BoundingBoxPadding))
        ];
        if (this.windowTitle) {
            rects.push(cc.rectSubPadding(this.windowTitle.getSceneBoundingBox(), cc.padding(styles.BoundingBoxPadding.Title)));
        }
        return rects;
    },

    enableTouchInShadow: function () {
        cleverapps.UI.onClick(this, function (touch) {
            if (this.window && !(this.closeButton instanceof TapToContinue)) {
                var size = this.window.getContentSize();
                var rect = cc.rect(0, 0, size.width, size.height);

                if (cc.rectContainsPoint(rect, this.window.convertToNodeSpace(touch.getLocation()))
                    || cc.rectContainsPoint(rect, this.window.convertToNodeSpace(touch.getStartLocation()))) {
                    return;
                }
            }

            this.onTouchInShadow();
        }.bind(this), {
            interactiveScale: false
        });
    },

    popUpAnimation: function () {
        var animation = this.chooseAnimation();
        if (animation) {
            if (this.window) {
                this.window.stopAllActions();
            }
            animation.open.call(this, this.onPopUpAnimationFinished.bind(this));
        } else {
            this.onPopUpAnimationFinished();
        }
    },

    stop: function () {
        this._super();

        if (this.window) {
            this.window.stopAllActions();
        }
    },

    closeAnimation: function (callback) {
        cleverapps.audio.playSound(bundles.main.urls.close_window_effect || bundles.main.urls.window_effect);

        var animation = this.chooseAnimation();

        if (!animation || (cleverapps.windows.list.length > 1 && !this.styles.windowShowUpAnimation.force)) {
            callback();
        } else {
            animation.close.call(this, callback);
        }
    },

    chooseAnimation: function () {
        var styles = this.styles || cleverapps.styles.Window;
        if (styles.windowShowUpAnimation && (this.window || this instanceof ForceView)) {
            if (!this.bg && !styles.windowShowUpAnimation.force) {
                return WINDOW_ANIMATIONS.default;
            }
            return WINDOW_ANIMATIONS[styles.windowShowUpAnimation.name];
        }
    },

    closeDuration: function () {
        var styles = this.styles || cleverapps.styles.Window;
        return styles.closeAnimation.duration;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CLOSE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.Window = {
    shadow: true,
    margin: 30,

    border: {
        scale: [0.8, 1, 1],
        x: 35,
        top: 110,
        bottom: 20
    },
    padding: {
        x: 50,
        top: 50,
        bottom: 30
    },

    titleMargin: 30,

    HelpButton: {
        zOrder: 1,
        scale: [0.8, 1, 1],
        position: {
            x: { align: "left", dx: 70 },
            y: { align: "top", dy: -45 }
        }
    },
    BottomButton: {
        width: 200,
        height: 90
    },
    HomeButton: {
        position: {
            x: { align: "right", dx: -40 },
            y: { align: "top", dy: -15 }
        }
    },

    minHeight: 200,
    minWidth: 240,

    windowShowUpAnimation: {
        name: "default",
        force: false
    },

    Background: {
        image: bundles.windows.frames.window_bg_png,
        scale9: cleverapps.UI.Scale9Rect.TwoPixelXY,
        padding: {
            x: 10,
            y: 30
        }
    },

    closeAnimation: {
        duration: 200
    },

    Footer: {
        x: { align: "center" },
        y: { align: "bottom", dy: 60 },

        margin: 20
    },

    BoundingBoxPadding: {
        x: 30,
        y: 30,
        Title: {
            x: 30,
            y: 30
        }
    },

    TapToContinue: {
        padding: {
            bottom: 60
        }
    }
};
