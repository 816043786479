/**
 * Created by r4zi4l on 09.11.2021
 */

var ExpeditionPassWindow = CleverappsWindow.extend({
    ctor: function (options) {
        this.mission = options;
        this._super.apply(this, arguments);
    },

    onWindowLoaded: function (mission) {
        var options = {
            name: "ExpeditionPassWindow",
            title: "ExpeditionPassWindow.title",
            content: new PassWindowContent({
                components: [{
                    name: "progress",
                    class: PassProgress
                }, {
                    name: "tickets",
                    class: PassTickets,
                    params: { close: this.close.bind(this) }
                },
                {
                    name: "header",
                    class: ExpeditionPassHeader,
                    params: {
                        pack_json: bundles["expeditionpass_" + cleverapps.skins.getSlot("skinName")].jsons.pack_json,
                        close: this.close.bind(this)
                    }
                }],
                width: cleverapps.styles.ExpeditionPassWindow.width,
                styles: cleverapps.styles.ExpeditionPassWindow,
                passLogic: mission.logic
            }),
            foreground: bundles.passforeground.frames.window_foreground_png,
            closeButton: true,
            noPadding: true,
            styles: cleverapps.styles.SalePassWindow.window
        };

        if (mission.rulesOptions) {
            options.help = function () {
                var options = mission.rulesOptions;
                var multipassName = cleverapps.skins.getSlot("skinName") + "_multipass_guidewindow";
                if (bundles[multipassName] && ["undersea", "undersea2", "dragonia", "dragonia2"].indexOf(cleverapps.meta.selectedLocationId()) === -1) {
                    options = {
                        name: multipassName,
                        bundle: bundles[multipassName]
                    };
                }

                new GuideWindow(options);
            };
        }

        this._super(options);
    },

    getPerson: function () {
        if (this.mission.location === "undersea2") {
            return "capitannemo";
        }
    },

    onShow: function () {
        this._super();
        this.mission.logic.handleShowWindow();
    },

    onClose: function () {
        cleverapps.focusManager.hideControlsWhileFocused(["MenuBarGoldItem", "MenuBarLivesItem"]);
    },

    listBundles: function () {
        return ["expeditionpass_" + cleverapps.skins.getSlot("skinName")];
    }

});
 
cleverapps.styles.ExpeditionPassWindow = {
    width: 1712,
    height: 844,

    window: {
        Foreground: {
            contentPadding: {
                top: 300
            }
        }
    },

    progress: {
        x: { align: "right", dx: 0 },
        y: { align: "bottom", dy: -37 }
    },

    header: {
        x: { align: "center" },
        y: { align: "top", dy: 24 }
    },

    tickets: {
        x: { align: "left", dx: 0 },
        y: { align: "center", dy: -106 }
    }
};
