/**
 * Created by mac on 4/21/20
 */

var EditorBase = function () {
    cleverapps.EventEmitter.call(this);

    this.loading = true;
    this.history = new UndoHistory();
    this.copyPaste = new CopyPaste();

    this.controls = [];
    this.addBasicControls();

    Editor.currentEditor = this;

    if (cleverapps.git) {
        cleverapps.git.changeFolders(GitManager.FOLDERS.LEVELS);
    }
};

EditorBase.prototype = Object.create(cleverapps.EventEmitter.prototype);
EditorBase.prototype.constructor = EditorBase;

EditorBase.prototype.addBasicControls = function () {
    this.controls = [
        {
            icon: bundles.editor.frames.editor_play_icon,
            hint: "Play",
            action: function () {
                cleverapps.focusManager.display({
                    focus: "EditorPlayLevel",
                    actions: [function (f) {
                        setTimeout(f, 500);
                    }, function (f) {
                        LevelManager.getInstance().playLevel(f);
                    }]
                });
            }
        },

        {
            icon: bundles.editor.frames.editor_clear_icon,
            hint: "Clear level",
            action: function () {
                if (confirm("Clear level?")) {
                    Editor.currentEditor.clear();
                }
            }
        },

        {
            icon: bundles.editor.frames.editor_undo_icon,
            hint: "undo",
            comboKeys: [cc.KEY.ctrl, cc.KEY.z],
            action: function () {
                Editor.currentEditor.history.undo();
            }
        },

        {
            icon: bundles.editor.frames.editor_redo_icon,
            comboKeys: [cc.KEY.ctrl, cc.KEY.shift, cc.KEY.z],
            hint: "redo",
            action: function () {
                Editor.currentEditor.history.redo();
            }
        },

        {
            icon: bundles.editor.frames.editor_copy_icon,
            hint: "copy",
            comboKeys: [cc.KEY.ctrl, cc.KEY.c],
            action: function () {
                Editor.currentEditor.copyPaste.copy();
            }
        },

        {
            icon: bundles.editor.frames.editor_paste_icon,
            hint: "paste",
            comboKeys: [cc.KEY.ctrl, cc.KEY.v],
            action: function () {
                Editor.currentEditor.copyPaste.paste();
            }
        }
    ];
};

EditorBase.prototype._save = cleverapps.accumulate(100, function () {
    if (this.loading || !this.prepareEdit()) {
        return;
    }

    var instance = LevelManager.getInstance();
    var level = instance.level;

    instance.update(this.getSaveData());
    instance.save(function () {
        if (!level.logEntry) {
            level.logEntry = cleverapps.administrator.log.createEntry({
                type: AdminLog.EDIT_LEVEL,
                episodeNo: instance.level.episodeNo,
                levelNo: instance.level.levelNo
            });
        } else {
            level.logEntry.updateData();
        }
    });
});

EditorBase.prototype.save = function () {
    if (this.loading || !this.prepareEdit()) {
        return;
    }

    this._save();
};

EditorBase.prototype.getSaveData = function () {
    var data = {};
    if (this.creationTime) {
        data.creationTime = this.creationTime;
    }
    if (this.comments) {
        data.comments = this.comments;
    }

    var level = LevelManager.getInstance().level;
    var content = level.content;
    if (!content.tag) {
        // migration
        if (content.hard) {
            data.tag = Level.TAGS.HARD;
        } else if (content.tricky) {
            data.tag = Level.TAGS.TRICKY;
        } else if (content.rules) {
            data.tag = Level.TAGS.RULES;
        }
    } else {
        data.tag = content.tag;
    }

    if (level.content.tutorial) {
        data.tutorial = level.content.tutorial;
    }

    if (level.content.skipVictoryWindow) {
        data.skipVictoryWindow = level.content.skipVictoryWindow;
    }

    return data;
};

EditorBase.prototype.load = function () {
    var level = LevelManager.getInstance().level;

    if (LevelManager.getInstance().version === undefined) {
        LevelManager.getInstance().setVersion(LevelManager.getInstance().getMaxVersion());
    }

    if (level.content.creationTime) {
        this.creationTime = level.content.creationTime;
    }

    if (level.content.comments) {
        this.comments = level.content.comments;
    }

    if (level.content.tutorial) {
        this.tutorial = level.content.tutorial;
    }

    if (level.content.skipVictoryWindow) {
        this.skipVictoryWindow = level.content.skipVictoryWindow;
    }

    this._load(level.content);

    this.loading = false;
};

EditorBase.prototype.prepareEdit = function () {
    return this.loading || LevelManager.getInstance().prepareEdit();
};

EditorBase.prototype.clear = function () {
    this.save();
};
