/**
 * Created by slava on 02.02.17.
 */

var BoxDecoratorCellView = BaseCellView.extend({
    ctor: function (decoratedCell) {
        this._super();

        this.setContentSize2(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        var spriteFilename = bundles.box.frames["box_stable" + decoratedCell.lives + "_png"];
        this.image = new cc.Sprite(spriteFilename);
        this.setContentSize(this.image.getContentSize());
        this.image.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.image);

        this.initialize(decoratedCell);

        this.cell.onChangeLivesListener = this.changeLives.bind(this);
    },

    finishSmile: function () {
        if (this.smiling) {
            this.smiling.finishAnimation();
            this.smiling = false;
            this.image.setVisible(true);
        }
    },

    createAnimation: function (toExplode) {
        var animation = BaseAnimation.factoryLimited(bundles.box.jsons.box_spine_json, BaseAnimation.BOX_DECORATOR_LIMIT);

        if (toExplode) {
            animation.setPosition(this.getPosition());
            this.parent.addChild(animation);
        } else {
            animation.setPosition(this.width / 2, this.height / 2);
            this.addChild(animation);
        }

        return animation;
    },

    changeLives: function () {
        if (this.cell.lives <= 0 || !this.cell.alive) {
            return;
        }

        this.finishSmile();

        if (this.livesAnimation) {
            var livesAnimation = this.livesAnimation;
            if (livesAnimation.isRunning()) {
                livesAnimation.setVisible(false); // without this 4 and 3 levels animations are still visible under 2nd level on multi-hurt (4 hurts simultaneously)
            }
            livesAnimation.finishAnimation();
        }

        var animation = this.livesAnimation = this.createAnimation();
        var name = "open" + (this.cell.lives + 1);
        animation.runAnimate(name, function () {
            if (this.cell) {
                this.image.setSpriteFrame(bundles.box.frames["box_stable" + Math.max(1, this.cell.lives) + "_png"]); // can be double hurt, don't forget about it, lives will be zero here for 2nd level box
            }
            this.image.visible = true;
            delete this.livesAnimation;
        }.bind(this), this.image);

        cleverapps.audio.playSound(bundles.box.urls["hurt_effect_" + (this.cell.lives + 1)]);
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};

        this._super();
    },

    smile: function () {
        if (this.smiling) {
            return;
        }

        this.smiling = this.createAnimation();
        var smileAnimationName = this.cell.lives === 1 ? "smile" : ("smile" + this.cell.lives);
        this.smiling.runAnimate(smileAnimationName, this.finishSmile.bind(this), this.image);
    },

    animateExplode: function (callback) {
        this.finishSmile();
        var animation = this.createAnimation(true);
        cleverapps.audio.playSound(bundles.game.urls.box_decorator_die_effect);

        animation.runAnimate("open", false, new cc.FadeOut(0.5));
        animation.runAction(new cc.CallFunc(function () {
            callback();
        }));
    }
});