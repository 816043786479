/**
 * Created by spepa on 20.12.2022
 */

var PromotionSaleWindow = CleverappsWindow.extend({
    ctor: function (offer) {
        this.logic = offer.logic;
        this.bundle = bundles[offer.bundle];

        this._super(offer);
    },

    onWindowLoaded: function () {
        var styles = {
            windowShowUpAnimation: { name: "slideUp", force: true }
        };

        if (cleverapps.resolution.isVertical() && this.bundle.jsons.bonussale_vertical_bg) {
            Object.assign(styles, cleverapps.styles.PromotionSaleWindow[this.logic.offer.type].verticalWindow || {});
        }

        this._super({
            name: "PromotionSaleWindow",
            noBackground: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            fireworks: true,
            content: this.createContent(),
            openSound: this.bundle.urls.bonussale_showup_effect,
            styles: styles
        });

        cleverapps.focusManager.hideAllControls();
        cleverapps.focusManager.showControlsWhileFocused(["MenuBarGoldItem", "MenuBarCoinsItem", "MenuBarLivesItem"]);

        this.logic.onUpdateLots = this.createListener(this.onLotBought.bind(this));
    },

    onShow: function () {
        this._super();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(this.logic.onWindowShowUpFinished.bind(this.logic))
        ));
    },

    createContent: function () {
        var styles = cleverapps.styles.PromotionSaleWindow[this.logic.offer.type];

        var isVertical = cleverapps.resolution.isVertical() && this.bundle.jsons.bonussale_vertical_bg;

        var json = isVertical ? this.bundle.jsons.bonussale_vertical_bg : this.bundle.jsons.bonussale_bg;

        var content = this.content = new cleverapps.Spine(json);
        content.setAnimationAndIdleAfter("open", "idle");

        var lotsSlot = content.getSlot("slot_lots");
        if (!lotsSlot) {
            lotsSlot = new cc.Node();
            content.addChild(lotsSlot);
            lotsSlot.setAnchorPoint(0.5, 0.5);
            lotsSlot.setPositionRound(styles.lots);
            if (styles.lots.width !== undefined) {
                lotsSlot.setContentSize(styles.lots);
            }
        }
        var descriptionSlot = content.getSlot("slot_description");
        if (!descriptionSlot) {
            descriptionSlot = new cc.Node();
            content.addChild(descriptionSlot);
            descriptionSlot.setAnchorPoint(0.5, 0.5);
            descriptionSlot.setPositionRound(styles.description);
            if (styles.description.width !== undefined) {
                descriptionSlot.setContentSize(styles.description);
            }
        }

        var lots = this.lots = this.logic.getLots().map(function (lot, ind) {
            var lotNode = new PromotionSaleLot(lot, this.logic);
            lotNode.setLocalZOrder(2 - ind);
            return lotNode;
        }.bind(this));

        var lotsLayout = new cleverapps.Layout(lots, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.lots.marginX
        });
        lotsSlot.addChild(lotsLayout);
        lotsLayout.setPositionRound(lotsSlot.width / 2, lotsSlot.height / 2);

        var description = cleverapps.UI.generateOnlyText("PromotionSaleWindow.description", cleverapps.styles.FONTS.BONUSBG_DESCRIPTION);
        descriptionSlot.addChild(description);

        description.setDimensions(descriptionSlot.width, 0);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.fitTo(undefined, descriptionSlot.height);
        description.setPositionRound(descriptionSlot.width / 2, descriptionSlot.height / 2);

        if (!isVertical) {
            this.createHeader();
        }

        this.createTitle();
        this.createTimer();
        return content;
    },

    createHeader: function () {
        var styles = cleverapps.styles.PromotionSaleWindow[this.logic.offer.type];

        var json = this.bundle.jsons.bonussale_header;

        if (json) {
            var header = new cleverapps.Spine(json);
            if (header.hasAnimation("open") && header.hasAnimation("idle")) {
                header.setAnimationAndIdleAfter("open", "idle");
            } else {
                header.setAnimation(0, "animation", false);
            }
            header.setPositionRound(styles.header);
            this.content.addChild(header);
        }
    },

    createTitle: function () {
        var styles = cleverapps.styles.PromotionSaleWindow[this.logic.offer.type];

        var titleSlot = this.content.getSlot("slot_title");
        if (!titleSlot) {
            titleSlot = new cc.Node();
            this.content.addChild(titleSlot);
            titleSlot.setAnchorPoint(0.5, 0.5);
            titleSlot.setPositionRound(styles.title);
            if (styles.title.width !== undefined) {
                titleSlot.setContentSize(styles.title);
            }
        }

        var msg = Messages.has("PromotionSaleWindow." + this.logic.offer.name + ".title") || "PromotionSaleWindow.title";
        var title = cleverapps.UI.generateOnlyText(msg, styles.title.font || cleverapps.styles.FONTS.BONUSBG_TITLE);
        titleSlot.addChild(title);

        title.fitTo(titleSlot.width, titleSlot.height);
        title.setPositionRound(titleSlot.width / 2, titleSlot.height / 2);
    },

    createTimer: function () {
        var styles = cleverapps.styles.PromotionSaleWindow[this.logic.offer.type].timer;
        if (!styles) {
            return;
        }

        var countDown = new cleverapps.CountDown(this.logic.getTimeLeft(), {
            onFinish: this.close.bind(this)
        });
        var timer = new cleverapps.CountDownView(countDown, {
            font: cleverapps.styles.FONTS.BONUSBG_TIMER
        });
        timer.setPositionRound(styles);
        this.content.addChild(timer);
    },

    onLotBought: function () {
        this.lots.forEach(function (lotView) {
            lotView.updateState();
        });

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                if (!this.closed) {
                    this.close();
                }
            }.bind(this))
        ));
    },

    listBundles: function (offer) {
        return [offer.bundle];
    },

    getPerson: function () {
        return cleverapps.styles.PromotionSaleWindow[this.logic.offer.type].person || cleverapps.persons.choose("jihn", "dwarf");
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BONUSBG_TITLE: {
        size: 100,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    BONUSBG_TIMER: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    PR_BONUSBG_TITLE: {
        size: 100,
        color: cleverapps.styles.COLORS.COLOR_DARK_YELLOW,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    BONUSBG_DESCRIPTION: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    }
});

cleverapps.styles.PromotionSaleWindow = {};
cleverapps.styles.PromotionSaleWindow[Offers.TYPES.PROMOTION_SALE] = {
    title: {
        x: { align: "center", dx: -10 },
        y: { align: "center", dy: 452 },

        width: 440,
        height: 110
    },

    description: {
        x: { align: "center", dx: -10 },
        y: { align: "bottom", dy: -20 },

        width: 1200,
        height: 70
    },

    header: {
        x: { align: "center" },
        y: { align: "top", dy: -40 }
    },

    lots: {
        x: { align: "center", dx: -8 },
        y: { align: "center", dy: -86 },

        marginX: 32
    },

    verticalWindow: {
        Background: {
            padding: {
                x: -15
            }
        }
    }
};
cleverapps.styles.PromotionSaleWindow[Offers.TYPES.CLPROMOTION_SALE] = cleverapps.styles.PromotionSaleWindow[Offers.TYPES.PROMOTION_SALE];

cleverapps.styles.PromotionSaleWindow[Offers.TYPES.PERIODIC_PROMOTION_SALE] = cleverapps.overrideStyles(cleverapps.styles.PromotionSaleWindow[Offers.TYPES.PROMOTION_SALE], {
    person: "bonussale_person",
    title: {
        font: cleverapps.styles.FONTS.PR_BONUSBG_TITLE,
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 421 }
    },

    lots: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 30 },
        marginX: 25
    },

    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -145 }
    },

    description: {
        y: { align: "bottom", dy: -50 }
    }

}, true);