/**
 * Created by iamso on 16.07.20.
 */

var DiscoverBooster = function (id) {
    Booster.call(this, id);
};

DiscoverBooster.prototype = Object.create(Booster.prototype);
DiscoverBooster.prototype.ctor = DiscoverBooster;

DiscoverBooster.prototype.isForceAvailable = function () {
    return Booster.prototype.isForceAvailable.call(this) && Game.currentGame.inflamer.wasMistake;
};

DiscoverBooster.prototype.onPressed = function (f, isRunningForce) {
    if (Game.currentGame.getAreasWithoutHint().length === 0) {
        cleverapps.notification.create("DiscoverBooster.hintsShown");
        f();
    } else {
        Booster.prototype.onPressed.call(this, f, isRunningForce);
    }
};

DiscoverBooster.prototype.execute = function () {
    this.executing = true;
    Booster.prototype.execute.apply(this, arguments);
    this.executing = false;
};

DiscoverBooster.prototype.onExecute = function (options) {
    Game.currentGame.boosterDiscover(options);
};