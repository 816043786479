/**
 * Created by slava on 23.03.17.
 */

var MenuBarView = cc.Node.extend({
    ctor: function () {
        this._super();

        var styles = cleverapps.styles.MenuBarView;
        var views = cleverapps.menuBar.getSceneItems().filter(function (item) {
            if (item.name === "CoinsItem" && cleverapps.gameModes.hideMenuBarCoinsItem) {
                return false;
            }

            return true;
        }).map(function (item) {
            var ViewClass = item.getViewClass();
            return new ViewClass(item);
        });

        var mainViews = [];
        var secondaryViews = [];

        var lastIndex = -1;
        views.forEach(function (view) {
            if (view.item.occupySlot === false) {
                secondaryViews.push({
                    view: view,
                    index: lastIndex
                });
            } else {
                mainViews.push(view);
                lastIndex++;
            }
        }, this);

        var layout = this.layout = new cleverapps.Layout(mainViews, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        secondaryViews.forEach(function (data) {
            this.addChild(data.view);
        }, this);

        layout.setAnchorPoint(0, 0);

        this.addChild(layout);

        var wrapInHidingNode = function (view) {
            var controlView = new HidingNode(view, cleverapps.UI.VERTICAL);
            var hiddenByDefault = view.item.hiddenByDefault;
            if (view.item.name === "GoldItem" && cleverapps.gameModes.showMenuBarGoldItem) {
                hiddenByDefault = undefined;
            }
            cleverapps.focusManager.registerControl(view.item.getControlName(), controlView, hiddenByDefault);
            return controlView;
        };

        this.mainViews = mainViews.map(wrapInHidingNode);
        this.secondaryViews = secondaryViews.map(function (data) {
            data.view = wrapInHidingNode(data.view);

            return data;
        });

        this.updateSize();
        this.updateContentSize();
        this.positionSecondaryViews();
    },

    positionSecondaryViews: function () {
        this.secondaryViews.forEach(function (data) {
            data.view.setScale(this.layout.scale);
            var position = this.mainViews[data.index].parent.convertToWorldSpace(this.mainViews[data.index].getPosition());
            data.view.setPositionRound(data.view.parent.convertToNodeSpace(position));
        }, this);
    },

    updateSize: function () {
        this.layout.setScale(connector.info.isMobile || cleverapps.resolution.isVertical()
            ? cleverapps.styles.MenuBarView.mobileScale[cleverapps.resolution.mode] : 1);

        this.updateContentSize();
        this.positionSecondaryViews();
    },

    updateContentSize: function () {
        this.setContentSize2(this.layout.width * this.layout.scale, this.layout.height * this.layout.scale);
    }
});

cleverapps.styles.MenuBarView = {
    mobileScale: [0.75, 0.9, 0.9],
    margin: 0
};
