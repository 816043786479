/**
 * Created by Andrey Popov on 12/1/20.
 */

var ChatScene = cleverapps.FixedWidthScene.extend({
    ctor: function (chat) {
        this._super();

        this.chat = chat;
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_CHAT);

        var chatHeader = new ChatHeader(this.chat);
        this.addChild(chatHeader);

        var chatView = new ChatView(this.chat);
        this.addChild(chatView);

        var chatInput = new ChatInput(this.chat);
        this.addChild(chatInput);

        cleverapps.placements.run(Placements.INTERMEDIATE);

        if (this.chat.hasUnread) {
            var lastReplyMessage = this.chat.getLastMessage();
            if (lastReplyMessage && lastReplyMessage.isResponse === 1) {
                cleverapps.playSession.set(cleverapps.EVENTS.STATS.CRM.REPLY, true);
                if (cleverapps.paymentsHistory.isPayer()) {
                    cleverapps.playSession.set(cleverapps.EVENTS.STATS.CRM.REPLY_PAYER, true);
                }

                if (lastReplyMessage.status === "resolved") {
                    cleverapps.playSession.set(cleverapps.EVENTS.STATS.CRM.RESOLVED, true);
                    if (cleverapps.paymentsHistory.isPayer()) {
                        cleverapps.playSession.set(cleverapps.EVENTS.STATS.CRM.RESOLVED_PAYER, true);
                    }
                }

                var lastUserMessage = this.chat.getLastMessage(true);
                if (lastUserMessage && new Date(lastReplyMessage.postDate) - new Date(lastUserMessage.postDate) > Chat.LATE_REPLY_INTERVAL) {
                    cleverapps.playSession.set(cleverapps.EVENTS.STATS.CRM.LATE_REPLY, true);
                    if (cleverapps.paymentsHistory.isPayer()) {
                        cleverapps.playSession.set(cleverapps.EVENTS.STATS.CRM.LATE_REPLY_PAYER, true);
                    }
                }
            }

            this.chat.markAsRead();
        } else {
            var msg = this.chat.getLastMessage();
            if (msg && msg.isResponse !== 1 && new Date(msg.postDate) + Chat.LATE_REPLY_INTERVAL > Date.now()) {
                cleverapps.playSession.set(cleverapps.EVENTS.STATS.CRM.UNANSWERED, true);
                if (cleverapps.paymentsHistory.isPayer()) {
                    cleverapps.playSession.set(cleverapps.EVENTS.STATS.CRM.UNANSWERED_PAYER, true);
                }
            }
        }
    },

    getBackgroundStyles: function () {
        return {
            bundle: "chat",
            patternId: "window_bg"
        };
    },

    listBundles: function () {
        var bundles = ["chat"];
        if (cleverapps.config.type === "merge") {
            bundles.push("episode_0");
        }
        return bundles;
    }
});