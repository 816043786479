/**
 * Created by andrey on 04.07.17.
 */

var CrossPromoWindow = CleverappsWindow.extend({
    onWindowLoaded: function (promotions) {
        this.promotions = promotions;
        var content = this.createContent();
        this.wideMode = cleverapps.resolution.mode;
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PROMO_SHOW);

        this._super({
            title: "CrossPromo.title",
            name: "crosspromowindow",
            content: content,
            foreground: cleverapps.config.name !== "fairy" ? bundles.windows.frames.window_foreground_png : undefined,
            styles: cleverapps.styles.CrossPromoWindow.window
        });
    },

    onClose: function () {
        cleverapps.crossPromo.saveShownMarkTime();
        MenuControlButton.updateAttention();
    },

    setupChildren: function () {
        this._super();
        if (this.wideMode !== cleverapps.resolution.mode) {
            this.window.removeFromParent();
            this.onWindowLoaded(this.promotions);
            this.windowTitle.show();
        }
    },

    createPromotionView: function (promotion) {
        var styles = cleverapps.styles.CrossPromoWindow.promotionView;
        var image = new cc.Sprite(bundles[promotion.bundleName].urls.image);
        var button = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "Play",
            onClicked: this.onPressed.bind(this, promotion)
        });

        button.setPositionRound(styles.button.position);
        image.addChild(button);

        return image;
    },

    createContent: function () {
        var styles = cleverapps.styles.CrossPromoWindow;

        var text = cleverapps.UI.generateOnlyText(
            "CrossPromo.text",
            cleverapps.styles.FONTS.CROSSPROMO_TEXT
        );

        var promotionViews = [];

        var topPadding = new cc.Node();

        topPadding.setContentSize({ height: 10, width: 10 });

        this.promotions.forEach(function (promotion) {
            promotionViews.push(this.createPromotionView(promotion));
        }.bind(this));

        var layout = new cleverapps.Layout(promotionViews, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        var scrollView = new cleverapps.UI.ScrollView(layout, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: cleverapps.UI.ScrollView.Vertical,
            mouseScroll: true
        });

        if (styles.top) {
            // var top = cleverapps.UI.createScale9Sprite(bundles.crosspromo.frames.scroll_top);
            // top.setContentSize2(styles.top);
            // scrollView.addChild(top);
            // top.setPositionRound(styles.top);
        }

        if (cleverapps.resolution.mode === cleverapps.resolution.mode.HORIZONTAL && connector.info.isMobile) {
            scrollView.setContentSize2(styles.scrollView.width, styles.scrollView.mobileHorizontalHeight);
        } else {
            scrollView.setContentSize2(styles.scrollView.width, styles.scrollView.height);
        }
        scrollView.setAnchorPoint(0.5, 0.5);
        scrollView.scrollTo(cleverapps.UI.ScrollView.SCROLLS.UPPER_LEFT);

        return new cleverapps.Layout([topPadding, text, scrollView], {
            direction: cleverapps.UI.VERTICAL,
            margin: 20
        });
    },

    onPressed: function (promotion) {
        connector.platform.switchGame(promotion.link, promotion.payload, function (error) {
            if (error) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PROMO + (error === connector.ERRORS.SWITCH_GAME.CANCEL ? "cancel" : "error") + "_" + promotion.id);
            }
        });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PROMO + promotion.id);
        this.close();
    },

    listBundles: function (promotions) {
        var bundles = promotions.map(function (promotion) {
            return promotion.bundleName;
        });

        bundles.push("crosspromo");

        return bundles;
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BUTTON_PROMO_TEXT: {
        size: 10,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    CROSSPROMO_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.CrossPromoWindow = {
    promotionView: {
        button: {
            width: 240,
            height: 100,
            position: {
                x: { align: "center" },
                y: { align: "bottom", dy: 30 }
            }
        }
    },
    margin: 30,
    scrollView: {
        width: 1220,
        height: [1600, 900, 900],
        mobileHorizontalHeight: 1300
    },

    window: {
        border: {
            x: 0,
            top: 25,
            bottom: 35
        },
        padding: {
            x: 20,
            top: 0,
            bottom: 0
        }
    },

    top: {
        width: 1250,
        height: 45,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 25 }
    }
};
