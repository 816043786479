/**
 * Created by r4zi4l on 15.03.2021
 */

var GuideWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options, unit) {
        this.stages = [];
        this.arrows = [];
        this.unit = unit;
        this.name = options.name;
        this.bundle = options.bundle || options.getGuideBundle();

        if (this.listSpines().length) {
            this.stagesContent = this.createSpineStages();
        } else if (options.stagesContent) {
            this.stagesContent = options.stagesContent.call(this, unit);
        } else {
            this.stagesContent = [new cc.Node(), new cc.Node(), new cc.Node()];
            cleverapps.throwAsync("no content for guide window " + options.name);
        }

        this._super({
            name: options.name,
            title: this.unit ? cleverapps.unitsLibrary.getUnitName(this.unit) : options.title || options.name + ".title",
            content: this.createContent(),
            contentPadding: cleverapps.styles.GuideWindow.contentPadding,
            noBackground: true,
            closeButton: true
        });

        this.showStages();
        this.showArrows();
    },

    isVertical: function () {
        return cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;
    },

    showStages: function () {
        var delay = 0.12;
        this.stages.forEach(function (stage, index) {
            var description = stage.children[1];
            description.setOpacity(0);
            description.setScale(0.8);

            stage.runAction(new cc.Sequence(
                new cc.Hide(),
                new cc.ScaleTo(0, 0.5),
                new cc.DelayTime(delay),
                new cc.Show(),
                new cc.CallFunc(function () {
                    var stageView = stage.children[0];
                    if (stageView instanceof cleverapps.Spine) {
                        stageView.setAnimation(0, "stage" + (index + 1), true);
                    }
                    description.runAction(
                        new cc.Sequence(
                            new cc.DelayTime(0.3),
                            new cc.Spawn(
                                new cc.ScaleTo(0.2, 1),
                                new cc.FadeIn(0.4)
                            )
                        )
                    );
                }),
                new cc.ScaleTo(0.5, stage.scale).easing(cc.easeBackOut())
            ));

            delay += 0.6;
        }, this);
    },

    showArrows: function () {
        var delay = 0;
        this.arrows.forEach(function (arrow, index) {
            var animation = this.isVertical() ? "vertical" : "horizontal";
            if (index === 1 && cleverapps.resolution.mode === cleverapps.WideMode.SQUARE
                || index === 1 && cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                animation = "diagonal";
            }

            var open, idle;
            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL && arrow.spine.hasAnimation("vertical" + (index + 1) + "_open")
            && arrow.spine.hasAnimation("vertical" + (index + 1) + "_idle")) {
                open = "vertical" + (index + 1) + "_open";
                idle = "vertical" + (index + 1) + "_idle";
            }

            arrow.runAction(new cc.Sequence(
                new cc.Hide(),
                new cc.DelayTime(delay),
                new cc.CallFunc(function () {
                    if (open && idle) {
                        arrow.spine.setAnimationAndIdleAfter(open, idle);
                    } else {
                        arrow.spine.setAnimation(0, animation);
                    }
                }),
                new cc.Show()
            ));
            delay += 0.4;
        }, this);
    },

    createContent: function () {
        var styles = cleverapps.styles.GuideWindow;

        var stage1 = this.createStage(this.stagesContent[0], this.name + ".firstStage");
        var stage2 = this.createStage(this.stagesContent[1], this.name + ".secondStage");
        var stage3 = this.createStage(this.stagesContent[2], this.name + ".thirdStage");

        this.stages = [stage1, stage2, stage3];

        var arrow1 = this.createArrow(1);
        var arrow2 = this.createArrow(2);

        this.arrows = [arrow1, arrow2];

        var stages, row1, row2, row3, alignNode;

        var margin = styles.defaultMargin[cleverapps.resolution.mode];
        var padding = styles.padding[cleverapps.resolution.mode];

        if (cleverapps.resolution.mode === cleverapps.WideMode.SQUARE) {
            row1 = new cleverapps.Layout([stage1, arrow1, stage2], { direction: cleverapps.UI.HORIZONTAL, margin: margin });
            alignNode = new cc.Node();
            alignNode.setContentSize(arrow2.getContentSize());

            [alignNode, stage3, arrow2].forEach(function (node) {
                node.setAnchorPoint(0.5, 1);
            });

            row2 = new cleverapps.Layout([alignNode, stage3, arrow2], {
                direction: cleverapps.UI.HORIZONTAL
            });
            stages = new cleverapps.Layout([row1, row2], { direction: cleverapps.UI.VERTICAL, margin: margin, padding: padding });
        } else if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            margin = Math.max(margin, (cleverapps.scenes.getRunningScene().width * 0.8 - stage1.width * 3 - arrow1.width * 2) / 4);
            stages = new cleverapps.Layout([stage1, arrow1, stage2, arrow2, stage3], {
                direction: this.isVertical() ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL,
                margin: margin,
                padding: padding
            });
        } else if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            var leftWidth = Math.max(stage1.width, stage3.width);
            var width = leftWidth + stage2.width;

            stage1 = cleverapps.UI.wrapWithPadding(stage1, { x: (leftWidth - stage1.width) / 2 });
            stage3 = cleverapps.UI.wrapWithPadding(stage3, { x: (leftWidth - stage3.width) / 2 });

            row1 = new cleverapps.Layout([stage1, arrow1], {
                direction: cleverapps.UI.HORIZONTAL,
                padding: { right: width - stage1.width - arrow1.width }
            });

            row2 = cleverapps.UI.wrapWithPadding(stage2, {
                left: width - stage2.width
            });

            row3 = new cleverapps.Layout([stage3, arrow2], {
                direction: cleverapps.UI.HORIZONTAL,
                padding: { right: width - stage3.width - arrow2.width }
            });

            stages = new cleverapps.Layout([row1, row2, row3], { direction: cleverapps.UI.VERTICAL, margin: margin, padding: padding });
        }

        cleverapps.UI.onClick(stages, this.close.bind(this), {
            interactiveScale: false
        });

        return stages;
    },

    createArrow: function (index) {
        var styles = cleverapps.styles.GuideWindow.stages;
        var spine = new cleverapps.Spine(this.bundle.jsons.arrows);
        spine.setAnchorPoint(0.5, 0.5);

        var arrowWrapper = new cc.Node();
        arrowWrapper.spine = spine;

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            arrowWrapper.setContentSize(spine.width, this.stages[0].height);
            spine.setPositionRound(arrowWrapper.width / 2, styles.description.height + styles.margin + this.stagesContent[0].height / 2);
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.SQUARE) {
            arrowWrapper.setContentSize(spine.width, this.stages[0].height);
            var position = cc.p(arrowWrapper.width / 2, styles.description.height + styles.margin + this.stagesContent[0].height / 2);
            if (index === 2) {
                position.y += this.stagesContent[0].height / 2;
            }
            spine.setPositionRound(position);
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            var stage = this.stages[index === 1 ? 0 : 2];
            arrowWrapper.setContentSize(spine.width, stage.height);
            spine.setPositionRound({ align: "center" }, { align: index === 1 ? "bottom" : "top" });
        }
        arrowWrapper.setAnchorPoint(0.5, 0.5);
        arrowWrapper.addChild(spine);

        return arrowWrapper;
    },

    createStage: function (stageView, descriptionText) {
        var styles = cleverapps.styles.GuideWindow.stages;

        var description = cleverapps.UI.generateOnlyText(descriptionText, this.isVertical()
            ? cleverapps.styles.FONTS.GUIDE_WINDOW_VERTICAL_STAGE_TEXT : cleverapps.styles.FONTS.GUIDE_WINDOW_STAGE_TEXT);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.setDimensions(styles.description.width, 0);
        description.fitTo(undefined, styles.description.height);
        description.setAnchorPoint(0.5, 0.5);

        var size = cc.size(styles.description.width, this.isVertical() ? description.height : styles.description.height);

        var descriptionNode = new cc.Node();
        descriptionNode.setContentSize2(size);
        descriptionNode.addChild(description);
        descriptionNode.setAnchorPoint(0.5, 0.5);
        description.setPositionRound(descriptionNode.width / 2, descriptionNode.height / 2);

        return new cleverapps.Layout([stageView, descriptionNode], {
            direction: cleverapps.UI.VERTICAL,
            margin: this.isVertical() ? styles.verticalMargin : styles.margin
        });
    },

    initStage: function (styles, callback) {
        var stage = new cc.Node();
        stage.setAnchorPoint(0.5, 0.5);
        stage.setContentSize2(cleverapps.styles.GuideWindow.stages);

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setPositionRound(styles.container.x, styles.container.y);
        stage.addChild(container);

        callback(styles, container);

        return stage;
    },

    addUnitIcon: function (parent, code, stage, styles) {
        var icon = UnitView.getUnitImage({ code: code, stage: stage }, { alignAnchorX: true, preferStatic: true, alignAnchorY: styles.alignAnchorY });
        icon.setPositionRound(styles);

        var size = cc.size(styles.width, styles.height);
        if (styles.padding) {
            size = cc.rectSubPadding(cc.rect(0, 0, cleverapps.styles.GuideWindow.stages.width, cleverapps.styles.GuideWindow.stages.height), cc.padding(styles.padding));
        }

        if (size.width || size.height) {
            cleverapps.UI.fitToBox(icon, {
                width: size.width,
                height: size.height,
                maxScale: styles.scale
            });
        } else if (styles.scale) {
            icon.setScale(styles.scale);
        }

        parent.addChild(icon);
        return icon;
    },

    addSprite: function (parent, resource, styles) {
        if (styles === false || !styles) {
            return new cc.Node();
        }
        var sprite = new cc.Sprite(resource);
        sprite.setAnchorPoint(0.5, 0.5);
        sprite.setPositionRound(styles.x, styles.y);
        if (styles.scale) {
            sprite.setScale(styles.scale);
        }
        if (styles.rotation) {
            sprite.setRotation(styles.rotation);
        }
        parent.addChild(sprite, styles.zOrder);
        return sprite;
    },

    createSpineStages: function (jsons) {
        jsons = jsons || this.listSpines();

        return jsons.map(function (json) {
            var spine = new cleverapps.Spine(json);
            spine.setAnchorPoint(0.5, 0.5);
            return spine;
        }, this);
    },

    listSpines: function () {
        return [
            this.bundle.jsons.guide_stages_1 || this.bundle.jsons.guide_stages,
            this.bundle.jsons.guide_stages_2 || this.bundle.jsons.guide_stages,
            this.bundle.jsons.guide_stages_3 || this.bundle.jsons.guide_stages
        ].filter(Boolean);
    },

    listBundles: function (options, unit) {
        var bundles = [(options.bundle || options.getGuideBundle()).getBundleName()];

        if (unit) {
            bundles = bundles.concat(Families.listLazyBundles(unit.code));
        }

        return bundles;
    }
});

GuideWindow.createMissionRulesWindow = function (mission) {
    return new GuideWindow(GuideWindow.getMissionRulesWindowOptions(mission));
};

GuideWindow.getMissionRulesWindowOptions = function (mission) {
    return {
        name: mission.startWindow.name,

        title: "Missions." + mission.getName() + ".Title",

        getGuideBundle: function () {
            return mission.startWindow.bundle;
        },

        stagesContent: function () {
            return this.createSpineStages([
                this.bundle.jsons.rules_stages_1 || this.bundle.jsons.rules_json,
                this.bundle.jsons.rules_stages_2 || this.bundle.jsons.rules_json,
                this.bundle.jsons.rules_stages_3 || this.bundle.jsons.rules_json
            ]);
        }
    };
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GUIDE_WINDOW_STAGE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE
    },
    GUIDE_WINDOW_VERTICAL_STAGE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },
    GUIDE_WINDOW_CLOSE_TEXT: {
        size: 26,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    GUIDEWINDOW_DESCRIPTION_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        lineHeight: 40
    }
});

cleverapps.styles.GuideWindow = {
    stages: {
        width: 400,
        height: 520,
        margin: 50,
        verticalMargin: 20,

        description: {
            width: 400,
            height: 156
        }
    },

    defaultMargin: [60, 80, 120],

    arrow: {
        x: { align: "center" },
        y: { align: "center", dy: 20 }
    },

    closeText: {
        x: { align: "center" },
        y: { align: "bottom", dy: -126 }
    },

    contentPadding: { top: 60 },

    padding: [{
        top: 80,
        bottom: 0,
        x: 0
    }, {
        x: 0,
        y: 0
    }, {
        x: 60,
        y: 60
    }]
};
