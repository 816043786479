/**
 * Created by ivan on 24.01.18.
 */

var ScaledProgressBar = cc.Node.extend({
    ctor: function (options) {
        this._super();
        this.options = options = Object.assign({}, options.type, options);

        this.progressFrames = options.progressFrames;
        this.layout = options.layout || ScaledProgressBar.HORIZONTAL;
        this.from = options.from || 0;
        this.to = options.to || 100;

        this.sizeDirty = true;

        if (options.background) {
            this.background = cleverapps.UI.createScale9Sprite(options.background);
            if (connector.platform.oneOf(connector.WECHAT)) {
                this.background._creationStack = new Error().stack;
            }
            this.background.setAnchorPoint(0, 0);
            this.addChild(this.background);
            this.applyCapInsets(this.background);
        }

        this.progress = cleverapps.UI.createScale9Sprite(options.progress);
        if (connector.platform.oneOf(connector.WECHAT)) {
            this.progress._creationStack = new Error().stack;
        }
        this.progress.setAnchorPoint(0, 0);
        this.addChild(this.progress);
        this.applyCapInsets(this.progress);

        var size = options.background ? cc.size(this.background.width, this.background.height) : cc.size(this.progress.width, this.progress.height);
        this.setContentSize2(size.width, size.height);
        this.setAnchorPoint(0.5, 0.5);

        if (options.barText) {
            this.createText(options.barText);
        }

        this.onChange();
    },

    createText: function (options) {
        options.font = options.font || cleverapps.styles.FONTS.SMALL_WHITE_TEXT;
        options.text = options.text || "%from%/%to%";

        this.barText = new TextWithIcon("", options);
        this.addChild(this.barText);
    },

    applyCapInsets: function (scale9Sprite) {
        if (this.layout === ScaledProgressBar.HORIZONTAL) {
            scale9Sprite.setCapInsets(cc.rect(scale9Sprite.width / 2 - 1, scale9Sprite.height / 3, 2, scale9Sprite.height / 3));
        } else {
            scale9Sprite.setCapInsets(cc.rect(scale9Sprite.width / 3, scale9Sprite.height / 2 - 1, scale9Sprite.width / 3, 2));
        }
    },

    onChange: function (silent) {
        if (this.background && this.sizeDirty) {
            this.background.setContentSize2(this.width, this.height);
        }

        if (this.options.onChange) {
            this.options.onChange.call(this, silent);
        }

        if (this.barText && this.sizeDirty) {
            this.updateBarTextPosition();
        }

        if (this.barText) {
            this.barText.setString(this.options.barText.text
                .replace("%from%", Math.round(this.from))
                .replace("%to%", Math.round(this.to))
                .replace("%percentage%", Math.round(this.getPercentage())));
        }

        this.updateProgressFrame();

        var size = cc.size(this.width, this.height);
        var minSize = this.options.minSize || cc.size(this.progress.getSpriteFrame().getOriginalSize());

        if (this.layout === ScaledProgressBar.HORIZONTAL) {
            size.width = (this.from > 0) ? Math.max(minSize.width, size.width * this.getPercentage() / 100) : 0;
        } else {
            size.height = (this.from > 0) ? Math.max(minSize.height, size.height * this.getPercentage() / 100) : 0;
        }

        this.progress.setContentSize(size.width, size.height);

        this.sizeDirty = false;
    },

    getPercentage: function () {
        return this.from * 100 / this.to;
    },

    updateProgressFrame: function () {
        if (this.progressFrames) {
            var newFrame;
            for (var percent in this.progressFrames) {
                if (this.getPercentage() >= percent) {
                    newFrame = this.progressFrames[percent];
                }
            }
            if (newFrame && this.currentFrame !== newFrame) {
                this.currentFrame = newFrame;
                this.progress.setSpriteFrame(newFrame);
            }
        }
    },

    setLength: function (length, silent) {
        if (this.layout === ScaledProgressBar.VERTICAL) {
            this.setContentSize2(this.width, length);
        } else {
            this.setContentSize2(length, this.height);
        }
        this.sizeDirty = true;
        this.onChange(silent);
    },

    setLayout: function (layout) {
        this.layout = layout;
        this.onChange();
    },

    stopChangeAction: function () {
        if (this.changeAction && !this.changeAction.isDone()) {
            this.stopAction(this.changeAction);
            delete this.changeAction;
        }
    },

    _animatedChange: function (from, options) {
        if (from === this.from) {
            return;
        }

        from = Math.max(Math.min(from, this.to), 0);
        var delta = from - this.from;
        options = options || {};

        this.stopChangeAction();
        this.updateProgressFrame();

        var time = options.time !== undefined ? options.time : 0.8;
        var action = new ScaledProgressBarProgressTo(time, from);

        if (options.easing !== false) {
            action.easing(options.easing || cc.easeCubicActionOut());
        }

        if (options.callback) {
            action = new cc.Sequence(
                action,
                new cc.CallFunc(options.callback)
            );
        }
        this.changeAction = this.runAction(action);

        if (options.delta) {
            cleverapps.aims.showDelta(delta, this.getParent(), {
                x: this.width * from / this.to,
                y: this.height,
                font: cleverapps.styles.FONTS.MENUBAR_TEXT_DELTA || cleverapps.styles.FONTS.MENUBAR_TEXT
            });
        }
    },

    setGoal: function (to, silent) {
        this.to = to;
        this.onChange(silent);
    },

    getGoal: function () {
        return this.to;
    },

    setPercentage: function (from, options) {
        options = options || {};
        this.stopChangeAction();

        from = Math.max(Math.min(from, this.to), 0);

        if (this.from !== from) {
            if (options.animated) {
                this._animatedChange(from, options);
            } else {
                this.from = from;
                this.onChange(true);
                options.callback && options.callback();
            }
        }
    },

    updateBarTextPosition: function () {
        this.barText.setPositionRound(this.width / 2 + (this.options.barText.dx || 0), this.height / 2 + (this.options.barText.dy || 0));
    },

    updateBarText: function (text) {
        this.options.barText.text = text;
        this.barText.setString(text);
        this.onChange();
    },

    getDelimiter: function () {
        var delimiterFrame;
        for (var percent in this.options.delimiters) {
            if (this.getPercentage() >= percent) {
                delimiterFrame = this.options.delimiters[percent];
            }
        }

        if (delimiterFrame) {
            return cleverapps.UI.createSprite(delimiterFrame);
        }
    }
});

var ScaledProgressBarProgressTo = cc.ProgressTo.extend({
    update: function (time) {
        if (!(this.target instanceof ScaledProgressBar)) {
            console.log(this.target, time);
            throw "Usage exception: ScaledProgressBarProgressTo should be used with ScaledProgressBar only.";
        }

        if (this._to < this._from) {
            this.target.from = Math.max(Math.min(this._from + (this._to - this._from) * time, this._from), this._to);
        } else {
            this.target.from = Math.max(Math.min(this._from + (this._to - this._from) * time, this._to), this._from);
        }

        this.target.onChange();
    },

    startWithTarget: function (target) {
        cc.ActionInterval.prototype.startWithTarget.call(this, target);
        this._from = target.from;
    }
});

ScaledProgressBar.HORIZONTAL = 0;
ScaledProgressBar.VERTICAL = 1;

ScaledProgressBar.Types = cleverapps.overrideTemplates({}, {
    blue: {
        progress: bundles.progress_bar.frames.bar_blue,
        background: bundles.progress_bar.frames.bg_dark
    },

    blue_with_frames: {
        progress: bundles.progress_bar.frames.bar_blue,
        background: bundles.progress_bar.frames.bg_dark,
        progressFrames: {
            0: bundles.progress_bar.frames.bar_red,
            30: bundles.progress_bar.frames.bar_orange,
            70: bundles.progress_bar.frames.bar_blue
        },
        delimiters: {
            0: bundles.progress_bar.frames.delimiter_red,
            30: bundles.progress_bar.frames.delimiter_orange,
            70: bundles.progress_bar.frames.delimiter_blue
        }
    },

    blue_small: {
        progress: bundles.progress_bar.frames.bar_blue_small,
        background: bundles.progress_bar.frames.bg_dark_small
    },

    blue_small_with_frames: {
        progress: bundles.progress_bar.frames.bar_blue_small,
        background: bundles.progress_bar.frames.bg_dark_small,
        progressFrames: {
            0: bundles.progress_bar.frames.bar_red_small,
            30: bundles.progress_bar.frames.bar_orange_small,
            70: bundles.progress_bar.frames.bar_blue_small
        }
    },

    blue_large_with_frames: {
        progress: bundles.progress_bar.frames.bar_blue_large,
        background: bundles.progress_bar.frames.bg_dark_large,
        progressFrames: {
            0: bundles.progress_bar.frames.bar_red_large,
            30: bundles.progress_bar.frames.bar_orange_large,
            70: bundles.progress_bar.frames.bar_blue_large
        }
    },

    green: {
        progress: bundles.progress_bar.frames.bar_green,
        background: bundles.progress_bar.frames.bg_green
    },

    green_large: {
        progress: bundles.progress_bar.frames.bar_green_large,
        background: bundles.progress_bar.frames.bg_green_large
    },

    red: {
        progress: bundles.progress_bar.frames.bar_red,
        background: bundles.progress_bar.frames.bg_dark
    },

    red_small: {
        progress: bundles.progress_bar.frames.bar_red_small,
        background: bundles.progress_bar.frames.bg_dark_small
    },

    blue_large_round: {
        progress: bundles.progress_bar.frames.bar_blue_large_round,
        background: bundles.progress_bar.frames.bg_green_large
    }
});