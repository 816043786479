/**
 * Created by iamso on 03.06.21
 */

var LivesFeast = function (mission, isNewMission) {
    this.mission = mission;

    if (isNewMission) {
        Merge.currentMerge.pixelsPlanner.restart();
    }
};

var SoftFeast = function (mission, isNewMission) {
    this.mission = mission;

    if (isNewMission) {
        Merge.currentMerge.pixelsPlanner.restart();
    }
};

var KrakenFeast = function (mission, isNewMission) {
    this.mission = mission;

    if (isNewMission) {
        Merge.currentMerge.monstersPlanner.spawnToAllSlots(true);
    }
};

KrakenFeast.prototype.stop = function () {
    if (typeof Merge !== "undefined" && Merge.currentMerge && MissionManager.hasProperParent(this.mission.type)) {
        Merge.currentMerge.monstersPlanner.stop();
        Merge.currentMerge.quests.applyDynamicFilter();
    }
};
