/**
 * Created by mac on 1/10/23
 */

var AboutWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        var links = [
            {
                button: "AboutWindow.terms",
                url: Terms.TERMS_URL,
                text: termsText[connector.info.source] || termsText.default
            },
            {
                button: "AboutWindow.privacy",
                url: Terms.PRIVACY_URL,
                text: privacyText[connector.info.source] || privacyText.default
            }
        ];

        var styles = cleverapps.styles.AboutWindow;

        var buttons = links.map(function (link) {
            return new cleverapps.UI.Button({
                text: link.button,
                onClicked: function () {
                    if (connector.platform.oneOf(connector.WECHAT, connector.INSTANT, connector.DISCORD)) {
                        new PrivacyWindow(link.button, link.text);
                    } else {
                        connector.platform.openUrl(link.url);
                    }
                },
                width: styles.button.width
            });
        });

        var buttonsBlock = cleverapps.resolution.isVertical() ? buttons : new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.button.margin
        });

        var text = cleverapps.UI.generateTTFText("AboutWindow.text", cleverapps.styles.FONTS.WINDOW_TEXT, {
            version: cleverapps.config.version,
            gameTitle: Messages.get(cleverapps.config.title)
        });
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.size[cleverapps.resolution.mode].width, 0);

        var deleteAccountButton = this.createDeleteAccountButton();

        var textBlock = cleverapps.UI.wrapWithPadding(text, styles.text.padding);
        var content = new cleverapps.Layout([textBlock, buttonsBlock, deleteAccountButton].flat().filter(Boolean), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        content = cleverapps.UI.wrapWithPadding(content, styles.padding);

        this._super({
            title: "AboutWindow.title",
            name: "aboutwindow",
            content: content
        });
    },

    createDeleteAccountButton: function () {
        if (!cleverapps.userDelete.isAvailable()) {
            return;
        }

        var styles = cleverapps.styles.AboutWindow.button;

        return new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_red,
            text: "DeleteAccount",
            onClicked: function () {
                new DeleteAccountWindow();
            },
            width: styles.width,
            height: styles.height
        });
    }
});

cleverapps.styles.AboutWindow = {
    margin: 30,

    padding: {
        bottom: 20
    },

    text: {
        size: [{
            width: 700
        }, {
            width: 1100
        }, {
            width: 1100
        }],

        padding: {
            x: 20,
            y: 20
        }
    },

    button: {
        width: 550,
        margin: 100
    }
};