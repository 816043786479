/**
 * Created by mac on 9/25/20
 */

var AdminEpisodes = function (administrator) {
    this.administrator = administrator;

    cleverapps.EventEmitter.call(this);

    this.onUpdateHoverEpisodeListener = function () {};
    this.onUpdateEpisodeListener = function () {};
};

AdminEpisodes.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminEpisodes.prototype.constructor = AdminEpisodes;

AdminEpisodes.prototype.updateDebugs = function () {
    var inherits = false;
    var lastNumber = -1;

    this.episodes.forEach(function (episode) {
        if (episode.isAddMoreEpisode()) {
            return;
        }

        var isNumber = cleverapps.isNumber(episode.episodeNo) && parseInt(episode.episodeNo) >= 0;
        var bundle = episode.getBundle();
        if (!bundle) {
            return;
        }

        var approved = true;
        var episodeType = Level.CalcLevelType(episode.episodeNo);

        if (cleverapps.config.type === "merge") {
            episode.setApproved(approved);
            return;
        }

        if (isNumber && inherits) {
            approved = false;
        }

        if (isNumber && lastNumber < (parseInt(episode.episodeNo) - 1)) {
            approved = false;
        }

        if (bundle.meta.episode.levelsAmount < AdminEpisode.LEVELS_PER_EPISODE && episodeType !== Level.LevelTypes.HIGHSCORE_MODE) {
            approved = false;
        }

        if (!isNumber && episodeType !== Level.LevelTypes.BONUS_WORLD && episodeType !== Level.LevelTypes.HIGHSCORE_MODE) {
            approved = false;
        }

        if (isNumber) {
            lastNumber = parseInt(episode.episodeNo);
        }

        episode.setApproved(approved);

        if (isNumber && (!approved || episode.isDebug())) {
            inherits = true;
        }
    });

    this.trigger("updateApproved");
};

AdminEpisodes.prototype.next = function (sign) {
    var pos = this.episodes.indexOf(this.active) + sign;

    if (this.episodes[pos] === undefined || this.episodes[pos].isAddMoreEpisode()) {
        return undefined;
    }

    return this.episodes[pos];
};

AdminEpisodes.prototype.updateIndexes = function () {
    this.episodes.forEach(function (episode, index) {
        if (episode.index !== undefined) {
            episode.index = index;
        }
    });
};

AdminEpisodes.prototype.getInsertionIndex = function (episodeNo) {
    var index = 0;
    while (index < this.total) {
        var prevEpisodeNo = this.episodes[index].episodeNo;
        if (AdminEpisodes.EPISODE_NUMBERS_COMPARATOR(prevEpisodeNo, episodeNo) >= 0) {
            break;
        }
        index++;
    }
    return index;
};

AdminEpisodes.prototype.insertToArray = function (episodeNo) {
    var index = this.getInsertionIndex(episodeNo);

    var episode = AdminEpisode.create(index, episodeNo);
    this.episodes.splice(index, 0, episode);
    this.episodesByName[episodeNo] = episode;
    this.total = this.episodes.length - 1;

    this.updateIndexes();
    this.updateDebugs();

    this.trigger("insert", episode);
};

AdminEpisodes.prototype.removeFromArray = function (episodeNo) {
    var episode = this.episodesByName[episodeNo];
    var index = episode.index;
    this.episodes.splice(index, 1);
    episode.purge();
    this.total = this.episodes.length - 1;
    delete this.episodesByName[episodeNo];

    this.updateIndexes();
    this.updateDebugs();

    this.trigger("remove", episode);
};

AdminEpisodes.prototype.renameInArray = function (oldEpisodeNo, newEpisodeNo) {
    var episode = this.episodesByName[oldEpisodeNo];
    var index = episode.index;
    this.episodes.splice(index, 1);
    delete this.episodesByName[oldEpisodeNo];
    this.total = this.episodes.length - 1;

    var newIndex = this.getInsertionIndex(newEpisodeNo);

    episode.setEpisodeNo(newEpisodeNo);
    episode.index = newIndex;
    this.episodes.splice(newIndex, 0, episode);
    this.episodesByName[newEpisodeNo] = episode;
    this.total = this.episodes.length - 1;

    this.updateIndexes();
    this.updateDebugs();
    this.trigger("rename", episode, oldEpisodeNo);
};

AdminEpisodes.prototype.createNew = function (callback) {
    var episodeNo = parseInt(this.episodes[this.total - 1].episodeNo) + 1;
    var data = {
        debug: true,
        levels: []
    };

    this.saveToStorage(episodeNo, data);
    this.insertToArray(episodeNo);
    this.setActive(this.episodesByName[episodeNo], callback);
};

AdminEpisodes.prototype.remove = function (episodeNo, callback) {
    var language = this.getEpisodesLanguage();
    var episode = this.episodesByName[episodeNo];

    cleverapps.git.remove("episode", {
        episodeNo: episodeNo,
        language: language
    });

    this.onUpdateEpisodeListener(episodeNo);
    this.administrator.simpleMethaDataProvider && this.administrator.simpleMethaDataProvider.reset();
    this.removeFromArray(episodeNo);

    var prevEpisode = this.episodes[Math.max(0, episode.index - 1)];
    if (prevEpisode && !prevEpisode.isAddMoreEpisode()) {
        this.setActive(prevEpisode, callback);
    } else {
        callback();
    }
};

AdminEpisodes.prototype.copy = function (episodeNo, callback) {
    var language = this.getEpisodesLanguage();

    var copyEpisodeNo = String(episodeNo).replace(/_copy(_\d+)?/, "") + "_copy";
    var copyBundleName = AdminEpisode.BundleId(copyEpisodeNo, language);

    for (var i = 1; bundles[copyBundleName]; ++i) {
        copyEpisodeNo = String(episodeNo).replace(/_copy(_\d+)?/, "") + "_copy_" + i;
        copyBundleName = AdminEpisode.BundleId(copyEpisodeNo, language);
    }

    var bundleName = AdminEpisode.BundleId(episodeNo, language);
    var bundle = bundles[bundleName];

    cleverapps.bundleLoader.loadBundle(bundleName, {
        onSuccess: function () {
            var content = JSON.parse(JSON.stringify(cc.loader.getRes(bundle.jsons.levels)));

            if (!content.levels) {
                content = { levels: content };
            }

            content.debug = true;

            this.saveToStorage(copyEpisodeNo, content);
            this.insertToArray(copyEpisodeNo);
            this.setActive(this.episodesByName[copyEpisodeNo], callback);

            cleverapps.bundleLoader.deleteBundle(bundleName);
        }.bind(this),

        onError: function () {
            cleverapps.notification.create("Copy episode error");
            callback();
        }
    });
};

AdminEpisodes.prototype.rename = function (episodeNo, newEpisodeNo, callback) {
    var language = this.getEpisodesLanguage();
    var bundleName = AdminEpisode.BundleId(episodeNo, language);
    var bundle = bundles[bundleName];

    cleverapps.bundleLoader.loadBundle(bundleName, {
        onSuccess: function () {
            var content = JSON.parse(JSON.stringify(cc.loader.getRes(bundle.jsons.levels)));

            cleverapps.git.remove("episode", {
                episodeNo: episodeNo,
                language: language
            });

            this.saveToStorage(newEpisodeNo, content);
            this.renameInArray(episodeNo, newEpisodeNo);
            cleverapps.administrator.adminLevels.reset(callback);

            cleverapps.bundleLoader.deleteBundle(bundleName);
        }.bind(this),

        onError: function () {
            cleverapps.notification.create("Rename episode error");
            callback();
        }
    });
};

AdminEpisodes.prototype.upload = function (levels, callback) {
    var episode = this.getActive();
    var episodeNo = episode.episodeNo;
    var language = cleverapps.config.type === "board" ? cleverapps.settings.language : undefined;

    try {
        levels = JSON.parse(levels);
    } catch (e) {
        cleverapps.notification.create("Error parse episode content");
    }

    var bundleName = AdminEpisode.BundleId(episodeNo, language);
    var bundle = bundles[bundleName];

    cleverapps.bundleLoader.loadBundle(bundleName, {
        onSuccess: function () {
            var content = cc.loader.getRes(bundle.jsons.levels);
            if (!content.levels) {
                content = { levels: content };
            }

            content.levels = content.levels.concat(levels);

            content.levels.forEach(function (level, levelNo) {
                if (!Array.isArray(level)) {
                    level = [level];
                }

                level.forEach(function (levelVersion) {
                    levelVersion.episodeNo = episodeNo;
                    levelVersion.levelNo = levelNo;
                });
            });

            cleverapps.git.edit("episode", {
                episodeNo: episodeNo,
                language: language,
                content: content
            });

            this.saveToStorage(episodeNo, content);

            cleverapps.administrator.adminLevels.reset(callback);

            cleverapps.bundleLoader.deleteBundle(bundleName);
        }.bind(this),

        onError: function () {
            cleverapps.notification.create("Upload episode error");
            callback();
        }
    });
};

AdminEpisodes.prototype.setDebug = function (debug, callback) {
    var episode = this.getActive();
    var episodeNo = episode.episodeNo;
    var language = cleverapps.config.type === "board" ? cleverapps.settings.language : undefined;

    var bundleName = AdminEpisode.BundleId(episodeNo, language);
    var bundle = bundles[bundleName];

    cleverapps.bundleLoader.loadBundle(bundleName, {
        onSuccess: function () {
            var content = cc.loader.getRes(bundle.jsons.levels);
            if (!content.levels) {
                content = { levels: content };
            }

            content.levels.forEach(function (level, levelNo) {
                if (!Array.isArray(level)) {
                    level = [level];
                }

                level.forEach(function (levelVersion) {
                    levelVersion.episodeNo = episodeNo;
                    levelVersion.levelNo = levelNo;
                });
            });

            content.debug = debug;

            cleverapps.git.edit("episode", {
                episodeNo: episodeNo,
                language: language,
                content: content
            });

            this.saveToStorage(episodeNo, content);

            cleverapps.bundleLoader.deleteBundle(bundleName);

            callback();
        }.bind(this),

        onError: function () {
            cleverapps.notification.create("Upload episode error");
            callback();
        }
    });
};

AdminEpisodes.prototype.getActive = function () {
    return this.active;
};

AdminEpisodes.prototype.setActive = function (episode, f, suggestedLevel) {
    if (this.active === episode) {
        f && f();
        return;
    }

    if (this.active !== undefined) {
        this.active.unselect();
    }

    this.active = episode;
    this.active.select();

    if (cleverapps.config.adminMode) {
        cleverapps.setUrlHash({ episode: this.active.episodeNo });
    }

    cleverapps.administrator.adminLevels.reset(f, suggestedLevel);
    cleverapps.administrator.log.onChangeEpisode(episode);

    this.trigger("setActive", episode);
};

AdminEpisodes.prototype.hover = function (episode) {
    if (this.hoverEpisode !== episode) {
        this.hoverEpisode = episode;
        this.onUpdateHoverEpisodeListener();
    }
};

AdminEpisodes.prototype.unhover = function (episode) {
    if (this.hoverEpisode === episode) {
        delete this.hoverEpisode;
        this.onUpdateHoverEpisodeListener();
    }
};

AdminEpisodes.prototype.reset = function (f) {
    delete this.hoverEpisode;

    var names = AdminEpisodes.GetEpisodeNames(cleverapps.settings.language);

    var episodes = [];
    var episodesByName = {};
    var prodEpisodesLength = -1;

    for (var i = 0; i < names.length; i++) {
        var name = names[i];
        var episode = AdminEpisode.create(i, name);
        episode.language = cleverapps.settings.language;
        prodEpisodesLength += episode.isNumeric();
        episodes.push(episode);
        episodesByName[name] = episode;
    }

    this.total = episodes.length;

    var addMoreEpisode = AdminEpisode.createAddMoreEpisode();
    episodesByName[addMoreEpisode.episodeNo] = addMoreEpisode;
    episodes.push(addMoreEpisode);

    this.episodes = episodes;
    this.episodesByName = episodesByName;

    this.updateDebugs();
    this.trigger("reset");

    var params = cleverapps.getRequestParameters(window.location.hash);
    var episodeNo = params.episode > prodEpisodesLength ? prodEpisodesLength : params.episode;
    var levelNo = params.level !== undefined && (AdminEpisode.LEVELS_PER_EPISODE >= params.level) ? params.level : cleverapps.user.level;

    if (!cleverapps.config.adminMode && cleverapps.user.isPassedAll()) {
        f();
        return;
    }

    var activeEpisode = this.episodesByName[episodeNo] || this.episodesByName[cleverapps.user.episode];
    if (activeEpisode) {
        this.setActive(activeEpisode, f, levelNo);
    } else {
        f();
    }
};

AdminEpisodes.prototype.getEpisode = function (episodeNo) {
    return this.episodesByName[episodeNo];
};

AdminEpisodes.prototype.update = function () {
    this.episodes.forEach(function (episode) {
        episode.onUpdate();
    });
};

AdminEpisodes.prototype.updateCurrent = function () {
    this.episodes.forEach(function (episode) {
        episode.onUpdateCurrent();
    });
};

AdminEpisodes.GetEpisodeNames = function (language) {
    var episodeBundles = Object.values(bundles).filter(function (bundle) {
        return bundle.meta.episode && bundle.meta.episode.episodeNo !== "coming_soon";
    });

    if (cleverapps.config.type === "board") {
        episodeBundles = episodeBundles.filter(function (bundle) {
            return bundle.meta.episode.language === language;
        });
    }

    var episodeNumbers = episodeBundles.map(function (bundle) {
        return bundle.meta.episode.episodeNo;
    });

    episodeNumbers = episodeNumbers.sort(AdminEpisodes.EPISODE_NUMBERS_COMPARATOR);

    return episodeNumbers;
};

AdminEpisodes.prototype.saveToStorage = function (episodeNo, data) {
    var language = this.getEpisodesLanguage();

    cleverapps.git.edit("episode", {
        episodeNo: episodeNo,
        language: language,
        content: data
    });

    this.updateDebugs();
    this.onUpdateEpisodeListener(episodeNo);
    if (this.episodesByName[episodeNo]) {
        this.episodesByName[episodeNo].onUpdate();
    }

    this.administrator.simpleMethaDataProvider && this.administrator.simpleMethaDataProvider.reset();
};

AdminEpisodes.prototype.scrollToEp = function (episodeNo) {
    this.trigger("scrollToEp", episodeNo);
};

AdminEpisodes.prototype.gatherDifficultyData = function (callback) {
    var serverData = {};
    var difficultyData = [];
    var hashesByEps = {};
    var ind = 0;

    var calcDifficulty = function () {
        for (var epNo in hashesByEps) {
            var levelsDifficulty = hashesByEps[epNo].map(function (lvlHash) {
                return serverData[lvlHash] || 0;
            });

            var avg;
            if (levelsDifficulty.some(function (difficulty) {
                return difficulty >= 95;
            })) {
                avg = 100;
            } else {
                var sum = 0;
                levelsDifficulty.forEach(function (difficulty) {
                    sum += 1 / (1 - difficulty / 100);
                });
                avg = Math.round((1 - 1 / (sum / levelsDifficulty.length)) * 100);
            }

            difficultyData.push({
                Episode: epNo,
                Difficulty: avg
            });
        }

        callback(difficultyData);
    };

    var gatherHashes = function (adminEpisode) {
        if (++ind === this.episodes.length) {
            calcDifficulty();
            return;
        }

        if (adminEpisode.isNumeric() && !adminEpisode.isDebug()) {
            hashesByEps[adminEpisode.episodeNo] = cleverapps.administrator.adminLevels.levels.filter(function (level) {
                return !level.addMoreLevel;
            }).map(function (level) {
                var content = level.getLevelContent();
                return Level.CalcHash(content);
            });
        }

        gatherHashes(this.episodes[ind]);
    }.bind(this);

    cleverapps.RestClient.get("/admin/epdifficulty/get", {}, function (data) {
        if (data.error) {
            console.log("Failed getting difficulty statistics", data.error);
        } else {
            data.forEach(function (level) {
                serverData[level.hash] = level.difficulty;
            });
            gatherHashes(this.episodes[0]);
        }
    }.bind(this), function (err) {
        console.log("Error getting difficulty statistics", err);
    });
};

AdminEpisodes.prototype.getEpisodesLanguage = function () {
    return cleverapps.config.type === "board" ? cleverapps.settings.language : undefined;
};

AdminEpisodes.ResetAction = function (f) {
    cleverapps.administrator.adminEpisodes.reset(f);
};

AdminEpisodes.EPISODE_NUMBERS_COMPARATOR = function (number1, number2) {
    var int1 = parseInt(number1);
    var int2 = parseInt(number2);

    if (int1 !== number1) {
        return -1;
    }

    if (int2 !== number2) {
        return 1;
    }

    if (int1 === int2) {
        return (number1 + "").localeCompare(number2 + "");
    }
    return int1 - int2;
};
