/**
 * Created by slava on 3/6/19
 */

var ColorHintAlgorithm = {
    type: "colorBooster",

    findMove: function () {
        if (!Game.currentGame || !Game.currentGame.field) {
            return;
        }

        var ideas = [
            ColorHintAlgorithm.feedEaterIdea,
            ColorHintAlgorithm.completeGoalIdea
        ];

        var moveOptions = HintHelper.findMove(ideas, cleverapps.Boosters.TYPE_COLOR);
        if (moveOptions) {
            return {
                type: ColorHintAlgorithm.type,
                options: moveOptions
            };
        }
    },

    completeGoalIdea: function (cell) {
        if (!HintHelper.isActiveGoal(cell)) {
            return;
        }

        var color = cell.getColor();
        if (!color) {
            return;
        }

        var goalId = cell.getGoalId();
        var remaining = HintHelper.countSameGoals(cell);

        var field = Game.currentGame.field;

        var colorTotal = 0;
        for (var i = 0; i < Field.SIZE; i++) {
            for (var j = 0; j < Field.SIZE; j++) {
                cell = field.cells[i][j];
                if (cell && cell.getColor() === color && cell.getGoalId() === goalId) {
                    colorTotal++;
                    var goalCoefComponent = cell.findComponent(GoalCoefComponent);
                    if (goalCoefComponent && goalCoefComponent.coef) {
                        colorTotal += goalCoefComponent.coef;
                    }
                }
            }
        }

        return colorTotal >= remaining;
    },

    feedEaterIdea: function (cell) {
        if (!Game.currentGame.EATERS || !cell.findComponent(ColorComponent)) {
            return;
        }

        var eater = HintHelper.getEater(cell.getColor());
        if (!eater) {
            return;
        }

        return eater.lives <= 10;
    }
};

AvailableMove.REGISTERED_ALGORITHMS.push(ColorHintAlgorithm);
