/**
 * Created by andrey on 29.01.18.
 */

var LocationView = cc.Node.extend({
    ctor: function (page) {
        this._super();
        this.page = page;
        this.pageNo = page.pageNo;
        this.levels = [];
        this.bundle = bundles[this.getBundleName()];

        this.setAnchorPoint(0.5, 0.5);
        this.page.currentView = this;

        this.setupChildren();

        this.setLocalZOrder(-this.pageNo);
    },

    setupChildren: function () {
        var sceneSize = cleverapps.resolution.getBgSize();
        var height = cleverapps.styles.LocationView.height;

        if (cleverapps.styles.LocationView.direction === cleverapps.UI.VERTICAL) {
            this.setPosition(sceneSize.width / 2, height * (this.pageNo + 1 / 2));
            this.bgScale = (height + cleverapps.styles.LocationView.addPixels) / height;
        } else {
            this.setPosition(sceneSize.width * (this.pageNo + 1 / 2), height / 2);
            this.bgScale = (sceneSize.width + cleverapps.styles.LocationView.addPixels) / sceneSize.width;
        }

        this.bg && this.bg.setScale(this.bgScale);
        this.updateScale();
    },

    customize: function () {
    },

    updateScale: function () {
        var styles = cleverapps.styles.LocationView;

        if (cleverapps.config.type === "board") {
            var scale = cleverapps.resolution.getSceneSize().width / (styles.Background.width + styles.padding);
            scale = Math.min(Math.max(styles.minScale, scale), 1);
            this.setScale(scale);
        }

        this.baseScaleX = this.scaleX;
        this.baseScaleY = this.scaleY;
    },

    onShow: function () {
        this.startLoadAnimation();

        var bundleNames = [this.page.getResourcesBundleId()];

        if (this.page.isComingSoon()) {
            bundleNames.push("episode_coming_soon");
        }

        var episodeBundles = bundleNames.filter(function (bundleName) {
            return bundleName !== undefined && bundles[bundleName];
        });

        if (!episodeBundles.length) {
            this.onLoad();
            return;
        }

        this.loadedBundles = episodeBundles;

        cleverapps.bundleLoader.loadBundles(episodeBundles, {
            onSuccess: this.onLoad.bind(this),
            onFailure: function () {
                this.loadedBundles = undefined;
            }.bind(this)
        });
    },

    onRemove: function () {
        this.removeLoadAnimation();
        this.removeFromParent();
    },

    onExit: function () {
        if (this.loadedBundles) {
            cleverapps.bundleLoader.deleteBundles(this.loadedBundles);
        }
    },

    addLevelsView: function () {
        if (cleverapps.config.rpg) {
            return;
        }

        var levelsView = new LevelsView(this.page);
        levelsView.setContentSize2(this.getContentSize());
        this.addChild(levelsView);

        levelsView.onLoad();
    },

    addBetweenEpisodesFlag: function () {
        var styles = cleverapps.styles.LevelsView;
        if (!styles.betweenEpisodesFlag || this.page.isComingSoon()) {
            return;
        }
        styles = styles.betweenEpisodesFlag;
        var positions = this.pageNo % 2 ? styles.levels_2 : styles.levels_1;
        if (!positions) {
            return;
        }

        var id = this.getBundleName();
        if (positions[id] === false) {
            return;
        }
        var flag = new cleverapps.Spine(styles.spine);
        flag.setPositionRound(positions[id] ? positions[id] : positions.default);
        flag.setAnimation(0, this.pageNo < levels.user.episode ? styles.passed : styles.notpassed, true);
        this.addChild(flag);
    },

    onLoad: function () {
        this.addDescription();
        this.finishLoadAnimation();

        this.addLevelsView();

        this.addBetweenEpisodesFlag();
        this.addTitle();

        this.customize();

        if (this.page.isComingSoon()) {
            this.addBackground();
            return;
        }

        if (cleverapps.config.name === "riddles" && cleverapps.bundleLoader.isLoaded(this.page.getResourcesBundleId())) {
            this.addSpines();
            this.addBackground();
        } else {
            this.addDefaultBackground();
        }

        this.addIsland();
    },

    addTitle: function () {
        if (typeof match3 !== "undefined") {
            return;
        }

        var toReplace = {
            episodeNo: (this.pageNo + 1)
        };

        var styles = cleverapps.styles.LocationView.title;
        var titleId = "EpisodeView.Title";
        if (this.page.isComingSoon()) {
            titleId = "EpisodeView.ComingSoonTitle";
        }

        if (styles.bg) {
            var titleBg = cleverapps.UI.createScale9Sprite(styles.bg.image);
            this.addChild(titleBg);
            titleBg.setPositionRound(styles.bg);
            titleBg.setContentSize2(styles.bg);
        }

        var title = this.title = cleverapps.UI.generateTTFText(titleId, cleverapps.styles.FONTS.EPISODE_TITLE_TEXT, toReplace);
        if (styles.maxWidth) {
            title.fitTo(styles.maxWidth);
        }
        title.y = styles.y;
        this.addChild(title, 1);
    },

    addDescription: function () {
        if (cleverapps.config.type !== "board" || cleverapps.config.subtype === "stacks") {
            return;
        }

        var styles = cleverapps.styles.LocationView;

        var description;
        if (!this.page.isComingSoon() && this.bundle) {
            var minLength = this.bundle.meta.episode.minLength;
            var maxLength = this.bundle.meta.episode.maxLength;

            var difficulty = Math.round(minLength / 3 + maxLength / 2 - 2);
            if (difficulty < 1) {
                difficulty = 1;
            }
            if (difficulty > 5) {
                difficulty = 5;
            }
            difficulty = this.formatDifficulty(difficulty);

            var info = minLength + " - " + maxLength;
            if (minLength === maxLength) {
                info = minLength;
            }

            description = cleverapps.UI.generateTTFText("EpisodeView.Description", cleverapps.styles.FONTS.DESCRIPTION_TEXT, {
                letters: info,
                difficulty: difficulty
            });
            description.fitTo(styles.Background.width * 0.90);
        } else {
            description = cleverapps.UI.generateTTFText("EpisodeView.ComingSoonDescription", cleverapps.styles.FONTS.DESCRIPTION_TEXT);
            description.fitTo(styles.Background.width * 0.90);
        }

        this.description = description;
        description.y = styles.description.y;
        this.addChild(description);
    },

    addBackground: function () {
        var bundleId = this.page.getResourcesBundleId();
        var bgUrl = bundles[bundleId] && bundles[bundleId].urls.background || this.bundle && this.bundle.urls.background;

        // word games
        if (!bgUrl) {
            return;
        }

        // was error loading in native
        if (cc.loader.getRes(bgUrl) === undefined) {
            console.log("No bg image loaded, using default: " + bgUrl);
            this.addDefaultBackground();
            return;
        }

        if (this.bg) {
            this.bg.removeFromParent();
        }
        this.bg = new cc.Sprite(cc.loader.getRes(bgUrl));
        this.bg.setScale(this.bgScale);
        this.bg.setLocalZOrder(-1);
        this.addChild(this.bg);
    },

    addDefaultBackground: function () {
        if (typeof match3 === "undefined") {
            var styles = cleverapps.styles.LocationView.Background;

            this.bg = cleverapps.UI.createScale9Sprite(bundles.main.frames.episode_bg_png, cleverapps.UI.Scale9Rect.TwoPixelY);
            this.bg.setContentSize(styles.width, styles.height);
            this.bg.y = styles.y;
            this.bg.setLocalZOrder(-1);
            this.addChild(this.bg);
        } else if (bundles.main.urls.blank_bg_jpg) {
            this.bg = new cc.Sprite(bundles.main.urls.blank_bg_jpg);
            this.bg.setScale(2 * this.bgScale);
            this.bg.setLocalZOrder(-2);
            this.addChild(this.bg);
        }
    },

    addSpines: function () {
        var bundleId = this.page.getResourcesBundleId();
        if (!bundles[bundleId]) {
            return;
        }

        for (var i = 0; i < 10; i++) {
            var spineJson = bundles[bundleId].jsons["spine_" + i + "_json"];

            if (!spineJson) {
                break;
            }
            var animation = new cleverapps.Spine(spineJson);
            var name = "animation";
            animation.setAnimation(0, name, true);
            animation.setLocalZOrder((this.pageNo === 0 && i === 2) ? -1 : 15);
            this.animation = animation;
            this.addChild(animation);
        }
    },

    addIsland: function () {
        var groupNo = Math.floor(this.pageNo / 10);
        var bundle = bundles["episode_" + groupNo + "_island"];
        if (bundle && bundle.jsons["island_" + this.pageNo + "_json"]) {
            var island = this.island = new IslandView(this.page, bundle);
            island.setLocalZOrder(3);
            this.addChild(island);
        }
    },

    removeLoadAnimation: function () {
        if (this.loading) {
            this.loading.finishAnimation();
            delete this.loading;
        }
    },

    startLoadAnimation: function () {
        if (!cleverapps.styles.LocationView.loadAnimation) {
            return;
        }

        if (cleverapps.silentIntro) {
            return;
        }

        var loading = this.loading = CloudsAnimation.factory();
        loading.setPositionRound(this.width / 2, this.height / 2);
        loading.startLoadAnimation(this);
        this.addChild(loading);
    },

    formatDifficulty: function (difficulty) {
        return difficulty;
    },

    finishLoadAnimation: function () {
        if (!cleverapps.styles.LocationView.loadAnimation) {
            return;
        }

        if (cleverapps.silentIntro) {
            return;
        }

        if (this.loading) {
            if (!(typeof HoseScene !== "undefined" && cleverapps.scenes.getRunningScene() instanceof HoseScene) || !this.loading.visible) {
                this.removeLoadAnimation();
            } else {
                this.loading.finishLoadAnimation(this.removeLoadAnimation.bind(this));
            }
        }
    },

    getBundleName: function () {
        return "episode_" + this.pageNo;
    }
});

cleverapps.styles.LocationView = {
    height: cleverapps.styles.SCENE_HEIGHT,

    addPixels: 2,

    direction: cleverapps.UI.HORIZONTAL,

    minScale: 0,
    padding: 0,

    Background: {
        width: 720,
        height: 1200
    },

    title: {
        y: 490
    },

    description: {
        y: 420
    },

    loadAnimation: false
};