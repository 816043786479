/**
 * Created by andrey on 07.09.17.
 */

var FieldBoostersView = cc.Node.extend({
    ctor: function (boosters) {
        this._super();
        this.boosters = boosters;

        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(10);
        this.setVisible(false);
        this.updateSize();

        this.shadowView = new ShadowLayer();
        this.shadowView.setLocalZOrder(FieldBoostersView.ZORDER);

        boosters.onSetActiveListener = this.createListener(this.onSetActive.bind(this));
        boosters.onExecuteListener = this.createListener(this.onExecute.bind(this));

        boosters.onGetBoosterView = this.createListener(function (boosterId) {
            return this.views[boosterId];
        }.bind(this));

        if (!connector.info.isNative) {
            cc.eventManager.addListener({
                event: cc.EventListener.MOUSE,
                onMouseMove: this.onMouseMove.bind(this)
            }, this);
        } else {
            cc.eventManager.addListener({
                event: cc.EventListener.TOUCH_ONE_BY_ONE,
                onTouchBegan: function () {
                    return true;
                },
                onTouchMoved: this.onMouseMove.bind(this),
                onTouchEnded: function () {
                    if (this.pointer) {
                        this.pointer.setVisible(false);
                    }
                }.bind(this)
            }, this);
        }

        boosters.on("show", this.showUpAnimation.bind(this), this);
        boosters.on("hide", this.hideAnimation.bind(this), this);

        this.views = {};
        this.boosters.listAll().forEach(function (booster) {
            var ViewClass = booster.getViewClass();

            var view = new ViewClass(booster);
            this.views[booster.id] = view;

            this.addChild(view);
        }, this);

        this.setupChildren();
    },

    showUpAnimation: function (silent) {
        var basePosition = this.calculatePositionRound(this.calculatePosition());
        var hidingPosition = this.calculatePositionRound(cleverapps.styles.FieldBoostersView.hidingPosition);

        this.setPosition(hidingPosition);
        this.setVisible(false);
        if (silent) {
            this.setVisible(true);
            this.setPositionRound(basePosition);
            return;
        }

        this.showUpAction = new cc.Sequence(
            new cc.DelayTime(cleverapps.styles.FieldBoostersView.delay),
            new cc.Show(),
            new cc.MoveTo(0.4, basePosition).easing(cc.easeIn(1))
        ).setFinalize(function () {
            this.setVisible(true);
            this.setPositionRound(this.calculatePosition());
            delete this.showUpAction;
        }.bind(this));

        this.runAction(this.showUpAction);
    },

    hideAnimation: function (silent) {
        var hidingPosition = this.calculatePositionRound(cleverapps.styles.FieldBoostersView.hidingPosition);
        if (silent) {
            this.setPositionRound(hidingPosition);
            this.setVisible(false);
            return;
        }

        this.showUpAction = this.runAction(new cc.Sequence(
            new cc.MoveTo(0.15, hidingPosition).easing(cc.easeIn(2)),
            new cc.Hide()
        )).setFinalize(function () {
            this.setVisible(false);
            this.setPositionRound(hidingPosition);
            delete this.showUpAction;
        }.bind(this));
    },

    updateSize: function () {
        var styles = cleverapps.styles.FieldBoostersView;
        this.setContentSize2(styles.sizes[cleverapps.resolution.mode]);
    },

    calculatePosition: function () {
        var position = cleverapps.styles.FieldBoostersView.position[cleverapps.resolution.mode];
        if (cleverapps.styles.UI.BoostersViewPosition) {
            position = cleverapps.styles.UI.BoostersViewPosition[this.boosters.amountAvailable()][cleverapps.resolution.mode];
        }
        return position;
    },

    setupChildren: function () {
        this.setPositionRound(this.calculatePosition());
        Object.keys(this.views).forEach(function (id) {
            var position = cleverapps.styles.UI.BoostersPositions[cleverapps.resolution.mode][id];
            this.views[id].setPositionRound(position);
        }.bind(this));
    },

    onSetActive: function () {
        if (this.boosters.active) {
            cleverapps.audio.playSound(bundles.game.urls.select_booster_effect);
        }

        if (this.boosters.active) {
            this.addShadow();
            this.addPointer();
        } else {
            this.removeShadow();
            this.removePointer();
        }
    },

    onExecute: function () {
        this.removeShadow();

        var pointer = this.pointer;
        if (!pointer) {
            return;
        }

        pointer.stopAllActions();
        pointer.runAction(new cc.Sequence(
            new cc.FadeOut(0.2),
            new cc.CallFunc(this.removePointer.bind(this))
        ));
    },

    addShadow: function () {
        if (Game.currentGame.tutorial && !Game.currentGame.tutorial.finished) {
            return;
        }

        this.shadowView.createShadow();
        this.addInfoView();
        this.moveUp();
    },

    removeShadow: function () {
        this.shadowView.deleteShadow();

        if (this.infoView) {
            this.infoView.hide();

            this.infoView.runAction(new cc.Sequence(
                new cc.DelayTime(0.2),
                new cc.CallFunc(this.moveDown.bind(this)),
                new cc.RemoveSelf()
            ));
        }
    },

    addInfoView: function () {
        if (this.infoView) {
            this.infoView.removeFromParent();
        }

        var scene = cleverapps.scenes.getRunningScene();

        this.infoView = new BoosterInfoView(this.boosters.active);
        scene.addChild(this.infoView);

        this.infoView.hide(true);
        this.infoView.show();
    },

    addPointer: function () {
        if (this.pointer) {
            this.pointer.removeFromParent();
        }

        var view = this.views[this.boosters.active.id];

        var pointer = this.pointer = new cc.Sprite(this.boosters.active.icon_moving);
        pointer.setVisible(false);
        cleverapps.scenes.getRunningScene().addChild(pointer);

        pointer.setPosition(this.pointer.getRelativeCoordinates(view));
        pointer.setLocalZOrder(100);
        pointer._setGlobalZOrder(true);
    },

    onMouseMove: function (event) {
        if (this.pointer && this.boosters.active) {
            var position = this.pointer.getParent().convertToNodeSpace(event.getLocation());
            this.pointer.setPosition(position);
            this.pointer.setVisible(true);
            this.pointer.mouseMoved = true;

            this.boosters.onTouchMoved(event);
        }

        if (this.boosters.active && this.infoView && this.infoView.valign === "bottom") {
            var hoverCell = this.boosters.active.hoverCell;
            if (hoverCell) {
                this.infoView.hide();
            } else {
                this.infoView.show(false, 0.5);
            }
        }
    },

    removePointer: function () {
        if (this.pointer) {
            this.pointer.removeFromParent();
            delete this.pointer;
        }
    },

    moveUp: function () {
        var fieldView = Game.currentGame.field.onGetView();

        if (fieldView.realZOrder === undefined) {
            fieldView.realZOrder = fieldView.getLocalZOrder();
        }

        fieldView.setLocalZOrder(FieldBoostersView.ZORDER + 1);
    },

    moveDown: function () {
        var fieldView = Game.currentGame.field.onGetView();

        if (fieldView.realZOrder !== undefined) {
            fieldView.setLocalZOrder(fieldView.realZOrder);
        }
    },

    listBoosters: function () {
        return Object.values(this.views);
    },

    completeAnimationOnResize: function () {
        if (this.showUpAction) {
            this.stopAction(this.showUpAction);
        }
    }
});

FieldBoostersView.ZORDER = 5;

cleverapps.styles.FieldBoostersView = {
    background: false,
    hidingPosition: [
        { x: { align: "center" }, y: { align: "bottom", dy: -660 } },
        { x: { align: "right", dx: 650 }, y: { align: "center" } },
        { x: { align: "right", dx: 650 }, y: { align: "center" } }
    ],
    position: [
        { x: { align: "center" }, y: { align: "bottom", dy: 10 } },
        { x: { align: "right" }, y: { align: "center" } },
        { x: { align: "right" }, y: { align: "center" } }
    ],
    sizes: [
        {
            width: 720,
            height: 200
        },
        {
            width: 190,
            height: 650
        },
        {
            width: 190,
            height: 650
        }
    ],
    delay: 0
};
