/**
 * Created by andrey on 22.05.17.
 */

var UnlimitedLives = function () {
    this.permanent = connector.platform.oneOf(connector.DIGITAL, connector.DIGITALWEB);
    this.value = undefined;

    this.load();
};

UnlimitedLives.prototype.buy = function (time) {
    if (this.permanent) {
        return;
    }

    if (typeof time === "string") {
        time = cleverapps.parseInterval(time);
    }
    if (this.checkBuyed()) {
        this.value.actionTime += time;
    } else {
        this.value = {
            buyTime: Date.now(),
            actionTime: time,
            fpShowed: 0
        };
    }
    this.save();
};

UnlimitedLives.prototype.reset = function () {
    this.value = undefined;
    this.save();
};

UnlimitedLives.prototype.getLeftTime = function () {
    if (this.permanent) {
        return Infinity;
    }
    if (this.value === undefined) {
        return 0;
    }
    var left = this.value.buyTime + this.value.actionTime - Date.now();
    if (left <= 0) {
        return 0;
    }
    return left;
};

UnlimitedLives.prototype.checkBuyed = function () {
    return this.getLeftTime() > 0;
};

UnlimitedLives.prototype.displayPromotionOnFinish = function () {
    if (cleverapps.flags.monetization !== cleverapps.Flags.MONETIZATION_REGULAR) {
        return;
    }

    if (this.value && !this.value.fpShowed && this.getLeftTime() === 0) {
        this.showPromotion();
    }
};

UnlimitedLives.prototype.showPromotion = function () {
    cleverapps.focusManager.display({
        focus: "ExtendUnlimitedLivesWindow",
        action: function (f) {
            this.value.fpShowed = 1;
            this.save();

            new ExtendUnlimitedLivesWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }.bind(this)
    });
};

UnlimitedLives.prototype.showFreeOffer = function (onSuccess) {
    cleverapps.focusManager.display({
        stack: true,
        focus: "FreeUnlimitedLivesWindow",
        action: function (f) {
            new FreeUnlimitedLivesWindow(onSuccess);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

UnlimitedLives.prototype.freeOfferIsAvailable = function () {
    return cleverapps.lives.isEmpty() && !cleverapps.forces.isShown(Forces.FREE_UNLIMITED_LIVES_FORCE.id) && cleverapps.user.getFloatLevel() < UnlimitedLives.FREE_OFFER_AVAILABLE_THRESHOLD;
};

UnlimitedLives.prototype.getInfo = function () {
    var compat = {};
    compat[UnlimitedLives.UNLIMITED_LIVES_COMPAT] = this.value;
    return compat;
};

UnlimitedLives.prototype.updateInfo = function (data, fromServer) {
    this.value = data[UnlimitedLives.UNLIMITED_LIVES_COMPAT];
    this.save(fromServer);
};

UnlimitedLives.prototype.load = function () {
    var save = cleverapps.dataLoader.load(DataLoader.TYPES.UNLIMITED_LIVES);

    if (save === undefined) {
        this.value = undefined;
        return;
    }

    this.value = save[UnlimitedLives.UNLIMITED_LIVES_COMPAT];
};

UnlimitedLives.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.UNLIMITED_LIVES, this.getInfo());

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("tempgoods");
    }
};

UnlimitedLives.FREE_OFFER_AVAILABLE_THRESHOLD = 4;
UnlimitedLives.UNLIMITED_LIVES_COMPAT = 1;