/**
 * Created by iamso on 25.02.19.
 */

var GumDecoratorCellView = BaseCellView.extend({
    ctor: function (decoratedCell) {
        this._super();

        this.setContentSize2(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        this.setAnchorPoint(0.5, 0.5);

        this.setLayers(decoratedCell);
        this.initialize(decoratedCell);
        this.setAllBorders();

        this.cell.onChangeLivesListener = this.changeLives.bind(this);
        this.cell.onSetBorders = this.setBorders.bind(this);
    },

    setLayers: function (decoratedCell) {
        this.layers = [];
        this.initialLayersCount = decoratedCell.lives;
        for (var i = 0; i < decoratedCell.lives; i++) {
            this.setImage(i, decoratedCell);
        }
    },

    setImage: function (layer, decoratedCell) {
        var image = new cc.Sprite(decoratedCell.x === -1 ? bundles.gum_decorator.frames.gum_goal_png : bundles.gum_decorator.frames.gum_body_png);

        if (cleverapps.environment.isEditorScene()) {
            image.setOpacity(90 + layer * 50);
        } else if (decoratedCell.x !== -1) {
            this.layers[layer] = image;
        }

        this.setContentSize(image.getContentSize());
        image.setPosition(this.width / 2, this.height / 2);
        this.addChild(image);
    },

    setAllBorders: function () {
        this.borders = {};

        if (cleverapps.environment.isEditorScene() || this.cell.x === -1) {
            return;
        }

        var angles = {
            "L": -90,
            "R": 90,
            "D": 180,
            "U": 0
        };

        for (var angle in angles) {
            var border = new cc.Sprite(bundles.gum_decorator.frames.gum_border_png);
            border.setRotation(angles[angle]);
            border.setLocalZOrder(5);

            var positionCoef = GumDecoratorCellView.POSITIONS[angle];
            border.setPosition(this.width * positionCoef.x, this.height * positionCoef.y);

            this.addChild(border);
            this.borders[angle] = border;
        }

        this.setBorders(this.cell);
    },

    removeAllBorders: function () {
        for (var border in this.borders) {
            this.borders[border].setVisible(false);
        }
    },

    setBorders: function (decoratedCell, option) {
        var thisCell = decoratedCell || this.cell;

        if (GumDecoratorCellView.FORCE_BORDERS === option) {
            this.onHoverNow = true;
        }

        var borders = Object.keys(this.borders);
        if (GumDecoratorCellView.FORCE_BORDERS !== option) {
            borders = thisCell.getBordersDirections();
            this.setLocalZOrder(this.baseZOrder + thisCell.lives);
        }

        if (option === GumDecoratorCellView.HOVER_END) {
            this.onHoverNow = false;
            Object.keys(this.borders).forEach(function (dir) {
                if (borders.indexOf(dir) !== -1) {
                    return;
                }
                this.borders[dir].runAction(new cc.Sequence(
                    new cc.DelayTime(0.15),
                    new cc.CallFunc(function () {
                        if (!this.onHoverNow) {
                            this.borders[dir].setVisible(false);
                            this.onHoverNow = false;
                        }
                    }.bind(this))
                ));
            }, this);
        } else {
            for (var dir in this.borders) {
                this.borders[dir].setVisible(borders.indexOf(dir) !== -1);
            }
        }
    },

    showHover: function (state) {
        if (state) {
            this.setBorders(this.cell, GumDecoratorCellView.FORCE_BORDERS);
        } else {
            this.setBorders(this.cell, GumDecoratorCellView.HOVER_END);
        }

        this._super.apply(this, arguments);
        this.setLocalZOrder(this.baseZOrder + this.cell.lives);
    },

    changeLives: function () {
        if (this.cell.lives <= 0 || !this.cell.alive) {
            return;
        }

        var originalScale = this.getScale();

        var lives = this.cell.lives;
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.25, originalScale * 1.3),
            new cc.CallFunc(function () {
                this.setScale(originalScale);

                var boom = this.boomAnimation();
                if (boom) {
                    boom.setScale(1.2);
                }

                this.setLocalZOrder(this.baseZOrder + lives);
                this.setBorders();
                this.removelayer(lives);
            }.bind(this))
        ));
        cleverapps.audio.playSound(cleverapps.styles.GumDecoratorCellView.stageDecreaseSound);
    },

    removelayer: function (layerInd) {
        var curLayer = this.layers[layerInd];
        if (curLayer) {
            curLayer.visible = false;
        }
    },

    unuse: function () {
        this.cell.onChangeLivesListener = function () {};
        this.cell.onSetBorders = function () {};

        this._super();
    },

    startExploding: function () {
        cleverapps.audio.playSound(cleverapps.styles.GumDecoratorCellView.dieSound);
        this.setBorders(this.cell, GumDecoratorCellView.FORCE_BORDERS);
        if (Game.currentGame.goals.hasType(this.cell.getGoalId())) {
            BaseCellView.prototype.startExplodingViaCollect.apply(this, arguments);

            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.35),
                new cc.CallFunc(function () {
                    if (!this.cell) {
                        return;
                    }

                    this.boomAnimation();
                    for (var i = 0; i < this.initialLayersCount; i++) { // there can be double hurt, but lives changed only after some time (0.25)
                        this.removelayer(i); // some layers can be still not hidden, so need "for" there
                    }
                    this.removeAllBorders();

                    var image = new cc.Sprite(bundles.gum_decorator.frames.gum_goal_png);
                    image.setPosition(this.width / 2, this.height / 2);
                    this.addChild(image);
                }.bind(this))
            ));
        } else {
            this._super.apply(this, arguments);
        }
    },

    boomColor: function () {
        return cleverapps.styles.GumDecoratorCellView.boomColor;
    }
});

cleverapps.styles.GumDecoratorCellView = {
    boomColor: new cc.Color(252, 163, 202, 255),
    stageDecreaseSound: bundles.game.urls.special_element_stage_decrease,
    dieSound: bundles.game.urls.special_element_die
};

GumDecoratorCellView.FORCE_BORDERS = 1;
GumDecoratorCellView.HOVER_END = 2;

GumDecoratorCellView.OPACITY_BASE = 50;
GumDecoratorCellView.OPACITY_STEP = 50;

GumDecoratorCellView.CODES = ["U", "R", "D", "L"];

GumDecoratorCellView.POSITIONS = {
    "U": { x: 0.5, y: 1 },
    "R": { x: 1, y: 0.5 },
    "D": { x: 0.5, y: 0 },
    "L": { x: 0, y: 0.5 }
};