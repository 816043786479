/**
 * Created by iamso on 16.07.20.
 */

var BrushBooster = function (id) {
    Booster.call(this, id);

    this.init();
    this.onAfterExecuted = function () {};
}; 

BrushBooster.prototype = Object.create(Booster.prototype);
BrushBooster.prototype.ctor = BrushBooster;

BrushBooster.prototype.isForceAvailable = function () {
    var inflamer = Game.currentGame.inflamer;
    var isAvailable = !this.used && (inflamer.value > 1 || inflamer.stage > 0);

    return Booster.prototype.isForceAvailable.call(this) && isAvailable;
};

BrushBooster.prototype.canExecute = function () {
    return Booster.prototype.canExecute.call(this) && !this.used;
};

BrushBooster.prototype.onPressed = function (f, isRunningForce) {
    if (this.used) {
        f();
    } else {
        Booster.prototype.onPressed.call(this, f, isRunningForce);
    }
};

BrushBooster.prototype.execute = function () {
    if (this.executing) {
        return;
    }

    this.executing = true;
    Booster.prototype.execute.apply(this, arguments);
    this.executing = false;
};

BrushBooster.prototype.onExecute = function () {
    this.used = true;
    Game.currentGame.showPaint();

    this.onAfterExecuted();
};

BrushBooster.prototype.init = function () {
    this.used = false;
};
