/**
 * Created by ivan on 11.04.18.
 */

var FriendRequestsToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.FRIEND_REQUESTS);
    this.lastNotProceedId = cleverapps.dataLoader.load(DataLoader.TYPES.LAST_VIEWED_FRIEND_REQUEST, { raw: true });

    levels.friendRequests.on("load", function () {
        this.mark();
        cleverapps.toolbar.updateItems();

        if (!levels.friendRequests.existNotProcessed()) {
            this.unmark();
            cleverapps.toolbar.updateItems(true);
        }
    }.bind(this));

    this.enable();
    this.mark();
};

FriendRequestsToolbarItem.prototype = Object.create(ToolbarItem.prototype);
FriendRequestsToolbarItem.prototype.constructor = FriendRequestsToolbarItem;

FriendRequestsToolbarItem.prototype.mark = function () {
    var friends = cleverapps.friends.listFriends().concat(cleverapps.invitalbleFriends.listFriends());
    if (friends.length === 0) {
        return;
    }

    var lastNotProcessedId = levels.friendRequests.lastNotProcessedId();
    if (lastNotProcessedId === false || lastNotProcessedId === this.lastNotProceedId) {
        return;
    }

    ToolbarItem.prototype.mark.apply(this);
};

FriendRequestsToolbarItem.prototype.isVisible = function () {
    return levels.friendRequests.existNotProcessed();
};

FriendRequestsToolbarItem.prototype.onClick = function () {
    this.lastNotProceedId = levels.friendRequests.lastNotProcessedId();
    cleverapps.dataLoader.save(DataLoader.TYPES.LAST_VIEWED_FRIEND_REQUEST, this.lastNotProceedId, { raw: true });
    this.unmark();

    cleverapps.focusManager.display({
        focus: "FriendRequestsWindow",
        control: ["MenuBarGoldItem", "MenuBarLivesItem"],
        action: function (f) {
            cleverapps.SocialManager.enforceLogin(function (code) {
                if (code !== cleverapps.CODE_SUCCEED) {
                    f();
                    return;
                }

                FriendsCache.loadFriends(function () {
                    new FriendRequestsWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                });
            });
        }
    });
};