/**
 * Created by anatoly on 06.02.2025
 */

var SwapHintAlgorithm = {
    type: "swap",

    findMove: function () {
        if (!Game.currentGame || !Game.currentGame.field) {
            return;
        }

        var field = Game.currentGame.field;
        var moveOptions = cleverapps.Random.choose(field.findValidMove(true), false);

        if (!moveOptions) {
            return;
        }

        var cellToSwap = field.cells[moveOptions.start.y + moveOptions.dir.row][moveOptions.start.x + moveOptions.dir.col];
        field.swap(moveOptions.start, cellToSwap, true);
        moveOptions.shape = field.findAllShapes(moveOptions.start)[0];
        field.swap(moveOptions.start, cellToSwap, true);

        return {
            type: SwapHintAlgorithm.type,
            options: moveOptions
        };
    }
};

AvailableMove.REGISTERED_ALGORITHMS.push(SwapHintAlgorithm);