/**
 * Created by Andrey Popov on 14.06.2024
 */

var OfferProductTile = BaseProductTile.extend({
    ctor: function (tileModel, options) {
        var styles = OfferProductTile.styles = OfferProductTile.styles || cleverapps.overrideStyles(cleverapps.styles.BaseProductTile, cleverapps.styles.OfferProductTile, true);

        options = options || {};

        this._super(tileModel, styles, options);
    },

    wrapIconWithBg: function (icon) {
        if (this.styles.icon.bg) {
            return this._super(icon);
        }

        var wrapper = new cc.Node();
        wrapper.setContentSize2(icon.width, icon.height);
        wrapper.setAnchorPoint(0.5, 0.5);
        var offset = cleverapps.skins.getSlot("offerHardShopIconOffset", this.tileModel.offer.name) || this.styles.icon.offsetY;
        wrapper.addChild(icon);
        icon.setPositionRound(wrapper.width / 2, wrapper.height / 2 + offset);

        return wrapper;
    },

    createDescription: function () {
        var styles = this.styles.description;

        var node = new cc.Node();
        node.setAnchorPoint(0.5, 0.5);
        node.setContentSize2(styles);

        if (styles.bg) {
            var bg = cleverapps.UI.createScale9Sprite(bundles.tile_shop.frames.offer_description_bg, cleverapps.UI.Scale9Rect.TwoPixelY);
            bg.setContentSize2(styles.bg);
            bg.setPositionRound(styles.bg);
            node.addChild(bg);
        }
        
        var content = new TileRewardsBlock(this.tileModel, {
            size: cc.size(styles),
            font: cleverapps.styles.FONTS.OFFER_TILE_REWARDS_TEXT,
            icons: {}
        });

        content.setPositionRound(node.width / 2, node.height / 2);
        node.addChild(content);

        return node;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    OFFER_TILE_REWARDS_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    }
});

cleverapps.styles.OfferProductTile = {
    icon: {
        offsetY: -40,
        bg: undefined
    },

    content: {
        y: { align: "bottom", dy: 75 },
        margin: 0
    },

    description: {
        width: 370,
        y: 20,
        bg: {
            width: 370,
            height: 148,
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    },

    title: {
        y: 20
    }
};
