/**
 * Created by andrey on 19.11.19.
 */

var SimpleMainScene = MainScene.extend({
    ctor: function (options) {
        this._super(options);

        if (cleverapps.config.rpg) {
            var currentLevel = cleverapps.meta.selectedLocationId();

            this.pagingScroll = new PagingScroll(cleverapps.meta.listAvailableLocations().length);
            this.pagingScroll.scrollToLevel(0, currentLevel);
        }
    },

    onSceneLoaded: function () {
        this._super();

        if (cleverapps.config.rpg) {
            var pagingScrollView = new PagingScrollView(this.pagingScroll);
            pagingScrollView.setLocalZOrder(-1);
            this.addChild(pagingScrollView);
        } else {
            this.progress = this.addProgress();
        }

        var playButton = new PlayButtonView();
        this.addChild(playButton, 1);
        cleverapps.focusManager.registerControl("play_button", new HidingNode(playButton, HidingNode.DIR.NONE));

        playButton.addFinger();

        if (cleverapps.highscore) {
            var scoreModeButton = new ScoreModeButton();
            this.addChild(scoreModeButton, 1);
            cleverapps.focusManager.registerControl("score_mode_button", new HidingNode(scoreModeButton, HidingNode.DIR.NONE));
        }
    },

    getBackgroundStyles: function () {
        var bundleName = cleverapps.meta.getSelectedLocation().getBackgroundBundle();
        if (bundleName) {
            return {
                bundle: bundleName,
                backgroundId: "background"
            };
        }
    },

    listBundles: function () {
        var res = [];

        if (this.styling && this.styling.bundle) {
            res.push(this.styling.bundle);
        }

        if (this.pagingScroll) {
            this.pagingScroll.listVisiblePages().forEach(function (page) {
                if (page.isComingSoon()) {
                    res.push("episode_coming_soon");
                }
            });

            res.push("episodes_resources");
        } else {
            res = res.concat(cleverapps.meta.getSelectedLocation().calcBundles());
        }

        return res;
    },

    addProgress: function () {
        var progress = new ProgressView();
        this.addChild(progress);

        var progressControl = new HidingNode(progress, HidingNode.DIR.NONE);
        cleverapps.focusManager.registerControl("progress_view", progressControl);

        return progressControl;
    },

    completeCurrentPage: function (callback) {
        var current = cleverapps.meta.selectedLocationId();
        cleverapps.focusManager.compound(callback, [
            function (f) {
                this.pagingScroll.gotoNextPage(f, current);
            }.bind(this),

            function (f) {
                setTimeout(f, 300);
            },
            function (f) {
                new FinishEpisodeWindow(current);

                cleverapps.focusManager.onceNoWindowsListener = f;
            },

            function (f) {
                var delay = 0;

                if (cleverapps.config.name === "runes") {
                    this.createFilmEffect({
                        scale: 1.2,
                        layerTimeout: 0.3
                    });
                    delay = 2000;
                }

                cleverapps.timeouts.setTimeout(f, delay);
            }.bind(this),

            function (f) {
                this.pagingScroll.animateClose(f, current);
            }.bind(this)
        ]);
    },

    startNextPage: function (callback) {
        var current = cleverapps.meta.selectedLocationId();
        cleverapps.focusManager.compound(callback, [
            function (f) {
                this.pagingScroll.gotoNextPage(f, current);
            }.bind(this),

            function (f) {
                this.pagingScroll.animateOpen(f, current);
            }.bind(this),

            function (f) {
                this.removeFilmEffect(0.6, f);
            }.bind(this)
        ]);
    },

    moveNextBg: function (callback) {
        cleverapps.focusManager.removeControl("progress_view");

        this.progress.removeFromParent();
        this.progress = this.addProgress();

        if (!this.background) {
            callback();
            return;
        }

        var prevBg = this.background;
        prevBg.setLocalZOrder(-7);

        this.setBackground(this.getBackgroundStyles());

        prevBg.runAction(new cc.Sequence(
            new cc.PlaySound(bundles.simple.urls.simple_transition_effect),
            new cc.FadeOut(1),
            new cc.RemoveSelf(),
            new cc.CallFunc(callback)
        ));
    }
});
