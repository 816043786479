/**
 * Created by andrey on 19.11.19.
 */

var MainScene = cleverapps.FixedWidthScene.extend({
    onSceneLoaded: function (options) {
        options = options || {};

        this._super(options.scene || cleverapps.Environment.SCENE_MAIN);

        if (typeof FlyingAdView !== "undefined" && !cleverapps.config.wysiwygMode) {
            this.addChild(new FlyingAdView());
        }

        cleverapps.environment.levelNo = options.levelNo !== undefined ? options.levelNo : levels.user.level;
        cleverapps.environment.episodeNo = options.episodeNo !== undefined ? options.episodeNo : levels.user.episode;

        cleverapps.placements.run(Placements.INTERMEDIATE);
    },

    _closeAction: function () {
        if (cleverapps.administrator && cleverapps.administrator.adminLocations && AdminLocationsScene.IsAvailable()) {
            AdminLocationsScene.open();
            return;
        }

        if (cleverapps.administrator && AdministratorScene.IsAvailable()) {
            cleverapps.flags.norest = false;
            cleverapps.dataLoader.setAlerted(false);
            connector.dataLoader.setEnabled(true);
            cleverapps.dataLoader.setEnabled(true);
            cleverapps.setRestDummies(false);
            AdministratorScene.open();
            return;
        }

        if (cleverapps.config.wysiwygMode) {
            cleverapps.config.editorMode = false;
            WysiwygScene.open(cleverapps.wysiwyg);
            return;
        }

        if (connector.platform.hasCloseApplication()) {
            cleverapps.focusManager.display({
                focus: "ConfirmExitWindow",
                action: function (f) {
                    new ConfirmExitWindow({
                        action: function () {
                            connector.platform.closeApplication();
                        }
                    });

                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
    }
});