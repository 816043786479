/**
 * Created by slava on 7/11/17
 */

var Boatswain = function (level) {
    this.level = level;

    this.setIntent(Boatswain.RETURN_INTENT);
};

Boatswain.prototype.setIntent = function (intent) {
    this.intent = intent;
};

Boatswain.prototype.setOutcome = function (outcome) {
    this.outcome = outcome;
};

Boatswain.prototype.playIntent = function (f) {
    if (this.intent === Boatswain.NEXT_INTENT && !this.canNext()) {
        this.intent = Boatswain.RETURN_INTENT;
    }

    switch (this.intent) {
        case Boatswain.NEXT_INTENT:
            cleverapps.focusManager.compound(f, [
                function (f) {
                    if (this.level.isDailyCup()) {
                        cleverapps.dailyCup.wantsToPlay(f);
                    } else if (this.level.isWeeklyTournamentLevel()) {
                        cleverapps.weeklyTournament.play(f);
                    } else {
                        cleverapps.meta.wantsToPlay(f);
                    }
                }.bind(this),

                function (f) {
                    if (cleverapps.environment.isGameScene() && !Game.currentGame) {
                        cleverapps.Plot.ReturnToMainScene(f, this.level, this.outcome);
                    } else {
                        f();
                    }
                }.bind(this)
            ]);
            break;

        case Boatswain.REPLAY_INTENT:
            cleverapps.focusManager.compound(f, [
                function (f) {
                    cleverapps.meta.wantsToReplay(f, this.level);
                }.bind(this),

                function (f) {
                    if (cleverapps.environment.isGameScene() && !Game.currentGame) {
                        cleverapps.Plot.ReturnToMainScene(f, this.level, this.outcome);
                    } else {
                        f();
                    }
                }.bind(this)
            ]);
            break;

        case Boatswain.RETURN_INTENT:
            if (this.level.isDailyCup()) {
                cleverapps.scenes.replaceScene(new DailyCupScene(), f);
            } else if (this.level.isWeeklyTournamentLevel()) {
                cleverapps.scenes.replaceScene(new WeeklyTournamentScene(), f);
            } else {
                cleverapps.Plot.ReturnToMainScene(f, this.level, this.outcome);
            }
    }
};

Boatswain.prototype.prepareNext = function () {
    if (!cleverapps.isKnockoutGame()) {
        cleverapps.missionManager.run();
    }
};

Boatswain.prototype.canNext = function () {
    if (typeof match3 !== "undefined" && match3.heroes) {
        var color = match3.heroes.readyToOpen();
        if (color) {
            return false;
        }
    }

    if (this.level.isDailyCup()) {
        return cleverapps.dailyCup.isRunning() && (!cleverapps.dailyCup.withLives() || !cleverapps.lives.isEmpty());
    }

    if (this.level.isWeeklyTournamentLevel()) {
        return cleverapps.weeklyTournament.canPlayNext();
    }

    var canNext = this.level.isRegular() && !cleverapps.isKnockoutGame();
    if (!canNext) {
        return false;
    }

    if (cleverapps.forces.checkMainForces()
        || cleverapps.missionManager.skipNext()
        || cleverapps.missionManager.wantsToShowCompleted()
        || cleverapps.missionManager.pendingStartWindow()
        || cleverapps.missionManager.getWantToShowNewStateMission()
    ) {
        return false;
    }

    switch (cleverapps.meta.getType()) {
        case Meta.FARM:
            if (!cleverapps.meta.getSelectedLocation().isCompleted()) {
                if (cleverapps.meta.getSelectedLocation().getBuilding().getQuest().isCompleted() || cleverapps.meta.getSelectedLocation().justCollectedDialogue) {
                    return false;
                }
            }
            break;
        case Meta.HOMEFIX:
            break;
        case Meta.HOSE:
        case Meta.SIMPLE:
            if (cleverapps.meta.getSelectedLocation().isCompleted() && !cleverapps.meta.isCompleted() || cleverapps.Plot.getDialogueAfterLevel(this.level)) {
                return false;
            }

            if (cleverapps.starChest && cleverapps.starChest.isEnabled() && cleverapps.starChest.stars >= cleverapps.StarChest.GOAL_AMOUNT) {
                return false;
            }

            break;
    }
    return true;
};

var BoatswainAction = function () {
    cleverapps.focusManager.distract({
        focus: "boatswain",
        control: this.introControls(),
        action: function (f) {
            this.game.boatswain.playIntent(f);
        }.bind(this)
    });
};

Boatswain.RETURN_INTENT = 0;
Boatswain.NEXT_INTENT = 1;
Boatswain.REPLAY_INTENT = 2;
