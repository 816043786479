/**
 * Created by Reda on 11.03.2025
 */

function TimeScaleManager() {
    this.value = cc.director.getScheduler().getTimeScale();
}

TimeScaleManager.prototype.add = function (step) {
    step = step === undefined ? TimeScaleManager.MIN_STEP : step;
    var newValue = this.value + step;
    this.setValue(+newValue.toFixed(2));
};

TimeScaleManager.prototype.setValue = function (value) {
    if (value >= TimeScaleManager.MIN_VALUE && value <= TimeScaleManager.MAX_VALUE) {
        this.lastValue = undefined;
        this.value = value;
        cc.director.getScheduler().setTimeScale(this.value);
    }
};

TimeScaleManager.prototype.toggle = function () {
    var value = 0;
    if (this.lastValue) {
        value = this.lastValue;
        this.lastValue = undefined;
    } else {
        this.lastValue = this.value;
    }
    cc.director.getScheduler().setTimeScale(value);
};

TimeScaleManager.MIN_STEP = 0.05;
TimeScaleManager.MIN_VALUE = 0;
TimeScaleManager.MAX_VALUE = 1;