/**
 * Created by vladislav on 13.07.20.
 */

var TileShuffleBooster = function (id) {
    Booster.call(this, id);
};

TileShuffleBooster.prototype = Object.create(Booster.prototype);
TileShuffleBooster.prototype.constructor = TileShuffleBooster;

TileShuffleBooster.prototype.onGameStarted = function () {
    Game.currentGame.log.on("change", this.updateState.bind(this));
    Game.currentGame.pagination.on("changePage", this.updateState.bind(this));
    cleverapps.userStatus.on("inactive_very_short", function () {
        this.canShowForce = true;
        Game.currentGame.counter.trigger();
    }.bind(this), Game.currentGame);
};

TileShuffleBooster.prototype.isForceAvailable = function () {
    return Booster.prototype.isForceAvailable.call(this) && this.canShowForce;
};

TileShuffleBooster.prototype.updateState = function () {
    if (Game.currentGame.table.cards.length <= 1) {
        this.onDisableListener();
    } else {
        this.onEnableListener();
    }
};

TileShuffleBooster.prototype.onExecute = function () {
    Game.currentGame.table.shuffle();
};
