/**
 * Created by mac on 10/12/22
 */

var CloseButton = cleverapps.UI.Button.extend({
    ctor: function (options) {
        this.options = options;

        this._super({
            type: {
                button_png: bundles.buttons_main.frames.window_close_png,
                button_on_png: bundles.buttons_main.frames.window_close_on_png
            },
            onClicked: options.onClicked
        });

        this.setLocalZOrder(3);
        this.updateSize();
    },

    updateSize: function () {
        this.baseScale = cleverapps.styles.CloseButton.scale[cleverapps.resolution.mode];
        this.setScale(this.baseScale);

        this.setPositionRound(this.options.styles || cleverapps.styles.CloseButton.position);
    },

    hide: function () {
        this.setVisible(false);
    },

    show: function () {
        this.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0, 0.3 * this.baseScale),
                new cc.Show(),
                new cc.ScaleTo(0.4, this.baseScale).easing(cc.easeBackOut())
            )
        );
    }
});

cleverapps.styles.CloseButton = {
    scale: [0.7, 1, 1],

    position: {
        x: { align: "right", dx: 20 },
        y: { align: "top", dy: 20 }
    }
};