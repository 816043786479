/**
 * Created by iamso on 23.11.2020.
 */

var AdminLogEntryView = cleverapps.Layout.extend({
    ctor: function (entry, logView) {
        this.entry = entry;
        this.logView = logView;

        this._super(this.createContentItems(), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.AdminLogEntryView.margin
        });

        this.addBg();
        this.addAuthor();
        entry.on("deleteLogEntry", this.delete.bind(this), this);
        entry.on("updateLogEntry", this.updateEntry.bind(this), this);
    },

    addBg: function () {
        var bg = cleverapps.UI.createScale9Sprite(this.logView.views.length % 2 === 0
            ? bundles.admin.frames.row_even_bg : bundles.admin.frames.row_odd_bg);
        bg.setContentSize(this.getContentSize());
        bg.setPositionRound(bg.width / 2, this.height / 2);
        this.addChild(bg, -1);
    },

    addAuthor: function () {
        var author = cleverapps.UI.generateOnlyText(this.entry.userName, cleverapps.styles.FONTS.ADMIN_LOG_AUTHOR_TEXT);
        author.fitTo(cleverapps.styles.AdminLogEntryView.authorWidth);
        this.addChild(author);
        author.setPositionRound(this.width - author.width / 2 - cleverapps.styles.AdminLogEntryView.authorPadding, this.height / 2);
    },

    createContentItems: function () {
        var styles = cleverapps.styles.AdminLogEntryView;

        var dateTxt = this.dateTxt = cleverapps.UI.generateOnlyText(this.entry.date.toLocaleString("ru").replace(",", " "), cleverapps.styles.FONTS.ADMIN_LOG_FONT, {
            ignoreWarning: true
        });
        var dateCont = new cc.Node();
        dateCont.setAnchorPoint(0.5, 0.5);
        dateCont.setContentSize2(styles.dateWidth, styles.entryHeight);
        dateCont.addChild(dateTxt);
        dateTxt.setPositionRound(dateCont.width / 2, dateCont.height / 2);

        var text = this.entry.getMessage();
        if (text.length > styles.maxMsgLength) {
            text = text.substr(0, styles.maxMsgLength) + "...";
        }
        var message = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.ADMIN_LOG_FONT, {
            ignoreWarning: true
        });
        message.setDimensions(styles.msgWidth - styles.msgPadding, 0);
        message.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);
        message.fitTo(undefined, styles.entryHeight);
        var msgCont = new cc.Node();
        msgCont.setAnchorPoint(0.5, 0.5);
        msgCont.setContentSize2(styles.msgWidth, styles.entryHeight);
        msgCont.addChild(message);
        message.setPositionRound(message.width / 2, msgCont.height / 2);

        return [dateCont, msgCont];
    },

    delete: function () {
        this.removeFromParent();
        this.logView.updateContent();
    },

    updateEntry: function () {
        this.dateTxt.setString(this.entry.date.toLocaleString("ru").replace(",", ""));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_LOG_FONT: {
        name: "formal",
        size: 16,
        color: cleverapps.styles.COLORS.BLACK
    },

    ADMIN_LOG_AUTHOR_TEXT: {
        name: "formal",
        size: 16,
        color: cleverapps.styles.COLORS.ADMIN_TEXT_COLOR
    }
});

cleverapps.styles.AdminLogEntryView = {
    maxMsgLength: 200,
    dateWidth: 190,
    msgWidth: 550,
    msgPadding: 160,
    entryHeight: 36,
    margin: 5,
    authorPadding: 10,
    authorWidth: 150
};