/**
 * Created by vladislav on 28.09.2020
 */

var EyeBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_EYE);
};

EyeBooster.prototype = Object.create(BaseBoosterBefore.prototype);
EyeBooster.prototype.constructor = EyeBooster;

EyeBooster.prototype.getAfterStartActions = function () {
    return function (f) {
        this.trigger("giveBonus");
        this.execute();
        Game.currentGame.counter.setTimeout(f, 5000);
    }.bind(this);
};