/**
 * Created by vladislav on 04/04/2022
 */

var Caravan = function (mission, isNewMission) {
    this.mission = mission;

    if (!isNewMission) {
        return;
    }

    var unit = Merge.currentMerge.shipsPlanner.spawnUnit();
    if (!unit) {
        return;
    }

    var unitStory = Merge.currentMerge.unitStories.findStory(Unit.GetKey(unit));
    if (!unitStory) {
        return;
    }

    this.unitStory = {
        story: unitStory,
        unit: unit
    };
};

Caravan.prototype.buyProduct = function (product) {
    cleverapps.paymentsLogic.giveReward(product.reward);
};

Caravan.prototype.beforeRemove = function () {
    if (typeof Merge !== "undefined") {
        Merge.currentMerge.shipsPlanner.removeUnit();
    }
};