/**
 * Created by olga on 22.12.2023
 */

var TilesUnlockWindow = CleverappsWindow.extend({
    ctor: function (options) {
        this.options = options || {};

        this._super.apply(this, arguments);
    },

    onWindowLoaded: function () {
        this.tilesCreated = false;
        var content = this.createContent();

        this._super({
            content: content,
            name: "tilesunlockwindow",
            noBackground: true,
            title: "TilesUnlockWindow.text"
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.TilesUnlockWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize(styles.width, styles.height);

        var animation = this.animation = new cleverapps.Spine(bundles.tiles_unlock_window.jsons.tiles_unlock_json);
        animation.setAnimationAndIdleAfter("open", "idle");
        cleverapps.audio.playSound(bundles.tiles_unlock_window.urls.rays_show_effect);

        this.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.DelayTime(1.4),
                new cc.CallFunc(function () {
                    this.idleSound = cleverapps.audio.playSound(bundles.tiles_unlock_window.urls.rays_idle_effect);
                }.bind(this)),
                new cc.DelayTime(6.6)
            )
        ));

        animation.setPositionRound(styles.animation);
        content.addChild(animation);
        var tiles = this.tiles = this.createTiles();
        tiles.forEach(function (tile) {
            content.addChild(tile);
        });

        return content;
    },

    createTiles: function () {
        var isRandom = this.options && this.options.random;

        var newSkins = TileGenerator.getNewSkins(isRandom);

        var tiles = newSkins.map(function (skin) {
            var view = CardView.createCardView(new Card({
                value: skin
            }));

            view.onTouchHandler.remove();
            view.onTouchHandler = undefined;

            view.hide = undefined;

            return view;
        });

        var callback = cleverapps.wait(2, function () {
            this.tilesCreated = true;
        }.bind(this));

        tiles.forEach(function (tile, index) {
            var actions = [];

            actions.push(tile.unlockSpawnAnimate(!index, callback));
            actions.push(new cc.CallFunc(callback));

            var hoveringOptions = {};
            if (!index) {
                hoveringOptions = {
                    leftAngle: -6,
                    rightAngle: -3
                };
            }
            actions.push(new cc.DelayTime(2.5));
            actions.push(StandartAnimations.hovering(tile, hoveringOptions));

            tile.runAction(new cc.Sequence(actions));
        });

        return tiles;
    },

    beforeCloseAnimation: function (callback) {
        callback = cleverapps.wait(2, callback);

        cleverapps.audio.stopSound(this.idleSound);
        cleverapps.audio.playSound(bundles.tiles_unlock_window.urls.rays_hide_effect);

        var first = true;
        this.animation.setAnimation(0, "close", false);
        this.animation.setCompleteListenerRemove();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.3),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.tiles_unlock_window.urls.tiles_unlock_effect);
            })
        ));

        this.tiles.forEach(function (tile) {
            tile.stopAllActions();
            tile.particles.removeFromParent();
            tile.unlockEndAnimate(callback, first);

            first = false;
        });
    },

    close: function () {
        if (this.tilesCreated) {
            this._super();
        }
    },

    listBundles: function () {
        return ["tiles_unlock_window"];
    }
});

cleverapps.styles.TilesUnlockWindow = {
    width: 650,
    height: 900,

    animation: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 }
    },

    tiles: {
        offset: 125,
        scale: 1.7
    }
};
