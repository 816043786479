/**
 * Created by andrey on 21.05.2020
 */

var ComboBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_COMBO);
};

ComboBooster.prototype = Object.create(BaseBoosterBefore.prototype);
ComboBooster.prototype.constructor = ComboBooster;

ComboBooster.prototype.getAfterStartActions = function () {
    return function (f) {
        ComboBooster.afterStartAction({
            amount: 5,
            booster: this
        }, f);
    }.bind(this);
};

ComboBooster.afterStartAction = function (options, f) {
    var amount = options.amount;
    var timeouts = [0, 120, 250, 360, 500, 620, 750, 860, 1000, 1100].slice(0, amount);

    var combosCreated = Game.currentGame.field.addRandomCombo(amount, {
        actionBefore: true,
        timeouts: timeouts,
        fromLantern: options.lantern
    });

    if (combosCreated < amount) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BOOSTER_COMBO_NO_PLACE, { value: (amount - combosCreated) });
    }

    if (combosCreated > 0 && options.booster) {
        options.booster.execute();
    }

    Game.currentGame.counter.setTimeout(f, 300);
};