/**
 * Created by iamso on 07.06.19.
 */

IslandView.prototype.updateAnimation = function () {
    if (bundles.episodes_resources.jsons.locked_json) {
        var styles = cleverapps.styles.IslandView;
        this.locked = new cleverapps.Spine(bundles.episodes_resources.jsons.locked_json);
        this.locked.setPositionRound(styles.locked.alignment);
        this.locked.setContentSize2(styles.locked.width, styles.locked.height);
        this.animation.addChild(this.locked);
        this.locked.setLocalZOrder(2);
        if (cleverapps.meta.selectedLocationId() < this.pageNo
            && !(cleverapps.meta.selectedLocationId() === this.pageNo && !cleverapps.meta.getSelectedLocation().isCompleted())) {
            this.locked.setAnimation(0, "close", true);
            this.lockedIdle = true;
        }
    }

    this.animation.setAnimation(0, "animation", true);
};

cleverapps.override(cleverapps.styles.IslandView, {
    locked: {
        width: 220,
        height: 220,
        alignment: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    }
});