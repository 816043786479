/**
 * Created by slava on 3/6/19
 */

var LineHintAlgorithm = {
    type: "lineBooster",

    findMove: function () {
        if (!Game.currentGame || !Game.currentGame.field) {
            return;
        }

        var ideas = [
            LineHintAlgorithm.completeGoalIdea,
            HintHelper.isBottomRowElement
        ];

        var moveOptions = HintHelper.findMove(ideas, cleverapps.Boosters.TYPE_LINE);
        if (moveOptions) {
            return {
                type: LineHintAlgorithm.type,
                options: moveOptions
            };
        }
    },

    completeGoalIdea: function (cell) {
        if (!HintHelper.isActiveGoal(cell) || !cell.hurtable) {
            return;
        }

        var goalId = cell.getGoalId();
        var remaining = HintHelper.countSameGoals(cell);

        var col = cell.col;
        var columnTotal = 0;
        for (var i = 0; i < Field.SIZE; i++) {
            cell = Game.currentGame.field.cells[i][col];
            if (cell && cell.getGoalId() === goalId) {
                columnTotal++;
            }
        }

        return columnTotal >= remaining;
    }
};

AvailableMove.REGISTERED_ALGORITHMS.push(LineHintAlgorithm);