/**
 * Created by evgenijsenkevic on 28.08.2024
 * */

var EditorLocationScene = cleverapps.FixedWidthScene.extend({
    ctor: function (locationId) {
        if (locationId) {
            cleverapps.meta.switchLocation(locationId);
        }

        this.location = cleverapps.meta.getSelectedLocation();

        this._super();
    },

    destructor: function () {
        this.editor.map.stop();

        if (cleverapps.config.type === "merge") {
            Merge.currentMerge = undefined;
        }

        this._super();
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_EDITOR);

        connector.ads.closeStickyCallback();
        cleverapps.resolution.setContainerPadding();

        cleverapps.skins.update();

        cleverapps.administrator.adminLocations.setActive(new AdminLocation(this.location.locationId));

        if (cleverapps.config.type === "merge") {
            var merge = new Merge();
            merge.stop();

            this.editor = new Editor(this.location, MergeOrangery);

            merge.map = this.editor.map;
        } else {
            this.editor = new Editor(this.location, HomefixOrangery);
        }

        var editorView = new EditorView(this.editor);
        this.addChild(editorView);

        var orangery = Editor.currentEditor.orangery;
        if (orangery) {
            var orangeryView = new OrangeryView(orangery);
            this.addChild(orangeryView);

            cleverapps.focusManager.registerControl(orangery.getControlName(), new HidingNode(orangeryView, cleverapps.UI.VERTICAL));
        }
    },

    _closeAction: function () {
        cleverapps.setUrlHash({
            mode: undefined,
            location: undefined
        });

        if (cleverapps.config.adminMode) {
            cleverapps.config.editorMode = false;
            AdminLocationsScene.open();
        }
    },

    getBackgroundStyles: function () {
        var SceneCtor = cleverapps.config.type === "merge" ? MergeScene : HomefixScene;
        return SceneCtor.prototype.getBackgroundStyles.apply(this, arguments);
    },

    listBundles: function () {
        var names = ["editor"].concat(
            Editor.BUNDLES,
            AdminLocation.ListLocationBundles(this.location.locationId)
        );

        return names.filter(function (bundleName) {
            return bundles[bundleName];
        });
    },

    lazyBundles: function () {
        var result = [];

        if (cleverapps.config.type === "merge") {
            result = result.concat(Families.listLazyBundles(Object.keys(this.location.families)));
        }

        return result;
    }
});

EditorLocationScene.open = function (f) {
    var params = cleverapps.getRequestParameters(location.hash);
    cleverapps.setUrlHash({ mode: "editor_location" });

    cleverapps.Synchronizer.prototype.showClientSessionExpiredWindow = function () {};

    cleverapps.config.editorMode = true;
    cleverapps.config.adminMode = true;

    cleverapps.adsLimits.updateVolume();

    if (f) {
        cleverapps.scenes.replaceScene(new EditorLocationScene(params.location), f);
    } else {
        cleverapps.focusManager.display({
            focus: "EditorLocationSceneOpen",
            action: function (f) {
                cleverapps.scenes.replaceScene(new EditorLocationScene(params.location), f);
            }
        });
    }
};