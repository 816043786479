/**
 * Created by mac on 10/6/20
 */

var AdminLevelMaxiature = {
    generate: function (adminLevel, options) {
        options = options || {};
        var MaxiatureGenerator = AdminLevelMaxiature[cleverapps.config.name] || AdminLevelMaxiature[cleverapps.config.type];
        if (adminLevel !== undefined && MaxiatureGenerator) {
            try {
                cleverapps.environment.levelNo = adminLevel.levelNo;
                cleverapps.environment.episodeNo = adminLevel.episodeNo;
                return MaxiatureGenerator(adminLevel, options);
            } catch (e) {
                console.log("Error in level maxiature " + cleverapps.humanReadableNumber(adminLevel.episodeNo, adminLevel.levelNo), e);
            }
        }

        return AdminLevelMaxiature.default();
    },

    bundles: {
        match3: function () {
            return ["enemies"];
        }
    },

    default: function () {
        return new cc.Sprite(bundles.admin.frames.loading_png);
    },

    merge: function (adminLevel) {
        var bundle = bundles["location_" + adminLevel.location];

        var merge = new FakeMerge(adminLevel);

        var adapter = new FakeMap2dAdapter();

        var map = merge.map = new Map2d(adapter, adminLevel.content.map, {
            units: bundle.meta.units,
            tiles: bundle.meta.tiles,
            visibleBox: adminLevel.content.visibleBox,
            terrains: adminLevel.content.terrains,
            field: adminLevel.content.field,
            waterBorder: 0
        });

        Map2d.InsertDefaults(map, adminLevel.content.field);

        var SIZE = map.getWidth() + map.getHeight();
        var totalWidth = SIZE * cleverapps.styles.Map2dView.cell.width / 2;
        var totalHeight = SIZE * cleverapps.styles.Map2dView.cell.height / 2;

        var context = {
            level: new Level(adminLevel.episodeNo, adminLevel.levelNo)
        };
        var bundles = GameScene.prototype.listBundles.call(context);

        var preview = new AdminLevelPreview(bundles, function (preview) {
            var view = new Map2dInnerView(map);
            view.setPositionRound({ align: "center" }, { align: "center" });
            view.setScale(Math.min(preview.width / view.width, preview.height / view.height));
            preview.addChild(view);
            view.removeFromParent();
            preview.addChild(view);
            view.setVisibleRect(cc.rect(0, 0, view.width, view.height));
        });
        preview.setContentSize(totalWidth, totalHeight);

        return preview;
    },

    tileGame: function (adminLevel) {
        var styles = cleverapps.styles.AdminLevelMaxiature.tiles;
        var wrapper;
        var mainScreen;
        var subScreensLayout;
        var selected = 0;
        var orientation = cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? TileTable.ORIENTATION_HORIZONTAL : TileTable.ORIENTATION_VERTICAL;
        var changeOrientationButton;
        var wrap = function (content) {
            wrapper = new cc.Node();
            wrapper.setContentSize2(styles);
            wrapper.addChild(content);
            content.setPositionRound(wrapper.width / 2, wrapper.height / 2);
            cleverapps.UI.fitToBox(content, wrapper.getContentSize());
            if (cleverapps.config.type === "solitaire") {
                changeOrientationButton = createChangeOrientationButton();
                changeOrientationButton.setPositionRound(styles.button);
                wrapper.addChild(changeOrientationButton);
            }
            return wrapper;
        };

        var toMiniature = function (screen, orientation) {
            var nextScreenLevel = AdminLevel.createFromData({}, adminLevel.getContent());
            nextScreenLevel.screens = [screen];
            return AdminLevelMiniature.tileGame(nextScreenLevel, orientation);
        };

        var createMainScreen = function (screen, orientation) {
            mainScreen = toMiniature(screen, orientation);
            mainScreen.setAnchorPoint(0.5, 0.5);
            cleverapps.UI.fitToBox(mainScreen, styles.mainScreen);
        };

        var createChangeOrientationButton = function () {
            var styles = cleverapps.styles.AdminDetailControls;

            var icon = new cc.Sprite(bundles.admin.frames.change_orientation);
            var button = new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                content: icon,
                width: styles.width,
                height: styles.height,
                onClicked: function () {
                    orientation ^= 1;
                    changeMainScreen(adminLevel.screens[selected], orientation);
                }
            });
            button.setVisible(adminLevel.screens[0].horizontalCards && adminLevel.screens[0].cards);

            return button;
        };

        var changeMainScreen = function (screen, horizontal) {
            changeOrientationButton && changeOrientationButton.setVisible(screen.horizontalCards && screen.cards);
            var oldScreen = mainScreen;
            createMainScreen(screen, horizontal);
            var parentLayout = oldScreen.parent;
            oldScreen.removeFromParent();
            var items = [mainScreen].concat(parentLayout.children);
            parentLayout.setItems(items, false);
            cleverapps.UI.fitToBox(parentLayout, styles);
        };

        if (adminLevel.screens.length > 1) {
            var select = function (newSelected) {
                if (newSelected === selected) {
                    return;
                }
                small.forEach(function (tileWithCaption, index) {
                    var sprite = new cc.Sprite(index === newSelected ? bundles.admin.frames.lvl_selected_bg : bundles.admin.frames.lvl_bg);
                    tileWithCaption.tile.setSpriteFrame(sprite.getSpriteFrame());
                });
                changeMainScreen(adminLevel.screens[newSelected]);
                selected = newSelected;
            };

            var subScreens = [];
            adminLevel.screens.forEach(function (screen) {
                subScreens.push(toMiniature(screen));
            });
            var small = [];
            for (var i = 0; i < subScreens.length; i++) {
                var bgImage = i === 0 ? bundles.admin.frames.lvl_selected_bg : bundles.admin.frames.lvl_bg;
                var bg = cleverapps.UI.createScale9Sprite(bgImage, cleverapps.UI.Scale9Rect.TwoPixelXY);
                bg.setContentSize2(styles.subScreens.bg);

                var text = cleverapps.UI.generateImageText(i + 1, cleverapps.styles.FONTS.ADMIN_LEVEL_TEXT);
                text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

                cleverapps.UI.fitToBox(subScreens[i], styles.subScreens);
                subScreens[i].setPositionRound(styles.subScreens);
                bg.addChild(subScreens[i]);

                var tileWithCaption = new cleverapps.Layout([bg, text], {
                    direction: cleverapps.UI.VERTICAL,
                    margin: styles.margins.vertical
                });
                tileWithCaption.tile = bg;

                small.push(tileWithCaption);

                cleverapps.UI.onClick(tileWithCaption, select.bind(this, i));
                cleverapps.UI.applyHover(tileWithCaption);

                subScreensLayout = new cleverapps.Layout(small, {
                    direction: cleverapps.UI.HORIZONTAL,
                    margin: styles.margins.horizontal
                });
            }
        }

        createMainScreen(adminLevel.screens[selected]);

        var layout = new cleverapps.Layout([mainScreen, subScreensLayout], {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.AdminLevelMaxiature.tiles.margins.vertical
        });

        return wrap(layout);
    },

    scramble: function (adminLevel) {
        var words = adminLevel.words;

        var game = new FakeGame(adminLevel);
        var styling = GameSceneBase.ChooseStyling(game.level);

        var mapWord = function (word, index) {
            var layout = new cleverapps.Layout(word.split("").map(function (symbol) {
                var bg = new cc.Sprite(styling.bgImage);
                var char = CharView.Create(symbol, { noBg: true });
                char.setPositionRound(bg.width / 2, bg.height / 2);
                bg.addChild(char);
                return bg;
            }), {
                direction: cleverapps.UI.HORIZONTAL,
                margin: cleverapps.styles.AdminLevelMaxiature.scramble.margin.x
            });

            layout.setLocalZOrder(-index);

            return layout;
        };

        var box = new cc.Node();
        if (adminLevel.hint) {
            var hint = cleverapps.UI.generateImageText(adminLevel.hint, cleverapps.styles.FONTS.MAXIATURE_LABEL);
            box.addChild(hint);
            box.setContentSize2(hint.width, hint.height * 1.5);
            box.setAnchorPoint(0.5, 0.5);
            hint.setPositionRound({ align: "center" }, { align: "top" });
        }

        return new cleverapps.Layout([box].concat(words.map(mapWord)), {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.AdminLevelMaxiature.scramble.margin.y
        });
    },

    differences: function (adminLevel, options) {
        var placeholder = new cc.Node();
        placeholder.setContentSize2(cleverapps.styles.AdminLevelMaxiature.bg);
        placeholder.setAnchorPoint(0.5, 0.5);

        var loading = new cc.Sprite(bundles.admin.frames.loading_png);
        placeholder.addChild(loading);
        loading.setPositionRound(placeholder.width / 2, placeholder.height / 2);

        var game = new FakeGame(adminLevel);
        var name = game.level.getImageBundle();
        if (bundles[name]) {
            cleverapps.bundleLoader.loadBundle(name, {
                onSuccess: function () {
                    var images = [new cc.Sprite(bundles[name].urls.a), new cc.Sprite(bundles[name].urls.b)];

                    adminLevel.differences.forEach(function (area, id) {
                        images.forEach(function (image) {
                            var frame = cleverapps.UI.createScale9Sprite(bundles.admin.frames.area_selection, cleverapps.UI.Scale9Rect.TwoPixelXY);
                            image.addChild(frame);

                            var frameInfo = PhotoViews.FrameSizeAndPos(new DifferenceArea(area));

                            frame.setContentSize(frameInfo);
                            frame.setPositionRound(frameInfo);

                            var bg = new cc.Sprite(bundles.admin.frames.number_bg);
                            bg.setPositionRound(frame.x - frame.width / 2, frame.y + frame.height / 2);
                            $(bg).styles(cleverapps.styles.AdminLevelMaxiature.label);
                            frame.parent.addChild(bg);

                            var label = cleverapps.UI.generateImageText((id + 1) + "", cleverapps.styles.FONTS.MAXIATURE_LABEL);
                            label.setPositionRound(bg.width / 2, bg.height / 2);
                            bg.addChild(label);
                        });
                    });

                    var layout = new cleverapps.Layout(images, {
                        direction: cleverapps.UI.VERTICAL,
                        reversed: true,
                        margin: cleverapps.styles.AdminLevelMaxiature.tiles.margins.vertical
                    });

                    if (!options.enlarge) {
                        cleverapps.UI.fitToBox(layout, cleverapps.styles.AdminLevelMaxiature.bg);
                    }
                    placeholder.addChild(layout);
                    layout.setPositionRound(placeholder.width / 2, placeholder.height / 2);

                    loading.removeFromParent();
                }
            });
        }

        return placeholder;
    },

    battlefield: function (adminLevel) {
        return AdminLevelMiniature.battlefield(adminLevel);
    },

    board: function (adminLevel) {
        var words = "Base: " + adminLevel.base + "\n\n";

        words += adminLevel.words.map(function (word, id) {
            var text = (id + 1) + ". " + word;
            if (adminLevel.definitions) {
                text += " - " + adminLevel.definitions[word];
            }
            return text;
        }).join("\n");

        return cleverapps.UI.generateOnlyText(words, cleverapps.styles.FONTS.ADMIN_DATA_TEXT);
        // var game = new FakeGame(adminLevel);
        // var boardView = new cleverapps.BoardView(game.board);
        //
        // return boardView;
    },

    match3: function (adminLevel) {
        var styles = cleverapps.styles.AdminLevelMaxiature;

        var view = AdminLevelMiniature.match3(adminLevel);

        var goalsView = new GoalsView(Game.currentGame, view);

        goalsView.rotation = 0;
        goalsView.views.forEach(function (goalView) {
            goalView.rotation = 0;
        });

        var movesView = new MovesView(Game.currentGame, goalsView);
        cleverapps.UI.wrap(movesView);

        var maxWidth = movesView.width + goalsView.width + goalsView.width / 2 + styles.match3.movesView.offsetX;
        if (maxWidth < view.width) {
            maxWidth = view.width;
        }

        var goalsAndMovesBg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        goalsAndMovesBg.setContentSize(maxWidth + styles.match3.bgPadding, goalsView.height + styles.match3.bgPadding);
        goalsAndMovesBg.addChild(goalsView);
        goalsView.setPositionRound(goalsAndMovesBg.width / 2, goalsAndMovesBg.height / 2);
        goalsAndMovesBg.addChild(movesView);
        movesView.setPositionRound(goalsView.x - goalsView.width / 2 - movesView.width / 2 - styles.match3.movesView.offsetX, goalsView.y);

        var fieldBg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        fieldBg.setContentSize(maxWidth + styles.match3.bgPadding, view.height + styles.match3.bgPadding);
        fieldBg.addChild(view);
        view.setPositionRound(fieldBg.width / 2, fieldBg.height / 2);

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.addChild(goalsAndMovesBg);
        content.addChild(fieldBg);
        goalsAndMovesBg.setPosition(fieldBg.x, fieldBg.y + fieldBg.height / 2 + goalsAndMovesBg.height / 2 + styles.match3.margin);

        cleverapps.UI.wrap(content);

        goalsView.updateSize = function () {};
        goalsView.setupChildren = function () {};
        if (goalsView.bgTitle) {
            goalsView.bgTitle.updateSize = function () {};
            goalsView.bgTitle.setupChildren = function () {};
        }
        if (goalsView.background) {
            goalsView.background.updateSize = function () {};
            goalsView.background.setupChildren = function () {};
        }
        movesView.setupChildren = function () {};

        return content;
    },

    blocks: function (adminLevel) {
        var grid = new Grid(adminLevel.content);
        var goals = new Goals(adminLevel);

        var gridView = this.gridView = new GridView(grid);
        gridView.pointHandler.remove();
        var goalsView = new GoalsView(goals);
        goalsView.show(true);

        var gridLayout = this.gridLayout = new cleverapps.Layout([goalsView, gridView], {
            direction: cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.BlocksGameScene.margin
        });

        return cleverapps.UI.wrap(gridLayout);
    }
};

AdminLevelMaxiature.tile3 = AdminLevelMaxiature.solitaire = AdminLevelMaxiature.tileGame;

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MAXIATURE_LABEL: {
        name: "formal",
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    }
});

cleverapps.styles.AdminLevelMaxiature = {
    bg: {
        width: 500,
        height: 670
    },

    scramble: {
        offset: {
            x: { align: "left", dx: 100 },
            y: { align: "top", dy: -100 }
        },
        margin: {
            x: -10,
            y: -30
        }
    },

    tiles: {
        width: 700,
        height: 700,
        margins: {
            vertical: 20,
            horizontal: 26
        },
        mainScreen: {
            width: 600,
            height: 800
        },
        subScreens: {
            width: 150,
            height: 200,
            x: { align: "center" },
            y: { align: "center" },
            bg: {
                width: 160,
                height: 210
            }
        },
        button: {
            x: { align: "right" },
            y: { align: "bottom" }
        }
    },

    label: {
        offsetX: 10,
        offsetY: -10
    },

    match3: {
        bgPadding: 60,
        margin: 20,
        movesView: {
            offsetX: 50
        }
    }
};