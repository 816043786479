/**
 * Created by andrey on 14.07.17.
 */

cleverapps.GameSyncer = {
    addUpdateTask: function () {
        cleverapps.synchronizer.addUpdateTask("games");
    },

    loadInfo: function () {
        var episodeNo, levelNo;

        var level = cleverapps.user.getCurrentLevel();
        episodeNo = level.episodeNo;
        levelNo = level.levelNo;

        var stored = cleverapps.GameSaver.load(cleverapps.GameSaver.getStoreSlot(episodeNo, levelNo));
        if (stored && Object.keys(stored).length > 0) {
            stored = Object.assign(stored, {
                episode: episodeNo || undefined,
                level: levelNo || undefined
            });
        }

        return stored || {};
    },

    saveInfo: function (serverGame) {
        if (serverGame === undefined) {
            return;
        }

        var episode = serverGame.episode || 0;
        var level = serverGame.level || 0;

        delete serverGame.episode;
        delete serverGame.level;

        cleverapps.GameSaver.saveInfo(cleverapps.GameSaver.getStoreSlot(episode, level), serverGame, true);
    }
};

CustomSyncers.extractors.games = function () {
    return cleverapps.GameSyncer.loadInfo();
};

CustomSyncers.importerData.games = function (serverGame) {
    cleverapps.GameSyncer.saveInfo(serverGame);
};
