/**
 * Created by iamso on 16.10.18.
 */

var ShareCheckBox = cleverapps.UI.CheckBox.extend({
    ctor: function (share) {
        this.share = share;

        this._super({
            onChange: this.share.onChange.bind(this.share),
            isSelected: this.share.isSelected(),
            label: {
                text: "Share",
                font: this.share.window.withBg ? cleverapps.styles.FONTS.WINDOW_TEXT : cleverapps.styles.FONTS.WHITE_TEXT,
                clickable: true
            },
            styles: cleverapps.styles.ShareCheckBox
        });

        this.updateState();
        this.setCascadeOpacityEnabledRecursively(true);
    },

    updateState: function () {
        this.setSelected(this.share.isSelected());
    }
});

cleverapps.styles.ShareCheckBox = {
    margin: 15,
    padding: {
        x: 0,
        y: 0
    }
};