/**
 * Created by mac on 7/19/18
 */

// TODO: 1 rename "sweetPack" -> "pack500", "jamPack" -> "pack1800", "tastyPack" -> "pack3800", "honeyPack" -> "pack6000", "epicPack" -> "pack25000"
// 2: simplify createByHardPrice & Kits: Product.TYPE_AUTO
// 3: unitsshop, prolongation => Product
var Product = function (data) {
    // TODO: static properties only eg .title, .description
    // TODO: keep reference to CatalogProduct
    // TODO: connector.payments.getCatalog() returns array of localized products with current platform ids
    Object.assign(this, data);

    if (cleverapps.fallSale && cleverapps.fallSale.isProductForSale(this.itemId)) {
        Object.assign(this, FallSale.PRODUCTS[this.itemId]);
    }

    this.title = Messages.getLocalized(this.title);
    this.description = Messages.getLocalized(this.description);

    if (this.type === undefined) {
        this.type = Product.TYPE_NATIVE;

        if (cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_ADS) {
            if (this.isRealProductForAds()) {
                this.type = Product.TYPE_ADS;

                this.prepareVideoProduct();
            }
        }
    }

    if (this.currencyImage) {
        Product.RegisterCurrencyImage(this.currency, this.currencyImage);
    }
};

Product.TYPE_NATIVE = 0;
Product.TYPE_HARD = 1;
Product.TYPE_SOFT = 2;
Product.TYPE_ADS = 3;
Product.TYPE_FREE = 4;

Product.prototype.isRealProductForAds = function () {
    return ["gold500", "gold1800", "gold3800", "gold6000", "gold25000", "sweetPack", "jamPack", "tastyPack", "honeyPack", "epicPack"].indexOf(this.itemId) !== -1;
};

Product.prototype.isNativeProduct = function () {
    return this.type === Product.TYPE_NATIVE;
};

Product.prototype.isSubscription = function () {
    return this.catalogProductType === "subscription";
};

Product.FormatTimePeriod = function (period, fullText) {
    if (typeof period === "object") {
        return period;
    }

    if (typeof period === "string") {
        period = cleverapps.parseInterval(period);
    }

    var secondsInHour = 60 * 60;
    var secondsInDay = secondsInHour * 24;

    var seconds = period / 1000;
    var minutes, days;

    if (fullText) {
        var codes = {
            days: {
                1: "1 day",
                2: "2 day",
                3: "3 day",
                5: "5 day",
                7: "7 day",
                9: "9 day",
                10: "10 day"
            },

            minutes: {
                15: "15 minutes",
                10: "10 minutes",
                30: "30 minutes",
                45: "45 minutes",
                60: "OneHour",
                120: "TwoHours",
                180: "ThreeHours",
                360: "6 hours",
                720: "12 hours"
            }
        };

        minutes = Math.round(seconds / 60);
        days = Math.floor(seconds / secondsInDay);

        return {
            title: "TimePeriod." + (codes.days[days] || codes.minutes[minutes]),
            time: period
        };
    }

    days = Math.floor(seconds / secondsInDay);
    seconds %= secondsInDay;

    var hours = Math.floor(seconds / secondsInHour);
    seconds %= secondsInHour;

    minutes = Math.round(seconds / 60);

    var toReplace = {
        day: days,
        hr: hours,
        min: minutes
    };

    var code = "minutesString";
    if (days > 0 && hours > 0) {
        code = "dayTimeString";
    } else if (days > 0) {
        code = "daysString";
    } else if (hours > 0 && minutes > 0) {
        code = "hoursMinutesString";
    } else if (hours > 0) {
        code = "hoursString";
    }

    return {
        title: Messages.get(code, toReplace),
        time: period
    };
};

Product.UnlimitedToHours = function (data) {
    if (data.unlimitedLives) {
        var hours = Math.round(data.unlimitedLives / 1000 / 60 / 60);
        data.unlimitedLives = {
            title: Messages.get("AMOUNT_HOURS", { amount: hours }),
            interval: hours + " hours"
        };
    }
    return data;
};

Product.prototype.getIcon = function () {
    return cleverapps.skins.getSlot(this.icon) || bundles.tile_shop.frames[this.icon] || this.icon;
};

Product.prototype.prepareVideoProduct = function () {
    var coef = 1;

    if (this.reward) {
        coef = 1 / 4;
        ["hard", "soft", "energy", "wands"].forEach(function (key) {
            if (this.reward[key]) {
                this.reward[key] = Math.ceil(this.reward[key] * coef / 10) * 10;
            }
        }, this);

        if (this.reward.boosters) {
            Object.keys(this.reward.boosters).forEach(function (key) {
                this.reward.boosters[key] = Math.ceil(this.reward.boosters[key] * coef);
            }, this);
        }
    }

    this.price = Math.ceil(this.priceUSD * coef * 2) * 5;
};

Product.prototype.getCurrentPrice = function () {
    var price = this.textPrice;
    return Product.ReplacePriceCurrencyIcon(price);
};

Product.prototype.getUsdPrice = function () {
    return this.priceUSD;
};

Product.prototype.showBoughtMessage = function (reward) {
    if (!this.image) {
        return;
    }

    // TODO: remove custom params to Product constructor options
    var message = this.boughtMessage || "Product.YouBought";
    var toReplace = this.boughtMessageParams || {};
    toReplace.moves = this.moves;

    if (reward && (reward.hard || reward.soft)) {
        toReplace.coins = reward.hard || reward.soft;
    }

    var imageUrl = connector.platform.getExternalUrl(this.image);
    cc.loader.load(imageUrl, function (status) {
        var image = undefined;
        if (!status) {
            image = new cc.Sprite(imageUrl);
        }
        cleverapps.notification.create(message, {
            toReplace: toReplace,
            image: image
        });
    });
};

Product.prototype.logBuyItem = function () {
    var window = cleverapps.windows.findWindow(ShopWindow);

    var properties = {
        store_type: window && window.getTitle(),
        item_name: this.constructor === Product ? this.title : undefined,
        item_price: this.price,
        cost_type: this.currency
    };

    var rewardId = Object.keys(this.reward || {})[0];
    if (rewardId) {
        properties.item_id = rewardId;
        properties.item_num = this.reward[rewardId];
    }

    if (this.itemId.startsWith("buyEnergy")) {
        properties.item_id = "energy";
        properties.item_num = this.reward;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.BUY_ITEM, properties);
};

Product.prototype.buy = function (f, options) {
    cleverapps.paymentsLogic.purchase(this, f, options);
};

Product.CreateById = function (id, context) {
    // TODO: move context to connector.payments.restoreProduct native method?
    // TODO: static products, do not modify, no need .clone
    var catalogProduct = connector.payments.getProduct(id);

    if (Products[id] && catalogProduct) {
        catalogProduct.catalogProductType = catalogProduct.type;
        delete catalogProduct.type;

        return new Product(Object.assign(
            catalogProduct,
            cleverapps.clone(Products[id], true),
            { context: context }
        ));
    }

    // TODO: homogeneous Products & Virtual products, convert one to vise versa (eg Kits, VideoProducts)
    if (VirtualProducts[id]) {
        return new VirtualProduct(Object.assign(
            cleverapps.clone(VirtualProducts[id], true),
            { context: context }
        ));
    }
};

Product.CreateByHardPrice = function (hardPrice, context, productIds) {
    productIds = productIds || ["kit1", "kit3", "kit10", "kit50"];
    if (cleverapps.flags.monetization !== cleverapps.Flags.MONETIZATION_REGULAR) {
        productIds = [];
    }

    var products = productIds.map(function (id) {
        return Product.CreateById(id, context);
    }).filter(function (product) {
        return product && product.getUsdPrice();
    });

    var closestProduct = undefined;
    var closestDiff = undefined;
    var usdPrice = hardPrice * Product.HARD_TO_USD;
    for (var i = 0; i < products.length; ++i) {
        var product = products[i];
        var diff = Math.abs(1 - usdPrice / product.getUsdPrice());

        if (diff <= Product.USD_EXCHANGE_THRESHOLD && (!closestProduct || diff < closestDiff)) {
            closestProduct = product;
            closestDiff = diff;
        }
    }

    if (closestProduct) {
        closestProduct.event = context.type;
    }

    return closestProduct || new VirtualProduct({
        currency: "hard",
        price: hardPrice,
        type: Product.TYPE_HARD,
        context: context
    });
};

Product.RegisterCurrencyImage = function (currency, image) {
    var replacer = "${" + currency + "}";

    if (Product.CURRENCY_ICONS[replacer]) {
        return;
    }

    Product.CURRENCY_ICONS[replacer] = {
        currency: currency,
        url: image,
        size: 50
    };

    if (typeof image === "string") {
        cc.loader.load(image);
    }
};

Product.ReplacePriceCurrencyIcon = function (price) {
    Object.keys(Product.CURRENCY_ICONS).forEach(function (replacer) {
        var currency = Product.CURRENCY_ICONS[replacer].currency;

        price = price.replace(" " + currency, replacer);
    });

    return price;
};

Product.USD_EXCHANGE_THRESHOLD = 0.21;

Product.HARD_TO_USD = 0.01;
if (["match3", "solitaire"].indexOf(cleverapps.config.type) !== -1 || cleverapps.config.name === "scramble") {
    Product.HARD_TO_USD = 0.005;
}

Product.WAIT_NO_MORE = 4000;

Product.CURRENCY_ICONS = {
    "${OK}": {
        currency: "OK",
        url: bundles.buttons_inlined_icons.frames.ok_icon_png
    },
    "${KG}": {
        currency: "KG",
        url: bundles.buttons_inlined_icons.frames.kred_icon_png
    }
};