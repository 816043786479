/**
 * Created by Andrey Popov on 21.01.2021.
 */

var WysiwygMenubarView = cc.Node.extend({
    ctor: function (wysiwygMenubar) {
        this._super();

        this.wysiwygMenubar = wysiwygMenubar;

        var styles = cleverapps.styles.WysiwygMenubarView;

        this.setContentSize2(cleverapps.resolution.getSceneSize().width, styles.height);
        this.setPositionRound(styles);
        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(1);

        this.wysiwygMenubar.on("updateTool", this.updateTool.bind(this), this);
        this.wysiwygMenubar.on("updateStatusText", this.updateStatusText.bind(this), this);

        this.statusText = cleverapps.UI.generateOnlyText("", cleverapps.styles.FONTS.WYSIWYG_STATUS_TEXT);
        this.statusText.setAnchorPoint(0.5, 0.5);
        this.statusText.setPositionRound(styles.statusText);
        this.addChild(this.statusText);

        if (cleverapps.git) {
            var gitButtons = new GitButtonsView();
            gitButtons.setPositionRound(styles.gitButtons);
            this.addChild(gitButtons);
        }

        this.showTools(WysiwygMenubar.TOOLS);

        cc.eventManager.addListener({
            event: cc.EventListener.KEYBOARD,

            onKeyPressed: function (key) {
                if (key === cc.KEY.shift) {
                    cleverapps.wysiwyg.hierarchy.toggleMultiSelection();
                }

                WysiwygMenubar.TOOLS.forEach(function (tool) {
                    if (this.toolsByCaption[tool.caption] && !this.toolsByCaption[tool.caption].disabled
                        && key === cc.KEY[tool.key] && cleverapps.keyboardController.isPressed(cc.KEY.ctrl)) {
                        tool.processor.apply(this.wysiwygMenubar);
                    }
                }.bind(this));
            }.bind(this),

            onKeyReleased: function (key) {
                if (key === cc.KEY.shift) {
                    cleverapps.wysiwyg.hierarchy.toggleMultiSelection();
                }
            }
        }, this);
    },

    updateTool: function (caption, visible) {
        if (visible) {
            this.toolsByCaption[caption].enable();
        } else {
            this.toolsByCaption[caption].disable();
        }

        if (caption === "Make ready") {
            this.updateReadyDirectoryDropdown(this.toolsByCaption[caption], visible);
        }
    },

    updateStatusText: function (text) {
        this.statusText.setString(text);
    },

    updateReadyDirectoryDropdown: function (tool, visible) {
        if (this.readyDirectoryDropdown) {
            this.readyDirectoryDropdown.removeFromParent();
            delete this.readyDirectoryDropdown;
        }

        if (visible) {
            var styles = cleverapps.styles.WysiwygMenubarView.readyDirectory;
            var hierarchy = cleverapps.wysiwyg.hierarchy;
            var dropdown = this.readyDirectoryDropdown = new DropDown({
                availableItems: hierarchy.directories.filter(function (item) {
                    return item !== "wysiwyg";
                }).map(function (item) {
                    return item.replace("wysiwyg/", "");
                }),
                value: hierarchy.readyDirectory,
                size: styles.dropdown,
                font: cleverapps.styles.FONTS.READY_DIRECTORY_ITEM_TEXT,
                noExpander: true
            });
            dropdown.setDelegate({
                editBoxEditingDidEnd: function (dropdown) {
                    cleverapps.wysiwyg.hierarchy.readyDirectory = dropdown.value;
                }
            });

            dropdown.setPositionRound(styles);
            tool.addChild(dropdown);
        }
    },

    showTools: function (tools) {
        var styles = cleverapps.styles.WysiwygMenubarView;

        this.toolsByCaption = {};
        var buttons = tools.map(function (tool) {
            var button = new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.wysiwyg,
                text: tool.icon ? "%%" : tool.caption,
                icons: {
                    "%%": tool.icon
                },
                textVariant: "strict",
                onClicked: tool.processor.bind(this.wysiwygMenubar),
                width: tool.icon ? styles.button.width : 3 * styles.button.width,
                height: styles.button.height,
                hint: tool.caption !== "Build" ? tool.caption + " (Ctrl + " + tool.key.toUpperCase() + ")" : undefined,
                hintLocation: cleverapps.UI.Tooltip.LOCATION_BELOW
            });
            this.toolsByCaption[tool.caption] = button;
            return button;
        }.bind(this));

        var leftNumb = 5;
        this.leftButtons = new cleverapps.Layout(buttons.slice(0, leftNumb), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        this.leftButtons.setPositionRound(styles.leftButtons);
        this.addChild(this.leftButtons);

        this.centerButtons = new cleverapps.Layout(buttons.slice(leftNumb), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        this.centerButtons.setPositionRound(styles.centerButtons);
        this.addChild(this.centerButtons);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    WYSIWYG_STATUS_TEXT: {
        size: 14,
        color: cleverapps.styles.COLORS.COLOR_BLUE,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    READY_DIRECTORY_ITEM_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.COLOR_BLUE,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});

cleverapps.styles.WysiwygMenubarView = {
    x: { align: "left" },
    y: { align: "top", dy: -4 },

    height: 74,

    button: {
        width: 60,
        height: 60,
        icon: {
            width: 40,
            height: 40
        }
    },

    leftButtons: {
        x: { align: "left", dx: 10 },
        y: { align: "center", dy: 4 }
    },

    centerButtons: {
        x: { align: "center", dx: -50 },
        y: { align: "center", dy: 4 }
    },

    statusText: {
        x: { align: "center" },
        y: { align: "bottom", dy: -5 }
    },

    readyDirectory: {
        x: { align: "right", dx: 284 },
        y: { align: "center", dy: -4 },

        dropdown: {
            width: 280,
            height: 32
        }
    },

    gitButtons: {
        x: { align: "center", dx: 400 },
        y: { align: "center", dy: 4 }
    },

    margin: 10
};
