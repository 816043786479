/**
 * Created by evgenijsenkevic on 28.08.2024
 * */

var AdminLocation = function (locationId) {
    cleverapps.EventEmitter.call(this);

    this.locationId = locationId;
};

AdminLocation.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminLocation.prototype.constructor = AdminLocation;

AdminLocation.prototype.getBundleName = function () {
    return "location_" + this.locationId;
};

AdminLocation.prototype.getLocationId = function () {
    return this.locationId;
};

AdminLocation.prototype.getContent = function () {
    return bundles[this.getBundleName()].jsons.map_json.getJson();
};

AdminLocation.prototype.onClick = function (f) {
    cleverapps.administrator.adminLocations.setActive(this);
    f();
};

AdminLocation.prototype.select = function () {
    this.trigger("select", this);
};

AdminLocation.prototype.unselect = function () {
    this.trigger("unselect");
};

AdminLocation.ListLocationBundles = function (locationId) {
    var locationBundleName = "location_" + locationId;

    return [
        locationBundleName,
        Map2d.getTilesTexture(bundles[locationBundleName].meta.tiles || locationId),
        Map2d.getUnitsTexture(bundles[locationBundleName].meta.units || locationId),
        "quests_" + (bundles[locationBundleName].meta.units || locationId),
        "unitstories_" + locationId
    ].filter(function (bundleName) {
        return bundles[bundleName];
    });
};
