/**
 * Created by olga on 24.01.2024
 */

var ChainSaleWindow = CleverappsWindow.extend({
    onWindowLoaded: function (offer, silent) {
        this.offer = offer;
        this.logic = offer.logic;

        this.wideMode = cleverapps.resolution.mode;

        this._super({
            title: "ChainSaleWindow.title",
            name: "ChainSaleWindow",
            noBackground: true,
            notCloseByTouchInShadow: true,
            content: this.createContent(silent),
            openSound: !silent ? bundles.chain_sale.urls.chain_sale_window_effect : undefined
        });

        offer.logic.on("changeStage", this.onChangeStage.bind(this), this.window);
        offer.logic.needWait = this.createListener(this.needWait.bind(this));
    },

    setupChildren: function () {
        this._super();

        if (this.wideMode !== cleverapps.resolution.mode) {
            this.wideMode = cleverapps.resolution.mode;
            this.window.removeFromParent(true);
            this.windowTitle.removeFromParent(true);
            this.closeButton.removeFromParent(true);
            this.onWindowLoaded(this.offer, true);
            this.windowTitle.show();
        }
    },

    isVertical: function () {
        return this.wideMode === cleverapps.WideMode.VERTICAL;
    },

    createContent: function (silent) {
        var styles = cleverapps.styles.ChainSaleWindow;
        var tilesNode = this.createTiles();
        var timer = this.timer = this.createTimer();
        var footnote = this.footnote = cleverapps.UI.generateTTFText("ChainSaleWindow.Footnote", cleverapps.styles.FONTS.CHAIN_SALE_FOOTNOTE_TEXT);
        footnote.setDimensions(styles.footnote.size[this.wideMode].width, 0);
        footnote.fitTo(undefined, styles.footnote.size[this.wideMode].height);
        footnote.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.content = new cleverapps.Layout([tilesNode, footnote, timer], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin[this.wideMode]
        });

        this.createBg(silent);
        return this.content;
    },

    onChangeStage: function () {
        if (!this.isVisible()) {
            this.showSilent = true;
        }

        if (this.logic.isAllSold()) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(1.5),
                new cc.CallFunc(function () {
                    this.receivingRewards = false;
                    if (!this.closed) {
                        this.close();
                    }
                }.bind(this))
            ));
            return;
        }

        var lots = this.logic.currentLots();
        var newLot = lots[ChainSaleLogic.VISIBLE_LOTS - 1];

        var collectedTile = this.tiles[0];
        this.tiles = this.tiles.slice(1);
        collectedTile.hideAnimation();

        var slots = this.getTileSlots(lots.length);
        if (newLot) {
            var newTile = this.createTile(newLot);
            newTile.setPosition(slots[ChainSaleLogic.VISIBLE_LOTS - 1]);
            this.tilesNode.addChild(newTile);
            this.tiles.push(newTile);
            newTile.addInAnimation();
        }

        this.receivingRewards = true;
        this.tiles.forEach(function (tile, index) {
            if (tile === newTile) {
                return;
            }
            var finishPosition = slots[index];
            tile.runAction(new cc.Sequence(
                new cc.DelayTime(1.44),
                new cc.MoveTo(0.2, finishPosition),
                new cc.CallFunc(function () {
                    if (index === 0) {
                        tile.open(false, function () {
                            this.receivingRewards = false;
                        }.bind(this));
                    }
                }.bind(this))
            ));
        }.bind(this));
    },

    needWait: function () {
        return !this.closed;
    },

    getTileSlots: function (length) {
        var slots = [];
        var styles = cleverapps.styles.ChainSaleWindow.tile;
        var x, y;
        var width = styles.size[this.wideMode].width;
        var height = styles.size[this.wideMode].height;
        var margin = styles.margin[this.wideMode];
        if (this.isVertical()) {
            x = width / 2;
            y = this.tilesNode.height - ((this.tilesNode.height - (height + margin) * length - margin) / 2) - height / 2 - margin;
            for (var i = 0; i < length; i++) {
                slots.push(cc.p(x, y));
                y -= height + margin;
            }
        } else {
            x = width / 2 + (this.tilesNode.width - (width + margin) * length - margin) / 2 + margin;
            y = height / 2;
            for (var j = 0; j < length; j++) {
                slots.push(cc.p(x, y));
                x += width + margin;
            }
        }

        return slots;
    },

    createTiles: function () {
        var tilesAmount = 3;
        var styles = cleverapps.styles.ChainSaleWindow.tile;
        var tilesNode = this.tilesNode = new cc.Node();
        tilesNode.setAnchorPoint(0.5, 0.5);

        var width = styles.size[this.wideMode].width;
        var height = styles.size[this.wideMode].height;
        var margin = styles.margin[this.wideMode];
       
        if (this.isVertical()) {
            tilesNode.setContentSize(width, height * tilesAmount + margin * tilesAmount);
        } else {
            tilesNode.setContentSize(width * tilesAmount + margin * tilesAmount, height);
        }

        this.tiles = [];
        var lots = this.logic.currentLots();
        var slots = this.getTileSlots(lots.length);
        for (var i = 0; i < lots.length; i++) {
            var view = this.createTile(lots[i]);
            view.setPositionRound(slots[i]);
            this.tiles.push(view);
            this.tilesNode.addChild(view);
        }
        return tilesNode;
    },

    createTile: function (stage) {
        return new ChainSaleLot({ stage: stage, logic: this.logic, window: this });
    },

    createTimer: function () {
        var styles = cleverapps.styles.ChainSaleWindow.timer;

        var finishTimerText = cleverapps.UI.generateTTFText("ChainSaleWindow.FinishTimerText", cleverapps.styles.FONTS.CHAIN_SALE_FOOTNOTE_TEXT);
        finishTimerText.setVisible(false);

        var timeLeft = this.logic.getTimeLeft();
        if (timeLeft > 0) {
            timeLeft = new cleverapps.CountDown(timeLeft, {
                onFinish: this.createListener(function () {
                    finishTimerText.setVisible(true);
                    this.countdown.setVisible(false);
                }.bind(this))
            });
        }

        var countdown = this.countdown = new cleverapps.CountDownView(timeLeft, {
            timerPosition: styles.text,
            font: cleverapps.styles.FONTS.CHAIN_SALE_TIMER_TEXT,
            icon: bundles.big_timer.frames.big_timer_png,
            background: {
                frame: bundles.big_timer.frames.big_timer_bg_png,
                width: styles.width,
                height: styles.height
            }
        });

        var wrapper = new cc.Node();
        wrapper.setContentSize2(countdown.getContentSize());
        wrapper.setAnchorPoint(0.5, 0.5);
        wrapper.addChild(countdown);
        countdown.setPositionRound(wrapper.width / 2, wrapper.height / 2);
        wrapper.addChild(finishTimerText);
        finishTimerText.setPositionRound(wrapper.width / 2, wrapper.height / 2);
        wrapper.setPositionRound(styles);

        return wrapper;
    },

    createBg: function (silent) {
        var styles = cleverapps.styles.ChainSaleWindow.bg;
        var bg = this.bg = new cleverapps.Spine(bundles.chain_sale.jsons.chain_sale_bg_json);
        if (this.isVertical() && bg.hasAnimation("idle_vert")) {
            bg.setAnimationAndIdleAfter(silent ? "idle_vert" : "open_vert", "idle_vert");
        } else {
            bg.setAnimationAndIdleAfter(silent ? "idle" : "open", "idle");
        }
        bg.setPositionRound(styles.positions[this.wideMode]);
        bg.setLocalZOrder(-1);
        this.content.addChild(bg);
    },

    onShow: function () {
        if (!this.showSilent) {
            for (var index = this.tiles.length - 1; index >= 0; index--) {
                var delay = 0.65 + 0.2 * index * 0.5;
                this.tiles[index].appearAnimation(delay);
            }

            [this.timer, this.footnote].forEach(function (item) {
                item.setOpacity(0);
                item.setVisible(false);
                item.runAction(new cc.Sequence(
                    new cc.DelayTime(0.95),
                    new cc.Show(),
                    new cc.FadeIn(0.15)
                ));
            });
        }

        this._super();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(this.logic.onWindowShowUpFinished.bind(this.logic))
        ));
    },

    beforeCloseAnimation: function (callback) {
        if (this.closeButton instanceof BandButton) {
            this.closeButton.hide();
        }

        [this.timer, this.footnote].forEach(function (item) {
            item.runAction(new cc.FadeOut(0.15));
        });

        var step = 0.075;
        callback = cleverapps.wait(this.tiles.length, callback);
        for (var index = this.tiles.length - 1; index >= 0; index--) {
            this.tiles[index].runAction(new cc.Sequence(
                new cc.DelayTime(step * index * 0.5),
                new cc.Spawn(
                    new cc.ScaleTo(0.3, 0).easing(cc.easeBackIn()),
                    new cc.Sequence(
                        new cc.DelayTime(0.2),
                        new cc.FadeTo(0.1, 0.3)
                    )
                ),
                new cc.CallFunc(callback)
            ));
        }
    },

    listBundles: function () {
        return ["chain_sale"];
    },

    close: function () {
        if (this.receivingRewards) {
            return;
        }

        this._super();
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CHAIN_SALE_FOOTNOTE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },
    CHAIN_SALE_TIMER_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.ChainSaleWindow = {
    margin: [30, 20, 20],
    timer: {
        text: {
            x: { align: "center", dx: -3 },
            y: { align: "center", dy: 2 }
        },
        width: 270,
        height: 67,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -122 }
    },
    bg: {
        positions: [
            { x: { align: "center", dx: 0 }, y: { align: "bottom", dy: -90 } },
            { x: { align: "center", dx: 0 }, y: { align: "center", dy: 0 } },
            { x: { align: "center", dx: 0 }, y: { align: "center", dy: 0 } }
        ]
       
    },

    tile: {
        size: [
            { width: 900, height: 450 },
            { width: 480, height: 730 },
            { width: 480, height: 730 }
        ],
        margin: [25, 10, 10]
    },

    footnote: {
        size: [
            { width: 900, height: 60 },
            { width: 1400, height: 60 },
            { width: 1400, height: 60 }
        ]
    }

};
