/**
 * Created by iamso on 06.08.19.
 */

var TreasureSearch = function (mission) {
    MissionGame.call(this, mission);
    this.usedCurrency = 0;
    this.type = cleverapps.config.ui === "tropical" ? TreasureSearch.TYPE_FISHING : TreasureSearch.TYPE_GARDEN;

    var fieldSize = TreasureSearch.FIELD_SIZE_BY_STAGES[this.getStage()];

    this.seed = mission.details && mission.details.seed;
    this.opened = {};

    if (mission.details) {
        cleverapps.fromBit(mission.details.opened).forEach(function (cellId) {
            var row = Math.floor(cellId / fieldSize.height);
            var col = cellId % fieldSize.width;
            this.opened[col + "_" + row] = cellId;
        }, this);
    }

    if (this.seed === undefined) {
        this.seed = Date.now();
        this.opened = {};
    }

    this.createField(fieldSize);

    cleverapps.adsLimits.on("update", this.onUpdateAds.bind(this), this);
};

TreasureSearch.prototype = Object.create(MissionGame.prototype);
TreasureSearch.prototype.constructor = TreasureSearch;

TreasureSearch.prototype.getInfo = function () {
    var cells = Object.keys(this.opened);

    if (cells.length === 0) {
        return {};
    }

    var opened = [];

    cells.forEach(function (cell) {
        opened.push(this.opened[cell]);
    }, this);

    return {
        seed: this.seed,
        opened: cleverapps.toBit(opened)
    };
};

TreasureSearch.prototype.createField = function (fieldSize) {
    var items = TreasureSearch.ITEMS_BY_STAGE[this.getStage()];
    var shapes = Object.keys(items).map(function (type) {
        var shape = [];
        var size = items[type].split("x");
        for (var row = 0; row < size[0]; row++) {
            shape.push([]);
            for (var col = 0; col < size[1]; col++) {
                shape[row].push(type);
            }
        }
        return shape;
    }).sort(function (a, b) {
        return Array.prototype.concat.apply([], b).length - Array.prototype.concat.apply([], a).length;
    });

    var placedShapes = 0;
    var seed = this.seed;
    var attempt = 0;

    while (placedShapes !== shapes.length) {
        var field = [];
        var shapeRotation = {};
        var goals = {};

        var cells = [];

        placedShapes = 0;

        for (var h = 0; h < fieldSize.height; h++) {
            field[h] = [];
            for (var w = 0; w < fieldSize.width; w++) {
                field[h][w] = TreasureSearch.CELL_EMPTY;

                cells.push({ row: h, col: w });
            }
        }

        cells = cleverapps.Random.shuffle(cells, seed);

        shapes.forEach(function (shape, index) {
            var code = shape[0][0];
            var rotation = cleverapps.Random.choose([0, -90, 90], seed + index);

            if (rotation !== 0) {
                // 90 degrees clockwise rotation
                shape = shape[0].map(function (val, index) {
                    return shape.map((function (row) {
                        return row[index];
                    })).reverse();
                });
                shapeRotation[code] = rotation;
            }

            for (var i = 0; i < cells.length; i++) {
                var head = cells[i];

                if (field[head.row][head.col] !== TreasureSearch.CELL_EMPTY) {
                    continue;
                }

                var freeArea = [];

                for (var row = 0; row < shape.length; row++) {
                    for (var col = 0; col < shape[0].length; col++) {
                        if (field[head.row + row] && field[head.row + row][head.col + col] === TreasureSearch.CELL_EMPTY) {
                            freeArea.push({ row: row, col: col });
                        }
                    }
                }

                if (freeArea.length === shape.length * shape[0].length) {
                    freeArea.forEach(function (place) {
                        var r = head.row + place.row;
                        var c = head.col + place.col;

                        field[r][c] = code;

                        if (!goals[code]) {
                            goals[code] = [];
                        }

                        goals[code].push({ row: r, col: c });
                    }, this);

                    placedShapes++;
                    break;
                }
            }
        }, this);

        seed += 1;

        attempt++;
        if (attempt > 1000) {
            cleverapps.throwAsync("Cant place all shapes after 1000 attempts. Field size: " + fieldSize.width + "x" + fieldSize.height);
            break;
        }
    }

    this.shapeRotation = shapeRotation;
    this.goals = goals;
};

TreasureSearch.prototype.open = function (col, row) {
    if (this.isOpened(col, row)) {
        return;
    }

    if (!this.canTakeCurrency()) {
        cleverapps.centerHint.createTextHint("TreasureSearchWindow.Currency.Text");

        return;
    }

    var fieldSize = TreasureSearch.FIELD_SIZE_BY_STAGES[this.getStage()];
    this.opened[col + "_" + row] = fieldSize.height * row + col;
    this.updateCurrency(-this.mission.getMinigamePrice(), this.getInfo());
    var goalType = this.getGoalType(col, row);
    var isGoalOpened = this.isGoalOpened(goalType);

    Mission.logEvent(cleverapps.EVENTS.MISSION_ROUND, this.mission.getName(), {
        index: ++this.usedCurrency
    });

    this.trigger("open", {
        row: row,
        col: col,
        openedGoal: isGoalOpened && goalType
    });

    if (this.isStageCompleted()) {
        var currentStage = this.getStage();
        var stageReward = this.getStageReward(currentStage);
        var rewardsList = new RewardsList(stageReward, { event: cleverapps.EVENTS.EARN.MISSION });
        rewardsList.receiveRewards();

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.TREASURE_SEARCH_REWARD + currentStage);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.TREASURE_SEARCH_REWARD_COST + currentStage, {
            value: RewardsList.calcCost(rewardsList)
        });

        this.seed = Date.now();
        this.opened = {};
        this.mission.update(1, this.getInfo());

        if (this.isFinished()) {
            this.complete();
            this.finish();
        } else {
            this.createField(TreasureSearch.FIELD_SIZE_BY_STAGES[this.getStage()]);
        }

        this.trigger("completeStage", currentStage, rewardsList);
    }
};

TreasureSearch.prototype.isOpened = function (col, row) {
    return this.opened[col + "_" + row] !== undefined;
};

TreasureSearch.prototype.getGoalType = function (col, row) {
    return Object.keys(this.goals).find(function (type) {
        return this.goals[type].some(function (cell) {
            return col === cell.col && row === cell.row;
        });
    }.bind(this));
};

TreasureSearch.prototype.isGoalOpened = function (goalType) {
    return this.goals[goalType] && this.goals[goalType].every(function (cell) {
        return this.isOpened(cell.col, cell.row);
    }.bind(this));
};

TreasureSearch.prototype.getStage = function () {
    return Math.min(this.mission.result, TreasureSearch.FIELD_SIZE_BY_STAGES.length - 1);
};

TreasureSearch.prototype.getCurrency = function () {
    return this.mission.currency;
};

TreasureSearch.prototype.canTakeCurrency = function () {
    return this.getCurrency() >= this.mission.getMinigamePrice();
};

TreasureSearch.prototype.isStageCompleted = function () {
    var allGoalsFound = true;

    Object.keys(this.goals).forEach(function (type) {
        this.goals[type].forEach(function (goal) {
            if (!this.isOpened(goal.col, goal.row)) {
                allGoalsFound = false;
            }
        }, this);
    }, this);

    return allGoalsFound;
};

TreasureSearch.prototype.isFinished = function () {
    return this.mission.logic.isFinished();
};

TreasureSearch.prototype.complete = function () {
    this.mission.logic.checkComplete();
};

TreasureSearch.prototype.getStageReward = function (stage) {
    return RewardsConfig.TreasureSearch.stages[stage];
};

TreasureSearch.prototype.isAdsReady = function () {
    return cleverapps.adsLimits.state(AdsLimits.TYPES.SHOVEL) === AdsLimits.STATE_READY;
};

TreasureSearch.prototype.onUpdateAds = function () {
    this.trigger("updateAds");
};

TreasureSearch.prototype.watchAd = function () {
    if (this.isAdsReady()) {
        cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.SHOVEL, function () {
            var reward = {
                mission: {
                    missionType: Mission.TYPE_TREASURE_SEARCH,
                    amount: 1
                }
            };

            cleverapps.focusManager.display({
                stack: true,
                focus: "watchAdsTreasureSearch",
                action: function (f) {
                    new RewardWindow(reward);
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });

            cleverapps.adsLimits.watch(AdsLimits.TYPES.SHOVEL);
        });
    }
};

TreasureSearch.prototype.updateCurrency = function (amount, details) {
    this.mission.logic.processEvent({
        amount: amount,
        details: details
    });
};

TreasureSearch.FIELD_SIZE_BY_STAGES = [
    { width: 4, height: 4 },
    { width: 5, height: 5 },
    { width: 5, height: 5 },
    { width: 6, height: 6 },
    { width: 7, height: 7 }
];

TreasureSearch.ITEMS_BY_STAGE = [{
    k: "3x2",
    j: "2x2",
    h: "2x1",
    g: "1x1"
}, {
    k: "1x1",
    j: "2x2",
    h: "2x1",
    g: "1x1"
}, {
    k: "1x1",
    j: "2x2",
    h: "2x1",
    g: "1x1"
}, {
    k: "1x1",
    j: "2x2",
    h: "2x1",
    g: "1x1"
}, {
    k: "1x1",
    j: "1x1",
    h: "2x1",
    g: "1x1"
}];

TreasureSearch.CELL_EMPTY = "e";

TreasureSearch.TYPE_GARDEN = 0;
TreasureSearch.TYPE_FISHING = 1;