/**
 * Created by Vladislav on 24.09.2024.
 */

var hiddenByDefault = function () {
    var scenes = [cleverapps.Environment.SCENE_OCTOPUS_HUNT, cleverapps.Environment.SCENE_WEEKLY_TOURNAMENT];

    if (["match3", "tile3", "battlefield"].includes(cleverapps.config.type)) {
        scenes.push(cleverapps.Environment.SCENE_GAME);
        scenes.push(cleverapps.Environment.SCENE_WYSIWYG);
    }

    return scenes;
};

var GoldMenuBarItem = {
    name: "GoldItem",
    lottery: true,
    isClickable: function () {
        return cleverapps.flags.monetization !== cleverapps.Flags.MONETIZATION_DISABLED;
    },
    clickableUpdater: function (f) {
        cleverapps.flags.on("change:monetization", f, this);
    },
    targets: "hard",
    plusButton: true,
    value: function () {
        return cleverapps.user.gold;
    },
    updater: function (f) {
        cleverapps.user.on("changeHard", f, this);
    },
    attentionUpdater: function (f) {
        cleverapps.adsLimits.on("update", f, this);

        if (cleverapps.subscription) {
            cleverapps.subscription.on("update", f, this);
        }

        if (cleverapps.growthFund) {
            cleverapps.growthFund.on("updateState", f, this);
        }
    },
    sparks: true,
    attention: function () {
        if (ShopProductSource.listProducts(ShopWindow.TABS.HARD_CURRENCY).indexOf("hardForVideo") !== -1
            && cleverapps.adsLimits.state(AdsLimits.TYPES.HARD) === AdsLimits.STATE_READY) {
            return true;
        }
        if (ShopProductSource.listProducts(ShopWindow.TABS.HARD_CURRENCY).indexOf("subsMonth") !== -1
            && cleverapps.subscription && cleverapps.subscription.needsAttention()) {
            return true;
        }
        if (cleverapps.growthFund && cleverapps.growthFund.needsAttention()) {
            return true;
        }
        return false;
    },
    availableOnScenes: function () {
        var scenes = [
            cleverapps.Environment.SCENE_MAIN,
            cleverapps.Environment.SCENE_HEROES,
            cleverapps.Environment.SCENE_DAILY_CUP,
            cleverapps.Environment.SCENE_BONUS_WORLD,
            cleverapps.Environment.SCENE_SLOT_MACHINE,
            cleverapps.Environment.SCENE_PINBALL,
            cleverapps.Environment.SCENE_OCTOPUS_HUNT,
            cleverapps.Environment.SCENE_GAME,
            cleverapps.Environment.SCENE_WEEKLY_TOURNAMENT
        ];
        return scenes;
    },
    hiddenByDefault: hiddenByDefault(),
    icon: bundles.menubar.frames.gold_png,
    onClickWindow: HardCurrencyShopWindow,
    incSound: bundles.menubar.urls.coins_effect,
    decSound: bundles.menubar.urls.coins_effect
};