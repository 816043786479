/**
 * Created by Ivan on 04.02.2025
 */

var ButtonBoosterTutorial = function () {
    return new TutorialScenario("booster", {
        listSteps: ButtonBoosterTutorial.listSteps,
        isAvailable: ButtonBoosterTutorial.chooseAvailableBooster,

        onStart: function () {
            this.booster = ButtonBoosterTutorial.chooseAvailableBooster();
            this.booster.onGetView().switchToFreePrice();
            cleverapps.boosters.add(this.booster.id);
        },
        onFinish: function () {
            this.booster.onGetView().switchToRegularPrice();
        }
    });
};

ButtonBoosterTutorial.listSteps = function () {
    var steps = [ButtonBoosterTutorial.createClickButtonStep()];

    var currentBooster = ButtonBoosterTutorial.chooseAvailableBooster();
    if (ButtonBoosterTutorial.CUSTOM_STEPS[currentBooster.id]) {
        steps = steps.concat(ButtonBoosterTutorial.CUSTOM_STEPS[currentBooster.id]());
    }

    return steps;
};

ButtonBoosterTutorial.chooseAvailableBooster = function () {
    if (!cleverapps.environment.isGameScene()) {
        return false;
    }
    
    return cleverapps.boosters.listBoosters().find(function (booster) {
        return booster.isForceAvailable();
    });
};

ButtonBoosterTutorial.createClickButtonStep = function () {
    var booster = cleverapps.tutorial.getActive().booster;
    var button = booster.onGetView();

    return new TutorialStep({
        onStart: function () {
            button.baseZOrder = button.getLocalZOrder();
            button.setLocalZOrder(0);

            cleverapps.focusManager.showControlsWhileFocused(
                cleverapps.boosters.listBoosters().filter(function (booster) {
                    return booster.isAvailable();
                }).map(function (button) {
                    return button.getControlId();
                })
            );

            new ActionPlayer([
                function (f) {
                    setTimeout(f, 700);
                },

                function (f) {
                    cleverapps.forces.create(button, booster.force, {
                        importantNodes: button.getForceImportantNodes(),
                        highlights: button.getForceHighlights()
                    });
                    cleverapps.forces.onceForceClosed = f;
                }
            ]).play();
           
            cleverapps.tutorial.on("clickBoosterButton", this.finish.bind(this), this);
        },

        onFinish: function () {
            if (button.baseZOrder) {
                button.setLocalZOrder(button.baseZOrder);
            }

            if (cleverapps.forces.isRunningForce(booster.force)) {
                cleverapps.forces.closeRunningForce();
            }

            Game.currentGame.counter.trigger();
        }
    });
};

ButtonBoosterTutorial.CUSTOM_STEPS = {};
ButtonBoosterTutorial.CUSTOM_STEPS[cleverapps.Boosters.TYPE_OPEN_LETTER] = function () {
    var booster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_OPEN_LETTER);
    var button = booster.onGetView();
    return [new TutorialStep({
        onStart: function () {
            var scene = cleverapps.scenes.getRunningScene();
            var movingNode = cleverapps.scenes.getMovingNode();

            cleverapps.forces.create(scene.keypadView.letters[2], {
                text: "OpenLetter.force2",
                finger: {
                    delay: 1
                }
            }, {
                actives: [scene.keypadView],
                highlights: [scene.keypadView, scene.boardView, movingNode],
                importantNodes: [scene.keypadView, scene.boardView, button.magnifier, button.closeButton]
            });

            cleverapps.tutorial.on("chooseLetter", this.finish.bind(this), this);
        },

        onFinish: function () {
            cleverapps.forces.closeRunningForce();
            Game.currentGame.counter.trigger();
        }
    })];
};