/**
 * Created by Andrey Popov on 1/21/21.
 */

var Assets = function (wysiwyg) {
    this.selectedTab = Assets.MODES.COMPONENTS;
    this.wysiwyg = wysiwyg;

    cleverapps.EventEmitter.call(this);

    this.components = Object.keys(Assets.COMPONENTS).filter(function (name) {
        var componentConfig = Assets.COMPONENTS[name];
        if (componentConfig.type && cleverapps.config.type !== componentConfig.type
            || componentConfig.subtype && cleverapps.config.subtype !== componentConfig.subtype
            || componentConfig.meta && cleverapps.meta.getType() !== componentConfig.meta) {
            return false;
        }
        return true;
    }).map(function (name) {
        var assetComponent = new AssetComponent(name, Assets.COMPONENTS[name]);
        assetComponent.on("componentMovedToScene", function (parentId, position) {
            this.wysiwyg.addComponent(assetComponent, parentId, position);
        }.bind(this), this);
        return assetComponent;
    }.bind(this));

    this.scripts = [];
    this.load();
};

Assets.prototype = Object.create(cleverapps.EventEmitter.prototype);
Assets.prototype.constructor = Assets;

Assets.prototype.load = function () {
    cleverapps.RestClient.get(
        "/wysiwyg/scripts",
        {},
        function (data) {
            this.scripts = Object.keys(data).map(function (scriptFileName) {
                var scriptData = this.createScriptData(data[scriptFileName], scriptFileName);
                var scriptComponent = new ScriptComponent(scriptFileName, scriptData);
                scriptComponent.on("scriptMovedToScene", function (parentId) {
                    this.wysiwyg.addScript(scriptComponent, parentId);
                }.bind(this), this);

                return scriptComponent;
            }.bind(this));
        }.bind(this),
        function (err) {
            console.log(err);
        },
        {
            ignoreNoRest: true
        }
    );
};

Assets.prototype.createScriptData = function (scriptFileContent, scriptFileName) {
    var scriptLines = scriptFileContent.split("\n");
    var scriptName = scriptLines.filter(function (line) {
        return line.indexOf("@name") !== -1;
    }).map(function (line) {
        return line.split("@name")[1].trim();
    });

    if (!scriptFileName) {
        scriptFileName = scriptName[0];
    }

    var scriptParameters = scriptLines.filter(function (line) {
        return line.indexOf("@parameter") !== -1;
    }).map(function (line) {
        return line.split("@parameter")[1].trim();
    }).join("\n");

    return {
        name: scriptFileName,
        exec: scriptFileContent.split("*/\n")[1],
        parameters: scriptParameters,
        editCallback: function () {
            this.wysiwyg.wysiwygPreview.trigger("clearComponents");
            this.wysiwyg.wysiwygPreview.trigger("showScript", scriptFileName);
        }.bind(this),
        deleteCallback: function () {
            this.deleteScript(scriptFileName);
        }.bind(this)
    };
};

Assets.prototype.onTabChanged = function (tab) {
    this.selectedTab = tab;
    this.trigger("showAssets");
};

Assets.MODES = {
    COMPONENTS: "Components",
    SCRIPTS: "Scripts"
};

Assets.GROUPS = {
    SIMPLE: "Simple",
    WITHPROGRESS: "With progress",
    GAMEBLOCKS: "Game blocks",
    GAMESPECIFIC: "Game specific"
};

Assets.COMPONENTS = {
    AdsSprite: {
        ctor: function () {
            return AdsSprite;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Sprite",
            icon: bundles.wysiwyg.frames.adssprite
        }
    },
    AdsScale9Sprite: {
        ctor: function () {
            return AdsScale9Sprite;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Scale 9 sprite",
            icon: bundles.wysiwyg.frames.adsscale9sprite
        }
    },
    AdsSpine: {
        ctor: function () {
            return AdsSpine;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Spine",
            icon: bundles.wysiwyg.frames.adsspine
        }
    },
    AdsText: {
        ctor: function () {
            return AdsText;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Text",
            icon: bundles.wysiwyg.frames.adstext

        }
    },
    AdsSound: {
        ctor: function () {
            return AdsSound;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Sound",
            icon: bundles.wysiwyg.frames.adssound
        }
    },
    AdsShadowLayer: {
        ctor: function () {
            return AdsShadowLayer;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "ShadowLayer",
            icon: bundles.wysiwyg.frames.adsshadowlayer
        }
    },
    AdsLogo: {
        ctor: function () {
            return AdsLogo;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Logo",
            icon: bundles.wysiwyg.frames.adslogo
        }
    },
    AdsPlayButton: {
        ctor: function () {
            return AdsPlayButton;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Play button",
            icon: bundles.wysiwyg.frames.adsplay
        }
    },
    AdsGame: {
        ctor: function () {
            return AdsGame;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Snapshot",
            icon: bundles.wysiwyg.frames.adsgame
        }
    },
    AdsPuzzle15: {
        ctor: function () {
            return AdsPuzzle15;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Puzzle 15",
            icon: bundles.wysiwyg.frames.adspuzzle15
        }
    },
    AdsExclamations: {
        ctor: function () {
            return AdsExclamations;
        },
        group: Assets.GROUPS.GAMESPECIFIC,
        type: "board",
        preview: {
            caption: "Exclamations",
            icon: bundles.wysiwyg.frames.adsexclamations
        }
    },
    // AdsVideo: {
    //     ctor: function () {
    //         return AdsVideo;
    //     },
    //     group: Assets.GROUPS.SIMPLE,
    //     preview: {
    //         caption: "Video",
    //         icon: bundles.wysiwyg.frames.adsvideo
    //     }
    // },
    AdsMap2d: {
        ctor: function () {
            return AdsMap2d;
        },
        group: Assets.GROUPS.GAMESPECIFIC,
        type: "merge",
        preview: {
            caption: "Map2d",
            icon: bundles.wysiwyg.frames.adsmap2d
        }
    }
};
