/**
 * Created by slava on 14/8/17
 */

var BoosterHintView = function () {
    cleverapps.availableMove.on("hide", this.hide.bind(this));

    cleverapps.availableMove.on(CellHintAlgorithm.type, this.show.bind(this));
    cleverapps.availableMove.on(LineHintAlgorithm.type, this.show.bind(this));
    cleverapps.availableMove.on(ColorHintAlgorithm.type, this.show.bind(this));
};

BoosterHintView.prototype.show = function (options) {
    this.hide();

    var clicks = [
        cleverapps.boosters.getBoosterById(options.boosterId).onGetView(),
        options.target
    ].filter(Boolean);

    this.finger = FingerView.hintTap(clicks, { repeatDelay: 3 });
};

BoosterHintView.prototype.hide = function () {
    FingerView.remove(this.finger);
    this.finger = undefined;
};
