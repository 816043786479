/**
 * Created by Vladislav on 26.09.2024.
 */

Placements.ENTRIES = {
    displaySceneIntro: {
        type: Placements.FREE_FOCUS,
        priority: 100,
        filter: function () {
            return !cleverapps.scenes.getRunningScene().introShown;
        },
        action: function () {
            var scene = cleverapps.scenes.getRunningScene();

            cleverapps.focusManager.display({
                focus: "Scene.intro",
                control: scene.introControls(),
                actions: [
                    function (f) {
                        scene.introShown = true;

                        var silent = cleverapps.silentIntro;

                        scene.playIntro(f, silent);
                    },
                    function (f) {
                        cleverapps.restoreProgress.update();
                        cleverapps.silentIntro = false;

                        f();
                    }
                ]
            });
        }
    },

    recalculateFlags: {
        type: Placements.ONSHOW_OR_STARTUP | Placements.INTERMEDIATE_OR_AFTER_FINISH,
        action: function () {
            cleverapps.flags.update();
        }
    },

    versionCheck: {
        type: Placements.ONSHOW_OR_STARTUP,
        action: function () {
            cleverapps.versionChecker.check();
        },
        interval: "1 hour"
    },

    versionCheckAlert: {
        type: Placements.FREE_FOCUS,
        priority: 11,

        filter: function () {
            return cleverapps.versionChecker.needUpdate();
        },

        action: function () {
            cleverapps.focusManager.display({
                focus: "VersionChecker.Alert",
                action: function (f) {
                    new NewVersionWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
    },

    snapshotPlayActions: {
        type: Placements.FREE_FOCUS,
        priority: -1000,
        filter: function () {
            return !cleverapps.config.wysiwygMode && cleverapps.loadedSnapshot && cleverapps.loadedSnapshot.actions
                && cleverapps.loadedSnapshot.actions.length && !cleverapps.loadedSnapshot._played;
        },
        action: function () {
            cleverapps.loadedSnapshot._played = true;

            console.log("loaded snapshot played");
            cleverapps.snapshots.playActions(cleverapps.loadedSnapshot.actions);
        }
    },

    prefabPlayerRun: {
        type: Placements.FREE_FOCUS,
        priority: -1000,
        filter: function () {
            return cleverapps.prefabPlayer && !cleverapps.prefabPlayer.options.download && !cleverapps.prefabPlayer._played;
        },
        action: function () {
            cleverapps.prefabPlayer._played = true;

            console.log("prefabPlayer run");
            cleverapps.prefabPlayer.run();
        }
    },

    interstitial: {
        type: Placements.FREE_FOCUS,
        priority: 0,

        filter: function () {
            return !cleverapps.config.wysiwygMode && InterstitialAction.checkLastFocus()
                && cleverapps.adsLimits.state(AdsLimits.TYPES.INTERSTITIAL_INACTIVE) === AdsLimits.STATE_READY;
        },

        action: function () {
            cleverapps.focusManager.display({
                focus: "showInterstitialPlacement",
                action: function (f) {
                    cleverapps.rewardedAdsManager.showInterstitial(AdsLimits.TYPES.INTERSTITIAL_INACTIVE, f);
                }
            });
        },

        delayFromStart: "3 minutes"
    },

    createShortcut: {
        type: Placements.FREE_FOCUS_MAIN,
        filter: function () {
            return connector.platform.canCreateShortcut() && levels.user.checkAvailable(cleverapps.Availables.CREATE_SHORTCUT);
        },
        action: function () {
            connector.platform.createShortcutCallback();
        },
        interval: "10 minutes"
    },

    joinGroup: {
        type: Placements.FREE_FOCUS_MAIN,
        filter: function () {
            return connector.social.isSupportsNativeCommunityJoin && levels.user.checkAvailable(cleverapps.Availables.JOIN_GROUP);
        },
        action: function () {
            connector.social.joinCommunityNativeCallback();
        },
        interval: "7 days",
        delayFromStart: "10 minutes"
    },

    showRestore: {
        type: Placements.FREE_FOCUS,
        filter: function () {
            return cleverapps.paymentsLogic.pendingPurchaseToRestore;
        },
        action: function () {
            cleverapps.paymentsLogic.showRestore();
        }
    },

    subscriptionOffer: {
        type: Placements.FREE_FOCUS_MAIN,
        filter: function () {
            return cleverapps.subscription.needDisplayWindow();
        },
        action: function () {
            cleverapps.focusManager.display({
                focus: "SubscriptionWindow",
                action: function (f) {
                    new SubscriptionWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        },
        interval: function () {
            return connector.platform.oneOf(connector.OK) ? "1 day" : "3 day";
        },
        delayFromStart: "10 minutes"
    },

    minigameNudge: {
        type: Placements.FREE_FOCUS_MAIN,
        priority: 4,
        filter: function () {
            var missions = cleverapps.missionManager.getPlayableMissions();
            return missions.length > 0;
        },
        action: function () {
            var missions = cleverapps.missionManager.getPlayableMissions();

            for (var i = 0; i < missions.length; i++) {
                var mission = missions[i];
                if (mission.nudgeWindow && mission.started
                    && (mission.needShowStartWindow === undefined || mission.needShowStartWindow)) {
                    cleverapps.focusManager.display({
                        focus: "NudgeWindow",
                        action: function (f) {
                            var NudgeWindowClass = mission.nudgeWindow;
                            new NudgeWindowClass(mission);
                            cleverapps.focusManager.onceNoWindowsListener = f;
                        }
                    });
                    return;
                }
            }
        },
        interval: "10 minutes"
    },

    missionShowNewState: {
        type: Placements.FREE_FOCUS_MAIN,
        priority: 1000,
        filter: function () {
            return cleverapps.missionManager.getWantToShowNewStateMission();
        },
        action: function () {
            var mission = cleverapps.missionManager.getWantToShowNewStateMission();
            cleverapps.focusManager.display({
                focus: "missionWindow",
                action: function (f) {
                    var WindowClass = mission.view;
                    new WindowClass(mission);
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
    },

    localstorageDisabled: {
        type: Placements.FREE_FOCUS_MAIN,
        priority: 8,
        filter: function () {
            return !cleverapps.dataLoader.enabled && !cleverapps.dataLoader.alerted;
        },
        action: function () {
            cleverapps.dataLoader.setAlerted(true);

            cleverapps.focusManager.display({
                focus: "LocalStorageDisabledWindow",
                action: function (f) {
                    new LocalStorageDisabledWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
    },

    sideBarIcons: {
        type: Placements.FREE_FOCUS,

        action: function () {
            cleverapps.sideBar.resetOnFreeFocus();
        }
    },

    sideBarForce: {
        type: Placements.FREE_FOCUS,
        priority: 7,

        filter: function () {
            return !cleverapps.environment.isGameScene() && cleverapps.sideBar.anyIconWithForceIndex() !== undefined;
        },

        action: function () {
            cleverapps.sideBar.showAnyForce();
        }
    },

    triggerGameCounter: {
        type: Placements.FREE_FOCUS_GAME,
        priority: 0,

        filter: function () {
            if (typeof Game !== "undefined") {
                return Game.currentGame;
            }

            if (typeof Merge !== "undefined") {
                return Merge.currentMerge;
            }
        },

        action: function () {
            if (typeof Game !== "undefined") {
                Game.currentGame.counter.trigger();
            }

            if (typeof Merge !== "undefined") {
                Merge.currentMerge.counter.trigger();
            }
        }
    },

    triggerMap2dCounter: {
        type: Placements.FREE_FOCUS_MAIN,
        priority: 0,

        filter: function () {
            return typeof Map2d !== "undefined" && Map2d.currentMap;
        },

        action: function () {
            Map2d.currentMap.counter.trigger();
        }
    },

    toolbarForce: {
        type: Placements.FREE_FOCUS_MAIN,

        filter: function () {
            return cleverapps.toolbar.anyItemWithForce();
        },

        action: function () {
            cleverapps.toolbar.showAnyForce();
        }
    },

    offerManagerProcess: {
        type: Placements.FREE_FOCUS,

        filter: function () {
            return cleverapps.environment.isMainScene() && !cleverapps.config.demoMode;
        },

        action: function () {
            cleverapps.offerManager.refreshAll();

            var offer = cleverapps.offerManager.findOfferWithPendingWindow();
            if (offer && !cleverapps.config.wysiwygMode) {
                cleverapps.offerManager.showOfferWindow(offer);
            }
        }
    },

    displayPassWindow: {
        type: Placements.FREE_FOCUS_MAIN,
        filter: function () {
            var mission = cleverapps.missionManager.findLocalPass();
            return mission && mission.logic.needDisplayWindow() && !cleverapps.config.demoMode;
        },
        action: function () {
            var mission = cleverapps.missionManager.findLocalPass();

            if (mission.isRunning()) {
                mission.logic.displayWindow();
            } else {
                cleverapps.focusManager.display({
                    focus: "missionCompleted" + mission.name,
                    action: function (f) {
                        mission.displayCompleted(f);
                    }
                });
            }
        }
    },

    localPushes: {
        type: Placements.ONSHOW_OR_STARTUP,
        action: function () {
            cleverapps.localPushes.onShow();
        }
    },

    syncClientSessionExpiredWindow: {
        type: Placements.FREE_FOCUS,
        priority: 10000,
        filter: function () {
            return cleverapps.synchronizer.needClientSessionExpiredWindow();
        },
        action: function () {
            cleverapps.synchronizer.showClientSessionExpiredWindow();
        }
    },

    syncReloadWindow: {
        type: Placements.FREE_FOCUS,
        priority: 10001,
        filter: function () {
            return cleverapps.synchronizer._syncIn.needShowReloadWindow();
        },
        action: function () {
            cleverapps.synchronizer._syncIn.showReloadWindow();
        }
    },

    syncSocialConnectWindow: {
        type: Placements.FREE_FOCUS,
        priority: 11,
        filter: function () {
            return cleverapps.synchronizer.wantsSocialConnectWindow;
        },
        action: function () {
            cleverapps.synchronizer.displaySocialConnectWindow();
        }
    },

    sync: {
        type: Placements.ONSHOW,
        action: function () {
            cleverapps.synchronizer.syncWhenReady();
        },
        interval: "30 seconds"
    },

    checkConnection: {
        type: Placements.FREE_FOCUS,
        filter: function () {
            var available = {
                level: 1.13,
                crazy: {
                    level: 0.26
                },
                yandex: {
                    level: 0.26
                }
            };
            if (cleverapps.config.type === "merge") {
                available = { level: 5 };
            }
            return !cleverapps.flags.nologin && !connector.social.isLoggedIn() && cleverapps.user.checkAvailable(available) && !cleverapps.config.wysiwygMode;
        },
        action: function () {
            cleverapps.focusManager.display({
                focus: "checkConnection",
                action: function (f) {
                    cleverapps.SocialManager.enforceLogin(f, {
                        withWindow: true
                    });
                }
            });
        },
        interval: "7 days"
    },

    mergeRateWindow: {
        type: Placements.FREE_FOCUS,
        filter: function () {
            return cleverapps.config.type === "merge" && RateWindow.CanRate();
        },
        action: function () {
            cleverapps.focusManager.display({
                focus: "nativePlacementRate",
                action: function (f) {
                    new RateWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
    },

    subscriptionReload: {
        type: Placements.ONSHOW_OR_STARTUP,
        filter: function () {
            return cleverapps.subscription;
        },
        action: function () {
            cleverapps.subscription.reload();
        }
    },

    missionManagerProcess: {
        type: Placements.FREE_FOCUS,
        priority: 2,

        filter: function () {
            return cleverapps.environment.isMainScene() && !cleverapps.config.demoMode;
        },

        action: function () {
            cleverapps.missionManager.run();

            var mission = cleverapps.missionManager.pendingStartWindow();
            if (mission) {
                cleverapps.missionManager.showStartWindow(mission);
            }
        },
        delayFromStart: "1 minutes"
    },

    missionManagerMigration: {
        type: Placements.FREE_FOCUS,
        priority: 2,

        filter: function () {
            return cleverapps.missionManager.needSaveAfterMigration || cleverapps.meta.needSaveAfterMigration;
        },

        action: function () {
            cleverapps.missionManager.needSaveAfterMigration = false;
            cleverapps.meta.needSaveAfterMigration = false;

            cleverapps.missionManager.save();
            cleverapps.meta.save();
        }
    },

    missionFinished: {
        type: Placements.FREE_FOCUS,
        priority: 5,

        filter: function () {
            if (!cleverapps.config.demoMode) {
                cleverapps.missionManager.removeSilents();
                if (cleverapps.environment.isMainScene()) {
                    return cleverapps.missionManager.wantsToShowCompleted();
                }
            }
        },

        action: function () {
            var mission = cleverapps.missionManager.wantsToShowCompleted();
            if (mission) {
                cleverapps.focusManager.display({
                    focus: "missionCompleted" + mission.name,
                    action: function (f) {
                        mission.displayCompleted(f);
                    }
                });
            }
        }
    },

    missionStartWindow: {
        type: Placements.FREE_FOCUS,
        priority: 6,

        filter: function () {
            return cleverapps.environment.isMainScene() && cleverapps.missionManager.pendingStartWindow();
        },

        action: function () {
            cleverapps.missionManager.showStartWindow(cleverapps.missionManager.pendingStartWindow());
        }
    },

    showChatPresents: {
        priority: 1,
        type: Placements.FREE_FOCUS,

        filter: function () {
            return cleverapps.chatPresents !== undefined
                && (cleverapps.environment.isMainScene() || cleverapps.environment.isGameScene());
        },

        action: function () {
            cleverapps.chat.giveRewards();
        }
    },

    subscriptionReward: {
        type: Placements.FREE_FOCUS_MAIN,
        interval: "1 day",

        filter: function () {
            return cleverapps.subscription.isRewardReady() && Subscription.IsAvailable();
        },

        action: function () {
            cleverapps.focusManager.display({
                focus: "SubscriptionReward",
                action: function (f) {
                    new ShopWindow({
                        tab: ShopWindow.TABS.HARD_CURRENCY, priorityType: SubscriptionTile, finger: true
                    });

                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
    },

    returnBonus: {
        type: Placements.FREE_FOCUS,
        priority: 50,

        filter: function () {
            return !cleverapps.loadedSnapshot && cleverapps.returnBonus.shouldReward();
        },

        action: function () {
            cleverapps.returnBonus.giveReward();
        }
    },

    fallSaleWindow: {
        type: Placements.FREE_FOCUS_MAIN,
        priority: 50,
        once: true,

        filter: function () {
            return cleverapps.fallSale && cleverapps.fallSale.needToShowWindow();
        },

        action: function () {
            cleverapps.fallSale.showWindow();
        }
    },

    subscribeBot: {
        type: Placements.STARTUP,
        filter: function () {
            return cleverapps.platformLogic.subscribeBot;
        },
        action: function () {
            cleverapps.platformLogic.subscribeBot();
        },
        interval: "3 days"
    },

    loadOldUserId: {
        type: Placements.ONSHOW_OR_STARTUP,
        filter: function () {
            return cleverapps.platformLogic.loadOldUserId && !cleverapps.platformLogic.oldUserId;
        },
        action: function () {
            cleverapps.platformLogic.loadOldUserId(function (oldUserId) {
                if (oldUserId) {
                    console.log("old userId loaded", oldUserId);
                    cleverapps.platformLogic.oldUserId = oldUserId;
                    cleverapps.userIdsHistory.add(oldUserId);
                }
            });
        },
        interval: "1 hour"
    },

    syncOldUserId: {
        type: Placements.FREE_FOCUS,
        priority: 12,
        filter: function () {
            return cleverapps.platformLogic.oldUserId && cleverapps.synchronizer.isReady();
        },
        action: function () {
            cleverapps.synchronizer.getProgress(cleverapps.platformLogic.oldUserId, {
                acceptOnlyIfBetter: true,
                callback: function () {
                    cleverapps.platformLogic.oldUserId = undefined;
                }
            });
        }
    },

    checkAdBlock: {
        type: Placements.FREE_FOCUS_MAIN,
        once: true,
        filter: function () {
            return cleverapps.config.debugMode;
        },
        action: function () {
            connector.ads.checkAdBlockCallback(function (enabled) {
                if (enabled) {
                    cleverapps.focusManager.display({
                        focus: "AdBlockWindow",
                        action: function (f) {
                            new AdBlockWindow();
                            cleverapps.focusManager.onceNoWindowsListener = f;
                        }
                    });
                }
            });
        }
    },

    reportScore: {
        type: Placements.ONSHOW_OR_STARTUP,
        filter: function () {
            return !connector.platform.oneOf(connector.GAMESNACK, connector.YOUTUBE);
        },
        action: function () {
            if (cleverapps.instantTournament.isAvailable()) {
                cleverapps.instantTournament.reportScore();
            } else {
                cleverapps.scoreTracker.report();
            }
        }
    },

    wechatShutdown: {
        type: Placements.FREE_FOCUS,
        priority: 10000,
        once: true,
        filter: function () {
            return cleverapps.config.name === "fairy" && connector.platform.oneOf(connector.WECHAT);
        },
        action: function () {
            cleverapps.focusManager.display({
                focus: "WechatShutdownWindow",
                action: function (f) {
                    new WechatShutdownWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
    },

    chat: {
        type: Placements.ONSHOW,
        action: function () {
            cleverapps.chat.load();
        },
        interval: "1 minute"
    },

    crossPromoMarkUpdate: {
        type: Placements.FREE_FOCUS,
        action: function () {
            MenuControlButton.updateAttention();
        },
        filter: function () {
            return cleverapps.crossPromo && cleverapps.crossPromo.hasMark();
        }
    },

    friendRequests: {
        type: Placements.ONSHOW_OR_STARTUP,
        action: function () {
            levels.friendRequests.serverReload(true);
            FakeRequestSender.sendIfNeed();
        },
        interval: "1 minute"
    },

    startTutorialScenario: {
        priority: -1000,
        type: Placements.FREE_FOCUS_MAIN | Placements.FREE_FOCUS_OTHER,

        filter: function () {
            return cleverapps.tutorial && cleverapps.tutorial.getAvailableScenario();
        },

        action: function () {
            cleverapps.tutorial.startScenario();
        }
    }
};
