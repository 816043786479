/**
 * Created by iamso on 05.03.19.
 */

var SideBarSlotView = cc.Node.extend({
    ctor: function (slot, parent) {
        this._super();

        var styles = this.styles = this.getStyles();
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles.width, styles.height);

        var showNode = this.showNode = new cc.Node();
        showNode.setAnchorPoint(0.5, 0.5);
        showNode.setContentSize2(styles.width, styles.height);
        parent.addChild(showNode);

        var hideNode = this.hideNode = new cc.Node();
        hideNode.setAnchorPoint(0.5, 0.5);
        hideNode.setContentSize2(styles.width, styles.height);
        parent.addChild(hideNode);

        this.sideBar = parent;
        this.row = slot.row;
        this.col = slot.col;

        this.setupChildren();

        this.debugId = "SideBarSlot" + this.row + this.col;
    },

    setupChildren: function () {
        this.stopAllActions();
        this.visible = true;

        this.setScale(connector.info.isMobile ? this.styles.mobileScale[cleverapps.resolution.mode] : this.styles.scale[cleverapps.resolution.mode]);
        this.showNode.setScale(this.scale);
        this.hideNode.setScale(this.scale);

        var padding = Array.isArray(this.styles.padding) ? this.styles.padding[cleverapps.resolution.mode] : this.styles.padding;
        var hideX = this.width * this.scale;
        var slotY = { align: "top", dy: -padding.top - this.row * (this.styles.margin + this.height * this.scale) };

        if (this.col === SideBar.LEFT_COLUMN) {
            this.hidePosition = cc.p({ align: "left", dx: -hideX }, slotY);
            this.showPosition = cc.p({ align: "left" }, slotY);
        } else {
            this.hidePosition = cc.p({ align: "right", dx: hideX }, slotY);
            this.showPosition = cc.p({ align: "right" }, slotY);
        }

        this.showNode.setPositionRound(this.showPosition);
        this.hideNode.setPositionRound(this.hidePosition);

        var controlName = this.iconView && (this.iconView.model.control || this.getControlName());

        if (cleverapps.focusManager.lastControlsVisible[controlName]) {
            this.isShown = true;
            this.setPositionRound(this.showPosition);
        } else {
            this.isShown = false;
            this.visible = false;
            this.setPositionRound(this.hidePosition);
        }
    },

    getStyles: function () {
        return cleverapps.styles.SideBarSlot;
    },

    getControlName: function () {
        return "sideBarSlot_" + this.row + "_" + this.col;
    },

    insert: function (icon) {
        this.iconView = icon;
        this.addChild(icon);

        icon.setPositionRound(this.iconView.model.countOfSlots() > 1 ? this.styles.iconsGroup : this.styles.icon);

        cleverapps.focusManager.registerControl(icon.model.control || this.getControlName(), this, Object.values(cleverapps.Environment).filter(function (scene) {
            return scene !== cleverapps.Environment.SCENE_MAIN;
        }));
    },

    eject: function () {
        cleverapps.focusManager.removeControl(this.iconView.model.control || this.getControlName());

        this.iconView.removeFromParent();
        this.iconView = undefined;
    },

    release: function () {
        cleverapps.focusManager.removeControl(this.iconView.model.control || this.getControlName());

        this.iconView.removeTemporarily(false);
        var icon = this.iconView;
        this.iconView = undefined;
        return icon;
    },

    showForce: function () {
        cleverapps.focusManager.showControlsWhileFocused(this.iconView.model.control || this.getControlName(), true);
        this.iconView.showForce();
    },

    toggle: function (visible, silent) {
        if (visible) {
            this.show(silent);
        } else {
            this.hide(silent);
        }
    },

    show: function (silent) {
        this.isShown = true;
        this.sideBar.updateZOrder();

        if (!this.iconView) {
            silent = true;
        } else if (this.iconView.showAttentionAnimation) {
            this.iconView.showAttentionAnimation();
        }

        this.stopAllActions();
        if (silent) {
            this.visible = true;
            this.setPositionRound(this.showPosition);
        } else {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.3 + 0.1 * this.row),
                new cc.Show(),
                new cc.MoveTo(1, this.showNode.getPosition()).easing(cc.easeElasticOut(0.6))
            ));
        }

        if (this.iconView) {
            this.iconView.onShow();
        }

        cleverapps.scenes.onAvoidNodeVisibilityChanged();
    },

    hide: function (silent) {
        this.isShown = false;

        if (!this.iconView) {
            silent = true;
        }

        this.stopAllActions();
        if (silent) {
            this.visible = false;
            this.setPositionRound(this.hidePosition);
        } else {
            this.runAction(new cc.Sequence(
                new cc.MoveTo(0.15, this.hideNode.getPosition()).easing(cc.easeBackIn()),
                new cc.Hide()
            ));
        }

        if (this.iconView) {
            this.iconView.onHide();
        }

        cleverapps.scenes.onAvoidNodeVisibilityChanged();
    }
});

cleverapps.styles.SideBarSlot = {
    width: 200,
    height: 200,
    margin: 20,
    scale: [0.8, 1, 1],
    mobileScale: [0.65, 0.9, 0.9],

    padding: [
        {
            top: 120
        },
        {
            top: 140
        },
        {
            top: 160
        }
    ],

    icon: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    iconsGroup: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -6 }
    }
};
