/**
 * Created by Ivan on 13.02.2025
 */

var StickersRewardWindow = CleverappsWindow.extend({
    ctor: function () {
        this.stickers = cleverapps.stickersBook.newStickers;
        cleverapps.stickersBook.newStickers = undefined;

        this._super();
    },

    onWindowLoaded: function () {
        this._super({
            name: "StickersRewardWindow",
            content: this.getContent(),
            noBackground: true,
            title: false,
            closeButton: false
        });
    },

    getContent: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();

        var content = this.content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize(sceneSize.width * 0.9, sceneSize.height * 0.65);

        this.createStickersLayout();
        this.createStickersSource();

        return content;
    },

    createStickersLayout: function () {
        var styles = cleverapps.styles.StickersRewardWindow.stickers;

        var stickersViews = this.stickersViews = this.stickers.map(function (sticker) {
            return new RewardStickerView(sticker, this);
        }.bind(this));

        var stickersLayout = this.stickersLayout = new cleverapps.WrapGridLayout(stickersViews, {
            columns: this.calcColumnsCount(stickersViews.length),
            margin: styles.margin,
            fitToBox: this.content.getContentSize(),
            padding: styles.padding
        });
        stickersLayout.setAnchorPoint(0.5, 0.5);
        this.content.addChild(stickersLayout);
        stickersLayout.setPositionRound(styles);
    },

    createStickersSource: function () {
        var stickersSource = this.stickersSource = new StickersPackView(bundles.stickers_reward_window.jsons.sticker_pack_json);
        this.content.addChild(stickersSource);

        stickersSource.setPositionRound(cleverapps.styles.StickersRewardWindow.source);
    },

    calcColumnsCount: function (stickersAmount) {
        if (stickersAmount <= 3) {
            return stickersAmount;
        }

        if (stickersAmount === 4) {
            return 2;
        }

        if (stickersAmount === 6) {
            return 3;
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            return 5;
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.SQUARE) {
            return 4;
        }

        return 3;
    },

    getReceiveDuration: function () {
        return 0.7;
    },

    createReceiveAnimation: function () {
        var delays = cleverapps.Random.shuffle(this.stickersViews.map(function (sticker, index) {
            return index;
        }));

        return new cc.Spawn(
            new cc.CallFunc(function () {
                this.stickersViews.forEach(function (sticker, index) {
                    sticker.receiveStickerAnimation(delays[index] * 0.1, sticker);
                });
            }.bind(this)),
            new cc.DelayTime(this.stickersViews.length * 0.1 + this.getReceiveDuration())
        );
    },

    createCollectAnimation: function () {
        return new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                this.stickersViews.forEach(function (stickerView) {
                    stickerView.collectStickerAnimation();
                });
            }.bind(this)),
            new cc.DelayTime(0.9),
            new cc.FadeOut(0.3)
        );
    },

    onShow: function () {
        this._super();

        this.stickersSource.showAnimation();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.75),
            this.createReceiveAnimation(),
            new cc.DelayTime(1.5),
            this.createCollectAnimation()
        )).setFinalize(function () {
            this.close();
        }.bind(this));
    },

    popUpAnimation: function () {},

    listBundles: function () {
        return cleverapps.unique(["stickers_reward_window"].concat(this.stickers.map(function (sticker) {
            return "collection_" + sticker.collection.index;
        })));
    }
});

cleverapps.styles.StickersRewardWindow = {
    stickers: {
        x: { align: "center" },
        y: { align: "center" },

        margin: {
            x: 40,
            y: 40
        },

        padding: {
            top: 20,
            bottom: 20
        }
    },

    source: {
        x: { align: "center" },
        y: { align: "bottom" }
    }
};

StickersRewardWindow.MAX_STICKERS_ON_SCREEN = 5;