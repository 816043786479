/**
 * Created by mac on 10/22/18
 */

var CombosLibrary = {
    actions: {
        hurt: function (combo, cell) {
            for (var i = 0; i < combo.algo.power; i++) {
                cell.hurt();
            }
            RugTile.hurtViaCombo(combo, cell);
        },

        splashHurt: function (combo, cells) {
            var distance = combo.algo.power;
            for (var i = combo.y - distance; i <= combo.y + distance; i++) {
                for (var j = combo.x - distance; j <= combo.x + distance; j++) {
                    if (i === combo.y || j === combo.x) {
                        cells.forEach(function (cell) {
                            if (j === cell.x && i === cell.y && Game.currentGame.field.cells[i] && Game.currentGame.field.cells[i][j]) {
                                Game.currentGame.field.cells[i][j].hurt();
                                RugTile.hurtViaCombo(combo, Game.currentGame.field.cells[i][j]);
                            }
                        });
                    }
                }
            }
        },

        incCoefOrMakeHero: function (comboCell, cell, order) {
            var p = cleverapps.Random.nextDouble();

            if (p >= comboCell.algo.makeHeroProb) {
                var goalCoefComponent = cell.findComponent(GoalCoefComponent);
                if (goalCoefComponent) {
                    goalCoefComponent.incCoef(comboCell.algo.power);
                    return;
                }
            }

            if (p < comboCell.algo.makeHeroProb || order === 0) {
                if (cell.canDelete()) {
                    var combo = ComboComponent.Create(cell, {
                        random: true
                    });

                    if (combo) {
                        cell.delete();
                        Game.currentGame.field.addCell(combo);
                        combo.animate(BaseCell.ANIMATION_CREATE);
                    }
                }
            }
        },

        shuffle: function (comboCell, cells) {
            var i;
            Game.currentGame.counter.setTimeout(function () {}, 600);
            var poses = [];
            for (i = 0; i < cells.length; i++) {
                poses.push({ x: cells[i].x, y: cells[i].y });
            }
            for (i = 0; i < cells.length; i++) {
                var pos = poses[(i + 1) % poses.length];
                Game.currentGame.field.cells[pos.y][pos.x] = cells[i];
                cells[i].x = pos.x;
                cells[i].y = pos.y;
                cells[i].animate(BaseCell.ANIMATION_GOTO_POSITION);
            }
        },

        activateCargo: function (combo, cell) {
            var cargo = new ComboCell(cell.x, cell.y, { algo: combo.algo.cargo });
            Game.currentGame.field.trigger("addCell", cargo);
            cargo.explode();

            cell.hurt();
        },

        blastWave: function (comboCell, targetCells) {
            var waves = [];

            var centerX = comboCell.x;
            var centerY = comboCell.y;
            var radius = 1;

            while (radius < 10) {
                var wave = [];

                targetCells.forEach(function (target) {
                    if ((target.x === centerX + radius || target.x === centerX - radius) && target.y <= centerY + radius && target.y >= centerY - radius) {
                        wave.push(target);
                    } else if ((target.y === centerY + radius || target.y === centerY - radius) && target.x <= centerX + radius && target.x >= centerX - radius) {
                        wave.push(target);
                    }
                });

                waves.push(wave);
                radius++;
            }

            waves.forEach(function (wave, index) {
                wave.forEach(function (cell) {
                    Game.currentGame.counter.setTimeout(function () {
                        cell.hurt();
                    }, 100 * index);
                });
            });
        }
    },

    helpers: {
        classifyTargets: function (combo) {
            var res = {
                simple: [],
                special: []
            };

            for (var i = 0; i < Field.SIZE; i++) {
                for (var j = 0; j < Field.SIZE; j++) {
                    var currentCell = Game.currentGame.field.cells[i][j];
                    var currentTile = Game.currentGame.field.floor[i][j];

                    if (currentCell) {
                        if (combo && !CombosLibrary.filters.notAttackBuddies(combo, currentCell)) {
                            continue;
                        }

                        if (!CombosLibrary.helpers.isForbiddenCell(currentCell)) {
                            if (CombosLibrary.helpers.isSimpleCell(currentCell, currentTile)) {
                                res.simple.push(currentCell);
                            } else {
                                res.special.push(currentCell);
                            }
                        }
                    }
                }
            }

            return res;
        },

        isForbiddenCell: function (cell) {
            if (cell.willBurn || !cell.alive || cell.lives === 0 || !cell.hurtable) {
                return true;
            }

            if (cell instanceof FireworkCell) {
                return true;
            }

            if (cell instanceof MultiColorCell) {
                return true;
            }

            if (cell instanceof CandleCell && cell.state === CandleCell.STATE_ON) {
                return true;
            }

            return false;
        },

        isSimpleCell: function (cell, tile) {
            var colorComponent = cell.findComponent(ColorComponent);
            var markComponent = cell.findComponent(MarkComponent);
            if ([HeroCell, TulpanCell, ComboCell].indexOf(cell.constructor) !== -1 || cell.constructor === BaseCell && (colorComponent || cell.findComponent(GoalCoefComponent))) {
                if ((!colorComponent || markComponent && markComponent.mark === undefined) && !(tile && tile.constructor === IceTile) && !(tile && tile.constructor === DirtyHoneyTile)
                    && !(tile && tile.constructor === RabbitTile && tile.lives > 0)) {
                    return true;
                }
            }

            return false;
        }
    },

    targets: {
        smartGoal: function (comboCell, returnAll) {
            var options = CombosLibrary.helpers.classifyTargets(comboCell);

            var goalCells = HintHelper.smartChoice();
            var allValids = options.special.concat(options.simple);
            var targets = cleverapps.intersect(allValids, goalCells, function (item) {
                return item.x + "_" + item.y;
            });
            if (targets.length) {
                return returnAll ? targets : cleverapps.Random.chooseAmount(targets, comboCell.algo.numTargets);
            }

            var valids = options.special;

            if (valids.length === 0) {
                valids = options.simple;
            }

            var maxLife = 0;
            for (var i = 0; i < valids.length; i++) {
                maxLife = Math.max(maxLife, valids[i].totalLives());

                if (maxLife >= comboCell.algo.power) {
                    maxLife = comboCell.algo.power;
                    break;
                }
            }

            var res = valids.filter(function (option) {
                return option.totalLives() >= maxLife;
            });

            return returnAll ? res : cleverapps.Random.chooseAmount(res, comboCell.algo.numTargets);
        },

        byRowOrCol: function (combo) {
            var path = [];
            for (var i = 1; i <= Field.SIZE; i++) {
                if (combo.algo.dir & CombosLibrary.LINER_DIRECTION_HORIZONTAL) {
                    if (Game.currentGame.field.cells[combo.y][combo.x + i]) {
                        path.push(Game.currentGame.field.cells[combo.y][combo.x + i]);
                    }

                    if (Game.currentGame.field.cells[combo.y][combo.x - i]) {
                        path.push(Game.currentGame.field.cells[combo.y][combo.x - i]);
                    }
                }

                if (combo.algo.dir & CombosLibrary.LINER_DIRECTION_VERTICAL) {
                    if (Game.currentGame.field.cells[combo.y + i] && Game.currentGame.field.cells[combo.y + i][combo.x]) {
                        path.push(Game.currentGame.field.cells[combo.y + i][combo.x]);
                    }

                    if (Game.currentGame.field.cells[combo.y - i] && Game.currentGame.field.cells[combo.y - i][combo.x]) {
                        path.push(Game.currentGame.field.cells[combo.y - i][combo.x]);
                    }
                }
            }

            return path;
        },

        inThreeRowsAndCols: function (combo) {
            var path = CombosLibrary.targets.byRowOrCol(combo);

            BaseCell.neighbors.forEach(function (dir) {
                path = path.concat(CombosLibrary.targets.byRowOrCol({
                    x: combo.x + dir.x,
                    y: combo.y + dir.y,
                    algo: { dir: dir.y === 0 ? CombosLibrary.LINER_DIRECTION_VERTICAL : CombosLibrary.LINER_DIRECTION_HORIZONTAL }
                }));
            });

            return path;
        },

        byRadius: function (combo) {
            var cells = [];
            var radius = combo.algo.power;

            for (var i = combo.y - radius; i <= combo.y + radius; i++) {
                for (var j = combo.x - radius; j <= combo.x + radius; j++) {
                    if (Game.currentGame.field.cells[i] && Game.currentGame.field.cells[i][j]) {
                        cells.push(Game.currentGame.field.cells[i][j]);
                    }
                }
            }

            return cells;
        }
    },

    filters: {
        notSelf: function (comboCell, cell) {
            return comboCell !== cell;
        },

        shuffleable: function (comboCell, cell) {
            return cell.findComponent(ColorComponent) && cell.movable && cell.alive && !cell.controlDisabled && !cell.sticky && cell.lives !== 0;
        },

        hurtable: function (comboCell, cell) {
            return cell && cell.hurtable && cell.alive && cell.lives > 0;
        },

        withCoefOrTurnToHero: function (comboCell, cell, notNecessarily) {
            if (!cell) {
                return false;
            }
            if (!cell.alive || cell.lives <= 0) {
                return false;
            }
            if (notNecessarily) {
                if (cell.constructor === BaseCell && cell.findComponent(ColorComponent)) {
                    return true;
                }
            }
            return cell.constructor === BaseCell && cell.findComponent(GoalCoefComponent);
        },

        closeBy: function (comboCell, cell) {
            return Math.abs(comboCell.x - cell.x) <= 1 && Math.abs(comboCell.y - cell.y) <= 1;
        },

        notOnThreeInRow: function (comboCell, cell, notNecessarily) {
            if (!Game.currentGame || notNecessarily) {
                return true;
            }
            return !Game.currentGame.field.isThreeInRow(cell.y, cell.x);
        },

        notAttackBuddies: function (comboCell, cell, notNecessarily) {
            if (notNecessarily) {
                return true;
            }
            var colorComponent = comboCell.findComponent(ColorComponent);
            var color = colorComponent && colorComponent.color || "";
            return !ComboComponent.checkComboTarget(cell.x, cell.y, color);
        },

        notAttackMulticolorCell: function (comboCell, cell, notNecessarily) {
            if (notNecessarily) {
                return true;
            }
            return !(cell instanceof MultiColorCell);
        }
    },

    checkFilters: function (comboCell, cell, notNecessarily) {
        if (!cell) {
            return false;
        }

        var filters = comboCell.algo.filters;
        if (!Array.isArray(filters)) {
            filters = [filters];
        }

        for (var f = 0; f < filters.length; f++) {
            var filter = filters[f];
            if (!filter(comboCell, cell, notNecessarily)) {
                return false;
            }
        }

        return true;
    }
};

CombosLibrary.ByShape = {};

CombosLibrary.TYPE_PLANE = "plane";
CombosLibrary.TYPE_LINER = "liner";
CombosLibrary.TYPE_BOMB = "bomb";
CombosLibrary.TYPE_SUPER_LINER = "super_liner";

CombosLibrary.LINER_DIRECTION_HORIZONTAL = 1;
CombosLibrary.LINER_DIRECTION_VERTICAL = 2;

CombosLibrary.ByShape[Shape.PLANE] = {
    name: CombosLibrary.TYPE_PLANE,

    shootFlyDuration: function () {
        return 1.6;
    },

    shootAlgorithm: "plane",

    action_timeout: 200,
    action_delay: 0,
    between_shoot_timeout: 0,

    noEffect: true,

    bigZOrder: true,

    findBeforeTargets: CombosLibrary.targets.byRadius,
    beforeAction: CombosLibrary.actions.splashHurt,

    numTargets: 1,
    power: 1,
    findTargets: CombosLibrary.targets.smartGoal,
    filters: [CombosLibrary.filters.hurtable],
    action: CombosLibrary.actions.hurt
};

CombosLibrary.Liner = {
    shootFlyDuration: function (cell, target) {
        var dist = Math.abs(target.x - cell.x) + Math.abs(target.y - cell.y);
        var fullDuration = cell.algo.direct_timeout * 0.8 / 1000;
        return fullDuration * dist / cell.algo.action_length;
    },

    shootAlgorithm: "none",

    action_timeout: 800,
    action_delay: 300,
    direct_timeout: 800,
    action_length: 8,
    between_shoot_timeout: 0,

    bigZOrder: true,

    numTargets: 100,
    power: 1,

    findTargets: CombosLibrary.targets.byRowOrCol,
    filters: [CombosLibrary.filters.hurtable],
    action: CombosLibrary.actions.hurt
};

CombosLibrary.ByShape[Shape.FOUR_H] = Object.assign({}, CombosLibrary.Liner, {
    name: CombosLibrary.TYPE_LINER,
    dir: CombosLibrary.LINER_DIRECTION_HORIZONTAL
});

CombosLibrary.ByShape[Shape.FOUR_V] = Object.assign({}, CombosLibrary.Liner, {
    name: CombosLibrary.TYPE_LINER,
    dir: CombosLibrary.LINER_DIRECTION_VERTICAL
});

CombosLibrary.ByShape[Shape.BOMB] = {
    name: CombosLibrary.TYPE_BOMB,

    shootAlgorithm: "none",

    action_timeout: 200,
    action_delay: 0,

    bigZOrder: true,

    power: 2,
    findTargets: CombosLibrary.targets.byRadius,
    filters: [CombosLibrary.filters.hurtable],
    actionToAllCells: true,
    action: CombosLibrary.actions.blastWave
};

CombosLibrary.powerUp = {
    plane_plane: {
        numTargets: 3
    },

    plane_liner: {
        action: CombosLibrary.actions.activateCargo
    },

    plane_bomb: {
        action: CombosLibrary.actions.activateCargo
    },

    liner_liner: {
        dir: (CombosLibrary.LINER_DIRECTION_HORIZONTAL | CombosLibrary.LINER_DIRECTION_VERTICAL),

        action_delay: 0,

        actionToAllCells: true,
        action: CombosLibrary.actions.blastWave
    },

    liner_bomb: {
        dir: (CombosLibrary.LINER_DIRECTION_HORIZONTAL | CombosLibrary.LINER_DIRECTION_VERTICAL),

        action_delay: 0,

        findTargets: CombosLibrary.targets.inThreeRowsAndCols,
        actionToAllCells: true,
        action: CombosLibrary.actions.blastWave
    },

    bomb_bomb: {
        power: 4
    }
};