/**
 * Created by mac on 7/24/17.
 */

var MovesView = cc.Node.extend({
    create: function () {
        var styles = cleverapps.styles.UI.MovesView;

        if (Game.currentGame.moves > 0) {
            this.addMovesBar();
            this.addBackground();

            this.text = cleverapps.UI.generateImageText(Game.currentGame.moves, cleverapps.styles.FONTS.MOVES_TEXT);
            this.text.baseScale = this.text.scale;
            this.text.setPositionRound(styles.text.margin.x, styles.text.margin.y);
            this.addChild(this.text);

            cleverapps.aims.registerTarget("moves", this.text, {
                controls: "panel_info",
                flyingUnderShadow: true,
                withoutDelta: true
            });

            if (styles.icon) {
                var iconRes = bundles.moves.frames.game_moves_icon;
                if (bundles.moves.frames.game_moves_en_icon && cleverapps.settings.language === "en") {
                    iconRes = bundles.moves.frames.game_moves_en_icon;
                }

                var icon = new cc.Sprite(iconRes);
                this.addChild(icon);
                icon.setPositionRound(styles.icon.x, styles.icon.y);
            }
        } else if (bundles.moves.frames.locked_icon) {
            var background = this.addBackground();

            var lockIcon = new cc.Sprite(bundles.moves.frames.locked_icon);
            background.addChild(lockIcon);

            lockIcon.setPositionRound(styles.locked.x, styles.locked.y);
        }

        Game.currentGame.onGetMovesView = this.createListener(function () {
            return this;
        }.bind(this));
    },

    ctor: function (game) {
        this._super();

        this.updateMovesTimeout = new cc.Node();
        this.addChild(this.updateMovesTimeout);

        this.maxMoves = game.moves;

        this.game = game;

        this.setAnchorPoint(0.5, 0.5);

        this.updateSize();
        this.setupChildren();

        this.create();

        game.onChangeMovesListener = this.updateMoves.bind(this);
        game.onAnimatedChangeMovesListener = this.changeMovesAnimation.bind(this);
        game.onChangeMovesStateListener = this.setStyle.bind(this);

        if (!cleverapps.environment.isEditorScene()) {
            game.on("showMovesView", this.createListener(this.onShowUpAnimationStart.bind(this)), this);
        }

        this.setCascadeOpacityEnabled(true);
    },

    onShowUpAnimationStart: function (silent) {
        var styles = cleverapps.styles.UI.MovesView;
        var targetPosition = this.getPosition();
        this.setVisible(true);

        if (silent) {
            this.setPositionRound(targetPosition);
        } else {
            this.setPositionRound(targetPosition.x, cleverapps.resolution.getSceneSize().height + styles.slideAnimation.movesView.offsetY);
            this.showUpAction = new cc.Sequence(
                new cc.DelayTime(styles.slideAnimation.controlsPanel.delay),
                new cc.MoveTo(0.4, targetPosition)
            ).setFinalize(function () {
                this.setPositionRound(targetPosition);
                delete this.showUpAction;
            }.bind(this));
            this.runAction(this.showUpAction);
        }
    },

    completeAnimationOnResize: function () {
        if (this.showUpAction) {
            this.stopAction(this.showUpAction);
        }
    },

    setupChildren: function () {
        if (cleverapps.environment.isEditorScene()) {
            return;
        }
        var styles = cleverapps.styles.UI.MovesView;
        this.setPositionRound(styles.position);
    },

    updateSize: function () {
        var styles = cleverapps.styles.UI.MovesView;
        if (styles.scale) {
            this.setScale(styles.scale[cleverapps.resolution.mode]);
        }
    },

    addBackground: function () {
        var styles = cleverapps.styles.UI.MovesView.background;
        var background;

        if (bundles.moves.jsons.moves_json) {
            background = new cleverapps.Spine(bundles.moves.jsons.moves_json);
            background.isAnimation = true;
            background.setAnimation(0, "normal", true);
        } else if (bundles.moves.frames.moves_jar_content_bg && Game.currentGame.moves <= 0) {
            background = new cc.Node();
            background.setAnchorPoint(0.5, 0.5);
        } else {
            styles.image = bundles.moves.frames.moves_bg_png;
            background = cleverapps.UI.createSprite(styles);
        }

        this.addChild(background);
        background.setPositionRound(styles.x, styles.y);

        if (this.game.moves > 0) {
            cleverapps.tooltipManager.create(background, {
                text: Messages.get("MovesTooltip"),
                location: cleverapps.UI.Tooltip.LOCATION_BELOW
            });
        }

        this.background = background;
        return background;
    },

    addMovesBar: function () {
        if (!bundles.moves.frames.moves_jar_content_bg) {
            return;
        }

        var styles = cleverapps.styles.UI.MovesView.content;

        var bar = this.bar = new ScaledProgressBar({
            progress: bundles.moves.frames.moves_jar_content_bg,
            layout: ScaledProgressBar.VERTICAL
        });
        bar.setAnchorPoint(0, 0);
        bar.setLength(styles.height);
        bar.setGoal(this.maxMoves);
        bar.setPercentage(100);

        this.addChild(bar);
        bar.setPositionRound(styles.x, styles.y);

        bar.setCascadeOpacityEnabled(true);
    },

    setStyle: function (style) {
        switch (style) {
            case Match3.MOVES_STATE_DANGER:
                this.setState(Match3.MOVES_STATE_DANGER);
                break;
            case Match3.MOVES_STATE_NORMAL:
                this.setState(Match3.MOVES_STATE_NORMAL);
                break;
        }
    },

    setState: function (state) {
        if (!this.text) {
            return;
        }

        if (this.background.isAnimation) {
            var animation = state === Match3.MOVES_STATE_DANGER ? "danger" : "normal";
            this.background.setAnimation(0, animation, true);
        }

        if (state === Match3.MOVES_STATE_DANGER) {
            this.text.runAction(
                new cc.Sequence(
                    new cc.TintTo(MovesView.HUMAN_DELAY, cleverapps.styles.COLORS.MOVES_DANGER.r, cleverapps.styles.COLORS.MOVES_DANGER.g, cleverapps.styles.COLORS.MOVES_DANGER.b),
                    new cc.RepeatForever(
                        new cc.Sequence(
                            new cc.ScaleTo(0.5, 1.1 * this.text.baseScale).easing(cc.easeBackInOut()),
                            new cc.ScaleTo(0.5, this.text.baseScale)
                        )
                    )
                )
            );
        } else {
            this.text.setColor(cleverapps.styles.COLORS.WHITE);
        }
    },

    runTextAnimation: function (moves, movesDiff) {
        if (!this.text) {
            return;
        }

        var deltaPosition = this.parent.convertToNodeSpace(this.text.parent.convertToWorldSpace(this.text.getPosition()));
        this.runAction(new cc.Sequence(
            new cc.DelayTime(MovesView.HUMAN_DELAY),
            new cc.CallFunc(function () {
                cleverapps.aims.showDelta(movesDiff, this.parent, {
                    x: deltaPosition.x,
                    y: deltaPosition.y,
                    moveRate: 1,
                    font: (movesDiff > 0) ? cleverapps.styles.FONTS.INC_MOVES_TEXT : cleverapps.styles.FONTS.DEC_MOVES_TEXT
                });

                this.text.setString(moves);
                this.text.setAnchorPoint(0.5, 0.5);

                if (movesDiff > 0) {
                    this.text.runAction(new cc.Sequence(
                        new cc.ScaleTo(0.2, 1.4 * this.text.baseScale),
                        new cc.ScaleTo(0.1, this.text.baseScale)
                    ));
                }
            }.bind(this))
        ));
    },

    updateMoves: function (moves, movesDiff, options) {
        options = options || {};

        var delay = options.delay || 0;

        this.movesDiff = (this.movesDiff || 0) + movesDiff;

        if (moves > this.maxMoves) {
            this.maxMoves = moves;
        }

        this.updateMovesTimeout.stopAllActions();
        this.updateMovesTimeout.runAction(new cc.Sequence(
            new cc.DelayTime(delay / 1000),
            new cc.CallFunc(function () {
                if (this.movesDiff !== 0) {
                    if (this.bar) {
                        this.bar.setGoal(this.maxMoves);
                        this.bar.setPercentage(moves, {
                            animated: true
                        });
                    }
                    this.runTextAnimation(moves, this.movesDiff);
                }

                delete this.movesDiff;
            }.bind(this))
        ));
    },

    changeMovesAnimation: function (amount, options, onFinish) {
        onFinish = onFinish || function () {};

        var fieldView = Game.currentGame.field.onGetView();

        if (!fieldView || !fieldView.isRunning()) {
            onFinish();
            return;
        }

        var moves = new cc.Node();
        moves.setAnchorPoint(0.5, 0.5);
        var movesText = cleverapps.UI.generateImageText(amount, cleverapps.styles.FONTS.MOVES_TEXT);
        var movesIcon = new cc.Sprite(bundles.moves.frames.moves_icon);
        moves.addChild(movesText);
        moves.addChild(movesIcon);
        cleverapps.UI.arrangeWithMargins(moves.children, {
            margin: 0,
            direction: cleverapps.UI.HORIZONTAL
        });
        cleverapps.UI.wrap(moves);

        var fieldSize = fieldView.getContentSize();
        moves.setPosition(fieldSize.width / 2, fieldSize.height / 2);
        fieldView.addChild(moves);

        var target = cleverapps.aims.getTarget("moves");

        if (options.actionBefore && options.fromLantern) {
            var styles = cleverapps.styles.UI.MovesView.lantern;

            moves.setScale(0.3);
            moves.setPosition(fieldSize.width / 2 + styles.dx, fieldSize.height / 2 + styles.dy);

            moves.runAction(new cc.Sequence(
                StandartAnimations.animateCollect(moves, target, {
                    scale: 1,
                    collectEffect: true
                }),
                new cc.CallFunc(function () {
                    BoostersBeforeHelper.animateAppear(moves, {
                        scale: 0,
                        target: target
                    }, onFinish);
                })
            ));
            return;
        }

        if (options.actionBefore) {
            moves.visible = false;

            setTimeout(function () {
                moves.visible = true;

                BoostersBeforeHelper.animateAppear(moves, {
                    scale: 0,
                    target: target
                }, onFinish);
            }, MovesView.ANIMATE_COLLECT_DELAY);
            return;
        }

        moves.runAction(new cc.Sequence(
            new cc.DelayTime(MovesView.ANIMATE_COLLECT_DELAY / 1000),
            StandartAnimations.animateCollect(moves, target, {
                scale: 1,
                collectEffect: true
            }),
            new cc.CallFunc(onFinish),
            new cc.RemoveSelf()
        ));
    },

    hide: function () {
        var scene = cleverapps.scenes.getRunningScene();
        var targetPosition = cc.p(this.x, scene.height + this.height + 2);
        this.runAction(new cc.Sequence(
            new cc.MoveTo(0.15, targetPosition).easing(cc.easeIn(2)),
            new cc.Hide()
        ));
    }
});

MovesView.HUMAN_DELAY = 0.4;
MovesView.ANIMATE_COLLECT_DELAY = 0;

cleverapps.styles.UI.MovesView = {
    position: [{
        x: { align: "left", dx: 85 },
        y: { align: "center", dy: 0 }
    }, {
        x: { align: "left", dx: 85 },
        y: { align: "center", dy: 0 }
    }, {
        x: { align: "center", dx: -15 },
        y: { align: "center", dy: 230 }
    }],

    text: {
        margin: { x: 0, y: -5 }
    },

    content: {
        height: 114,
        x: -60,
        y: -60
    },

    scale: [0.8, 1, 1],

    margin: [
        { x: { align: "center", dx: -238 }, y: -100 },
        { x: { align: "center", dx: -238 }, y: -100 },
        { x: { align: "center", dx: -238 }, y: -100 }
    ],

    background: {
        x: 0,
        y: 0
    },

    locked: {
        x: { align: "center" },
        y: { align: "center" }
    },

    slideAnimation: {
        controlsPanel: {
            delay: 3.5
        },
        movesView: {
            offsetY: -200
        }
    },

    lantern: {
        dx: 0,
        dy: 0
    }
};
