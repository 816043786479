/**
 * Created by r4zi4l on 04.05.2022
 */

var Offers = {
    TYPES: {}
};

Offers.TYPES.LIVESFEAST = 1;
Offers.TYPES.SOFTFEAST = 2;
Offers.TYPES.KRAKENFEAST = 3;
Offers.TYPES.BUILDPASS = 4;
Offers.TYPES.SALEPASS = 5;
Offers.TYPES.EXPEDITION = 6;
Offers.TYPES.UNDERSEA = 7;
Offers.TYPES.UNDERSEA2 = 8;
Offers.TYPES.SNAILFEAST_UNDERSEA2 = 9;
Offers.TYPES.HALLOWEEN = 10;
Offers.TYPES.SNAILFEAST = 11;
Offers.TYPES.RAPUNZEL = 12;
Offers.TYPES.XMAS = 13;
Offers.TYPES.RAPUNZEL2 = 14;

Offers.TYPES.EASTER = 16;
Offers.TYPES.DRAGONIA2 = 17;
Offers.TYPES.DRAGONIA3 = 18;
Offers.TYPES.SNAILFEAST_DRAGONIA3 = 19;
Offers.TYPES.CHINA = 20;
Offers.TYPES.CHAIN_SALE = 21;
Offers.TYPES.SUPPLIES_SALE = 22;
Offers.TYPES.PROMOTION_SALE = 23;
Offers.TYPES.RAPUNZEL3 = 24;
Offers.TYPES.CLCHAIN_SALE = 25;
Offers.TYPES.CLSUPPLIES_SALE = 26;
Offers.TYPES.CLPROMOTION_SALE = 27;
Offers.TYPES.PACK = 28;
Offers.TYPES.PIXEL_OFFER = 29;
Offers.TYPES.PERIODIC_PROMOTION_SALE = 30;
Offers.TYPES.CLPASS = 31;
Offers.TYPES.STICKERS_PACK = 32;
Offers.TYPES.SNAILFEAST_UNDERSEA3 = 33;
Offers.TYPES.UNDERSEA3 = 34;
Offers.TYPES.PINBALL_PACK = 35;
Offers.TYPES.SNAILFEAST_HALLOWEEN = 36;
Offers.TYPES.BLACKFRIDAY = 37;
Offers.TYPES.SNAILFEAST_BLACKFRIDAY = 38;
Offers.TYPES.BOOSTER_OFFER = 39;
Offers.TYPES.SNAILFEAST_XMAS = 40;
Offers.TYPES.SNAILFEAST_CHINA = 41;
Offers.TYPES.SPRING = 42;
Offers.TYPES.SNAILFEAST_SPRING = 43;
Offers.TYPES.LAST_PURCHASE = 44;

Offers.initialize = function () {
    Offers[Offers.TYPES.PACK] = {
        name: "Pack",
        cooldown: "1 day",
        logic: PackOfferLogic,
        bundle: "starter_pack",
        sideBarJson: [bundles.sidebar.jsons.pack_icon_json, bundles.sidebar.jsons.pack1_icon_json, bundles.sidebar.jsons.pack2_icon_json],
        view: PackWindow,
        pendingWindow: true,
        availableByStages: [
            {
                level: cleverapps.config.type === "merge" ? 6.2 : 2.65
            }, {
                level: cleverapps.config.type === "merge" ? 8.2 : 4.65
            }, {
                level: cleverapps.config.type === "merge" ? 10.2 : 6.65
            }
        ]
    };

    if (cleverapps.config.features.includes("chainsale")) {
        Offers[Offers.TYPES.CHAIN_SALE] = {
            available: {
                level: 5.3
            },
            name: "ChainSale",
            duration: "3 day",
            cooldown: "14 days",
            logic: ChainSaleLogic,
            bundle: "chain_sale",
            sideBarJson: bundles.sidebar.jsons.chain_sale_json,
            view: ChainSaleWindow,
            pendingWindow: true
        };
    }

    if (cleverapps.config.features.includes("suppliessale")) {
        Offers[Offers.TYPES.SUPPLIES_SALE] = {
            available: {
                level: 5.3,
                debugMode: ["spades"].includes(cleverapps.config.name)
            },
            name: "SuppliesSale",
            cooldown: "30 days",
            logic: SuppliesSaleLogic,
            sideBarJson: bundles.sidebar.jsons.supplies_icon_json,
            view: SuppliesWindow,
            pendingWindow: true
        };
    }

    if (cleverapps.config.features.includes("promotionsale")) {
        Offers[Offers.TYPES.PROMOTION_SALE] = {
            available: {
                level: 4.7,
                debugMode: ["solitaire"].includes(cleverapps.config.type)
            },
            name: "PromotionSale",
            duration: "3 day",
            cooldown: "14 days",
            logic: PromotionSaleLogic,
            bundle: "promotion_sale",
            sideBarJson: bundles.sidebar.jsons.promotion_sale_json,
            view: PromotionSaleWindow,
            pendingWindow: true
        };
    }

    if (cleverapps.config.type === "merge") {
        Offers[Offers.TYPES.LIVESFEAST] = {
            mission: Mission.TYPE_LIVESFEAST,
            price: 250,
            view: SingleProductOfferWindow,
            reward: RewardsConfig.LivesFeast.offer,
            sideBarJson: bundles.sidebar.jsons.lives_feast_offer,
            name: "LivesFeast",
            bundle: "lives_offer"
        };

        Offers[Offers.TYPES.BUILDPASS] = {
            mission: Mission.TYPE_BUILDPASS,
            price: 299,
            reward: RewardsConfig.BuildPass.offer,
            view: SingleProductOfferWindow,
            sideBarJson: bundles.sidebar.jsons.buildpass_offer,
            name: "BuildPass",
            bundle: "buildpass_offer"
        };

        Offers[Offers.TYPES.SALEPASS] = {
            mission: Mission.TYPE_SALEPASS,
            price: 299,
            reward: RewardsConfig.SalePass.offer,
            view: SingleProductOfferWindow,
            sideBarJson: bundles.sidebar.jsons.salepass_offer,
            name: "SalePass",
            bundle: "salepass_offer"
        };

        if (["mbga", "sp_mbga"].indexOf(connector.info.source) === -1) {
            Offers[Offers.TYPES.SOFTFEAST] = {
                mission: Mission.TYPE_SOFTFEAST,
                price: 250,
                reward: RewardsConfig.SoftFeast.offer,
                view: SingleProductOfferWindow,
                sideBarJson: bundles.sidebar.jsons.soft_feast_offer,
                name: "SoftFeast",
                bundle: "soft_offer"
            };
        }

        Offers[Offers.TYPES.KRAKENFEAST] = {
            mission: Mission.TYPE_KRAKENFEAST,
            reward: RewardsConfig.KrakenFeast.offer,
            price: 299,
            view: SingleProductOfferWindow,
            sideBarJson: bundles.sidebar.jsons.kraken_offer,
            name: "KrakenFeast",
            bundle: "kraken_offer"
        };

        Offers[Offers.TYPES.EXPEDITION] = {
            location: "dragonia",
            hero: { code: "dragonpack", stage: 0 },
            product: "expeditionPack",
            force: Forces.OFFER_ICON_SLOT2,
            fog: "fog4",
            reward: RewardsConfig.DragoniaPack,
            view: SingleProductOfferWindow,
            sideBarJson: bundles.sidebar.jsons.dragonia_offer,
            name: "DragoniaPack",
            bundle: "dragonia_offer"
        };

        Offers[Offers.TYPES.SNAILFEAST] = {
            mission: Mission.CompoundTypeFromLocationId(Mission.TYPE_SNAIL_FEAST, "dragonia2"),
            price: 99,
            view: SingleProductOfferWindow,
            sideBarJson: function () {
                return cleverapps.skins.getSlot("snailhouse_offer_json") || bundles.sidebar.jsons.snailhouse_offer_json;
            },
            reward: RewardsConfig.SnailHouseDragoniaPack,
            name: "SnailPack",
            bundle: "snailhouse_offer"
        };

        Offers[Offers.TYPES.PIXEL_OFFER] = {
            available: {
                level: 5.3,
                debugMode: ["hustlemerge", "fairy", "wondermerge"].includes(cleverapps.config.name)
            },
            name: "PixelOffer",
            duration: "2 hours",
            cooldown: "12 hours",
            logic: PixelOfferLogic,
            sideBarJson: bundles.sidebar.jsons.pixel_offer_icon_json,
            view: PixelOfferWindow,
            availableByStages: [{}, {}, {}, {}, {}, {}]
        };

        Offers[Offers.TYPES.CLPASS] = {
            mission: Mission.TYPE_COLLECTIONS_PASS,
            price: 999,
            view: SingleProductOfferWindow,
            reward: RewardsConfig.CollectionsPass.offer,
            sideBarJson: bundles.sidebar.jsons.collections_pass_offer,
            name: "CollectionsPass",
            bundle: "clpass_offer",
            location: "collections",
            cooldown: "48 hours",
            available: {
                debugMode: ["hustlemerge"].includes(cleverapps.config.name)
            }
        };

        if (cleverapps.config.features.includes("chainsale")) {
            Offers[Offers.TYPES.CLCHAIN_SALE] = Object.assign({}, Offers[Offers.TYPES.CHAIN_SALE], {
                name: "ClChainSale",
                reward: RewardsConfig.ClChainSale,
                location: "collections",
                available: {
                    level: false,
                    gameLevel: 5.3
                }
            });
        }
        if (cleverapps.config.features.includes("suppliessale")) {
            Offers[Offers.TYPES.CLSUPPLIES_SALE] = Object.assign({}, Offers[Offers.TYPES.SUPPLIES_SALE], {
                name: "ClSuppliesSale",
                reward: RewardsConfig.ClSupplies.rewards,
                location: "collections",
                available: {
                    level: false,
                    gameLevel: 5.3
                }
            });
        }
        if (cleverapps.config.features.includes("promotionsale")) {
            Offers[Offers.TYPES.CLPROMOTION_SALE] = Object.assign({}, Offers[Offers.TYPES.PROMOTION_SALE], {
                name: "ClPromotionSale",
                reward: RewardsConfig.ClPromotionSale,
                location: "collections",
                available: {
                    level: false,
                    gameLevel: 4.7
                }
            });
            Offers[Offers.TYPES.PERIODIC_PROMOTION_SALE] = {
                name: "PrPromotionSale",
                reward: RewardsConfig.PrPromotionSale,
                feature: "periodic_offer",
                logic: PromotionSaleLogic,
                bundle: "periodic_promotion_offer",
                cooldown: "7 days",
                sideBarJson: bundles.sidebar.jsons.periodic_promotion_sale_json,
                view: PromotionSaleWindow,
                pendingWindow: true,
                available: {
                    level: 4.7,
                    vk: { disabled: true },
                    ok: { disabled: true },
                    mm: { disabled: true },
                    mygames: { disabled: true },
                    yandex: { disabled: true },
                    fotostrana: { disabled: true }
                }
            };
        }

        if (typeof MergeLogic !== "undefined") {
            MergeLogic.INIT_OFFERS();
        }
    }

    if (cleverapps.config.features.includes("pinball")) {
        Offers[Offers.TYPES.PINBALL_PACK] = {
            mission: Mission.TYPE_PINBALL,
            product: "kit3",
            view: SingleProductOfferWindow,
            reward: RewardsConfig.PinballPack,
            sideBarJson: bundles.sidebar.jsons.pinball_offer_json,
            name: "PinballOffer",
            bundle: "pinball_offer"
        };
    }

    if (cleverapps.config.name === "woodenblock") {
        Offers[Offers.TYPES.BOOSTER_OFFER] = {
            sideBarJson: bundles.sidebar.jsons.booster_offer_icon_json,
            name: "BoosterOffer",
            bundle: "booster_offer",
            reward: RewardsConfig.BoosterOffer,
            logic: BoosterOfferLogic,
            view: BoosterOfferWindow
        };
    }

    if (cleverapps.config.features.includes("last_purchase")) {
        Offers[Offers.TYPES.LAST_PURCHASE] = {
            available: {
                level: 7,
                forPayer: true,
                inABExperiments: ABExperiments.HAS_LAST_PURCHASE
            },
            name: "LastPurchase",
            bundle: "last_purchase",
            logic: LastPurchase,
            view: LastPurchaseWindow,
            pendingWindow: true,
            displayWindowPriority: 44,
            cooldown: cleverapps.config.debugMode ? "10 minutes" : "7 days"
        };
    }

    Offers.offersByLocation = {};
    Offers.offerByMission = {};
    Offers.noMissionOffer = {};
    Offers.offerByHero = {};
    Offers.offersByFog = {};

    for (var name in Offers.TYPES) {
        var type = Offers.TYPES[name];
        var offer = Offers[type];

        if (offer) {
            offer.type = type;

            if (offer.location) {
                if (!Offers.offersByLocation[offer.location]) {
                    Offers.offersByLocation[offer.location] = [];
                }

                Offers.offersByLocation[offer.location].push(offer);
            }

            if (offer.mission) {
                Offers.offerByMission[offer.mission] = offer;
            } else {
                Offers.noMissionOffer[offer.name] = offer;
            }

            if (offer.hero) {
                Offers.offerByHero[Unit.GetKey(offer.hero)] = offer;
            }

            if (offer.fog) {
                if (Offers.offersByFog[offer.fog]) {
                    Offers.offersByFog[offer.fog].push(offer);
                } else {
                    Offers.offersByFog[offer.fog] = [offer];
                }
            }
        }
    }
};

if (typeof cc === "undefined") {
    module.exports = Offers;
}
