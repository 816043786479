/**
 * Created by slava on 8/8/18
 */

var RowView = cc.Scale9Sprite.extend({
    ctor: function (row, options) {
        options = options || {};

        this.row = row;
        this.options = options;

        options.prizes = options.prizes !== undefined ? options.prizes : true;
        options.badges = options.badges !== undefined ? options.badges : true;

        row.view = this;

        var styles = cleverapps.styles.RowView;

        this.clickableArea = options.clickableArea;

        var background = this.row.isPlayer() ? bundles.table.frames.row_you_png : bundles.table.frames.row_player_png;
        this._super(background, cleverapps.UI.getScale9Rect(background, cleverapps.UI.Scale9Rect.TwoPixelXY));

        if (this.row.isPlayer() && bundles.table.frames.row_you_frame_png) {
            var bg = this.bg = cleverapps.UI.createScale9Sprite(bundles.table.frames.row_you_frame_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
            bg.setPositionRound({ align: "center" }, { align: "center" });
            bg.setLocalZOrder(1);
            this.addChild(bg);
        }

        this.updatePlace(row.data.place);

        var place = this.createPlace(row.data.place);
        var avatar = this.avatar = this.createAvatar();
        var name = this.name = this.createName();
        name.setPositionRound(styles.name);

        var prize = this.createPrize();

        var score = this.scoreNode = this.createScore();

        cleverapps.tooltipManager.create(score, {
            text: this.options.scoreTooltip || "ResultTooltip",
            location: cleverapps.UI.Tooltip.LOCATION_BELOW,
            clickableArea: this.clickableArea
        });

        var nameWrapper = this.nameWrapper = new cc.Node();
        nameWrapper.setAnchorPoint(0.5, 0.5);
        nameWrapper.addChild(name);
        this.content = new cleverapps.Layout([place, avatar, nameWrapper, prize, score].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL
        });

        if (options.mission && this.row.isPlayer() && cleverapps.config.debugMode) {
            cleverapps.UI.onClick(this, function () {
                options.mission.update(10);
            });
        }

        this.addChild(this.content);
        this.updateSize();
        this.content.setPositionRound(styles.content);
    },

    updateSize: function () {
        var styles = cleverapps.styles.RowView;

        cleverapps.UI.fitToBox(this.avatar, {
            height: styles.content.height,
            maxScale: cleverapps.resolution.isVertical() ? styles.avatar.verticalScale : 1
        });

        if (this.prizeNode) {
            cleverapps.UI.fitToBox(this.prizeNode, {
                width: cleverapps.resolution.isVertical() ? styles.prize.verticalWidth : styles.prize.width,
                height: styles.content.height
            });
        }

        var width = this.options.width || styles.width;
        if (this.options.dynamicWidth) {
            width = Math.max(styles.minWidth, Math.min(width, cleverapps.resolution.getSceneSize().width - styles.scenePadding[cleverapps.resolution.mode] * 2));
        }

        this.setContentSize2(width, styles.height);
        if (this.bg) {
            this.bg.setContentSize2(width, styles.height);
        }
        var margin = cleverapps.resolution.isVertical() ? styles.content.verticalMargin : styles.content.margin;

        var nameWidth = width - 2 * styles.paddingX - [this.placeNode, this.avatar, this.prizeNode, this.scoreNode].filter(Boolean).reduce(function (width, item) {
            return width + item.width * item.scaleX + margin;
        }, 0);

        cleverapps.UI.fitToBox(this.name, {
            width: nameWidth,
            height: styles.name.height
        });

        this.nameWrapper.setContentSize2(nameWidth, styles.height);
        this.content.setOptions({ margin: margin });
    },

    createPlace: function (place) {
        var styles = cleverapps.styles.RowView;

        var placeNode = this.placeNode = new cc.Node();
        placeNode.setAnchorPoint(0.5, 0.5);

        var content;
        var badgeIcon = bundles.table.frames["badge_" + place];
        if (this.options.badges && badgeIcon) {
            content = new cc.Sprite(badgeIcon);
        } else {
            content = cleverapps.UI.generateImageText(
                this.row.data.notInTop ? "-" : place,
                this.row.isPlayer() ? cleverapps.styles.FONTS.ROW_PLAYER_TEXT : cleverapps.styles.FONTS.ROW_TEXT
            );
        }

        placeNode.setContentSize2(
            Math.max(
                this.options.badges && styles.place.withBadge.width || 0,
                styles.place.background ? styles.place.background.width : styles.place.width
            ),
            styles.content.height
        );

        placeNode.addChild(content);
        content.setPositionRound(placeNode.width / 2, placeNode.height / 2);

        return placeNode;
    },

    createAvatar: function () {
        return new cleverapps.UI.Avatar(this.row.data.avatar, {
            userId: this.row.id,
            frame: this.row.isPlayer() ? bundles.table.frames.table_avatar_frame2 : bundles.table.frames.table_avatar_frame,
            styles: cleverapps.styles.RowView.avatar.padding
        });
    },

    createName: function () {
        var userName = cleverapps.UI.cutPlayerName(this.row.data.name
            || typeof this.row.id === "string" && "Player_" + this.row.id.substring(this.row.id.length - 3) || "", 12);

        var font = this.row.isPlayer() ? cleverapps.styles.FONTS.ROW_PLAYER_TEXT : cleverapps.styles.FONTS.ROW_TEXT;

        return cleverapps.UI.generateOnlyText(userName, font);
    },

    createPrize: function () {
        if (!this.options.prizes) {
            return;
        }

        var styles = cleverapps.styles.RowView;

        var width = cleverapps.resolution.isVertical() ? styles.prize.verticalWidth : styles.prize.width;

        var prizeNode = this.prizeNode = new cc.Node();
        prizeNode.setAnchorPoint(0.5, 0.5);
        prizeNode.setContentSize2(width, styles.content.height);

        var prizeIcon = this.prizeIcon = this.row.data.prizeIcon;
        if (prizeIcon) {
            var prize = new cc.Sprite(prizeIcon);
            prizeNode.addChild(prize);
            prize.setPositionRound({ x: { align: "center" }, y: { align: "center" } });

            cleverapps.tooltipManager.create(prizeNode, {
                rewards: this.row.data.prizeRewards,
                text: this.row.getPrizeTooltip(),
                clickableArea: this.clickableArea
            });
        }

        return prizeNode;
    },

    createScore: function () {
        var styles = cleverapps.styles.RowView.score;

        var image = this.row.isPlayer() ? bundles.table.frames.amount_bg_you_png : bundles.table.frames.amount_bg_png;

        var background = new cc.Scale9Sprite(image);

        var icon;

        if (this.options.dataIcon) {
            icon = this.scoreIcon = new cc.Sprite(this.options.dataIcon);
            icon.setPositionRound(styles.icon);
            background.addChild(icon);
        }

        var amount = this.scoreAmount = cleverapps.UI.generateImageText("222", cleverapps.styles.FONTS.ROW_SCORE_TEXT);

        background.addChild(amount);

        background.updateSize = function () {
            background.setContentSize2(cleverapps.resolution.isVertical() ? styles.verticalWidth : styles.width, styles.height);
            amount.setPositionRound((background.width + (icon ? icon.x + icon.width / 4 : 0)) / 2, background.height / 2);
            amount.fitToSize = {
                width: (background.width - (icon ? icon.x + icon.width / 4 : 0)) * 0.9,
                height: background.height * 0.9
            };
            this.updateScore(this.row.score, { silent: true });
        }.bind(this);
        background.updateSize();
        
        return background;
    },

    updatePlace: function (place) {
        if (this.place === place) {
            return;
        }
        this.place = place;

        this.refreshPlace(this.place);
        this.updateLocalZOrder();
    },

    updateLocalZOrder: function () {
        this.setLocalZOrder(this.row.isPlayer() ? 1000 : this.place);
    },

    updateScore: function (score, options) {
        options = options || {};
        var callback = options.callback || function () {};

        if (!this.scoreAmount || this.score === score) {
            callback();
            return;
        }

        this.score = score;

        this.scoreAmount.stopAllActions();
        this.scoreAmount.setString(this.score);
        this.scoreAmount.fitTo(this.scoreAmount.fitToSize.width, this.scoreAmount.fitToSize.height);

        if (options.silent) {
            callback();
            return;
        }

        callback = cleverapps.wait(2, callback);

        this.scoreAmount.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.5),
            new cc.ScaleTo(0.2, 1)
        )).setFinalize(callback);

        if (this.scoreIcon && this.options.dataIconAnimation && this.row.isPlayer()) {
            var animation = new cleverapps.Spine(this.options.dataIconAnimation);
            animation.setAnimation(0, "animation");
            animation.setCompleteListenerRemove(callback);
            animation.setLocalZOrder(100);
            this.addChild(animation);

            var tableView = this.row.table.getView();

            var updatePosition = function () {
                var parent = animation.parent;
                animation.setPosition(parent.convertToNodeSpace(this.scoreIcon.parent.convertToWorldSpace(this.scoreIcon.getPosition())));
                animation.setVisible(cc.rectContainsPoint(cc.rect(0, 0, parent.width, parent.height), animation.getPosition()));
            }.bind(this);

            animation.update = function (dt) {
                cleverapps.Spine.prototype.update.call(this, dt);
                updatePosition();
            }.bind(this);

            animation.scheduleUpdate();
            updatePosition();

            animation.replaceParentSamePlace(tableView, { keepScale: true });

            cleverapps.audio.playSound(this.options.dataIconAnimationSound);
        } else {
            callback();
        }
    },

    updatePrize: function () {
        if (!this.options.prizes || this.prizeIcon === this.row.data.prizeIcon) {
            return;
        }

        var position = this.prizeNode.getPosition();
        this.prizeNode.removeFromParent(true);

        this.prizeNode = this.createPrize();
        this.prizeNode.setPositionRound(position);
        this.content.addChild(this.prizeNode);

        this.prizeNode.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.1),
            new cc.ScaleTo(0.2, 1)
        ));
    },

    refreshPlace: function (place) {
        if (!this.placeNode) {
            return;
        }

        var position = this.placeNode.getPosition();

        this.placeNode.removeFromParent(true);

        this.placeNode = this.createPlace(place);
        this.content.addChild(this.placeNode);
        this.placeNode.setPositionRound(position);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ROW_SCORE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    ROW_PLAYER_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    ROW_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.RowView = {
    width: 700,
    height: 82,
    minWidth: 700,
    scenePadding: [100, 250, 250],

    paddingX: 15,

    content: {
        height: 62,
        margin: 16,
        verticalMargin: 16,
        x: { align: "center" },
        y: { align: "center" }
    },

    place: {
        withBadge: {
            width: 100
        },

        width: 50
    },

    name: {
        height: 50,

        x: { align: "center" },
        y: { align: "center", dy: -3 }
    },

    avatar: {
        padding: {
            x: 6,
            top: 6,
            bottom: 6
        },
        verticalScale: 1
    },

    prize: {
        width: 80,
        verticalWidth: 80
    },

    score: {
        width: 140,
        verticalWidth: 140,
        height: 60,

        icon: {
            x: 10,
            y: { align: "center" }
        }
    }
};