/**
 * Created by andrey on 21.05.2020
 */

var MultiColorBooster = function () {
    BaseBoosterBefore.call(this, cleverapps.Boosters.TYPE_MULTICOLOR);
};

MultiColorBooster.prototype = Object.create(BaseBoosterBefore.prototype);
MultiColorBooster.prototype.constructor = MultiColorBooster;

MultiColorBooster.prototype.getAfterStartActions = function () {
    return function (f) {
        MultiColorBooster.afterStartAction({
            amount: 1,
            booster: this
        }, f);
    }.bind(this);
};

MultiColorBooster.afterStartAction = function (options, f) {
    var amount = options.amount;
    var multicolorsCreated = Game.currentGame.field.addRandomMulticolor(amount, {
        actionBefore: true,
        fromLantern: options.lantern
    });
    if (multicolorsCreated < amount) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BOOSTER_MULTICOLOR_NO_PLACE, { value: amount - multicolorsCreated });
    }

    if (multicolorsCreated > 0 && options.booster) {
        options.booster.execute();
    }

    Game.currentGame.counter.setTimeout(f, 300);
};