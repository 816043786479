/**
 * Created by andrey on 15.01.18.
 */

var SocialSender = function () {
    this.batch = {};

    this.sendBatch = cleverapps.accumulate(
        cleverapps.parseInterval("10 seconds"),
        this._sendBatch.bind(this)
    );
};

SocialSender.prototype.askPermissions = function (options, callback) {
    if (!this.isPushNotifications()) {
        callback(cleverapps.CODE_SUCCEED, true);
        return;
    }

    cleverapps.abExperiments.whenGroupsUpdated(cleverapps.once(function () {
        this._askPermissions(options, callback);
    }.bind(this)));
};

SocialSender.prototype._askPermissions = function (options, callback) {
    if (!SocialSender.CLEVERAPPS_PUSHES()) {
        callback(cleverapps.CODE_SUCCEED, true);
        return;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PUSHES.PERMISSION_WANT);
    console.log("pushes askPermissions start");

    var touchEvent = cc.sys.capabilities.touches ? "touchend" : "click";
    var firstClick = function () {
        window.parent[SocialSender.POST_MESSAGE]({ initServiceWorker: true }, "*");
        cc.game.canvas.removeEventListener(touchEvent, firstClick);
    };
    cc.game.canvas.addEventListener(touchEvent, firstClick);

    var messageHandler = function (event) {
        if (!event.data.initServiceWorker) {
            return;
        }

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PUSHES.PERMISSION_SHOW);
        console.log("pushes askPermissions response", event);

        if (event.data.subscription) {
            this.subscription = event.data.subscription;
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PUSHES.PERMISSION_GOT);
        }

        callback(cleverapps.CODE_SUCCEED, Boolean(this.subscription));

        window.removeEventListener("message", messageHandler);
    }.bind(this);

    window.addEventListener("message", messageHandler, false);
};

SocialSender.prototype._sendBatch = function () {
    if (this.isPushNotifications() && !this.subscription) {
        return;
    }

    var path = cleverapps.config.deployment + "/" + cleverapps.config.name + "-push/sendBatch";
    if (cleverapps.isLocalhost()) {
        path = "http://localhost:" + (cleverapps.config.restPort + 2000) + "/sendBatch";
    }

    var batch = this.batch;

    var data = {
        userId: cleverapps.LocalPushes.GetPushUserId(connector.platform.getUserID(), connector.info.source),
        language: cleverapps.settings.language,
        batch: batch
    };

    if (this.isPushNotifications()) {
        data.userId = cleverapps.LocalPushes.GetPushUserId(this.subscription.endpoint, connector.CLEVERAPPS);
        data.keys = this.subscription.keys;
    }

    this.batch = {};

    console.log("Sending batch - ", batch);
    cleverapps.RestClient.post(path, data, function () {
        console.log("Success push", batch);
    }, function () {
        console.log("Failure push", batch);
    }, {
        fullPath: true
    });
};

SocialSender.prototype.sendPush = function (info, timeout) {
    if (!this.canSend()) {
        return;
    }

    var type = info.code.toUpperCase();

    if (connector.platform.oneOf(connector.YANDEX) && cleverapps.YandexPushesMap[cleverapps.config.name][type] === undefined) {
        return;
    }

    this.batch[info.id] = Math.floor(timeout / 1000);

    this.sendBatch();

    return true;
};

SocialSender.prototype.cancelPush = function (info) {
    if (!this.canSend()) {
        return;
    }

    this.batch[info.id] = 0;

    this.sendBatch();
};

SocialSender.prototype.isPushNotifications = function () {
    return connector.platform.oneOf(connector.CLEVERAPPS);
};

SocialSender.prototype.canSend = function () {
    return !connector.platform.withTmpID() || this.isPushNotifications();
};

SocialSender.CLEVERAPPS_PUSHES = function () {
    return cleverapps.abExperiments.getGroup(ABExperiments.Tests.clpushes) === "A";
};

SocialSender.isAppropriate = function () {
    if (connector.platform.oneOf(connector.YANDEX)) {
        return cleverapps.YandexPushesMap[cleverapps.config.name];
    }

    return connector.platform.oneOf(
        connector.OK,
        connector.FACEBOOK,
        connector.INSTANT,
        connector.KONGREGATE,
        connector.MOBAGE,
        connector.MM,
        connector.VK,
        connector.DISCORD,
        connector.CLEVERAPPS
    );
};

SocialSender.POST_MESSAGE = "postMessage";