/**
 * Created by Ivan on 31.07.2024
 */

var RewardStickerView = cc.Node.extend({
    ctor: function (sticker, stickerCollectView) {
        this._super();
        this.stickerCollectView = stickerCollectView;
        var ViewClass = sticker.getViewClass();
        var stickerView = this.stickerView = new ViewClass(sticker);
        stickerView.setAnchorPoint(0.5, 0.5);

        this.setAnchorPoint(0.5, 0);
        this.setVisible(false);
        this.setCascadeOpacityEnabledRecursively(true);
        this.setContentSize2(stickerView.getContentSize());
        this.addChild(stickerView);
        stickerView.setPositionRound(this.width / 2, this.height / 2);

        if (this.stickerView.sticker.duplicate) {
            var duplicateLabel = this.duplicateLabel = cleverapps.UI.generateOnlyText("Duplicate.Text", cleverapps.styles.FONTS.DUPLICATE_TEXT);
            this.addChild(duplicateLabel);
            duplicateLabel.setPositionRound(cleverapps.styles.StickerView.text);
            duplicateLabel.setVisible(false);
        } else {
            this.stickerView.createBadge("stickers_reward_window");
            this.stickerView.badge.setVisible(false);
        }
    },

    receiveStickerAnimation: function (delay) {
        delay = delay || 0;

        var hoveringOptions = {
            animationTime: cleverapps.Random.random(4, 8),
            leftAngle: cleverapps.Random.random(2, 12),
            rightAngle: cleverapps.Random.random(2, 12)
        };

        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(delay),
            StandartAnimations.showUp(this),
            new cc.CallFunc(function () {
                this.stickerView.ray = StandartAnimations.rays(this.stickerView, { skin: "blue" });
                this.stickerView.ray.setLocalZOrder(-1);
                this.stickerView.ray.replaceParentSamePlace(this.getParent(), {
                    keepScale: true
                });
            }.bind(this)),
            new cc.Spawn(
                new cc.CallFunc(function () {
                    this.duplicateLabel ? this.showDuplicate() : this.showBadge();
                }.bind(this)),
                new cc.DelayTime(0.4)
            ),
            new cc.DelayTime(Math.random() * 0.5),
            StandartAnimations.hovering(this, hoveringOptions)
        ));
    },

    collectStickerAnimation: function () {
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.1, 1.1),
            new cc.ScaleTo(0.1, 0.7),
            new cc.CallFunc(function () {
                this.stickerView.ray && this.stickerView.ray.removeFromParent();
            }.bind(this)),
            StandartAnimations.animateCollect(this.stickerView, "stickersBook", {
                adjustToTarget: true,
                collectEffect: true
            }),
            new cc.RemoveSelf()
        ));
    },

    showDuplicate: function () {
        this.duplicateLabel.runAction(new cc.Sequence(
            new cc.ScaleTo(0, 2),
            new cc.Show(),
            new cc.ScaleTo(0.4, 1).easing(cc.easeBackOut())
        ));
    },

    showBadge: function () {
        this.stickerView.badge.runAction(new cc.Sequence(
            new cc.ScaleTo(0, 3),
            new cc.Show(),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.stickers_reward_window.urls.sticker_new_effect);
            }),
            new cc.ScaleTo(0.2, 1).easing(cc.easeBackOut()),
            new cc.DelayTime(0.2)
        ));
    }
});

cleverapps.styles.RewardStickerView = {
    collectAnimation: {
        scale: 0.7
    }
};