/**
 * Created by Vladislav on 18.09.2024.
 */

cleverapps.createDebugGlobalObjects = function () {
    cleverapps.snapshots = new Snapshots();

    cleverapps.git = new GitManager();
    cleverapps.git.load();

    cleverapps.snapshotBar = new SnapshotBar();

    cleverapps.administrator = new Administrator();

    if (typeof CardBlockHandler !== "undefined") {
        cleverapps.cardBlockHandler = new CardBlockHandler();
    }
    cleverapps.toolModel = new ToolModel();
    cleverapps.sceneDebugger = new SceneDebugger();
    cleverapps.shortcuts = new Shortcuts();
    cleverapps.drawCallsDebugger = new DrawCallsDebugger();
    cleverapps.debugSlider = new DebugSlider();
    cleverapps.debugStats = new DebugStats();

    cleverapps.timeScaleManager = new TimeScaleManager();

    MinimalDialogueDebug.initialize();
};