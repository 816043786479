/**
 * Created by Aleksandr on 15.09.2022.
 */

var ClipPreviewBar = function (prefabPlayer) {
    this.prefabPlayer = prefabPlayer;

    this.clipProps = this.prefabPlayer.options.clipConfig.properties;

    this.onHide = function () {};
    this.onShow = function () {};
    this.onResetTimer = function () {};
    this.onStopTimer = function () {};
};

ClipPreviewBar.prototype.replay = function () {
    this.prefabPlayer.reloadSnapshot();
};

ClipPreviewBar.prototype.download = function (downloadActions) {
    if (cleverapps.wysiwyg.directory === "wysiwyg/sidebar") {
        this.exportOffersAndMissions();
        return;
    }

    var player = new ActionPlayer();

    player.add(function (f) {
        cleverapps.loadSdk("//cdnjs.cloudflare.com/ajax/libs/jszip/3.10.1/jszip.min.js", {
            onSuccess: f
        });
    });

    downloadActions.forEach(function (action, index) {
        player.add(function (f) {
            var needReload = !this.prefabPlayer.screenshotParameters || index === 0;

            if (this.prefabPlayer.options.language !== action.language) {
                needReload = true;
                cleverapps.settings.setLanguage(action.language);
                cleverapps.settings.updateDependents();
            }

            cleverapps.setUrlHash({ res: action.resolution, lang: action.language });
            this.prefabPlayer.options.download = true;
            this.prefabPlayer.options.language = action.language;
            this.prefabPlayer.options.resolutionName = action.resolution;
            this.prefabPlayer.options.onFinish = f;

            if (needReload) {
                this.prefabPlayer.reloadSnapshot();
            } else {
                this.prefabPlayer.run();
            }
        }.bind(this));
    }.bind(this));

    player.add(function (f) {
        this.prefabPlayer.completeDownload(function () {
            f();
            window.location.reload();
        });
    }.bind(this));

    player.play();
};

ClipPreviewBar.prototype.exportOffersAndMissions = function () {
    var player = new ActionPlayer();

    player.add(function (f) {
        cleverapps.loadSdk("//cdnjs.cloudflare.com/ajax/libs/jszip/3.10.1/jszip.min.js", {
            onSuccess: f
        });
    });

    cc.director.setClearColor(new cc.Color(0, 0, 0, 0));
    cleverapps.skins.active = [];
    cleverapps.skins.active.push(cleverapps.SkinManager.SKINS.undersea);
    cleverapps.skins.active.push(cleverapps.SkinManager.SKINS["undersea_expedition"]);

    var processOfferOrMission = function (offer, isOffer) {
        var spine = Array.isArray(offer.sideBarJson) ? offer.sideBarJson[0] : offer.sideBarJson;
        if (typeof spine === "function") {
            spine = spine();
        }

        if (!spine && offer.sideBarIcon) {
            spine = offer.sideBarIcon;
        }

        if (spine && offer.name) {
            player.add(function (f) {
                this.prefabPlayer.caption = (isOffer ? offer.name + "_offer" : offer.name + "_mission").toLowerCase();
                this.prefabPlayer.options.download = true;
                this.prefabPlayer.options.language = "en";
                this.prefabPlayer.options.resolutionName = "square_200x200";
                this.prefabPlayer.options.onFinish = f;

                this.prefabPlayer.run();

                if (this.icon) {
                    this.icon.removeFromParent();
                }

                this.icon = new cleverapps.Spine(spine);
                this.icon.setAnimation(0, "animation", false);
                this.prefabPlayer.prefabNodes[0].addChild(this.icon);
                this.icon.setPositionRound(this.prefabPlayer.prefabNodes[0].width / 2, this.prefabPlayer.prefabNodes[0].height / 2);
            }.bind(this));
        }
    }.bind(this);

    Object.values(Offers).forEach(function (offer) {
        processOfferOrMission(offer, true);
    });

    Object.values(Missions).forEach(function (offer) {
        processOfferOrMission(offer);
    });

    player.add(function (f) {
        this.prefabPlayer.completeDownload(function () {
            f();
            window.location.reload();
        });
    }.bind(this));

    player.play();
};

ClipPreviewBar.prototype.edit = function () {
    cleverapps.setUrlHash({ wysiwyg: true, snapshot: undefined });
    this.prefabPlayer.options.snapshot = undefined;
    window.location.reload();
};

ClipPreviewBar.prototype.changeLanguage = function (language) {
    cleverapps.setUrlHash({ lang: language });
    this.prefabPlayer.options.language = language;
    cleverapps.settings.setLanguage(language);
    cleverapps.settings.updateDependents();
    this.prefabPlayer.reloadSnapshot();
};

ClipPreviewBar.prototype.changeResolution = function (resolution) {
    cleverapps.setUrlHash({ res: resolution });
    this.prefabPlayer.options.resolutionName = resolution;

    if (this.prefabPlayer.screenshotParameters) {
        this.prefabPlayer.run();
    } else {
        this.prefabPlayer.reloadSnapshot();
    }
};

ClipPreviewBar.prototype.resetTimer = function () {
    this.onResetTimer();
};

ClipPreviewBar.prototype.stopTimer = function () {
    this.onStopTimer();
};