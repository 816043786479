/**
 * Created by slava on 24.03.17.
 */

var ForceView = CleverappsWindow.extend({
    ctor: function (element, force, options) {
        this.forceConfig = force;
        this.options = options || {};
        this.force = force;
        this.element = element;

        this.actives = this.options.actives || [];
        if (element !== ForceView.NO_ELEMENT) {
            this.actives.push(element);
        }

        this.highlights = (this.options.highlights || []).concat(this.actives);

        this._super(element, force);
    },

    getEventNodes: function () {
        return this.actives;
    },

    afterResize: function () {
        if (this.text) {
            this.createDialogue();
        }
    },

    upNodesForForce: function () {
        this.highlights.forEach(function (node) {
            node._setGlobalZOrder(true);
        }, this);
    },

    backNodesFromForce: function () {
        this.highlights.forEach(function (node) {
            node._setGlobalZOrder(false);
        }, this);
    },

    listRects: function () {
        var rects = [];
        if (this.options.importantNodes) {
            this.options.importantNodes.forEach(function (node) {
                rects.push(node.getSceneBoundingBox());
            });
        }

        if (this.pointer) {
            rects.push(this.pointer.getSceneBoundingBox());
        }

        if (this.finger) {
            rects.push(this.finger.getOverlapNode().getSceneBoundingBox());
        }

        this.actives.forEach(function (active) {
            rects.push(active.getSceneBoundingBox());
        });

        return rects;
    },

    onWindowLoaded: function (element, force) {
        this.id = force.id;

        this._super({
            content: new cc.Node(),
            noBackground: true,
            name: "forcewindow" + this.id,
            closeButton: false,
            styles: this.options.styles ? this.options.styles : cleverapps.styles.Force
        });

        if (force.controls) {
            cleverapps.focusManager.showControlsWhileFocused(force.controls, true);
        }

        this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER + 9);

        cleverapps.toolbar.freezeUpdates();

        this.element = element;

        if (element !== ForceView.NO_ELEMENT && force.finger !== false) {
            var fingerStyle = force.finger || {};
            if (typeof fingerStyle === "function") {
                fingerStyle = fingerStyle();
            }
            if (fingerStyle.mobile && cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                fingerStyle = fingerStyle.mobile;
            }

            var fingerDelay = fingerStyle.delay;

            if (fingerStyle.path) {
                this.finger = FingerView.hintDrag(fingerStyle.path, fingerStyle);
            } else if (fingerStyle.swipe) {
                this.finger = FingerView.hintSwipe(fingerStyle.swipe[0], fingerStyle.swipe[1], fingerStyle);
            } else {
                this.finger = FingerView.hintTap(fingerStyle.tap || element, {
                    delay: fingerDelay,
                    repeatDelay: fingerDelay
                });
            }
        }
        if (element !== ForceView.NO_ELEMENT && force.pointer) {
            this.pointer = PointerView.create({
                target: element,
                direction: this.options.pointerDirection
            });
            this.highlights.push(this.pointer);
        }
        this.upNodesForForce();
        this.text = typeof (force.text) === "function" ? force.text(force, element) : force.text;

        if (element === ForceView.NO_ELEMENT || this.forceConfig.closeByTouchInShadow) {
            this.addCloseByTouchInShadow();
        }

        if (element !== ForceView.NO_ELEMENT) {
            this.watchElementInterval = cleverapps.timeouts.setInterval(function () {
                // console.log('watchElementInterval', this.element.parent)
                if (!this.element.parent || !this.element.visible || this.forceConfig.timeout) {
                    if (cleverapps.forces.isRunningForce()) {
                        cleverapps.forces.closeRunningForce();
                    } else {
                        this.close();
                        cleverapps.forces.clearForce(this.id);
                    }
                }
            }.bind(this), this.forceConfig.timeout || 5000);
        }

        if (this.text) {
            this.createDialogue();
        }
    },

    createDialogue: function () {
        if (this.dialogue) {
            this.dialogue.removeFromParent();
        }

        this.dialogue = new MinimalDialogue({
            text: this.text,
            person: this.force.person,
            targetRect: this.element && this.element !== ForceView.NO_ELEMENT && this.element.getSceneBoundingBox(),
            rects: this.listRects(),
            position: typeof (this.force.position) === "function" ? this.force.position() : this.force.position
        });
        this.dialogue._globbbb = true;
        this.addChild(this.dialogue);
        this.dialogue.display();
    },

    onClose: function () {
        if (this.watchElementInterval) {
            cleverapps.timeouts.clearInterval(this.watchElementInterval);
        }
        this.backNodesFromForce();

        FingerView.remove(this.finger);
        PointerView.remove(this.pointer);
        this.pointer = undefined;
        this.finger = undefined;

        cleverapps.toolbar.unfreezeUpdates();
    },

    addCloseByTouchInShadow: function () {
        this.clickArea = new cc.Node();
        this.clickArea.alwaysOn = true;
        this.clickArea.setContentSize(this.getContentSize());
        this.addChild(this.clickArea);

        cleverapps.UI.onClick(this.clickArea, function () {
            if (cleverapps.forces.isRunningForce()) {
                cleverapps.forces.closeRunningForce();
            }
        }, {
            interactiveScale: false
        });
    },

    stopFinger: function () {
        if (this.finger) {
            this.finger.stopPlaying();
        }
    },

    resumeFinger: function () {
        if (this.finger) {
            this.finger.resumePlaying();
        }
    },

    hideForceHints: function () {
        if (this.dialogue) {
            this.dialogue.removeFromParent();
            this.dialogue = undefined;
        }

        if (this.pointer) {
            PointerView.remove(this.pointer);
            this.pointer = undefined;
        }

        if (this.finger) {
            FingerView.remove(this.finger);
            this.finger = undefined;
        }

        cleverapps.focusManager.setEventNodes([]);
    },

    stop: function () {
    },

    inflateWindow: function () {

    }
});

cleverapps.styles.Force = {
    windowShowUpAnimation: {
        name: "instant",
        force: true
    }
};

ForceView.NO_ELEMENT = "emptyForceElement";
