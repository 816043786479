/**
 * Created by evgenijsenkevic on 25.12.2024
 */

var FriendView = cc.Node.extend({
    ctor: function (friend) {
        this._super();

        this.friend = friend;
        this.styles = cleverapps.styles.FriendView;
        this.setContentSize(this.styles.width, this.styles.height);
        this.setAnchorPoint(0.5, 0.5);

        this.createBackground();
        this.createFriendContent();
        this.createSendButton();
    },

    createBackground: function () {
        var bg = cleverapps.UI.createScale9Sprite(bundles.request_center.frames.friend_message_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(this.width, this.height);
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg);
    },

    createFriendContent: function () {
        var avatar = new cleverapps.UI.Avatar(this.friend);
        avatar.setPositionRound(this.styles.avatar.position);
        this.addChild(avatar);

        var name = cleverapps.UI.generateOnlyText(this.friend.name, cleverapps.styles.FONTS.FRIEND_NAME);
        name.setPositionRound(this.styles.name.position);
        this.addChild(name);
    },

    processSendButton: function () {
        levels.FriendRequests.sendGift(this.friend.id);
    },

    createSendButton: function () {
        var sendButton = new cleverapps.UI.Button({
            onClicked: this.processSendButton.bind(this),
            type: cleverapps.styles.UI.Button.Images.small_button_green,
            text: "send coins",
            width: this.styles.sendButton.width,
            height: this.styles.sendButton.height
        });

        sendButton.setPositionRound(this.styles.sendButton.position);
        this.addChild(sendButton);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FRIEND_NAME: {
        size: 35,
        color: cleverapps.styles.COLORS.COLOR_BROWN,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.FriendView = {
    width: 490,
    height: 190,

    avatar: {
        position: {
            x: { align: "left", dx: 40 },
            y: { align: "top", dy: -30 }
        }
    },

    name: {
        position: {
            x: { align: "center" },
            y: { align: "top", dy: -30 }
        }
    },

    sendButton: {
        width: 170,
        position: {
            x: { align: "right", dx: -40 },
            y: { align: "bottom", dy: 40 }
        }
    }
};